<template>
  <div class="cbr-split-panel">
    <div
      ref="first"
      class="cbr-split-panel__first"
      :style="stylesFirst"
    >
      <slot name="first" />
    </div>
    <div
      id="split-pane-divider"
      ref="divider"
      class="cbr-split-panel__divider"
    />
    <div
      ref="second"
      class="cbr-split-panel__second"
      :style="stylesSecond"
    >
      <slot name="second" />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'

const props = defineProps({
  initFirstSize: {
    type: Number,
    default: () => 350
  },
  firstMinSize: {
    type: Number,
    default: () => 200
  }
})

const firstSize = ref(props.initFirstSize)
const startMoveFirstSize = ref(null)
const startMoveScreenPos = ref(null)

const stylesFirst = computed(() => {
  return {
    height: `${firstSize.value}px`
  }
})
const stylesSecond = computed(() => {
  return {
    height: `calc(100% - ${firstSize.value}px - 5px)`,
    'max-height': `calc(100% - ${firstSize.value}px - 5px)`,
  }
})

const onTouchStart = (e) => {
  if (e.target.id === 'split-pane-divider') {
    startMoveFirstSize.value = firstSize.value
    startMoveScreenPos.value = e.screenY
  }
}
const onTouchEnd = () => {
  startMoveFirstSize.value = null
}
const onTouchMove = (e) => {
  if (startMoveFirstSize.value) {
    firstSize.value = startMoveFirstSize.value + e.screenY - startMoveScreenPos.value
  }
}

onMounted(() => {
  document.addEventListener('mousemove', onTouchMove)
  document.addEventListener('mousedown', onTouchStart)
  document.addEventListener('mouseup', onTouchEnd)
})
onUnmounted(() => {
  document.removeEventListener('mousemove', onTouchMove)
  document.removeEventListener('mousedown', onTouchStart)
  document.removeEventListener('mouseup', onTouchEnd)
})
</script>

<style scoped lang="scss">
.cbr-split-panel {
  width: 100%;
  height: 100%;
  &__first, &__second {
    overflow-y: hidden;
  }
  &__divider {
    height: 5px;
    background: linear-gradient(90deg, rgba($ActionColor, 0) 0%, rgba($ActionColor, 0.5) 21%, rgba($ActionColor, 0.5) 76.5%, rgba($ActionColor, 0) 100%);
    width: 100%;
    cursor: row-resize;
  }
}
.divider {

}
</style>
