
const state = {
  icons: [],
  components: [],
  selected: false,
  states: ['StateOff', 'StateOn', 'StateWarning', 'StateCritical'],
  statesName: {
    StateOff: 'properties_panel_object_btn_state_off',
    StateOn: 'properties_panel_object_btn_state_on',
    StateWarning: 'properties_panel_object_btn_state_warning',
    StateCritical: 'properties_panel_object_btn_state_critical'
  },
  effects: ['AttackDown', 'ProgressbarYellow', 'ProgressbarRed', 'NetworkScanning', 'VulnerabilitiesScanning'],
  effectsName: {
    AttackDown: 'properties_panel_object_btn_attack_down',
    Lose: 'properties_panel_properties_scene_btn_lose',
    ProgressbarYellow: 'properties_panel_properties_scene_btn_progressbar_yellow',
    ProgressbarRed: 'properties_panel_properties_scene_btn_progressbar_red',
    NetworkScanning: 'properties_panel_object_btn_network_scanning',
    VulnerabilitiesScanning: 'properties_panel_object_btn_vulnerabilities_scanning'
  },
  gifEffects: [],
  gifEffectsData: [],
  sceneEffects: ['Lose', 'ProgressbarRed', 'ProgressbarYellow'],
  sceneEffectsName: {
    Lose: 'properties_panel_properties_scene_btn_lose',
    ProgressbarRed: 'properties_panel_object_btn_progressbar_red',
    ProgressbarYellow: 'properties_panel_object_btn_progressbar_yellow'
  },
  lineEffects: ['UserSettings', 'WarningNetworkScan', 'AdditionalScanBlue', 'CriticalSuccessfulAtack', 'AdditionalScanGreen'],
  lineEffectsName: {
    UserSettings: 'properties_panel_object_btn_user_settings',
    WarningNetworkScan: 'properties_panel_object_btn_warning_network_scan',
    AdditionalScanBlue: 'properties_panel_object_btn_addtitional_scan_blue',
    CriticalSuccessfulAtack: 'properties_panel_object_btn_critical_succesful_attack',
    AdditionalScanGreen: 'properties_panel_object_btn_addtitional_scan_green'
  },
  tools: {},
  players: [],
  playerGame: null,
  switchBaseObj: false,
  switchToJson: false,
  base: {
    round: 0,
    phaseRound: 0,
    vector: '',
    phase: '',
    attack: '',
    checker: '',
    attackStates: {},
    checkerStates: {},
    noiseAttackStates: {},
    botStates: {},
    gameRunning: true,
    baseId: Math.floor(Math.random() * 100),
    stage: 0,
    active: false
  },
  baseItems: [],
  triggers: {
    dialogShown: false
  },
  doubleIds: [],
  selectedAll: {
    essence: [],
    lines: [],
    planes: [],
    textFields: [],
  },
  showContextMenu: false,
  contextMenuItems: {
    copy: false,
    paste: false,
  },
  copiesTriggers: {},
  copyObjects: {},
  selectedPoint: false,
  copy: false,
  paste: false,
  block: true,
  delBtn: false,
  clickCoords: {},
  elemTarget: null,
  defaultBase: {
    round: 0,
    phaseRound: 0,
    vector: '',
    phase: '',
    attack: '',
    checker: '',
    attackStates: {},
    checkerStates: {},
    noiseAttackStates: {},
    botStates: {},
    gameRunning: true,
    baseId: Math.floor(Math.random() * 100),
    stage: 0,
    active: false
  },
  defaultStateItems: {
    essence: [],
    lines: [],
    planes: [],
    textFields: [],
    scene: []
  }
}

export default state
