<template>
  <div id="components-panel" class="library" :class="isLeftPanelFixed ? 'locked' : null">
    <div class="library-title">
      <span>{{ $t('panel_objects_hdr_library') }}</span>
      <CbrHint
        :description="$t(isLeftPanelFixed ? 'panel_objects_btn_unlock_panel' : 'panel_objects_btn_lock_panel')"
        bottom
        no-icon
      >
        <div @click="lockPanel">
          <CbrSwitch
            :value="isLeftPanelFixed"
            icon-on="mdi-pin-outline"
            icon-off="mdi-pin-off-outline"
            fade
          />
        </div>
      </CbrHint>
    </div>
    <div class="library-content">
      <div class="d-flex">
        <CbrCombobox
          v-model="category"
          :items="categories"
          :preselect="category"
          hide-details
          select-only
          size="small"
        />
        <CbrHint :title="addComponentHint" right>
          <CbrButton icon="$toolsLibPlus" size="small" class="ml-2" @click="addComponent" />
        </CbrHint>
      </div>
      <CbrTextField
        v-model="search"
        :placeholder="$t('panel_objects_edit_search')"
        hide-details
        search
        small
      />
      <template v-if="props.state.isLoaded && (foundIcons.length || foundComponents.length)">
        <hr class="library-content__line" />
        <div v-if="category.value === 'objects'" class="library-objects">
          <CbrHint
            v-for="component in foundComponents"
            :key="component.name"
            :description="component.name"
            no-icon
            right
          >
            <button @click="addItem(component.name)">
              <img :src="component.stateOn" />
            </button>
          </CbrHint>
        </div>
        <div v-else class="library-objects">
          <CbrHint v-for="icon in foundIcons" :key="icon.name" :description="icon.name" no-icon right>
            <button @click="addIcon(icon)">
              <img :src="icon.image" />
            </button>
          </CbrHint>
        </div>
      </template>
      <div
        v-else-if="props.state.isLoaded"
        class="library-content__no-items"
      >
        <p class="library-content__no-items-text">{{ i18n.t('panel_objects_lbl_nothing_found') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { constructor } from '@/plugins/map/constructor/player'
import { i18n } from '@/i18n-setup'
import { useStore } from '@/store'

const store = useStore()
const emit = defineEmits(['addComp', 'addIcon'])

const props = defineProps({
  // loading state
  state: {
    type: Object,
    required: true,
  }
})

const isLeftPanelFixed = ref(false)
const search = ref('')
const categories = ref([
  {
    name: i18n.t('panel_objects_btn_object'),
    value: 'objects'
  },
  {
    name: i18n.t('panel_objects_btn_icons'),
    value: 'icons'
  }
])
const category = ref(categories.value[0])

const components = computed(() => store.getters.components)
const icons = computed(() => store.getters.icons)
const selected = computed(() => store.getters.selected)
const tools = computed(() => store.getters.tools)

const foundComponents = computed(() => {
  return components.value.filter(component =>
    component.name.toLowerCase().indexOf(search.value.toLowerCase()) !== -1 &&
    !Object.values(component).includes(undefined)
  )
})
const foundIcons = computed(() => icons.value.filter(icon =>
  icon.name.toLowerCase().indexOf(search.value.toLowerCase()) !== -1))

const addItem = (name) => tools.value.AddItem(name)
const addIcon = (icon) => tools.value.AddIcon(constructor, selected.value, icon)

const addComponent = () => {
  if (category.value.value === 'icons') {
    emit('addIcon', { name: 'addIcon', isOpen: true })
  } else {
    emit('addComp', { name: 'addComp', isOpen: true })
  }
}
const addComponentHint = computed(() => {
  return i18n.t(category.value.value === 'icons'
    ? 'main_menu_btn_add_new_icon_library'
    : 'main_menu_btn_add_new_component_to_library')
})
isLeftPanelFixed.value = JSON.parse(localStorage.getItem('tools-config'))?.isLeftPanelFixed || false
const lockPanel = () => {
  isLeftPanelFixed.value = !isLeftPanelFixed.value
  localStorage.setItem(
    'tools-config',
    JSON.stringify(
      Object.assign(JSON.parse(localStorage.getItem('tools-config')) || {}, {
        isLeftPanelFixed: isLeftPanelFixed.value
      })
    )
  )
}
</script>

<style lang="scss" scoped>
.library {
  @include cut-corners(7px, true, 7px, false);
  transition: all ease 0.3s;
  overflow: hidden;
  position: absolute;
  backdrop-filter: blur(12.5px);
  height: calc(100vh - 94px);
  width: 224px;
  left: -150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 16px 0;
  padding-left: 8px;
  z-index: 98;
  &-title {
    @include cut-corners(7px, true, 5px, false);
    // TODO replace with var
    background-color: rgba(#D9D9D9, 0.15);
    color: $base-color-text;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px 0 12px;
    height: 36px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100% - 36px);
    padding: 16px 16px 8px;
    gap: 16px;
    background: rgba($base-color-light, 0.05);
    backdrop-filter: blur(12.5px);
    &__line {
      border: 1px solid rgba($base-color-light, 0.15);
      border-width: 1px 0 0 0;
    }
    &__no-items {
      margin-top: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__no-items-text {
      margin: 0;
      color: rgba($StaticLight, .5);
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
    }
  }
  &-objects {
    @include scrollbar-medium;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    button {
      width: 64px;
      height: 64px;
      img {
        max-width: 64px;
        max-height: 64px;
      }
    }
  }
  &.locked {
    left: 0;
  }
  &:hover {
    left: 0;
  }
}
</style>
