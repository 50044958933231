const handleFirstOpenMenuWrapper = (selectElement) => {
  return () => {
    let menu = null
    let isTopMenu = false
    // Верхнее или нижнее меню
    if (selectElement.closest('.table-footer-top')) {
      isTopMenu = true
    }
    // Одно меню может быть уже открыто
    document.querySelectorAll('.menuable__content__active').forEach(i => {
      if (!i.closest('pagination-select')) {
        menu = i
      }
    })
    setTimeout(() => {
      menu.remove()
      let elem = document.createElement('div')
      let wrapper = document.createElement('div')
      let scrollBlock = selectElement.closest('.scrollable-block')
      elem.style.position = 'relative'
      menu.style.position = 'sticky'
      wrapper.style.position = 'absolute'
      // wrapper.style.left = '0px'
      elem.style['min-height'] = '1px'
      wrapper.style['min-width'] = '68px'
      wrapper.classList.add('pagination-select')
      wrapper.appendChild(menu)
      elem.appendChild(wrapper)
      if (!scrollBlock) {
        menu.style['min-width'] = '68px'
        if (isTopMenu) {
          wrapper.classList.add('pagination-select-top')
        } else {
          wrapper.classList.add('pagination-select-bottom')
        }
        if (isTopMenu) {
          selectElement.prepend(elem)
        } else {
          selectElement.append(elem)
        }
      } else {
        selectElement.closest('.scrollable-block').prepend(elem)
        const resizeObserver = new ResizeObserver(entries => {
          const menuH = entries[0].target.clientHeight
          const elRect = selectElement.getBoundingClientRect()
          const prntTop = scrollBlock.getBoundingClientRect().top
          const scrollTop = scrollBlock.scrollTop
          elem.style.top = ((elRect.top + elRect.height + menuH < window.innerHeight || elRect.top - prntTop < menuH - 10
            ? (elRect.top + elRect.height - prntTop) : (elRect.top - prntTop - menuH)) + scrollTop - 10) + 'px'
          elem.style.left = (elRect.left - 20) + 'px'
          menu.style.left = (elRect.left - 20) + 'px'
        })
        // start observing a DOM node
        resizeObserver.observe(menu)
      }
    }, 0)
  }
}

const addSelectClickHandler = (container) => {
  container.querySelectorAll('.v-input__control').forEach(i => {
    i.addEventListener('click', handleFirstOpenMenuWrapper(i), { once: true })
  })
}

export default addSelectClickHandler
