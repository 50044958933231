export const scoreboardRu = {
  scoreboard_standalone: {
    lbl: {
      logo: 'Кибермир',
      results_table: 'Таблица результатов',
      sorting_by_score: 'Сортировка по баллам',
      distribution_in_two_columns: 'Распределение в 2 колонки',
      team: 'Команда', // newIdLoc
      image: 'Изображение',
      drag_and_drop: 'или перетащите сюда',
      scores: 'Баллы',
      adjustment: 'Коррекция', // newIdLoc
      total: 'Итого'
    },
    btn: {
      add: 'Добавить',
      clear_all: 'Очистить все',
      publish: 'Опубликовать',
      download_in_Excel: 'Скачать .XLSX', // newIdLoc
      select_file: 'Выберите файл'
    },
    link: {
      link_on_scoreboard: 'Ссылка на скорборд',
      update: 'Обновить ссылку'
    },
    hint: {
      update_link: 'Обновление ссылки',
      select_file: {
        incorrect_file_format: 'Некорректный формат (выберите .png)',
        incorrect_file_size: 'Файл не должен превышать 50Мб'
      },
      err_invalid: 'Значение должно быть целым от {min} до {max} (включительно)'
    },
    edit: {
      event_name: 'Введите название мероприятия' // newIdLoc
    },
    txt: {
      no_data: 'Добавьте команды для отображения в таблице результатов' // newIdLoc
    }
  },
  scoreboard_standalone_publish_result: {
    lbl: {
      question: 'Вы действительно хотите опубликовать результаты?',
    },
    btn: {
      cancel: 'Отменить', // newIdLoc
      publish: 'Опубликовать' // newIdLoc
    },
    hdr: {
      header: 'Публикация результатов',
    },
  },
  scoreboard_standalone_delete_team: {
    lbl: {
      question: 'Вы действительно хотите удалить команду?',
    },
    btn: {
      cancel: 'Отменить', // newIdLoc
      delete: 'Удалить' // newIdLoc
    },
    hdr: {
      header: 'Удаление команды',
    },
  },
  scoreboard_standalone_clear_data: {
    lbl: {
      question: 'Вы действительно хотите очистить все данные?',
    },
    btn: {
      cancel: 'Отменить', // newIdLoc
      clear: 'Очистить' // newIdLoc
    },
    hdr: {
      header: 'Очистка данных',
    },
  },
  scoreboard_standalone_update_link: {
    lbl: {
      question: 'Вы действительно хотите обновить ссылку на табло результатов?',
    },
    btn: {
      cancel: 'Отменить', // newIdLoc
      refresh: 'Обновить' // newIdLoc
    },
    hdr: {
      header: 'Обновление ссылки',
    },
  },
}
