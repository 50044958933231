<template>
  <div class="color-picker">
    <v-menu
      :close-on-content-click="false"
      :disabled="props.disabled"
      v-model="isColorPickerShown"
      max-width="380"
      offset-y="offset-y"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="color-preview"
          v-bind="attrs"
          v-on="on"
          :class="{ 'color-preview--stroke': props.stroke, 'color-preview--line': props.line }"
          :style="{ backgroundColor: value }"
        ></div>
      </template>
      <div class="cbr-color-picker">
        <div class="cbr-color-picker__header">
          <span>Выбор цвета</span>
          <div @click="isColorPickerShown = false" class="cbr-color-picker__header-close">
            <Icon icon-name="close_modal" width="32px" height="32px" />
          </div>
        </div>
        <v-color-picker
          @input="$emit('input', generateColor($event))"
          :value="value"
          :mode.sync="mode"
          dot-size="25"
          swatches-max-height="200"
          canvas-height="85"
          width="380"
        >
        </v-color-picker>
        <div class="cbr-color-picker__mode-container">
          <CbrCombobox
            v-model="mode"
            :preselect="mode"
            :items="modes"
          />
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script setup>
import MainHelper from '@/helpers/main.helper.js'
import { ref } from 'vue'
const props = defineProps({
  value: {
    required: true
  },
  disabled: {
    default: false,
    type: Boolean
  },
  stroke: {
    default: false,
    type: Boolean
  },
  line: {
    default: false,
    type: Boolean
  }
})
const isColorPickerShown = ref(false)
const generateColor = (color) => MainHelper.generateColorString(color)

const modes = ref(['hsla', 'rgba', 'hexa'])
const mode = ref('rgba')
</script>

<style lang="scss" scoped>
.cbr-color-picker {
  display: block;
  width: 380px;
  height: 240px;
  overflow-y: hidden;
  background: $base-color-back;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $base-color-text;
    height: 32px;
    padding: 14px 0px 14px 16px;
  }

  &__header-close {
    margin-top: 3px;
    cursor: pointer;
  }

  &__mode-container {
    position: relative;
    width: 95px;
    bottom: 50px;
    left: 16px;
  }
}

::v-deep .v-color-picker__canvas {
  height: 85px !important;
  canvas {
    height: 85px !important;
    width: 380px !important;
  }
}

::v-deep .v-color-picker__dot {
  border-radius: 0%;
  @include cut-corners(7px, true, 7px, false);
  display: block;
  padding: 0;
}

::v-deep .v-color-picker__preview:not(.v-color-picker__preview--hide-alpha) .v-color-picker__hue {
  margin-bottom: 15px;
}

::v-deep .v-btn--round {
  display: none;
}

::v-deep .v-color-picker__input {
  @include cut-corners(8px, true, 8px, false, 2px, rgba($base-color, 0.8));
  input {
    border: none !important;
    margin-bottom: 0;
    color: $base-color-text;
  }
  span {
    display: none;
  }
}

::v-deep .v-color-picker__edit {
  margin-left: 100px !important;
}

::v-deep .v-color-picker__input {
  margin-right: 5px !important;
  padding: 0px;
}

.color-picker {
  height: 24px;
}
.color-preview {
  @include cut-corners(5px, true, 5px, false);
  width: 24px;
  height: 24px;

  &.color-preview--stroke {
    background: none !important;
  }

  &.color-preview--line {
    width: 100%;
  }
}
</style>
