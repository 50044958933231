<template>
  <v-app-bar
    color="transparent"
    class="app-bar"
    :class="{ 'dashboard-app-bar': isDashboard, 'user-run-event': isUser && isRunEventPage }"
    ref="toolbar"
    :height="isDashboard ? '156px' : '64px'"
    app
    flat
  >
    <div class="run-event-menu" v-if="isRunEventPage">
      <div v-for="item in filteredMenuItems" :key="item.id" class="d-flex">
        <CbrHint
          top
          :disabled="!item.tooltip"
          bottom
          :description="item.tooltip"
          noTitle
        >
          <div
            @click.exact="handleNavBarButtonClick(item, $event)"
            @click.ctrl.exact="
              item.newTab
                ? handleNavBarButtonCtrlClick(item)
                : handleNavBarButtonClick(item, $event)
            "
            :class="item.class"
            :id="item.idName"
            class="show-scoring-button"
          >
            <a v-if="item.id === RUN_EVENT_MENU_MAP_3D" :href="item.link()" class="mt-1">
              <Icon :icon-name="item.icon" :width="36" :height="36"/>
            </a>
            <router-link v-else-if="item.link" :to="item.link()">
              <Icon
                class="mt-2 toolbar-icon"
                :icon-name="item.icon"
                :width="36"
                :height="36"
              />
            </router-link>
            <Icon v-else :icon-name="item.icon" :width="36" :height="36" class="toolbar-icon"/>
          </div>
        </CbrHint>
      </div>
    </div>
    <div v-else class="logo">
      <router-link to="/">
        <span class="logo-name">{{ i18n.t('user_authorization_lbl_cybermir') }}</span>
        <span class="logo-name logo-name-light">{{ i18n.t('user_authorization_lbl_solar') }}</span>
      </router-link>
    </div>
    <DashboardFilters v-if="isDashboard" class="dashboard-filters"/>
    <CbrHint
      v-if="isRunEventPage && isAdmin"
      bottom
      no-icon
      :title="!event ? '' : event.name"
    >
      <div ref="eventName" class="event-name-text ml-2">
        {{ !event ? '' : event.name }}
      </div>
    </CbrHint>
    <ToolbarEventState
      id="event-timeline-container"
      ref="timeBlock"
      v-if="isEventPage"
      :event="event"
      @changeStatus="onChangeStatus"
    />
    <CbrBreadCrumbs v-if="route.meta.breadCrumb"/>
    <div v-if="isConnectToVmPage" class="vm-block">
      <span>
        <CbrIcon size="20" class="mr-3">$vm</CbrIcon>
        {{ route.params.slot }} <mark>></mark> {{ route.params.vm }}
      </span>
    </div>
    <div
      id="toolbar-right-panel"
      class="d-flex justify-end ml-auto"
    >
      <CbrHint
        v-if="!indicatorNoWarning && !isUser && !isObserver && user.organization.internal"
        :title="integrityStateObj.title"
        :subTitle="integrityStateObj.subTitle"
        :description="integrityStateObj.description"
        bottom
      >
        <div class="px-4 align-self-center">
          <CbrIcon large>$indicator_lamp</CbrIcon>
        </div>
        <template v-slot:description>
          <div :style="{ color: $h.colors.rgba('$ActionColor', 0.8)}">
            {{ integrityStateObj.path }}
          </div>
        </template>
      </CbrHint>
      <!-- <v-layout class="d-flex justify-end" row align-center v-if="isRunEventPage && isUser">
        <span class="spice-gc-switcher-title">{{ $t('event_screen_chk_remocon_switcher') }}:</span>
        <CbrSwitch
          v-model="guacamoleMode"
          @input="onChangeGuacamoleMode($event)"
          class="pl-3 pr-5 spice-gc-switcher-radio"
          medium
        />
      </v-layout> -->
      <v-divider class="divider" vertical v-if="isConnectToVmPage"/>
      <div v-if="(isRunEventPage && isUser) || isConnectToVmPage" class="reloadVM align-self-center"
           @click="confirmVMReload">
        <CbrHint :description="$t('event_screen_reload_vm')" no-icon bottom>
          <CbrIcon size="32">$reloadVm</CbrIcon>
        </CbrHint>
      </div>
      <v-divider class="divider" vertical/>
      <div class="user-info-container">
        <div class="user-info-container-menu" v-show="true">
          <v-menu @input="setTopMenuState($event)" v-model="menuValue">
            <template v-slot:activator="{ on, attrs }">
              <v-btn id="main-menu-btn" v-on="on" v-bind="attrs" icon large>
                <CbrIcon>mdi-menu</CbrIcon>
              </v-btn>
            </template>
            <v-list class="menu-content" id="navbarMenu">
              <div class="menu-content-user">
                <Avatar :user-id="user.id" class="avatar" @click="showUserProfile"/>
                <div class="menu-content-user-fr">
                  <div class="menu-content-user-fio">
                    <CbrHint bottom no-icon :title="userFullName" :open-delay="1000">
                      <span class="menu-content-user-fio-text text-ellipsis">{{ $h.users.getNameWithInitials(user) }}</span>
                    </CbrHint>
                  </div>
                  <div class="menu-content-user-pos">
                    <CbrHint bottom no-icon :title="user.position" :open-delay="1000">
                      <span class="menu-content-user-pos-text text-ellipsis">{{ user.position }}</span>
                    </CbrHint>
                  </div>
                  <div class="menu-content-user-email">
                    <CbrHint bottom no-icon :title="user.email" :open-delay="1000">
                      <CbrIcon size="14" style="margin-right: 6px; min-width: 14px">$envelope_email</CbrIcon>
                      <span class="menu-content-user-email-text text-ellipsis">{{ user.email }}</span>
                    </CbrHint>
                  </div>
                </div>
                <v-divider class="divider exit-button-divider" vertical/>
                <div class="exit-button-container">
                  <CbrButton
                    id="page-main-header-btn-exit"
                    @click="$store.dispatch('account/logout')"
                    icon="$exit"
                    icon-size="24px"
                    transparent
                  />
                </div>
              </div>
              <hr class="menu-item-separat" />
              <div
                v-for="(block, index2) in ['lk', 'divlk', 'all', 'divmap', 'map', 'divadm', 'adm']"
                :key="index2"
                class="menu-content-block"
              >
                <div v-for="(item, index) in filteredNavbarMenuItems(block)" :key="index">
                  <component
                    :is="item.externalLink ? 'a' : 'router-link'"
                    :to="item.link"
                    :href="item.link"
                    :target="item.newTab ? '_blank' : ''"
                  >
                    <v-list-item :id="item.id" class="menu-item">
                      <CbrIcon v-if="item.icon">${{ item.icon }}</CbrIcon>
                      <span class="menu-item-span">
                        {{ item.title }}
                      </span>
                    </v-list-item>
                  </component>
                </div>
                <hr
                  class="menu-item-separat"
                  v-if="(
                    (block === 'divmap' && filteredNavbarMenuItems('map').length && !isAuditor && !isObserver) ||
                    (block === 'divadm' && filteredNavbarMenuItems('adm').length && !isAuditor && !isObserver)
                  )"/>
              </div>
            </v-list>
          </v-menu>
        </div>
        <Avatar :user-id="user.id" class="avatar" @click="menuValue = true"/>
      </div>
    </div>
    <!-- <ConsoleSwitchDialog
      :to-spice="!guacamoleMode"
      :is-open="consoleSwitchDialogOpen"
      @cancel="onCancelSwitchGuacamoleMode"
      @confirm="onConfirmGuacamoleMode"
    /> -->
  </v-app-bar>
</template>
<script setup>
import events from '@/helpers/events.helper'
import { useStore } from '@/store'
import { computed, onBeforeUnmount, onMounted, ref, watch, defineAsyncComponent } from 'vue'
import { i18n } from '@/i18n-setup.js'
import roles from '@/helpers/roles.helper'
import users from '@/helpers/users.helper'
import eventService, { DEFENCE } from '@/services/event.service'
import { useRoute } from 'vue-router/composables'
import mainHelper from '@/helpers/main.helper'
import { URL } from '@/services/sse/bus'
import { SaveMessagesModes } from '@/services/tabs-sync'
import usersService from '@/services/user.service'
import eventScoreboard from '@/services/scoreboard.service'
import { useSSE } from '@/composables/useSSE'
import { useStateLoading } from '@/composables/useStateLoading'
import ToolbarEventState from '@/components/ToolbarEventState'
import Avatar from '@/components/user/Avatar'

const route = useRoute()
const store = useStore()
const LK_BUTTON_ID = 'page-main-header-btn-lk'
const EVENTS_BUTTON_ID = 'page-main-header-btn-events'
const MISSIONS_BUTTON_ID = 'page-main-header-btn-missions'
const ADMINISTRATION_BUTTON_ID = 'page-main-header-btn-administration'
const SCOREBOARD_BUTTON_ID = 'page-main-header-btn-scoreboard'
const AUDIT_BUTTON_ID = 'page-main-header-btn-audit'
const TASKS_BUTTON_ID = 'page-main-header-btn-tasks'
const DASHBOARD_BUTTON_ID = 'page-main-header-btn-dashboard'
const INTEGRITY_BUTTON_ID = 'page-main-header-btn-integrity'
const VISUAL_EDITOR_BUTTON_ID = 'page-main-header-btn-visual-editor'
const EDITOR_3D_BUTTON_ID = 'page-main-header-btn-3d-editor'

const RUN_EVENT_MENU_MATERIALS = 'materials'
const RUN_EVENT_MENU_MAP = 'map'
const RUN_EVENT_MENU_MAP_3D = 'map3D'
const RUN_EVENT_MENU_SCOREBOARD = 'scoreboard'
const RUN_EVENT_MENU_REPORT = 'report'
const RUN_EVENT_MENU_EVENT = 'event'

// const ConsoleSwitchDialog = defineAsyncComponent(() => import('@/components/event/console/ConsoleSwitchDialog'))

const DashboardFilters = defineAsyncComponent(() => import('@/components/DashboardFilters'))

const mutationObserver = ref(null)

const runEventMenuItems = ref([
  {
    icon: 'event_materials',
    tooltip: i18n.t('event_screen_hint_btn_materials'),
    class: ['pt-1'],
    idName: 'page-main-header-btn_materials',
    event: 'global/showMaterials',
    id: RUN_EVENT_MENU_MATERIALS,
    visible: true
  },
  {
    icon: 'topology',
    tooltip: i18n.t('event_screen_hint_btn_topology'),
    event: 'global/showNetworkMap',
    idName: 'page-main-header-btn_map',
    id: RUN_EVENT_MENU_MAP,
    link: () => '',
    newTab: true,
    visible: true
  },
  {
    icon: 'mission_map_3d_re',
    tooltip: i18n.t('event_screen_hint_btn_topology_3d'),
    event: 'global/showNetworkMap3D',
    idName: 'page-main-header-btn_map_3d',
    id: RUN_EVENT_MENU_MAP_3D,
    link: () => `/3d/?id=${event.value?.id}`
  },
  {
    icon: 'score',
    tooltip: i18n.t('event_screen_hint_btn_scoreboard'),
    event: 'global/showScoreBoard',
    idName: 'page-main-header-btn_scoring',
    permission: [roles.ADMIN.key],
    id: RUN_EVENT_MENU_SCOREBOARD,
    link: () => event.value?.gameType?.toLowerCase() === DEFENCE ? '/scoreboard' : `/event-scoreboard/${event.value?.gameType?.toLowerCase()}/${event.value?.id}`
  },
  {
    icon: 'report',
    tooltip: i18n.t('event_screen_hint_btn_participants_report'),
    event: 'showReport',
    permission: [roles.USER.key],
    id: RUN_EVENT_MENU_REPORT,
    link: () => `/event/${event.value?.gameType?.toLowerCase() ?? DEFENCE}/${route.params.eventId}/report`
  },
  {
    icon: 'open_event',
    tooltip: i18n.t('navigation_panel_popup_menu_btn_event'),
    event: 'openEvent',
    id: RUN_EVENT_MENU_EVENT,
    link: () => `/event/${route.params.type}/${route.params.eventId}`
  }
])
const indicatorNoWarning = ref(true)

const indicatorStates = ref({
  INTEGRITY_UPDATE_ALL_STARTED: {},
  INTEGRITY_UPDATE_ALL_COMPLETED: {},
  INTEGRITY_NO_WARNING: {},
  INTEGRITY_CONTROL_VIOLATED: {
    title: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_broken_object'),
    subTitle: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_control'),
    description: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_broken_object_details'),
    path: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_broken_object_path')
  },
  INTEGRITY_CONTROL_DB_EMPTY: {
    title: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_empty_database'),
    subTitle: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_control'),
    description: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_empty_database_details'),
    path: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_empty_database_path')
  },
  INTEGRITY_CONTROL_DB_VIOLATED: {
    title: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_broken_database'),
    subTitle: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_control'),
    description: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_broken_database_details'),
    path: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_broken_database_path')
  },
  INTEGRITY_EDITING_FILES_EXIST: {
    title: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_edit_object'),
    subTitle: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_control'),
    description: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_edit_object_details'),
    path: i18n.t('integrity_control_hint_indicator_lamp_hint_integrity_edit_object_path')
  }
})

const scoreBoardRecordList = ref([])

const defaultAvatar = ref(require('@/assets/images/avatar2.png'))

const updateInterval = ref(null)

const avatarPath = ref('')

const topMenuHidden = ref(false)

const topMenuIsActive = ref(false)

const topMenuHideTimeout = ref(null)

// const guacamoleMode = ref(true)

// const consoleSwitchDialogOpen = ref(false)

const menuValue = ref(false)
const event = computed(() => store.getters['global/currentEvent'])
const user = computed(() => store.getters['account/user'])
const isObserver = computed(() => store.getters['account/isObserver'])
const isUser = computed(() => store.getters['account/isUser'])
const userFullName = computed(() => store.getters['account/userFullName'])
const isAdmin = computed(() => store.getters['account/isAdmin'])
const isAuditor = computed(() => store.getters['account/isAuditor'])
const userOrganization = computed(() => store.getters['account/userOrganization'])
const isInternal = computed(() => store.getters['account/isInternal'])
const userRole = computed(() => store.getters['account/userRole'])
const isSystemAdmin = computed(() => store.getters['account/isSystemAdmin'])
const isTeacher = computed(() => store.getters['account/isTeacher'])
const integrityState = computed(() => store.getters['global/integrityState'])

const { eventsSubscribesMap } = useSSE()
eventsSubscribesMap.value = !isUser.value && !isObserver.value && userOrganization.value?.internal ? {
  [URL.system()]: {
    handler: msg => {
      const message = typeof msg === 'string' ? msg : msg.data
      if (message) {
        const parsedMsg = JSON.parse(message)
        const type = parsedMsg.type
        if (!indicatorStates.value[type]) return
        switch (type) {
          case 'INTEGRITY_UPDATE_ALL_STARTED':
            store.commit('global/integrityUpdatedAll', false)
            break
          case 'INTEGRITY_UPDATE_ALL_COMPLETED':
            store.commit('global/integrityUpdatedAll', true)
            break
          case 'INTEGRITY_NO_WARNING':
            indicatorNoWarning.value = true
            break
          default:
            store.commit('global/setIntegrityState', type)
            indicatorNoWarning.value = false
            break
        }
      }
    },

    saveMessageMode: SaveMessagesModes.LAST,
    filterOnSave: [
      'INTEGRITY_NO_WARNING',
      'INTEGRITY_CONTROL_VIOLATED',
      'INTEGRITY_CONTROL_DB_EMPTY',
      'INTEGRITY_CONTROL_DB_VIOLATED',
      'INTEGRITY_EDITING_FILES_EXIST'
    ]
  }
} : {}

const items = computed(() => [
  {
    title: i18n.t('navigation_panel_popup_menu_btn_lk'),
    block: 'lk',
    icon: 'menu_lk',
    link: '/',
    permission: [roles.USER.key],
    id: LK_BUTTON_ID
  },
  {
    title: i18n.t('navigation_panel_popup_menu_btn_events'),
    block: 'all',
    icon: 'menu_event',
    link: isUser.value ? '/lk/user?tab=events' : '/',
    permission: [roles.ADMIN.key, ...roles.teacherRoles(), roles.OBSERVER.key],
    id: EVENTS_BUTTON_ID
  },
  {
    title: i18n.t('navigation_panel_popup_menu_btn_missions'),
    block: 'all',
    icon: 'menu_mission',
    link: '/missions',
    permission: [...roles.adminRoles(user.value, true)],
    id: MISSIONS_BUTTON_ID
  },
  {
    title: i18n.t('navigation_panel_popup_menu_btn_tasks'),
    block: 'all',
    icon: 'menu_task',
    link: '/tasks',
    permission: [...roles.adminRoles(user.value, true)],
    id: TASKS_BUTTON_ID
  },
  {
    title: i18n.t('navigation_panel_popup_menu_btn_scoreboard'),
    block: 'all',
    icon: 'menu_results',
    link: '/scoreboard',
    permission: [roles.ADMIN.key],
    internalOrg: true,
    id: SCOREBOARD_BUTTON_ID
  },
  {
    title: i18n.t('navigation_panel_popup_menu_btn_dashboard'),
    block: 'all',
    icon: 'menu_dashboard',
    link: '/dashboard',
    permission: [...roles.adminRoles(user.value, true)],
    internalOrg: true,
    id: DASHBOARD_BUTTON_ID
  },
  {
    title: i18n.t('navigation_panel_popup_menu_btn_visualization_editor'),
    block: 'map',
    icon: 'menu_editor',
    link: '/tools',
    newTab: true,
    permission: [...roles.adminRoles(user.value, true)],
    id: VISUAL_EDITOR_BUTTON_ID
  },
  {
    title: i18n.t('navigation_panel_popup_menu_btn_3d_map'),
    block: 'map',
    icon: 'menu_3d_editor',
    link: '/3d/editor/',
    externalLink: true,
    newTab: true,
    permission: [...roles.adminRoles(user.value, true)],
    id: EDITOR_3D_BUTTON_ID
  },
  {
    title: i18n.t('navigation_panel_popup_menu_btn_admin_panel'),
    block: 'adm',
    icon: 'menu_admin',
    link: '/admin',
    permission: [...roles.adminRoles(user.value)],
    id: ADMINISTRATION_BUTTON_ID
  },
  {
    title: i18n.t('audit_journal_hdr_audit'),
    block: 'adm',
    icon: 'menu_audit',
    link: '/audit',
    permission: isInternal.value ? [roles.AUDITOR.key] : [],
    id: AUDIT_BUTTON_ID
  },
  {
    title: i18n.t('integrity_control_lbl_integrity_control'),
    block: 'adm',
    icon: 'menu_integrity',
    link: '/integrityControl',
    permission: isInternal.value ? [roles.ADMIN.key] : [],
    internalOrg: true,
    id: INTEGRITY_BUTTON_ID
  }
])

const checkPermission = (permission) => {
  return permission === undefined || permission.indexOf(userRole.value) !== -1
}

const checkOrganization = (internalOrg) => {
  return internalOrg === undefined || internalOrg === userOrganization.value.internal
}

const showUserProfile = () => {
  users.showProfile(user.value)
}

created()
function created () {
  loadEvent()
  if (!isSystemAdmin.value && !isAuditor.value) {
    getScoreboardPermissions()
  }
}

const filteredNavbarMenuItems = (block) => {
  return items.value.filter((item) => {
    return item.block === block && checkPermission(item.permission) && checkOrganization(item.internalOrg)
  })
}
const handleNavBarButtonClick = (item, e) => {
  if (item.link && !item.newTab) {
    return
  }
  if (item.id === RUN_EVENT_MENU_EVENT) {
    mainHelper.processRoute(
      {
        name: 'EventCard',
        params: { eventId: route.params.eventId }
      },
      e
    )
  } else {
    store.commit(item.event, [route.params.eventId, e])
  }
}
const mapSrc = computed(() => {
  return `/api/event/${route.params.eventId}/network/image?locale=${i18n.locale}`
})
const menuItemsForResistanceAndCTF = computed(() => {
  const menuItemsForResistanceAndCtfIds = [RUN_EVENT_MENU_SCOREBOARD, RUN_EVENT_MENU_EVENT]
  return runEventMenuItems.value.filter(({ id }) => menuItemsForResistanceAndCtfIds.includes(id))
})

const filteredMenuItems = computed(() => {
  if (event.value?.gameType.toLowerCase() === DEFENCE) {
    return runEventMenuItems.value.filter(item => {
      return (
        item.visible !== false &&
        checkPermission(item.permission) &&
        checkOrganization(item.internalOrg) &&
        isButtonVisible(item)
      )
    })
  } else {
    return menuItemsForResistanceAndCTF.value
  }
})
const noAvatar = ref(true)
const { setStateLoaded } = useStateLoading()
onMounted(() => {
  usersService.getAvatar(user.value.id)
    .then(() => {
      avatarPath.value = `/api/user/${user.value.id}/image?${Date.now()}`
    })
    .catch(() => {
      avatarPath.value = defaultAvatar.value
      noAvatar.value = true
    })
    .finally(() => {
      setStateLoaded()
    })
  hideTopMenu()
  // guacamoleMode.value = sessionStorage.getItem('console_mode') !== `${route.params.eventId}_false`
  window.addEventListener('resize', calcBlockWidth)
  mutationObserver.value = new MutationObserver(() => calcBlockWidth())
  mutationObserver.value.observe(document.getElementById('html'), { childList: true, subtree: true })
})
onBeforeUnmount(() => {
  if (updateInterval.value) {
    clearInterval(updateInterval.value)
  }
  window.removeEventListener('resize', calcBlockWidth)
  mutationObserver.value.disconnect()
})
const onChangeStatus = () => {
  loadEvent()
}
function loadEvent () {
  if (
    route.params.eventId &&
    route.name !== 'EventPlayerReport' &&
    route.name !== 'EventCard'
  ) {
    events.getCachedEvent(route.params.eventId, route.params.type)
      .then(event => {
        users.setEventFullName(event)
        store.commit('global/setScoreBoardDialog', event)
        runEventMenuItems.value.find(
          item => item.id === RUN_EVENT_MENU_MATERIALS
        ).visible = !!event.mission?.media?.items
          .filter(m => m.mediaType === 'MANUAL').length
        const eventType = event.gameType || event.mission?.mode
        if (eventType.toLowerCase() === DEFENCE) {
          hasMap().then(() => {
            runEventMenuItems.value.find(
              item => item.id === RUN_EVENT_MENU_MAP
            ).visible = true
          }).catch(() => {
            runEventMenuItems.value.find(
              item => item.id === RUN_EVENT_MENU_MAP
            ).visible = false
          })
          hasMap3D().then(() => {
            runEventMenuItems.value.find(
              item => item.id === RUN_EVENT_MENU_MAP_3D
            ).visible = true
          }).catch(() => {
            runEventMenuItems.value.find(
              item => item.id === RUN_EVENT_MENU_MAP_3D
            ).visible = false
          })
        }
        processScoreBoardRecordList(scoreBoardRecordList.value)
      })
      .catch(err => {
        events.processError(err, i18n.t('user_panel_popup_no_access_rights_txt_page_view'))
      })
  }
}
const handleNavBarButtonCtrlClick = (item) => {
  item.link = () => mapSrc.value
  setTimeout(() => {
    item.link = () => ''
  }, 0)
}
const hasMap = () => {
  return eventService.hasNetworkMap(event.value.id)
}

const hasMap3D = () => {
  return eventService.hasNetworkMap(event.value.id)
}

function getScoreboardPermissions () {
  eventScoreboard
    .getScoreboard()
    .then(data => processScoreBoardRecordList(data.scoreBoardRecordList))
    .catch(() => processScoreBoardRecordList([]))
}

const processScoreBoardRecordList = (scoreBoardRecordList) => {
  scoreBoardRecordList.value = scoreBoardRecordList
  const userHasAccess = isUser.value && scoreBoardRecordList.length
  const observerHasAccess = isObserver.value && scoreBoardRecordList.length
  const teacherHasAccess = isTeacher.value && scoreBoardRecordList.length

  const runEventMenuItem = runEventMenuItems.value.find(
    item => item.id === RUN_EVENT_MENU_SCOREBOARD
  )
  if (userHasAccess) {
    const scoreboardMenuItem = items.value.find(item => item.id === SCOREBOARD_BUTTON_ID)
    if (scoreboardMenuItem) {
      scoreboardMenuItem.internalOrg = undefined
      scoreboardMenuItem.permission.push(roles.USER.key)
    }
    if (runEventMenuItem) {
      runEventMenuItem.permission.push(roles.USER.key)
    }
  }
  if (observerHasAccess) {
    const scoreboardMenuItem = items.value.find(item => item.id === SCOREBOARD_BUTTON_ID)
    if (scoreboardMenuItem) {
      scoreboardMenuItem.internalOrg = undefined
      scoreboardMenuItem.permission.push(roles.OBSERVER.key)
    }
    if (runEventMenuItem) {
      runEventMenuItem.permission.push(roles.OBSERVER.key)
    }
  }
  if (teacherHasAccess) {
    const scoreboardMenuItem = items.value.find(item => item.id === SCOREBOARD_BUTTON_ID)
    if (scoreboardMenuItem) {
      scoreboardMenuItem.internalOrg = undefined
      scoreboardMenuItem.permission.push(...roles.teacherRoles())
    }
    if (runEventMenuItem) {
      runEventMenuItem.permission.push(...roles.teacherRoles())
    }
  }
}

const displayTopMenu = () => {
  if (topMenuHideTimeout.value) {
    clearTimeout(topMenuHideTimeout.value)
  }
  topMenuHidden.value = false
}

const isEventPage = computed(() => {
  return route.name === 'runEvent' || route.name === 'EventCard'
})

const isRunEventPage = computed(() => {
  return route.name === 'runEvent'
})

const isConnectToVmPage = computed(() => {
  return route.name === 'connectToVm'
})

const isDashboard = computed(() => {
  return route.name === 'dashboard'
})

const integrityStateObj = computed(() => {
  return indicatorStates.value[integrityState.value]
})
const hideTopMenu = () => {
  if (isRunEventPage.value && isUser.value && !topMenuIsActive.value) {
    topMenuHideTimeout.value = window.setTimeout(() => {
      topMenuHidden.value = true
    }, 1000)
  }
}

const setTopMenuState = (isActive) => {
  topMenuIsActive.value = isActive
  hideTopMenu()
}

const isButtonVisible = (item) => {
  return item.id !== RUN_EVENT_MENU_REPORT || event.value?.scoringEnabled
}
const confirmVMReload = () => {
  store.commit('global/showUserDialog', true)
}

const eventName = ref()
const timeBlock = ref()
const calcBlockWidth = () => {
  if (!!eventName.value && !!timeBlock.value) {
    const w =
    timeBlock.value.$el.getBoundingClientRect().left -
    eventName.value.getBoundingClientRect().left - 10
    eventName.value.style.maxWidth = `${w}px`
  }
}
// const onChangeGuacamoleMode = (val) => {
//   consoleSwitchDialogOpen.value = true
// }

// const onCancelSwitchGuacamoleMode = () => {
//   consoleSwitchDialogOpen.value = false
//   guacamoleMode.value = !guacamoleMode.value
// }

// const onConfirmGuacamoleMode = () => {
//   consoleSwitchDialogOpen.value = false
//   sessionStorage.setItem('console_mode', `${event.value.id}_${guacamoleMode.value}`)
//   store.commit('global/guacamoleMode', guacamoleMode.value)
// }

watch(() => route.params.eventId, () => {
  if (!route.params.eventId) {
    store.commit('global/setCurrentEvent', null)
  } else {
    loadEvent()
  }
  // guacamoleMode.value = sessionStorage.getItem('console_mode') !== `${route.params.eventId}_false`
})

watch(() => menuValue.value, () => {
  if (menuValue.value) {
    getScoreboardPermissions()
  }
})

watch(() => isRunEventPage.value, (val) => {
  displayTopMenu()
  hideTopMenu()
  if (val) {
    getScoreboardPermissions()
  }
})
</script>

<style lang="scss" scoped>
.main-menu-container ::v-deep .v-menu__content, .menuable__content__active {
  z-index: 1000 !important;
}

.toolbar_title {
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #00FFF0;
  margin-left: 20px;
}

.run-event-menu {
  height: 100%;
  display: inline-flex;
}

.show-scoring-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 15px;
  height: 100%;
}

.logo {
  padding-left: 12px;

  a {
    display: grid;

    .logo-name {
      font-size: 22px;
      line-height: 26px;
      text-align: left;
      font-family: 'BF_Modernista';
      text-transform: uppercase;
      color: $base-color;

      &-light {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  .logo-icon {
    width: 129px;
    height: 36px;
  }
}

.app-bar {
  z-index: 100 !important;
  overflow: hidden;
  box-shadow: none !important;
  border-bottom: 1px solid rgba($base-color, 0.15) !important;
  padding-right: 0 !important;
  transition: all 0.2s;
  ::v-deep .v-toolbar__content {
    padding-right: 28px !important;
  }
}
.app-bar.user-run-event {
  background-color: rgba($PopupDarkBack, 0.8) !important;
  backdrop-filter: blur(37.5px);
}

.event-name-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: $ActionColor;
}

.user-name {
  position: relative;
  padding: 0 10px;
  background-color: #00F0FF;
  max-width: 11em;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    right: -1px;
    border-bottom: 8px solid #01251f;
    border-left: 8px solid #00f0ff;
    width: 0;
  }
}

.exit-button-divider {
  border-color: rgba($StaticLight, 0.1) !important;
}
.exit-button-container {
  margin-left: auto;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item {
  width: 370px;
  display: flex;
  height: 32px;
  min-height: 32px;
  padding: 0 25px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  align-self: stretch;

  span {
    color: $ActionColor !important;
  }
}

.menu-item:hover {
  background: linear-gradient(90deg, rgba($ActionColor, 0.10) 0%, rgba($ActionColor, 0.00) 102.55%) !important;

  span {
    color: $StaticLight !important;
  }

  ::v-deep g {
    opacity: 1;
  }
}

.menu-item-span {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
}

.menu-item-separat {
  background: rgba($StaticLight, 0.25);
  height: 1px;
  width: calc(100% - 10px);
  border: none;
  margin: 8px 5px;
  align-items: flex-start;
}

#main-menu-btn {
  color: rgba($ActionColor, 0.8);
}

#main-menu-btn:hover {
  color: $ActionColor;
}

.v-menu__content {
  width: 380px;
  background-color: transparent;
  transform: translateY(8px) translateX(-6px) !important;
  overflow: hidden;
  border-radius: 0;
  box-shadow: none !important;
  left: calc(100% - 374px) !important;
  top: -8px !important;
}
::v-deep .v-toolbar__content {
  padding-right: 0;
}

.menu-content {
  width: 380px;
  background-color: $LightBackColor !important;
  @include cut-corners(0px, false, 9px, false, 2px, rgba($ActionColor, 0.25) !important);
  padding: 6px 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;

  &-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }

  &-user {
    display: flex;
    align-items: center;
    width: 380px;
    gap: 6px;
    padding: 0 6px;

    &-fr {
      display: grid;
      gap: 4px;
      width: 225px;
      max-width: 225px;
    }

    &-fio {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      height: 19px;
      max-width: inherit;

      ::v-deep div:first-child {
        width: 100%;
      }

      &-text {
        height: 19px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: block;
        align-items: center;
        color: $StaticLight;
      }
    }

    &-pos {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 14px;
      max-width: inherit;

      ::v-deep div:first-child {
        width: 100%;
      }

      &-text {
        height: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: block;
        align-items: center;
        color: rgba($StaticLight, 0.5);
      }
    }

    &-email {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 16px;
      max-width: inherit;

      ::v-deep div:first-child {
        width: 100%;
      }

      &-text {
        height: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: block;
        align-items: center;
        color: $ActionColor;
      }
    }
  }
}

.hidden {
  position: absolute;
  transform: translateY(-64px) !important;
  transition: all 0.3s;
  animation: toolbar-fading 0.4s ease;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}

@keyframes toolbar-fading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// .spice-gc-switcher {
//   &-radio span {
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 16px;
//     color: #04CCDC;
//   }
//   &-title {
//     font-size: 16px;
//     font-weight: 400;
//     line-height: 18.96px;
//     text-align: left;
//     color: $StaticLight;
//   }
// }

.vm-block {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  font-size: 20px;
  color: $base-color-text;

  mark {
    color: $base-color;
    background-color: transparent;
  }
}

.reloadVM {
  width: 65px;
  display: flex;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

.toolbar-icon {
  ::v-deep path {
    fill: $ActionColor;
  }
}

.dashboard-filters {
  height: 92px;
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  padding: 0 28px 0 30px;
}

.user-info-container {
  display: flex;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;

  &-menu {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      position: absolute;
      top: -30px;
      right: 0;
    }
  }

  .avatar {
    cursor: pointer;
  }
}
</style>
