<template>
  <div v-if="isAdmin || !isError"
       class="event-timeline-container d-flex justify-center"
  >
    <div v-if="isPreProcessing || isEnded" class="top">
      <div class="left" :class="startDateTimeTitleClass">
        <span v-if="isPreProcessing" class="start-through">{{ $t('event_card_edit_start') }}</span>
        <span v-if="isPreProcessing && allowedChangeEventState"
              class="time-before-text time-before-text-sm pl-2">{{ timeBefore }}</span>
        <CbrIcon v-if="isFinished || isCanceled">$flag</CbrIcon>
        <CbrIcon size="18" v-if="isError">$event_err</CbrIcon>
        <span v-if="isEnded" class="dt-title start-through" :class="startDateTimeTitleClass">{{
            startDateTimeTitle
          }}</span>
      </div>
    </div>
    <div v-if="isPreProcessing || isEnded" class="bottom">
      <div v-if="isPreProcessing">
        <span v-if="!allowedChangeEventState" class="time-before-text time-before-text-md">{{ timeBefore }}</span>
        <CbrButton
          v-if="isPlanned && allowedChangeEventState"
          :text="$t('event_card_btn_prepare')"
          icon="$start_infrastructure"
          warning
          size="small"
          width="160px"
          @click="eventPrepare"
        />
        <CbrButton
          v-if="isPreparing && allowedChangeEventState"
          :text="preparingTitle"
          border
          disabled
          size="small"
          width="160px"
        />
        <CbrButton
          v-if="isReady && allowedChangeEventState"
          :text="$t('event_card_btn_start')"
          icon="$run_event"
          cbr-icon
          size="small"
          width="160px"
          @click="eventRun"
        />
      </div>
      <div v-if="isEnded" class="event-timeline-date-btn">
        <span class="dt-text" :class="startDateTimeTitleClass">{{ startDateTimeText }}</span>
        <CbrButton
          v-if="isError"
          :text="$t('event_card_btn_prepare')"
          icon="$start_infrastructure"
          warning
          size="small"
          width="160px"
          @click="eventPrepare"
        />
      </div>
    </div>
    <div v-if="isProcessing" class="event-timeline" :class="{ user: !isAdmin }">
      {{ time }}
    </div>
    <div v-if="isFinishing" class="start-through finishing">
      <CbrIcon class="mr-2">$loading</CbrIcon>
      <span>{{ $t('event_card_lbl_debriefing') }}..</span>
    </div>
    <ControlPanel
      v-if="allowedChangeEventState && isProcessing && isAdmin"
      :status="eventState"
      :game-type="gameType"
      @changeStatus="onChangeStatus"
    />
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, onUnmounted, ref } from 'vue'
import moment from 'moment/moment'
import permissions from '@/helpers/permissions.helper'
import events from '@/helpers/events.helper'
import { useStore } from '@/store'
import { i18n } from '@/i18n-setup'
import eventService from '@/services/event.service'
import ControlPanel from '@/components/ControlPanel'

const instance = getCurrentInstance().proxy
const store = useStore()

const props = defineProps({
  event: {
    type: Object,
    default: () => {
    }
  }
})
const emit = defineEmits(['changeStatus'])

const time = ref(moment(0).utc().format('HH:mm:ss'))
const timeBefore = ref(null)
const timeCorrection = ref(0)
const updateTimeInterval = ref(null)

const isAdmin = computed(() => store.getters['account/isAdmin'])
const allowedChangeEventState = computed(() => permissions.allowedChangeEventState())
const eventState = computed(() => props.event?.eventState)
const gameType = computed(() => props.event?.gameType?.toLowerCase() || props.event?.mission.mode.toLowerCase())
const isPlanned = computed(() => events.PLANNED.key === props.event?.eventState)
const isPreparing = computed(() => events.PREPARING.key === props.event?.eventState)
const isReady = computed(() => events.READY.key === props.event?.eventState)
const isPreProcessing = computed(() => isPlanned.value || isPreparing.value || isReady.value)
const isProcessing = computed(() => [events.RUNNING.key, events.PAUSED.key].includes(props.event?.eventState))
const isFinished = computed(() => events.FINISHED.key === props.event?.eventState)
const isCanceled = computed(() => events.CANCELED.key === props.event?.eventState)
const isError = computed(() => events.ERROR.key === props.event?.eventState)
const isEnded = computed(() => isFinished.value || isCanceled.value || isError.value)
const isFinishing = computed(() => events.FINISHING.key === props.event?.eventState)
const preparingTitle = computed(() => `${i18n.t('event_card_lbl_event_status_preparation')}..`)
const startDateTimeTitle = computed(() => {
  switch (props.event.eventState) {
    case events.ERROR.key:
      return i18n.t('event_card_lbl_error')
    case events.CANCELED.key:
      return i18n.t('event_card_lbl_canceled')
    case events.FINISHED.key:
      return i18n.t('event_card_lbl_finished')
    default:
      return ''
  }
})
const startDateTimeTitleClass = computed(() => props.event.eventState.toLowerCase())
const startDateTimeText = computed(() => moment(props.event.startedAt).format('DD MMMM HH:mm'))

const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60

const setTime = () => {
  const eventStartTime = moment(props.event?.startedAt)
  const diffInMilliseconds = moment().diff(eventStartTime) - timeCorrection.value
  let hours = Math.floor(diffInMilliseconds / (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR))

  hours = Math.max(hours, 0)

  const minutesAndSeconds = moment.utc(Math.abs(diffInMilliseconds)).format('mm:ss')
  time.value = `${hours < 10 ? `0${hours}` : hours}:${minutesAndSeconds}`
  timeBefore.value = instance.$h.events.formatTimeBeforeEvent(props.event?.startedAt)

  if ([events.PLANNED.key, events.PREPARING.key].includes(props.event?.eventState) && diffInMilliseconds > 0) {
    time.value = '00:00:00'
    timeBefore.value = '00:00:00'
  }
}

const onChangeStatus = () => {
  emit('changeStatus')
}
const eventPrepare = () => {
  props.event.eventState = events.PREPARING.key
  eventService.startInfrastructure(props.event.id, gameType.value)
}
const eventRun = async () => {
  props.event.eventState = events.RUNNING.key
  await eventService.manualStartEvent(props.event.id, gameType.value)
  const event = await eventService.getEvent(props.event.id, gameType.value)
  store.commit('global/setCurrentEvent', event)
}

onMounted(() => {
  timeCorrection.value = +localStorage.getItem('time-correction') || 0

  updateTimeInterval.value = setInterval(() => setTime(), 1000)
})

onUnmounted(() => {
  if (updateTimeInterval.value) {
    clearInterval(updateTimeInterval.value)
  }
})
</script>

<style lang="scss" scoped>
.event-timeline-container {
  width: 310px;
  height: 64px;
  background: rgba($base-black, 0.26);
  backdrop-filter: blur(12.5px);

  @include cut-corners(28px, false, 28px, false, 2px, rgba($ActionColor, 0.1));

  position: absolute;
  left: calc(50vw - 155px);

  display: flex;
  flex-direction: column;
  align-items: center;

  .event-timeline {
    display: flex;
    justify-content: center;

    //font-family: "Rubik-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
    color: $ActionColor;
  }

  .event-timeline.user {
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0;
  }

  @media only screen and (max-width: 650px) {
    & {
      display: none;
    }
  }

  .top {
    height: 28px;
    display: flex;
    align-items: center;

    .left {
      display: flex;
      gap: 6px;
      align-items: center;
    }

    .left.finished {
      .v-icon {
        stroke: $ActionColor;
      }
    }

    .left.canceled, .left.error {
      background-color: transparent !important;

      .v-icon {
        stroke: $AlarmTextColor;
        fill: $AlarmTextColor;
      }
    }
  }

  .bottom {
    height: 36px;
    display: flex;
    align-items: center;
  }

  .time-before-text {
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: $ActionColor !important;

    &-lg {
    }

    &-md {
      font-size: 24px;
      line-height: 28px;
    }

    &-sm {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .dt-title.finished {
    color: rgba($ActionColor, 0.8) !important;
  }

  .dt-title.canceled, .dt-title.error {
    color: $AlarmTextColor !important;
    background-color: transparent !important;
  }

  .dt-text.finished {
    color: $ActionColor;
  }

  .dt-text.canceled, .dt-text.error {
    color: $AlarmTextColor;
    background-color: transparent !important;
    text-align: center;
  }

  .dt-text.error {
    text-align: center;
  }

  .start-through {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: rgba($ActionColor, 0.36);
  }

  .start-through.finishing {
    .v-icon {
      width: 16px;
      height: 16px;
    }
  }
}

.event-timeline-date-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 16px;
}
</style>
