<template>
  <component
    :is="getComponent"
    :buttonWidth="buttonWidth"
    v-bind="attrs"
    @files-upload="onFilesUpload"
    @file-delete="onFileDelete"
    @file-download="onFileDownload"
    @file-error="onFileError"
  />
</template>

<script setup>
import { computed, useAttrs } from 'vue'
import CbrFilesBlockBase from '@/components/kit/CbrFilesBlockBase.vue'
import CbrFilesBlockExpanding from '@/components/kit/CbrFilesBlockExpanding.vue'

const props = defineProps({
  expanding: {
    type: Boolean,
    default: false
  },
  buttonWidth: {
    type: String,
  }
})
const attrs = useAttrs()
const getComponent = computed(() => {
  return props.expanding ? CbrFilesBlockExpanding : CbrFilesBlockBase
})

const emit = defineEmits(['files-upload', 'file-download', 'file-delete', 'file-error'])
function onFilesUpload (e) {
  emit('files-upload', e)
}
function onFileDownload (fileIndex) {
  emit('file-download', fileIndex)
}
function onFileDelete (fileIndex) {
  emit('file-delete', fileIndex)
}
function onFileError (e) {
  emit('file-error', e)
}
</script>
