<template>
  <v-container fluid>
    <div :id="props.id">
      <CbrIcon class="logo-image">{{getLogoImage}}</CbrIcon>
    </div>
  </v-container>
</template>

<script setup>
import { computed } from 'vue'
import { i18n } from '@/i18n-setup'

const props = defineProps({
  id: String
})

const getLogoImage = computed(() => `$logo_solar_cyber_${i18n.locale}`)
</script>

<style lang="scss" scoped>
.logo-image {
  width: 300px;
  height: 72px;
}
</style>
