import store from '@/store'
import { constructor } from './player'
import { config } from './map-config'
import { objects } from './objects'
import { isCursorIn, isCursorInLine, getPointsIndexesOfLinePart, mouse, normalizeCoordsFromEvent, ContextMenu, HandMove, MoveIconHandler } from './cursor'
import Essence from './essence'
import Line from './line'
import Plane from './plane'
import TextField from './text-field'
import Icon from './icon'
import { SetPlacement } from './selector-tools'
import { checkerTrigger, PasteTriggerMenu, ApplyTrigger } from './trigger'

export default class Tools {
  constructor () {
    this.placement = false
    this.connection = false
    this.addPointInLine = false
    this.addLine = false
    this.makePlane = false
    this.editPlane = false
    this.moveIcon = false
    this.menu = false
  }

  zeroing () {
    document.body.style.cursor = 'default'
    this.placement = false
    this.connection = false
    this.addPointInLine = false
    this.makePlane = false
    this.editPlane = false
    this.makeText = false
    this.moveIcon = false
    this.menu = false
    if (this.addLine) {
      this.removeAddLineListeners()
      this.addLine = false
    }
    if (this.editedPlane) {
      this.editedPlane.removeEditPlaneListeners()
    }
    if (this.removeSelectRectangleListners) {
      this.removeSelectRectangleListners()
    }
  }

  clearSelectionObjects () {
    constructor.essence.forEach((item) => {
      item.selected = false
    })
    constructor.lines.forEach((item) => {
      item.selected = false
    })
    constructor.planes.forEach((item) => {
      item.selected = false
    })
    constructor.textFields.forEach((item) => {
      item.selected = false
    })
    store.commit('clearSelection')
  }

  AddSceneEffect (effect) {
    var dable = false
    for (const i in constructor.scene.effect) {
      if (constructor.scene.effect[i] === effect) {
        constructor.scene.effect.splice(i, 1)
        dable = true
      }
    }
    if (!dable) {
      constructor.scene.effect.push(effect)
    }
    const effects = []
    for (const effect of constructor.scene.effect) {
      if (!effects.includes(effect)) {
        effects.push(effect)
      }
    }
    constructor.scene.effect = effects
  }

  AddItem (name) {
    this.clearSelectionObjects()
    const essence = new Essence(
      (mouse.x + (200 + constructor.translateX * (-1))) / constructor.zoom,
      (mouse.y + (constructor.translateY * (-1))) / constructor.zoom, name,
      constructor.ctx,
      constructor.cvs
    )
    essence.selected = true
    constructor.essence.push(essence)
    store.commit('selected', essence)
    const item = { ...essence }
    const type = 'essence'
    store.commit('addToSelection', { item, type })
    this.Placement()
  }

  AddIcon (player, item, iconData, object) {
    if (!item) return
    var icon = new Icon(iconData.name, iconData.image)
    icon.id = iconData.id
    icon.width = icon.img.width
    icon.height = icon.img.height
    icon.parentX = item.x
    icon.parentY = item.y
    if (player.cvs.classList[0] === 'modal') {
      icon.x = (object.width / 2) - (icon.width / 2)
    }
    item.icons = [icon]
    tools.MoveIcon(player, object || item)
  }

  DeleteIcon (item, icon) {
    const iconIndex = item.icons.indexOf(icon)
    if (iconIndex !== -1) {
      item.icons.splice(iconIndex, 1)
    }
  }

  AddEffect (object, state) {
    object.effect.push(state)
  }

  DeleteEffect (object, state) {
    object.effect = object.effect.filter(effect => effect !== state)
  }

  AddLine () {
    this.zeroing()
    this.clearSelectionObjects()
    this.addLine = true
    this.addLineListeners()
  }

  addLineListeners () {
    window.addEventListener('mousedown', this.addLineMouseDownHandler)
    window.addEventListener('mousemove', this.addLineMouseMoveHandler)
    window.addEventListener('mouseup', this.addLineMouseUpHandler)
  }

  removeAddLineListeners () {
    window.removeEventListener('mousedown', this.addLineMouseDownHandler)
    window.removeEventListener('mousemove', this.addLineMouseMoveHandler)
    window.removeEventListener('mouseup', this.addLineMouseUpHandler)
  }

  addLineMouseDownHandler = (e) => {
    if (e.target.id === 'player' && e.which === 1) {
      constructor.lines.forEach((item) => item.selected = false)
      this.startConnect = true
      this.connect = new Line()
      this.connect.ctx = constructor.ctx
      this.connect.startX = (mouse.x + (constructor.translateX * (-1))) / constructor.zoom
      this.connect.startY = (mouse.y + (constructor.translateY * (-1))) / constructor.zoom
      this.connect.endX = this.connect.startX
      this.connect.endY = this.connect.startY
      this.connect.startID = true
      this.connect.selected = true
      constructor.lines.push(this.connect)
      store.commit('selected', this.connect)
    }
  }

  addLineMouseMoveHandler = (e) => {
    mouse.x = e.pageX
    mouse.y = e.pageY - constructor.topOffset
    if (this.startConnect) {
      this.connect.endX = (mouse.x + (constructor.translateX * (-1))) / constructor.zoom
      this.connect.endY = (mouse.y + (constructor.translateY * (-1))) / constructor.zoom
    }
  }

  addLineMouseUpHandler = (e) => {
    if (
      e.target.id === 'player' &&
      this.startConnect &&
      this.connect.endX === this.connect.startX &&
      this.connect.endY === this.connect.startY
    ) {
      const index = constructor.lines.indexOf(this.connect)
      constructor.lines.splice(index, 1)
      store.commit('clearSelection')
    } else {
      store.commit('setSelectedAll', { lines: [{ ...this.connect }], essence: [], planes: [], textFields: [] })
    }
    this.startConnect = false
    this.connect = false
  }

  Connect () {
    this.zeroing()
    this.clearSelectionObjects()
    this.connection = true
    let isConnecting = false
    let connect
    let from

    window.onmousedown = (e) => {
      mouse.x = e.pageX
      mouse.y = e.pageY - constructor.topOffset
      for (let essence of constructor.essence) {
        if (e.button === 0 && isCursorIn(essence)) {
          isConnecting = true
          connect = new Line()
          connect.ctx = constructor.ctx
          connect.startX = essence.x + essence.centerPointOffsetX
          connect.startY = essence.y + essence.centerPointOffsetY
          connect.endX = connect.startX
          connect.endY = connect.startY
          connect.startID = essence.id
          from = essence
          constructor.lines.push(connect)
          store.commit('addToSelection', { item: connect, type: 'lines' })
          store.commit('selected', connect)
          break
        }
      }
    }

    window.onmousemove = (e) => {
      const coords = normalizeCoordsFromEvent(e)
      if (isConnecting) {
        connect.endX = coords.x
        connect.endY = coords.y
      }
    }

    window.onmouseup = (e) => {
      mouse.x = e.pageX
      mouse.y = e.pageY - constructor.topOffset

      if (!connect) {
        return
      }

      let isConnected = false
      for (let essence of constructor.essence) {
        if (e.button === 0 && isCursorIn(essence) && essence !== from) {
          connect.endX = essence.x + essence.centerPointOffsetX
          connect.endY = essence.y + essence.centerPointOffsetY
          connect.endID = essence.id
          isConnected = true
          break
        }
      }

      if (!isConnected) {
        const index = constructor.lines.indexOf(connect)
        constructor.lines.splice(index, 1)
      }
      isConnecting = false
      connect = false
    }
  }

  AddPointInLine () {
    this.zeroing()
    this.addPointInLine = true
    let line = store.getters.selected
    if (!line || line.name !== 'line' || line.isBlocked) {
      this.addPointInLine = false
      tools.Placement()
      return
    }
    if (this.addPointInLine) {
      isCursorInLine(line)
      let point = {
        x: 0,
        y: 0,
        isBlocked: false
      }
      window.onmousedown = (e) => {
        if (line.isBlocked) {
          this.addPointInLine = false
          tools.Placement()
          return
        }
        if (e.target.id === 'player') {
          const coords = normalizeCoordsFromEvent(e)
          point.x = coords.x
          point.y = coords.y
          // определяем, в какой отрезок поставлена точка
          const nearbyPointsInd = getPointsIndexesOfLinePart(line, e.pageX, e.pageY - constructor.topOffset)
          if (nearbyPointsInd) {
            const newPointIndex = line.points.push(point) - 1
            line.graphList.push([newPointIndex, [nearbyPointsInd[0], nearbyPointsInd[1]]])
            const firstPointInd = line.graphList.findIndex(entity => entity[0] === nearbyPointsInd[0])
            const secondPointInd = line.graphList.findIndex(entity => entity[0] === nearbyPointsInd[1])
            line.graphList[firstPointInd][1] = [...line.graphList[firstPointInd][1].filter(i => i !== nearbyPointsInd[1]), newPointIndex]
            line.graphList[secondPointInd][1] = [...line.graphList[secondPointInd][1].filter(i => i !== nearbyPointsInd[0]), newPointIndex]
            // обновить анимацию
            line.animationVisited = []
          }
        }
      }
      window.onmousemove = (e) => {
        if (point) {
          const coords = normalizeCoordsFromEvent(e)
          point.x = coords.x
          point.y = coords.y
        }
      }
      window.onmouseup = (e) => {
        point = {
          x: 0,
          y: 0
        }
      }
    }
  }

  DeletePoint (point) {
    if (point.point.isBlocked || point.line.isBlocked) {
      return
    }
    const pointLine = point.line
    const pointIndex = pointLine.points.findIndex(i => i.x === point.point.x && i.y === point.point.y)
    const endPointIndexes = pointLine.graphList
      .filter(graph => graph[1].length === 1)
      .map(graph => graph[0])

    // удаление смежных рёбер
    const deleteEntitiesIndexes = [pointIndex]
    const deletePointsIndexes = [pointLine.graphList.findIndex(i => i[0] === pointIndex)]
    pointLine.graphList.forEach((entity, entityIndex) => {
      const toIndex = entity[1].indexOf(pointIndex)
      if (toIndex !== -1) {
        if (entity[1].length === 1) {
          // если вершина смежного ребра лист, то удалим её
          deleteEntitiesIndexes.push(entityIndex)
          deletePointsIndexes.push(entity[0])
        } else {
          entity[1].splice(toIndex, 1)
        }
      }
    })
    // если больше чем 2 смежные точки то внизу проверка на разрыв графа
    const pointEntity = pointLine.graphList.find(entity => entity[0] === pointIndex)
    const checkRazriv = pointEntity[1].length > 2
    if (!checkRazriv && pointEntity[1].length === 2) {
      const pointFirst = pointLine.graphList.find(entity => entity[0] === pointEntity[1][0])
      const pointSecond = pointLine.graphList.find(entity => entity[0] === pointEntity[1][1])
      // частный случай когда удаляемая точка соседняя с концом линии - не нужно соединять
      if (!endPointIndexes.includes(pointFirst[0]) && !endPointIndexes.includes(pointSecond[0])) {
        pointFirst[1].push(pointSecond[0])
        pointSecond[1].push(pointFirst[0])
      }
    }
    // удаляем из графа
    pointLine.graphList = pointLine.graphList.filter((entity, entityInd) => !deleteEntitiesIndexes.includes(entityInd))
    // из точек, исправляя индексы
    for (let i = 0; i < deletePointsIndexes.length; i++) {
      pointLine.points.splice(deletePointsIndexes[i], 1)
      pointLine.DecrementGraphListWhenDelete(deletePointsIndexes[i])
      for (let j = i; j < deletePointsIndexes.length; j++) {
        if (deletePointsIndexes[j] > deletePointsIndexes[i]) {
          deletePointsIndexes[j]--
        }
      }
    }
    // обновить анимацию
    pointLine.animationVisited = []

    if (pointLine.points.length === 0) {
      constructor.lines.splice(constructor.lines.indexOf(pointLine), 1)
    }
    store.commit('setSelectedPoint', null)

    // если разорван

    if (checkRazriv) {
      const connectedComponents = pointLine.GetConnectedComponents()
      if (connectedComponents.length > 1) {
        const getCompareValue = (point1, point2) => (point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2
        for (let i = 0; i < connectedComponents.length - 1; i++) {
          // ищем и соединяем ближайшие точки
          let minCompareValue = Number.MAX_SAFE_INTEGER
          let pointIndexes = []
          connectedComponents[i].forEach(firstCompPointInd => {
            connectedComponents[i + 1].forEach(secondCompPointInd => {
              const compVal = getCompareValue(pointLine.points[firstCompPointInd], pointLine.points[secondCompPointInd])
              if (compVal < minCompareValue) {
                pointIndexes = [firstCompPointInd, secondCompPointInd]
                minCompareValue = compVal
              }
            })
          })

          pointLine.graphList.find(entity => entity[0] === pointIndexes[0])[1].push(pointIndexes[1])
          pointLine.graphList.find(entity => entity[0] === pointIndexes[1])[1].push(pointIndexes[0])
        }
      }
    }
  }

  MakePlane (e) {
    this.zeroing()
    this.clearSelectionObjects()
    this.makePlane = true
    var plane = new Plane(constructor.ctx)
    plane.Place(e)
    constructor.planes.push(plane)
    store.commit('selected', plane)
    plane.addPlaneListeners()
  }

  EditPlane () {
    this.zeroing()
    this.editPlane = true
    let plane = store.getters.selected
    if (plane.name === 'plane') {
      this.editedPlane = plane
      this.editedPlane.EditPlane()
    } else {
      this.clearSelectionObjects()
    }
  }

  CreateText (e) {
    if (e.which === 1 || e.which === 55) {
      const text = new TextField(constructor.ctx)
      text.Place(e)
      constructor.textFields.push(text)
      text.addTextHandler()
      return text
    }
  }

  MakeTextField (e) {
    this.zeroing()
    this.makeText = true
    this.CreateText(e)
  }

  Delete () {
    this.delete = true
    var select = store.getters.selected
    constructor.lines.forEach((item) => {
      if (item === select) {
        var index = constructor.lines.indexOf(item)
        constructor.lines.splice(index, 1)
        store.state.selected = false
      }
    })
    constructor.essence.forEach((item) => {
      if (item === select) {
        var index = constructor.essence.indexOf(item)
        item.gifEffects.forEach(effect => {
          effect.ResetEffect()
        })
        constructor.essence.splice(index, 1)
        store.state.selected = false
      }
    })
    constructor.planes.forEach((item) => {
      if (item === select) {
        var index = constructor.planes.indexOf(item)
        constructor.planes.splice(index, 1)
        store.state.selected = false
      }
    })
    constructor.textFields.forEach((item) => {
      if (item === select) {
        var index = constructor.textFields.indexOf(item)
        constructor.textFields.splice(index, 1)
        store.state.selected = false
      }
    })
    this.delete = false
    store.dispatch('selected', constructor.scene)
  }

  // map configuration (map-config.js)
  GetConfig () {
    return config.GetConfig()
  }

  UploadSaved (savedPlayer) {
    config.SetConfig(savedPlayer)
  }

  UploadConfig () {
    config.UploadConfig()
  }

  LoadConfig () {
    return config.LoadConfig()
  }

  AppendConfig () {
    config.LoadMoreConfig()
  }
  // end map configuration

  // common actions with objects (objects.js)
  CopyPasteObjects (e) {
    objects.CopyPasteObjects(e, this.AddEffect)
  }

  DeleteObjects () {
    objects.DeleteSelectedObjects()
  }

  GoToCenter () {
    objects.GoToCenter()
  }

  BringToFront (id) {
    objects.BringToFront(id)
  }

  BringToBack (id, tempData) {
    objects.BringToBack(id, tempData)
  }

  BlockObjects () {
    objects.BlockObjects()
  }

  UnBlockObjects () {
    objects.UnBlockObjects()
  }

  ChangeCenter (id, x, y) {
    objects.ChangeCenter(id, x, y)
  }
  // end common actions

  // cursor actions
  ContextMenu (e) {
    ContextMenu(e)
  }

  HandMove () {
    this.zeroing()
    this.placement = true
    HandMove()
  }

  Placement () {
    this.zeroing()
    this.placement = true
    SetPlacement()
  }

  MoveIcon (player, item) {
    if (!item) return
    this.zeroing()
    this.moveIcon = true
    MoveIconHandler(player, item)
  }
  // end cursor actions

  // trigger options (trigger.js)
  checkerTrigger () {
    checkerTrigger()
  }

  CopyTriggerMenu () {
    store.commit('setCopyTriggers', checkerTrigger())
  }

  PasteTriggerMenu () {
    PasteTriggerMenu()
  }

  ApplyTrigger (item, trigger) {
    ApplyTrigger(item, trigger)
  }
  // end trigger options

  // baseObject options
  AddBaseItem (item) {
    store.dispatch('baseItems', item)
  }

  ApplyBase (item) {
    store.dispatch('base', item)
  }
  // end baseObject options
}
export const tools = new Tools()
