<template>
  <div
    class="cbr-files-block"
    :class="getClasses"
    :id="elementId"
    ref="cbrFilesBlockTemplateRef"
    @drop.prevent="onDrop"
    @dragover.prevent="onDragOver"
    @dragleave.prevent="onDragLeave"
    @dragend.prevent="onDragEnd"
    @dragenter.prevent="onDragEnter"
  >
    <div
      class="cbr-files-block__wrapper"
      :style="getStyles"
    >
      <CbrFilesList
        v-if="files.length"
        :files="props.files"
        :no-delete-button="props.noDeleteButton"
        @download="onFileDownload"
        @delete="onFileDelete"
      />
      <div v-else class="cbr-files-block__no-files">
        <p>{{ props.placeholder }}</p>
      </div>
      <CbrFileInput
        ref="fileInputTemplateRef"
        class="cbr-files-block__input"
        :justify="props.justifyInput"
        :button-text="props.buttonText"
        :button-icon="buttonIcon"
        :button-width="buttonWidth"
        :accept="accept"
        :error="error"
        :showDragArea="false"
        :dragAndDropSpan="true"
        @change="onFilesUpload"
        @update:error="onFileError"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useFilesDragAndDrop } from '@/composables/useFilesDragAndDrop'

const props = defineProps({
  backgroundColor: {
    type: String,
    default: 'transparent'
  },

  // FilesList prop
  files: {
    type: Array,
    default: () => []
  },
  // FilesList prop
  noDeleteButton: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: 'Добавьте файл'
  },

  // FilesInput prop
  accept: {
    type: String,
  },
  error: {
    type: String
  },
  // FilesInput prop
  multiple: {
    type: Boolean,
    default: () => false
  },
  // FilesInput prop
  buttonWidth: {
    type: String,
    default: '278px'
  },
  // FilesInput prop
  buttonText: {
    type: String,
    default: null
  },
  buttonIcon: {
    type: String,
    default: '$cbrPlusCircle18'
  },
  // FilesInput prop
  justifyInput: {
    type: String,
    default: 'center',
    validator: (value) => {
      return ['center', 'start'].includes(value)
    }
  }
})
const emit = defineEmits(['files-upload', 'file-download', 'file-delete', 'file-error'])
function onFilesUpload (e) {
  emit('files-upload', e)
}
function onFileDownload (fileIndex) {
  emit('file-download', fileIndex)
}
function onFileDelete (fileIndex) {
  emit('file-delete', fileIndex)
}
function onFileError (e) {
  emit('file-error', e)
}

const getClasses = computed(() => {
  const baseClass = 'cbr-files-block'

  return {
    [`${baseClass}--dragging-over`]: isDraggingOver.value
  }
})
const getStyles = computed(() => {
  return {
    backgroundColor: props.backgroundColor
  }
})

const {
  elementId,
  cbrFilesBlockTemplateRef,
  fileInputTemplateRef,
  isDraggingOver,
  onDragEnter,
  onDragLeave,
  onDragEnd,
  onDrop,
  onDragOver
} = useFilesDragAndDrop()
</script>

<style scoped lang="scss">
.cbr-files-block {
  width: 100%;
  position: relative;

  &:not(&--dragging-over) {
    @include cut-corners(10px, true, 10px, false, 2px, $border-disabled);
    display: flex;
    padding: 0;
    .cbr-files-block__wrapper {
      padding: 10px;
    }
  }

  &--dragging-over {
    display: block;
    padding: 0;
    .cbr-files-block__wrapper {
      padding: 12px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px dashed var(--ActionColor, #00FFF0);
      background: rgba($PopupDarkBack, 0.70);
      z-index: 2;
      backdrop-filter: blur(12.5px);
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/icons/svg/uikit/drop-files-plus.svg");
      background-position: center;
      z-index: 3;
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__no-files {
    @include expansion-panel-no-data;
  }

  &__input {
    margin-top: 8px;
  }
}
</style>
