import moment from 'moment'
import roles from '@/helpers/roles.helper'
import eventService, { DEFENCE } from '@/services/event.service'
import { i18n } from '@/i18n-setup.js'
import ForbiddenBackEvents from '@/components/error/ForbiddenBackEvents'
import router from '@/router'

const events = {
  RUNNING: {
    key: 'RUNNING',
    // name: i18n.t('event_card_lbl_event_status_in_progress')
  },
  CANCELED: {
    key: 'CANCELED',
    // name: i18n.t('event_card_lbl_canceled')
  },
  FINISHED: {
    key: 'FINISHED',
    // name: i18n.t('event_card_lbl_finished')
  },
  PLANNED: {
    key: 'PLANNED',
    // name: i18n.t('event_card_lbl_event_status_waiting')
  },
  PREPARING: {
    key: 'PREPARING',
    // name: i18n.t('event_card_lbl_event_status_preparation')
  },
  ERROR: {
    key: 'ERROR',
    // name: i18n.t('event_card_lbl_error')
  },
  READY: {
    key: 'READY',
    // name: i18n.t('event_card_lbl_event_status_starting_soon')
  },
  PAUSED: {
    key: 'PAUSED',
    // name: i18n.t('event_card_lbl_pause_state')
  },
  PREPARATION_PAUSE: {
    key: 'PREPARATION_PAUSE',
    // name: i18n.t('event_card_lbl_pause')
  },
  FINISHING: {
    key: 'FINISHING',
    // name: i18n.t('event_card_lbl_debriefing')
  },
  CLEANING_UP: {
    key: 'CLEANING_UP',
    // name: i18n.t('event_card_lbl_debriefing')
  },
  getMomentStartedAt (item) {
    return moment(item.startedAt)
  },
  formatTimeBeforeEvent (startedAt) {
    moment.locale(i18n.locale)
    const duration = moment.duration(moment(startedAt).diff(moment()))
    const daysNum = parseInt(duration.asDays())
    const daysText = daysNum ? daysNum + ' ' + i18n.t('datetime_d') : ''
    return `${daysText} ${moment.utc(duration.asMilliseconds()).format('HH')}${i18n.t('datetime_h')} ${moment.utc(duration.asMilliseconds()).format('mm')}${i18n.t('datetime_m')}`
  },
  isEditable (status) {
    return [events.FINISHED.key, events.CANCELED.key, events.CLEANING_UP.key, events.PREPARING.key].indexOf(status) === -1
  },
  isFinished (status) {
    return [events.FINISHED.key, events.FINISHING.key, events.CLEANING_UP.key].includes(status)
  },
  getStatusName (status) {
    switch (status) {
      // ожидание
      case this.PLANNED.key:
        return i18n.t('event_card_lbl_event_status_waiting')
      // подготовка
      case this.PREPARING.key:
        return i18n.t('event_card_lbl_event_status_preparation')
      // скоро начнется
      case this.READY.key:
        return i18n.t('event_card_lbl_event_status_starting_soon')
      // в процессе
      case this.RUNNING.key:
        return i18n.t('event_card_lbl_event_status_in_progress')
      // пауза
      case this.PAUSED.key:
        return i18n.t('event_card_lbl_pause_state')
      // пауза в конце раунда
      case this.PREPARATION_PAUSE.key:
        return i18n.t('event_card_lbl_pause')
      // отменено
      case this.CANCELED.key:
        return i18n.t('event_card_lbl_canceled')
      // завершено
      case this.FINISHED.key:
        return i18n.t('event_card_lbl_finished')
      // ошибка
      case this.ERROR.key:
        return i18n.t('event_card_lbl_error')
      // подведение итогов
      case this.FINISHING.key:
        return i18n.t('event_card_lbl_debriefing')
      // завершение
      case this.CLEANING_UP.key:
        return i18n.t('event_card_lbl_cleaning_up')
    }

    return events[status] ? events[status].name : 'Неизвестно'
  },
  getStatusNameCard (status) {
    if (status === events.PREPARATION_PAUSE.key) {
      return this.getStatusName(events.PAUSED.key)
    }
    return this.getStatusName(status)
  },
  showEventConsole (id) {
    window.open(`${location.protocol}//${location.host}/event/${id}/console`,
      '', 'width=800,height=500')
  },
  isPreFinishState (status) {
    return [this.RUNNING.key, this.PAUSED.key, this.PREPARATION_PAUSE.key, this.FINISHING.key].includes(status)
  },
  getIconByStatus (status) {
    switch (status) {
      case this.PREPARING.key:
      case this.PLANNED.key:
      case this.READY.key:
      case this.RUNNING.key:
        return '$calendar'
      case this.FINISHED.key:
        return '$finish'
      case this.CANCELED.key:
      case this.ERROR.key:
        return '$event_err'
    }
  },
  getTitleClass (status) {
    switch (status) {
      case events.RUNNING.key:
        return 'light-green--text text--accent-4 font-weight-bold'
      case events.CANCELED.key:
        return 'red--text'
      case events.FINISHED.key:
        return 'blue--text text--lighten-3 font-weight-bold'
      case events.PLANNED.key:
        return this.waitingStatusDetailed ? 'white--text' : 'orange--text font-weight-bold'
      case events.READY.key:
        return 'accent3--text font-weight-bold'
      case events.ERROR.key:
        return 'red--text font-weight-bold'
      case events.PREPARING.key:
        return 'candle_light--text font-weight-bold'
      default:
        return 'orange--text'
    }
  },
  async getUserUnfinishedEventsCount (userId) {
    const result = await eventService.getUserUnfinishedEventsCount(userId)
    return result.count
  },
  getMemberEventKey (type) {
    switch (type) {
      case roles.OBSERVER.key :
        return 'observers'
      case roles.USER.key:
        return 'participants'
      default:
        return null
    }
  },
  async getUserUnfinishedEvents (userId, type) {
    const events = await eventService.getEvents()
    const key = this.getMemberEventKey(type)

    return events.filter(event => event[key] && event[key].filter(member => member.id === userId).length !== 0)
  },
  async processError (e, error403) {
    if (e.response && e.response.status === 403) {
      await router.push({
        name: 'error',
        params: {
          errorText: error403,
          action: ForbiddenBackEvents
        }
      })
    } else {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
  async getCachedEvent (id, gameType = DEFENCE) {
    const requestDelayInMilliseconds = 2000
    let data = JSON.parse(localStorage.getItem('events-cache')) || null
    try {
      if (data && data[`event-${id}`] && data[`event-${id}`].responseTime && (moment().diff(moment(data[`event-${id}`].responseTime)) < requestDelayInMilliseconds)) {
        return Promise.resolve(data[`event-${id}`].event)
      } else {
        const response = await eventService.getEvent(id, gameType)
        data = { [`event-${id}`]: { responseTime: moment(), event: response } }
        localStorage.setItem('events-cache', JSON.stringify(data))
        return response
      }
    } catch (e) {
      await this.processError(e, i18n.t('user_panel_popup_no_access_rights_txt_page_view'))
    }
  },
  role: Symbol('user role')
}

export default events
