import { render, staticRenderFns } from "./AddIcon.vue?vue&type=template&id=33eac380&scoped=true"
import script from "./AddIcon.vue?vue&type=script&setup=true&lang=js"
export * from "./AddIcon.vue?vue&type=script&setup=true&lang=js"
import style0 from "./AddIcon.vue?vue&type=style&index=0&id=33eac380&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33eac380",
  null
  
)

export default component.exports