<template>
  <CbrDialog
    :value="true"
    :title="`${$t('properties_panel_object_btn_set_trigges')} ${objectId}`"
    max-width="1160px"
    @input="close()"
  >
    <template slot="content">
      <div class="cbr-trigger-dialog">
        <div class="cbr-trigger-dialog__table-container">
          <CbrDataTable
            class="cbr-trigger-table"
            @click:row="applyTrigger($event)"
            v-model="selected"
            :headers="headers"
            :items="triggers"
            :items-per-page="-1"
            :item-class="getItemClass"
            :no-data-text="$t('trigger_object_lbl_no_triggers')"
            item-key="_id"
            disable-pagination
            disable-header
          >
          <template #item.number="{ item }">
            <div class="cbr-trigger-table__number">
              {{ triggers.map(item => item._id).indexOf(item._id) + 1 }}
            </div>
          </template>
          <template #item.checkbox="{ item }">
            <div class="cbr-trigger-table__checkbox">
              <CbrCheckBox
                :checked="isCheckedItem(item)"
                @change="checkItem($event.target.checked, item)"
                size="small"
              />
            </div>
          </template>
          <template #item.name="{ item }">
            <div class="text-ellipsis">{{ item.name }}</div>
          </template>
          <template #item.effect="{ item }">
            <ToolsTriggersDialogArrayColumn
              :items="item.effect.filter(effect => effects.map(effect => effect.name).includes(effect))"
            />
          </template>
          <template #item.gifEffects="{ item }">
            <ToolsTriggersDialogArrayColumn
              :items="item.effect.filter(effect => gifEffects.map(effect => effect.name).includes(effect))"
            />
          </template>
          <template #item.NotificationColor="{ item }">
            <div
              class="cbr-trigger-dialog__line-color"
              :style="{ backgroundColor: item.NotificationColor }"
            ></div>
          </template>
          <template #item.bgColor="{ item }">
            <div class="cbr-trigger-dialog__bg-wrapper">
              <div
                class="cbr-trigger-dialog__bg"
                :style="{ backgroundColor: item.bgColor }"
              ></div>
            </div>
          </template>
          <template #item.lineColor="{ item }">
            <div class="cbr-trigger-dialog__line-wrapper">
              <div
                class="cbr-trigger-dialog__line-color"
                :style="{ border: `2px solid ${item.lineColor}` }"
              ></div>
            </div>
          </template>
          <template #item.color="{ item }">
            <div class="cbr-trigger-table__line-container">
              <div class="cbr-trigger-dialog__line-color" :style="{ backgroundColor: item.color }"></div>
              <span v-if="item.lineWidth" class="cbr-trigger-table__line-width">{{ item.lineWidth }}</span>
              <span v-if="item.textSize" class="cbr-trigger-table__line-width">{{item.textSize}}</span>
              <span class="cbr-trigger-table__line-px">&nbsp;px</span>
            </div>
          </template>
          <template #item.icons="{ item }">
            <div
              class="cbr-trigger-table__icon"
              v-if="item.icons.length"
            >
              <div class="cbr-trigger-table__icon-img">
                <img :src="item.icons[0].url" />
              </div>
              <div class="cbr-trigger-table__icon-label">
                <CbrHint bottom :description="item.icons[0]?.name" noIcon class="w100">
                  <div class="mx-2 text-ellipsis">{{ item.icons[0]?.name }}</div>
                </CbrHint>
              </div>
            </div>
          </template>
          <template #item.menu="{ item }">
            <div class="cbr-trigger-table__menu">
              <v-menu offset-y v-model="menuStates[item._id]" content-class="cbr-trigger-table__menu-content">
                <template v-slot:activator="{ on }">
                  <v-btn icon x-small v-on="on" :class="{ 'cbr-trigger-menu-active': menuStates[item._id] }" class="mr-1">
                    <CbrIcon class="cbr-trigger-table__menu-dots">mdi-dots-horizontal</CbrIcon>
                  </v-btn>
                </template>
                <v-list class="cbr-trigger-table__list">
                  <v-list-item class="cbr-trigger-table__list-item">
                    <div
                      @click="copyTriggers(item)"
                      :disabled="!selected.length"
                      class="cbr-trigger-table__list-title"
                    >
                      {{ $t('trigger_text_btn_copy') }}
                    </div>
                  </v-list-item>
                  <v-list-item class="cbr-trigger-table__list-item">
                    <div
                      @click="doubleTrigger(item)"
                      :disabled="currentTrigger._id === defaultTrigger._id"
                      class="cbr-trigger-table__list-title"
                    >
                      {{ $t('trigger_line_btn_duplicate') }}
                    </div>
                  </v-list-item>
                  <v-list-item
                    class="cbr-trigger-table__list-item"
                    :class="{
                      'cbr-trigger-table__list-last': checkedTriggers.length ||
                        (copiesTriggers.triggers?.length && entityType === copiesTriggers.type)
                    }"
                  >
                    <div
                      @click="deleteTrigger(item)"
                      :disabled="!selected.length"
                      class="cbr-trigger-table__list-title"
                    >
                      {{ $t('trigger_text_btn_delete') }}
                    </div>
                  </v-list-item>
                  <v-list-item class="cbr-trigger-table__list-item" v-if="checkedTriggers.length">
                    <div
                      @click="copyCheckedTriggers(item)"
                      class="cbr-trigger-table__list-title"
                    >
                      {{ $t('trigger_text_btn_copy') }} ({{ checkedTriggers.length }})
                    </div>
                  </v-list-item>
                  <v-list-item
                    class="cbr-trigger-table__list-item"
                    v-if="entityType === copiesTriggers.type && copiesTriggers.triggers.length"
                  >
                    <div
                      @click="pasteTriggers(item)"
                      class="cbr-trigger-table__list-title"
                    >
                      {{ $t('trigger_text_btn_paste') }} ({{ copiesTriggers.triggers.length }})
                    </div>
                  </v-list-item>
                  <v-list-item class="cbr-trigger-table__list-item" v-if="checkedTriggers.length">
                    <div
                      @click="doubleCheckedTriggers(item)"
                      class="cbr-trigger-table__list-title"
                    >
                      {{ $t('trigger_line_btn_duplicate') }} ({{ checkedTriggers.length }})
                    </div>
                  </v-list-item>
                  <v-list-item class="cbr-trigger-table__list-item" v-if="checkedTriggers.length">
                    <div
                      @click="deleteCheckedTriggers(item)"
                      class="cbr-trigger-table__list-title"
                    >
                      {{ $t('trigger_text_btn_delete') }} ({{ checkedTriggers.length }})
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </CbrDataTable>
        </div>
        <div class="cbr-trigger-dialog__btn-container">
          <CbrButton
            :text="$t('trigger_object_btn_create_state')"
            id="trigger_object_btn_create_state"
            width="264px"
            @click="addNewTrigger()"
            icon="$cbrPlusCircle"
            cbr-icon
            border
          />
        </div>
        <CbrContentBlock maxHeight="340px">
          <div class="cbr-trigger-dialog__name-container">
            <span>{{$t('trigger_text_hdr_name_lbl')}}</span>
              <CbrTextField
                v-model="currentTrigger.name"
                :placeholder="i18n.t('trigger_text_plh_name')"
                :disabled="currentTrigger._id === defaultTrigger._id"
                :error-messages="nameErrors"
                :clearable="false"
                :maxlength="NAME_LENGTH_COUNTER"
                required
                class="cbr-trigger-name"
                hide-details
                @blur="v$.name.$touch()"
              />
          </div>
          <div
            v-if="isScene"
            class="cbr-trigger-dialog__scene"
          >
            <div class="cbr-trigger-dialog__scene-image">
              <canvas ref="preview"></canvas>
            </div>
            <div class="cbr-trigger-dialog__scene-effects">
              <p class="cbr-trigger-dialog__scene-effects-heading">{{ i18n.t('trigger_scene_hdr_effect') }}</p>
              <div class="cbr-trigger-dialog__scene-effects-list">
                <CbrCheckBox
                  v-for="effect in effects"
                  :key="effect.name"
                  :checked="effect.selected"
                  @change="selectEffect(effect)"
                  :disabled="currentTrigger._id === defaultTrigger._id"
                  :label="effectLabel(effect)"
                />
              </div>
            </div>
            <div class="cbr-trigger-dialog__scene-settings">
              <div class="cbr-trigger-dialog__scene-settings-text-container">
                <CbrTextField
                  class="cbr-trigger-dialog__scene-settings-notification"
                  v-model="currentTrigger.Notification"
                  :title="i18n.t('trigger_scene_label_notification')"
                  :disabled="currentTrigger._id === defaultTrigger._id"
                  :error-messages="notificationErrors"
                  :maxlength="NOTIFICATION_LENGTH_COUNTER"
                  small
                  required
                />
                <ColorPicker
                  v-model="currentTrigger.NotificationColor"
                  :disabled="currentTrigger._id === defaultTrigger._id"
                />
              </div>
              <div class="cbr-trigger-dialog__scene-settings-condition-container">
                <CbrTextArea
                  v-model="currentTrigger.condition"
                  :info-hint="{
                    title: 'Examples',
                    description: EXAMPLES,
                  }"
                  :title="$t('trigger_scene_lbl_condition')"
                  :disabled="currentTrigger._id === defaultTrigger._id"
                  :maxlength="CONDITION_LENGTH_COUNTER"
                  :error-messages="conditionErrors"
                  :rows="5"
                  @blur="v$.condition.$touch()"
                  counter
                  required
                />
              </div>
            </div>
          </div>
          <div
            v-else
            class="cbr-trigger-dialog__object-container"
          >
            <div
              :class="{'cbr-border-right': isEssence}"
              class="cbr-trigger-dialog__object-image"
            >
              <canvas ref="preview"></canvas>
              <template v-if="isEssence">
                <CbrCombobox
                  :preselect="selectedState"
                  :disabled="currentTrigger._id === defaultTrigger._id"
                  required
                  :items="states"
                  hideDetails
                  size="small"
                  width="196px"
                  select-only
                  @input="currentTrigger.state = $event.value"
                />
              </template>
            </div>
            <div class="cbr-trigger-dialog__all-content" :class="{ 'fill-width': isEssence }">
              <div>
                <template v-if="isEssence">
                    <div class="cbr-trigger-dialog__effects-container">
                      <div class="cbr-trigger-dialog__effects-text">{{ $t('trigger_essence_hdr_effect') }}</div>
                        <div
                          class="cbr-trigger-dialog__object-effect"
                          v-for="effect in effects"
                          :key="effect.name"
                        >
                          <CbrCheckBox
                            :checked="effect.selected"
                            @change="applyEffect(effect, $event)"
                            :disabled="currentTrigger._id === defaultTrigger._id"
                            :label="effectLabel(effect)"
                          />
                        </div>
                      </div>
                </template>
              </div>
              <template v-if="isEssence">
                  <div class="cbr-trigger-dialog__icons-gif">
                    <div class="cbr-trigger-dialog__icons-container">
                      <span class="cbr-trigger-dialog__icons-span">{{$t('trigger_object_lbl_icon')}}</span>
                      <CbrCombobox
                        @input="addIcon($event)"
                        @clear="deleteIcon"
                        :disabled="currentTrigger._id === defaultTrigger._id"
                        :preselect="currentTrigger.icons.length ? { name: currentTrigger.icons[0].name, image: currentTrigger.icons[0].img.src } : null"
                        :items="icons"
                        item-text="name"
                        size="small"
                        clearable
                        selectOnly
                        image
                      />
                    </div>
                    <template v-if="gifEffects.length">
                      <div class="cbr-trigger-dialog__gif-container">
                        <div class="cbr-trigger-dialog__object-effect">{{ $t('trigger_panel_object_lbl_gif_effects') }}</div>
                          <div
                            class="cbr-trigger-dialog__gif"
                            v-for="effect in gifEffects"
                            :key="effect.name"
                          >
                            <CbrCheckBox
                              :checked="effect.selected"
                              @change="applyEffect(effect, $event)"
                              :disabled="currentTrigger._id === defaultTrigger._id"
                              :label="effect.name"
                            />
                          </div>
                      </div>
                    </template>
                  </div>
              </template>
              <template v-if="isLine">
                  <div class="cbr-trigger-dialog__line-container">
                    <div>
                      <div class="cbr-trigger-dialog__line-title">{{ $t('trigger_line_hdr_effect') }}</div>
                      <CbrCombobox
                        :value="currentTrigger.value?.effect[0]"
                        :preselect="triggers[currentTriggerIndex]?.effect[0]"
                        :items="effects"
                        width="390px"
                        item-text="name"
                        item-value="name"
                        :placeholder="$t('trigger_panel_select_plh')"
                        :disabled="currentTrigger._id === defaultTrigger._id"
                        @change="selectEffect($event)"
                        select-only
                        clearable
                        size="small"
                        required
                      />
                    </div>
                    <div class="cbr-trigger-dialog__width-color">
                      <div>
                        <div>{{ $t('trigger_line_hdr_width') }}</div>
                        <CbrSpinner
                          v-model="currentTrigger.lineWidth"
                          :disabled="currentTrigger._id === defaultTrigger._id"
                          :error-messages="lineWidthErrors"
                          :min-value="1"
                          mediumWidth="140px"
                          size="small"
                        />
                      </div>
                      <div class="flex-grow-1">
                        <div>{{ $t('trigger_scene_lbl_color') }}</div>
                        <ColorPicker
                          v-model="currentTrigger.color"
                          :disabled="currentTrigger._id === defaultTrigger._id"
                          line
                        />
                      </div>
                    </div>
                  </div>
              </template>
              <template v-if="isPlane">
                  <div class="cbr-trigger-dialog__plane-container">
                    <div>{{$t('trigger_background_hdr_background_color')}}</div>
                    <ColorPicker
                      v-model="currentTrigger.bgColor"
                      :disabled="currentTrigger._id === defaultTrigger._id"
                      line
                    />
                    <div class="mt-6">{{$t('trigger_background_hdr_stroke_color')}}</div>
                    <ColorPicker
                      v-model="currentTrigger.lineColor"
                      :disabled="currentTrigger._id === defaultTrigger._id"
                      line
                    />
                  </div>
              </template>
              <template v-if="isText">
                  <div class="cbr-trigger-dialog__object-text">
                    <div class="cbr-trigger-dialog__text-title">
                      <div>{{ $t('trigger_text_lbl_text') }}</div>
                      <CbrTextField
                        v-model="currentTrigger.message"
                        :disabled="currentTrigger._id === defaultTrigger._id"
                        :error-messages="textMessageErrors"
                        :maxlength="TEXT_LENGTH_COUNTER"
                        small
                        required
                      />
                    </div>
                    <div class="cbr-trigger-dialog__size-color">
                      <div>
                        <div>{{ $t('trigger_text_lbl_size') }}</div>
                        <CbrSpinner
                          v-model="currentTrigger.textSize"
                          :disabled="currentTrigger._id === defaultTrigger._id"
                          :min-value="1"
                          :error-messages="textSizeErrors"
                          manual
                          mediumWidth="130px"
                          size="small"
                        />
                      </div>
                      <div class="flex-grow-1">
                        <div>{{ $t('trigger_text_lbl_color') }}</div>
                        <ColorPicker
                          v-model="currentTrigger.color"
                          :disabled="currentTrigger._id === defaultTrigger._id"
                          line
                        />
                      </div>
                    </div>
                  </div>
              </template>
            </div>
            <div
              class="cbr-trigger-dialog__condition"
              :style="{ width: isText ? '390px' : '470px' }"
            >
              <div class="cbr-trigger-dialog__condition-text">
                <CbrHint
                  title="Examples"
                  :description="EXAMPLES"
                  top
                  content-class="move-right"
                >
                  <CbrIcon class="cbr-trigger-dialog__condition-icon" size="15">$info_fill</CbrIcon>
                </CbrHint>
                <span class="cbr-trigger-dialog__icons-span ml-1">{{$t('trigger_scene_lbl_condition')}}</span>
              </div>
              <CbrTextArea
                v-model="currentTrigger.condition"
                :disabled="currentTrigger._id === defaultTrigger._id"
                :maxlength="CONDITION_LENGTH_COUNTER"
                :error-messages="conditionErrors"
                :rows="8"
                @blur="v$.condition.$touch()"
                counter
                required
              />
            </div>
          </div>
        </CbrContentBlock>
      </div>
      <!-- Сбросить изменения -->
      <CbrDialog
        v-model="showWarning"
        :title="$t('trigger_popup_reset_triggers_hdr')"
        :first-line-text="$t('trigger_notification_reset_first_line')"
        :second-line-text="$t('trigger_notification_reset_second_line')"
      >
        <template slot="footer">
          <CbrButton
            @click="$emit('close')"
            :text="$t('trigger_notification_save_btn_reset')"
            error
          />
          <CbrButton
            @click="showWarning = false"
            :text="$t('trigger_notification_save_btn_cancel')"
            border
          />
        </template>
      </CbrDialog>
      <!-- Удалить триггер -->
      <CbrDialog
        v-model="showDeleteDialog"
        :title="$t('trigger_popup_delete_triggers_hdr')"
        :error-text="$t('trigger_notification_delete_text_items')"
      >
        <template slot="footer">
          <CbrButton
            @click="deleteTriggers()"
            :text="$t('trigger_notification_delete_btn_delete')"
            error
          />
          <CbrButton
              @click="showDeleteDialog = false"
              :text="$t('trigger_notification_save_btn_cancel')"
              border
            />
        </template>
      </CbrDialog>
      <!-- Ошибка в триггерах -->
      <CbrDialog
        v-model="isErrorsDialog"
        :title="$t('trigger_notification_error_lbl_text')"
        :second-line-text="$t('trigger_notification_error_hdr_set_triggers')"
        :error-text="getErrorTriggersNumbers()"
        error
      >
        <template slot="footer">
          <CbrButton
              @click="isErrorsDialog = false"
              :text="$t('trigger_notification_save_btn_cancel')"
              border
            />
        </template>
      </CbrDialog>
    </template>
    <template slot="footer">
        <CbrButton
          v-if="!isScene"
          :text="$t('tech_map_btn_cancel')"
          @click="close()"
          border
        />
        <CbrButton
          @click="saveTriggers()"
          :disabled="!triggersChanged"
          :text="$t('trigger_text_btn_save')"
        />
    </template>
  </CbrDialog>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import { useStore } from '@/store'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required, numeric } from '@vuelidate/validators'
import { constructor, Player } from '@/plugins/map/constructor/player'
import { tools } from '@/plugins/map/constructor/tools'
import MapHelper, {
  defaultSceneParams,
  defaultLineParams,
  defaultPlaneParams,
  defaultEssenceParams,
  defaultTextParams
} from '@/helpers/map.helper.js'
import lodashClonedeep from 'lodash.clonedeep'
import ToolsTriggersDialogArrayColumn from '@/components/map/Triggers/ToolsTriggersDialogArrayColumn'
import ColorPicker from '@/components/ColorPicker'
import { i18n } from '@/i18n-setup.js'
import CbrDataTable from '@/components/kit/CbrDataTable'

const ENTITY_ID_SCENE = 'SCENE'
const ENTITY_ID_PLANE = 'BACKGROUND'
const ENTITY_ID_TEXT = 'TEXT'

const NAME_LENGTH_COUNTER = 64
const CONDITION_LENGTH_COUNTER = 1024
const NOTIFICATION_LENGTH_COUNTER = 128
const TEXT_LENGTH_COUNTER = 128

const REQUIRED_ERROR = i18n.t('trigger_object_lbl_required_field')
const INCORRECT_FORMAT_ERROR = i18n.t('trigger_object_lbl_incorrect_format')

const EXAMPLES = `base.round == 10
base.phase == 'phase_000'`

const ENTITY_TYPE_LINE = 'line'
const ENTITY_TYPE_PLANE = 'plane'
const ENTITY_TYPE_ESSENCE = 'object'
const ENTITY_TYPE_TEXT = 'text'
const ENTITY_TYPE_SCENE = 'scene'

const defaultTrigger = ref({
  _id: 0,
  name: 'Untitled',
  condition: null,
  effect: []
})

const DEFAULT_HEADERS = [
  {
    value: 'number',
    align: 'center'
  },
  {
    value: 'checkbox'
  },
  {
    text: 'trigger_scene_hdr_name',
    value: 'name'
  },
  {
    text: 'trigger_scene_hdr_effects',
    value: 'effect'
  }
]

const store = useStore()

const showWarning = ref(false)
const triggersWithErrors = ref([])
const entityType = ref(null)
const initialized = ref(false)
const triggerChanged = ref(false)
const objectId = ref(null)
const effects = ref([])
const selected = ref([])
const checkedTriggers = ref([])
const triggers = ref([])
const currentTrigger = ref({
  bgColor: null,
  textSize: null,
  message: null,
  name: '',
  lineColor: null,
  lineWidth: null,
  state: null,
  color: null,
  effect: [],
  icons: [],
  Notification: null,
  NotificationColor: null
})

const selectedState = computed(() => {
  const state = currentTrigger.value.state
  return state
    ? { value: state, name: i18n.t(store.getters.statesName[state]) }
    : null
})

const actionTrigger = ref(null)
const player = ref(null)
const previewObject = ref(null)
const headers = ref(DEFAULT_HEADERS)
const menuStates = ref({})
const showDeleteDialog = ref(false)
const isErrorsDialog = ref(false)

const validationObject = computed(() => {
  return {
    name: {
      required,
      maxLength: maxLength(NAME_LENGTH_COUNTER)
    },
    condition: {
      required,
      maxLength: maxLength(CONDITION_LENGTH_COUNTER),
      eval: value => /^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@\\[\]^_`{|}~\n]*$/.test(value)
    },
    textSize: {
      required,
      numeric
    },
    message: {
      required,
      maxLength: maxLength(TEXT_LENGTH_COUNTER)
    },
    lineWidth: {
      required,
      numeric
    },
    Notification: {
      required,
      maxLength: maxLength(NOTIFICATION_LENGTH_COUNTER)
    }
  }
})

const v$ = useVuelidate(validationObject, currentTrigger)

const object = computed(() => store.getters.selected)
const isEssence = computed(() => object.value.img)
const isLine = computed(() => object.value.name === ENTITY_TYPE_LINE)
const isScene = computed(() => !object.value.name && object.value.zoom)
const isPlane = computed(() => object.value.name === ENTITY_TYPE_PLANE)
const isText = computed(() => object.value.name === ENTITY_TYPE_TEXT)
const copiesTriggers = computed(() => store.getters.copiesTriggers)
const currentTriggerIndex = computed(() => triggers.value.indexOf(currentTrigger.value))

const lineEffects = computed(() => store.getters.lineEffects.map(lineEffectName => ({
  name: i18n.t(store.getters.lineEffectsName[lineEffectName]),
  selected: !!currentTrigger.value.effect.includes(lineEffectName)
})).sort((a, b) => a.name.localeCompare(b.name, 'en')))

const sceneEffects = computed(() => store.getters.sceneEffects.map(sceneEffectName => ({
  name: sceneEffectName,
  selected: !!currentTrigger.value.effect.includes(sceneEffectName)
})).sort((a, b) => a.name.localeCompare(b.name, 'en')))

const icons = computed(() => store.getters.icons.sort((a, b) => a.name.localeCompare(b.name, 'en')))

const objectEffects = computed(() => store.getters.effects.map(objectEffectName => ({
  name: objectEffectName,
  selected: !!currentTrigger.value.effect.includes(objectEffectName)
})).sort((a, b) => a.name.localeCompare(b.name, 'en')))

const gifEffects = computed(() => store.getters.gifEffectsData.filter(effect => checkEffect(effect)).map(gifEffect => ({
  ...gifEffect,
  selected: !!currentTrigger.value.effect.includes(gifEffect.name)
})).sort((a, b) => a.name.localeCompare(b.name, 'en')))

const effectsName = computed(() => store.getters.effectsName)
const effectLabel = (effect) => i18n.t(effectsName.value[effect.name])

const states = computed(() => [...store.getters.states]
  .map((state) => {
    return {
      value: state,
      name: i18n.t(store.getters.statesName[state])
    }
  })
)

const triggersChanged = computed(() => triggers.value.length !== object.value.triggers.length || (initialized.value && triggerChanged.value))

const nameErrors = computed(() => {
  const errors = []
  if (!v$.value.name.$dirty) return errors
  v$.value.name.required.$invalid && errors.push(REQUIRED_ERROR)
  return errors
})

const conditionErrors = computed(() => {
  const errors = []
  if (!v$.value.condition.$dirty) return errors
  v$.value.condition.required.$invalid && errors.push(REQUIRED_ERROR)
  v$.value.condition.eval.$invalid && errors.push(INCORRECT_FORMAT_ERROR)
  return errors
})

const textMessageErrors = computed(() => {
  const errors = []
  v$.value.message.required.$invalid && errors.push(REQUIRED_ERROR)
  return errors
})

const textSizeErrors = computed(() => {
  const errors = []
  v$.value.textSize.required.$invalid && errors.push(REQUIRED_ERROR)
  v$.value.textSize.numeric.$invalid && errors.push(INCORRECT_FORMAT_ERROR)
  return errors
})

const lineWidthErrors = computed(() => {
  const errors = []
  if (!v$.value.lineWidth.$dirty) return errors
  !v$.value.lineWidth.required && errors.push(REQUIRED_ERROR)
  !v$.value.lineWidth.numeric && errors.push(INCORRECT_FORMAT_ERROR)
  return errors
})

const notificationErrors = computed(() => {
  const errors = []
  if (!v$.value.Notification.$dirty) return errors
  return errors
})

watch(() => currentTrigger.value, (v) => {
  applyCurrentTriggerToPreviewObject()
}, { deep: true })

watch(() => triggers.value, () => {
  let objectTriggersString = object.value.triggers
  let triggersString = lodashClonedeep(triggers.value).map(item => {
    delete item._id
    return item
  })
  triggerChanged.value = JSON.stringify(triggersString) !== JSON.stringify(objectTriggersString)
}, { deep: true })

onMounted(() => {
  createPreview()
})

const getErrorTriggersNumbers = () => {
  const allNumbers = triggers.value.map(trigger => trigger._id)
  return triggersWithErrors.value.map(trigger => allNumbers.indexOf(trigger._id) + 1).join(', ')
}

const showErrorsDialog = () => {
  isErrorsDialog.value = true
}

const emit = defineEmits(['close'])
const close = () => {
  if (triggersChanged.value) {
    showWarning.value = true
  } else {
    emit('close')
  }
}

const checkEffect = (effect) => effect.componentName === previewObject.value.name

const addIcon = (currentIcon) => {
  if (!currentIcon) return
  const icon = icons.value.find(icon => icon.name === currentIcon.name)
  tools.AddIcon(player.value, currentTrigger.value, icon, previewObject.value)
  tools.Placement()
}

const deleteIcon = () => {
  currentTrigger.value.icons = []
}

const copyTriggers = (item) => {
  menuStates.value[item._id] = false
  store.commit('setCopyTriggers', { type: entityType.value, triggers: [item] })
}

const pasteTriggers = (item) => {
  menuStates.value[item._id] = false
  copiesTriggers.value.triggers.forEach(trigger => addTrigger(trigger))
}

const addHeader = (text, value) => {
  headers.value = headers.value.filter(header => header.value !== 'menu')
  headers.value.push({ text, value })
  headers.value.push({ text, value: 'menu', align: 'end' })
}

const selectEffect = (effect) => {
  effects.value.forEach(_effect => {
    _effect.selected = _effect.name === effect?.name
    applyEffect(_effect)
  })
}

const applyEffect = (effect, event) => {
  const isChecked = event.target.checked
  if (isChecked) {
    tools.AddEffect(currentTrigger.value, effect.name)
  } else {
    tools.DeleteEffect(currentTrigger.value, effect.name)
  }
}

let preview = ref(null)
const createPreview = () => {
  const ESSENCE_SIZE = {
    width: 196,
    height: 196
  }
  const SCENE_SIZE = {
    width: 366,
    height: (width) => width * (constructor.cvs.height / constructor.cvs.width)
  }
  const DEFAULT_SIZE = {
    width: 220,
    height: (width) => width * (constructor.cvs.height / constructor.cvs.width)
  }

  player.value = new Player()
  player.value.cvs = preview.value
  player.value.ctx = player.value.cvs.getContext('2d')
  store.commit('players', player.value)
  const PREVIEW_SIZE_X = isScene.value ? SCENE_SIZE.width : isEssence.value ? ESSENCE_SIZE.width : DEFAULT_SIZE.width
  player.value.Play(PREVIEW_SIZE_X, isEssence.value ? ESSENCE_SIZE.height : DEFAULT_SIZE.height(PREVIEW_SIZE_X))
  player.value.Size(PREVIEW_SIZE_X, isEssence.value ? ESSENCE_SIZE.height : DEFAULT_SIZE.height(PREVIEW_SIZE_X))
  player.value.translateX = constructor.translateX
  player.value.translateY = constructor.translateY
  previewObject.value.ctx = player.value.ctx

  if (isLine.value) drawLine()
  else if (isPlane.value) drawPlane()
  else if (isText.value) drawText()
  else if (isEssence.value) drawEssence()
  else if (isScene.value) drawScene()

  if (isText.value) return

  if (!isScene.value) {
    player.value.SetStartAndEndCoordinates(player.value)
    player.value.ScaleAndCenter(10, 10)
    previewObject.value.zoom = player.value.zoom
  } else {
    player.value.SetStartAndEndCoordinates(player.value)
    const realWidth = player.value.endX - player.value.startX
    const realHeight = player.value.endY - player.value.startY
    const scaleRatio = Math.min(player.value.cvs.width / realWidth, player.value.cvs.height / realHeight, 0.15)
    const paddingX = (player.value.cvs.width - realWidth * scaleRatio) / 2
    const paddingY = (player.value.cvs.height - realHeight * scaleRatio) / 2
    const offsetX = -player.value.startX * scaleRatio + paddingX
    const offsetY = -player.value.startY * scaleRatio + paddingY

    player.value.ctx.translate(offsetX, offsetY)
    player.value.Zoom(scaleRatio)
    player.value.scene.zoom = 1
  }
}

const isErrors = () => {
  triggersWithErrors.value = []
  let isError = false
  triggers.value.forEach(trigger => {
    let hasErrors = false
    Object.keys(defaultTrigger.value).forEach(fieldName => {
      if (Object.keys(validationObject.value).includes(fieldName)) {
        Object.values(validationObject.value[fieldName]).forEach(item => {
          const isValid = typeof item === 'function'
            ? item(trigger[fieldName])
            : item.$validator(trigger[fieldName])
          if (!isValid) hasErrors = true
        })
      }
    })
    if (hasErrors) {
      isError = true
      triggersWithErrors.value.push(trigger)
    }
  })
  return isError
}

const saveTriggers = () => {
  if (isErrors()) {
    showErrorsDialog()
  } else {
    object.value.triggers = triggers.value.map(trigger => {
      checkTriggerName(trigger)
      const triggerClone = cloneObject(trigger)
      delete triggerClone._id
      return triggerClone
    })
    triggerChanged.value = false
    showWarning.value = false
    close()
  }
}

const getItemClass = (trigger) => ({
  'constructor-triggers__trigger': true,
  'constructor-triggers__trigger--active': currentTrigger.value._id === trigger._id
})

const isCheckedItem = (item) => {
  return checkedTriggers.value.includes(item)
}
const checkItem = (val, item) => {
  if (val) {
    checkedTriggers.value.push(item)
  } else {
    checkedTriggers.value = checkedTriggers.value.filter(trigger => trigger !== item)
  }
}

const copyCheckedTriggers = (item) => {
  menuStates.value[item._id] = false
  store.commit('setCopyTriggers', { type: entityType.value, triggers: checkedTriggers.value })
}
const doubleCheckedTriggers = (item) => {
  menuStates.value[item._id] = false
  checkedTriggers.value.forEach(trigger => addTrigger(trigger))
}
const deleteCheckedTriggers = (trigger) => {
  actionTrigger.value = checkedTriggers.value
  showDeleteDialog.value = true
}

const addNewTrigger = () => {
  const trigger = cloneObject(defaultTrigger.value)
  applyTrigger(addTrigger(trigger))
}

const addTrigger = (trigger) => {
  const triggerClone = cloneObject(trigger)
  triggerClone._id = triggers.value.length ? Math.max(...triggers.value.map(_trigger => _trigger._id)) + 1 : 1
  triggers.value.push(triggerClone)
  return triggerClone
}

const doubleTrigger = (item) => {
  applyTrigger(addTrigger(item))
}

const deleteTrigger = (trigger) => {
  actionTrigger.value = trigger
  showDeleteDialog.value = true
}

const filterTriggers = (trigger) => {
  triggers.value = triggers.value.filter(item => item !== trigger)
  checkedTriggers.value = checkedTriggers.value.filter(item => item !== trigger)
}
const deleteTriggers = () => {
  if (Array.isArray(actionTrigger.value)) {
    actionTrigger.value.forEach(item => filterTriggers(item))
  } else {
    filterTriggers(actionTrigger.value)
  }
  applyTrigger(defaultTrigger.value)
  selected.value = []
  showDeleteDialog.value = false
}

const applyTrigger = (trigger) => {
  currentTrigger.value = trigger

  // Re-select effects
  effects.value.forEach(effect => {
    effect.selected = currentTrigger.value.effect.includes(effect.name)
  })
}

const cloneObject = (object) => {
  const objectClone = lodashClonedeep(object)
  return Object.assign(Object.create(Object.getPrototypeOf(objectClone)), objectClone)
}

const drawScene = () => {
  // Handle scene
  player.value.scene = previewObject.value

  // Clone and add planes
  constructor.planes.forEach(_plane => {
    const plane = cloneObject(_plane)
    plane.ctx = player.value.ctx
    player.value.planes.push(plane)
  })

  // Clone and add essences
  constructor.essence.forEach(_essence => {
    const essence = cloneObject(_essence)
    essence.ctx = player.value.ctx
    player.value.essence.push(essence)
  })

  // Clone and add lines
  constructor.lines.forEach(_line => {
    const line = Object.assign(Object.create(Object.getPrototypeOf(_line)), _line)
    line.ctx = player.value.ctx
    player.value.lines.push(line)
  })

  // Clone and add text fields
  constructor.textFields.forEach(_textField => {
    const textField = Object.assign(Object.create(Object.getPrototypeOf(_textField)), _textField)
    textField.ctx = player.value.ctx
    player.value.textFields.push(textField)
  })
}

const drawText = () => {
  player.value.textFields.push(previewObject.value)
  const textCoordinates = MapHelper.getTextStartAndEndCoordinates(previewObject.value)
  const width = Math.abs((textCoordinates.endX - textCoordinates.startX) * Math.cos((previewObject.value.rotateDegree * Math.PI) / 180))
  const height = Math.abs((textCoordinates.endY - textCoordinates.startY) * Math.sin((previewObject.value.rotateDegree * Math.PI) / 180))
  // Calculate paddings to move an entity to the center of the canvas
  const paddingX = Math.max((player.value.cvs.width - width) / 2, 20)
  // Move entity to fit the canvas
  previewObject.value.x -= Math.min(textCoordinates.startX, textCoordinates.endX) - paddingX
  previewObject.value.y -= Math.min(textCoordinates.startY, textCoordinates.endY) - 20

  const zoom = Math.min((player.value.cvs.width - 20) / width, (player.value.cvs.height - 40) / height, 1)
  player.value.Zoom(zoom)
}

const drawEssence = () => {
  previewObject.value.cvs = player.value.cvs

  if (previewObject.value.gifEffects.length) {
    setTimeout(() => {
      previewObject.value.gifEffects.forEach(gifEffect => {
        gifEffect.SetCanvas(player.value.cvs)
        gifEffect.img = gifEffect.img.cloneNode()
        gifEffect.isEffectPlay = false
      })
    }, 500)
  }
  player.value.essence.push(previewObject.value)

  // Calculate paddings to move an entity to the center of the canvas
  const essenceCoordinates = MapHelper.getEssenceStartAndEndCoordinates(previewObject.value)
  const { paddingX, paddingY } = getObjectPaddings(essenceCoordinates)

  // Move entity to fit the canvas
  const offsetX = essenceCoordinates.startX - paddingX
  const offsetY = essenceCoordinates.startY - paddingY
  previewObject.value.x -= offsetX
  previewObject.value.y -= offsetY
}

const drawPlane = () => {
  player.value.planes.push(previewObject.value)

  // Calculate paddings to move an entity to the center of the canvas
  const planeCoordinates = MapHelper.getPlaneStartAndEndCoordinates(previewObject.value)
  const { paddingX, paddingY } = getObjectPaddings(planeCoordinates)

  // Move entity to fit the canvas
  const offsetX = planeCoordinates.startX - paddingX
  const offsetY = planeCoordinates.startY - paddingY
  previewObject.value.a.x -= offsetX
  previewObject.value.b.x -= offsetX
  previewObject.value.c.x -= offsetX
  previewObject.value.d.x -= offsetX
  previewObject.value.a.y -= offsetY
  previewObject.value.b.y -= offsetY
  previewObject.value.c.y -= offsetY
  previewObject.value.d.y -= offsetY
}

const drawLine = () => {
  player.value.lines.push(previewObject.value)

  // Calculate paddings to move an entity to the center of the canvas
  const lineCoordinates = MapHelper.getLineStartAndEndCoordinates(previewObject.value)
  const { paddingX, paddingY } = getObjectPaddings(lineCoordinates)

  // Move entity to fit the canvas
  const offsetX = lineCoordinates.startX - paddingX
  const offsetY = lineCoordinates.startY - paddingY
  previewObject.value.points.forEach(point => (point = { x: (point.x -= offsetX), y: (point.y -= offsetY) }))
}

const getObjectPaddings = (coordinates) => {
  let paddingX = 0
  let paddingY = 0
  const isEntityWithinTheScene = coordinates.endX - coordinates.startX < player.value.cvs.width && coordinates.endY - coordinates.startY < player.value.cvs.height
  if (isEntityWithinTheScene) {
    paddingX = (player.value.cvs.width - (coordinates.endX - coordinates.startX)) / 2
    paddingY = (player.value.cvs.height - (coordinates.endY - coordinates.startY)) / 2
  }
  return { paddingX, paddingY }
}

const applyCurrentTriggerToPreviewObject = () => {
  if (previewObject.value) {
    Object.assign(previewObject.value, {
      bgColor: currentTrigger.value.bgColor,
      textSize: currentTrigger.value.textSize,
      message: currentTrigger.value.message,
      lineColor: currentTrigger.value.lineColor,
      lineWidth: currentTrigger.value.lineWidth,
      state: currentTrigger.value.state,
      color: currentTrigger.value.color,
      effect: currentTrigger.value.effect,
      icons: currentTrigger.value.icons,
      Notification: currentTrigger.value.Notification,
      NotificationColor: currentTrigger.value.NotificationColor
    })
  }
}

const handleObjectTriggers = () => {
  object.value.triggers.forEach((trigger, index) => {
    const triggerClone = cloneObject(trigger)
    checkTriggerName(triggerClone)
    triggerClone._id = index + 1
    triggerClone.effect = Array.isArray(triggerClone.effect) ? triggerClone.effect : [triggerClone.effect]
    triggers.value.push(triggerClone)
  })
}

const checkTriggerName = (trigger) => {
  trigger.name = trigger.name || trigger.condition.substring(0, NAME_LENGTH_COUNTER)
}

const init = () => {
  previewObject.value = cloneObject(object.value)

  // Handle object type depended entities
  headers.value = [...DEFAULT_HEADERS]
  if (isEssence.value) {
    addHeader('trigger_object_hdr_gif_effects_column', 'gifEffects')
    addHeader('trigger_object_hdr_state_column', 'state.name')
    addHeader('trigger_object_hdr_icon_column', 'icons')
    effects.value = objectEffects.value
    objectId.value = object.value.id
    entityType.value = ENTITY_TYPE_ESSENCE
    Object.assign(defaultTrigger.value, cloneObject(defaultEssenceParams))
    Object.assign(previewObject.value, cloneObject(defaultEssenceParams))
  } else if (isScene.value) {
    addHeader('trigger_scene_hdr_notification', 'Notification', 'center')
    addHeader('trigger_scene_lbl_notification_color', 'NotificationColor')
    effects.value = sceneEffects.value
    objectId.value = ENTITY_ID_SCENE
    entityType.value = ENTITY_TYPE_SCENE
    Object.assign(defaultTrigger.value, cloneObject(defaultSceneParams))
    Object.assign(previewObject.value, cloneObject(defaultSceneParams))
  } else if (isLine.value) {
    addHeader('trigger_line_lbl_color', 'color')
    effects.value = lineEffects.value
    objectId.value = object.value.id
    entityType.value = ENTITY_TYPE_LINE
    Object.assign(defaultTrigger.value, cloneObject(defaultLineParams))
    Object.assign(previewObject.value, cloneObject(defaultLineParams))
  } else if (isPlane.value) {
    headers.value = headers.value.filter(item => item.value !== 'effect')
    addHeader('trigger_background_lbl_background_color', 'bgColor')
    addHeader('trigger_background_lbl_stroke_color', 'lineColor')
    objectId.value = ENTITY_ID_PLANE
    entityType.value = ENTITY_TYPE_PLANE
    Object.assign(defaultTrigger.value, cloneObject(defaultPlaneParams))
    Object.assign(previewObject.value, cloneObject(defaultPlaneParams))
  } else if (isText.value) {
    headers.value = headers.value.filter(item => item.value !== 'effect')
    addHeader('trigger_text_lbl_text', 'message')
    addHeader('trigger_text_lbl_color', 'color')
    objectId.value = ENTITY_ID_TEXT
    entityType.value = ENTITY_TYPE_TEXT
    Object.assign(defaultTrigger.value, cloneObject(defaultTextParams), { message: object.value.message })
    Object.assign(previewObject.value, cloneObject(defaultTextParams), { message: object.value.message })
  }

  headers.value.forEach(header => {
    header.text = i18n.t(header.text)
    header.sortable = false
  })

  applyTrigger(defaultTrigger.value)
  handleObjectTriggers()
  nextTick(() => {
    triggerChanged.value = false
    initialized.value = true
  })
}

init()
</script>

<style scoped lang="scss">
.cbr-trigger-dialog {
  height: 600px;
  width: 100%;

  &__table-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 196px;
    padding-bottom: 16px;
  }

  &__btn-container {
    display: flex;
    justify-content: center;
    height: 48px;
  }

  &__name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 26px 4px 26px;
    span {
      width: 80px;
      color: $base-color;
    }
    .cbr-trigger-name {
      width: 100%;
    }
    border-bottom: 1px solid rgba($base-color, 0.1);
  }

  &__object-container {
    max-height: 280px;
    display: flex;
    justify-content: space-between;
    padding: 16px 26px 20px 26px;
  }

  &__object-image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 16px;
    gap: 24px;
  }

  &__all-content {
    display: flex;
  }

  &__effects-container {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    border-right: 1px solid rgba($base-color-light, 0.1);
    width: 100%;
    height: 100%;
  }

  &__effects-text {
    color: $base-color;
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__object-effect {
    color: $base-color;
    margin-bottom: 6px;
  }

  &__icons-gif {
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
  }

  &__icons-container {
    display: flex;
    flex-direction: column;
    max-height: 50px;
    max-width: 250px;
    width: 250px;
  }

  &__icons-span {
    color: $base-color;
    font-size: 16px;
  }

  &__select-container {
    display: flex;
    align-items: center;
    padding-left: 6px;
  }

  &__select-icon {
    margin-right: 6px;
  }

  &__select-name {
    color: $base-color-text;
  }

  &__menu-icon {
    position: absolute;
    top: 4px;
  }

  &__gif-container {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }

  &__gif {
    margin-bottom: 6px;
  }

  &__condition {
    display: flex;
    flex-direction: column;
    width: 470px;
  }

  &__condition-text {
    display: flex;
    align-items: center;
  }

  &__condition-icon {
    ::v-deep path {
      fill: $base-color;
    }
  }

  &__line {
    display: flex;
    flex-direction: column;
    width: 390px;
  }

  &__line-settings {
    display: flex;
  }

  &__line-container {
    display: flex;
    flex-direction: column;
    width: 390px;
    margin-right: 24px;
  }

  &__line-title {
    color: $base-color;
    font-size: 16px;
  }

  &__width-color {
    display: flex;
    justify-content: space-between;
    color: $base-color;
    font-size: 16px;
    gap: 12px;
  }

  &__line-wrapper {
    display: flex;
  }

  &__line-color {
    @include cut-corners(3px, true, 3px, false);
    display: block;
    width: 16px;
    height: 16px;
  }

  &__plane-container {
    display: flex;
    flex-direction: column;
    color: $base-color;
    font-size: 16px;
    width: 250px;
  }

  &__bg-wrapper {
    display: flex;
    justify-content: end;
    margin-right: 6px;
  }

  &__bg {
    height: 16px;
    width: 16px;
    @include cut-corners(3px, true, 3px, false);
  }

  &__object-text {
    display: flex;
    flex-direction: column;
    color: $base-color;
    font-size: 16px;
    width: 380px;
  }

  &__size-color {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  &__scene-container {
    display: flex;
  }

  &__scene-effects {
    display: flex;
    flex-direction: column;
    width: 210px;
    color: $base-color;
    font-size: 16px;
    border-right: 1px solid rgba($base-color-light, 0.1)
  }

  &__text-color {
    color: $base-color;
    font-size: 16px;
    width:300px;
    margin-left:5px;
    margin-right:5px;
  }
}

.cbr-trigger-table {
  @include scrollbar-small;
  &__number {
    width: 26px;
  }
  &__checkbox {
    width: 26px;
  }
  &__icon {
    display: flex;
    &-img {
      display: flex;
      align-items: center;
      max-height: 24px;
      width: 24px;
      img {
        max-width: 100%;
        max-height: 90%;
      }
    }
    &-label {
      max-width: calc(100% - 24px);
    }
  }
  &__menu {
    display: flex;
    justify-content: end;
    color: $base-color;
  }
  &__menu-dots {
    color: $base-color !important;
  }
  &__menu-content {
    background-color: rgba($base-color, 0.05) !important;
    backdrop-filter: blur(12.5px);
    filter: drop-shadow(0px 4px 4px rgba($base-black, 0.25));
  }
  &__list {
    width: 200px;
  }
  &__list-item {
    padding: 4px 25px;
    min-height: 28px;
  }
  &__list-title {
    color: $base-color;
    font-size: 16px;
  }
  &__list-last {
    border-bottom: 1px solid rgba($base-color-light, 0.1) !important;
  }
  &__line-container {
    display: flex;
    align-items: center;
  }
  &__line-width {
    margin-left: 6px;
    color: $base-color-light;
  }
  &__line-px {
    color: $base-color-text;
  }

  ::v-deep td {
    @include text-ellipsis;
  }
}

.cbr-warning-dialog {
  &__content-delete {
    text-align: center;
    width: 360px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    width: 95%;
  }
}

.cbr-trigger-dialog__object-icon {
  max-height: 26px !important;
  width: 100%;
  @include cut-corners(7px, true, 7px, false, 2px, $base-color);
  ::v-deep .v-text-field>.v-input__control>.v-input__slot:before  {
    display: none !important;
  }
  ::v-deep .v-select__slot{
    width: 220px !important;
    max-width: 220px !important;
  }
}

::v-deep .v-data-table.cbr-trigger-table {
  .v-data-table__wrapper {
    overflow-y: hidden;
  }
  tr {
    background: rgba($base-color, 0.05);
    td {
      height: 24px;
      line-height: 24px;
      color: $base-color-text;
      padding: 0px;
      &:not(:nth-child(-n+2)) {
        width: 30%;
        max-width: 300px;
        padding: 0 8px;
      }
      &:first-child {
        width: 26px;
        border-right: 2px solid rgba($base-black, .12);
        border-left: 2px solid transparent;
      }
    }
    &:hover:not(.v-data-table__empty-wrapper) {
      background: rgba($base-color, 0.08) !important;
      cursor: pointer;
      td:first-child {
        border-left: 2px solid $base-color;
      }
    }
    &.v-data-table__empty-wrapper {
      display: flex;
      margin-top: 78px;
      td {
        width:100% !important;
        align-content: center;
      }
    }
  }
}

.cbr-border-right {
  border-right: 1px solid rgba($base-color-light, 0.1);
}

.cbr-trigger-menu-active {
  border: 2px solid $base-color;
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

::v-deep .label-value {
  color: $base-color !important;
}

#trigger_object_btn_create_state ::v-deep svg path {
  fill: $ActionColor;
}

::v-deep .constructor-triggers__trigger--active {
  background-color: rgba($base-color, 0.08) !important;
  tr {
    background: rgba($base-color, 0.08) !important;
  }
  td:first-child {
    border-left: 2px solid $base-color !important;
  }
}

.cbr-trigger-dialog {
  &__scene {
    display: grid;
    grid-template-columns: 366px 215px 405px;
    grid-template-rows: 240px;
    margin: 16px 26px;
    gap: 24px;
    overflow: hidden;
  }
  &__scene-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__scene-effects {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__scene-effects-heading {
    color: $ActionColor;
    margin: 0 !important;
    font-family: Rubik,sans-serif;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;

  }
  &__scene-effects-list {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  &__scene-settings {
    display: flex;
    flex-direction: column;
    gap: 5px;
    &-notification {
      flex: 1;
    }
    &-text-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
</style>
