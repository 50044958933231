<template>
  <div class="log-line">
    <span class="time">{{ messageTime }}</span>
    <component :is="{render:dynamicMessage}"/>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import moment from 'moment'

const props = defineProps({
  message: Object,
  startedAt: Number,
  isObserver: Boolean
})

const messageTime = computed(() => {
  const diff = moment(props.message.timestamp).diff(moment(props.startedAt))
  return moment(diff).utc().format('HH:mm:ss')
})

const dynamicMessage = (createElement) => {
  const messageElements = []
  const m = props.message.message
  const matches = m.matchAll(/{(\d+)}/g)
  let lastPart = 0
  for (const match of matches) {
    messageElements.push(m.substring(lastPart, match.index))
    const number = parseInt(match[1])
    const argument = props.message.arguments[number]
    if (typeof argument !== 'undefined') {
      switch (argument.color) {
        case 'WHITE':
          messageElements.push(createElement('span', { class: { 'log-message-white-argument': true } }, argument.value))
          break
        case 'GREEN':
          messageElements.push(createElement('span', { class: { 'log-message-green-argument': true } }, argument.value))
          break
        case 'RED':
          messageElements.push(createElement('span', { class: { 'log-message-red-argument': true } }, argument.value))
          break
        case 'ORANGE':
          messageElements.push(createElement('span', { class: { 'log-message-orange-argument': true } }, argument.value))
          break
        default:
          messageElements.push(argument.value)
      }
    }
    lastPart = match.index + match.length + 1
  }
  messageElements.push(m.substring(lastPart))
  return createElement('span', { class: { text: true } }, messageElements)
}
</script>

<style lang="scss">
.log-message-green-argument {
  color: $ActionColor;
}

.log-message-white-argument {
  color: $StaticLight;
}

.log-message-orange-argument {
  color: $WarningColor;
}

.log-message-red-argument {
  color: $AlarmTextColor;
}
</style>

<style lang="scss" scoped>
.log-line {
  display: flex;

  color: $StaticLight;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .time {
    white-space: nowrap;
    margin-right: 0.5em;
  }

  .text {
    color: rgba($StaticLight, 0.5);
    width: 350px;
  }
}
</style>
