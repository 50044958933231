import httpClient from './http'

const END_POINT = '/user'
export default {
  getUsers (organizationId, role, search, filterActive, sortField, sortDesc = false, page = 0, pageSize = 200) {
    const params = {
      organizationId: organizationId,
      role: role,
      search: search,
      activeness: filterActive,
      page: page,
      size: pageSize,
      sort: sortField,
      order: sortDesc ? 'desc' : 'asc'
    }
    return httpClient.get(END_POINT, {
      params: params
    })
  },
  getUsersWithParams ({
    organizationId,
    role,
    roles,
    search,
    activeness,
    sortField,
    sortDesc = false,
    orgType = null,
    page = 0,
    size = 200,
    ctf
  }) {
    return httpClient.get(END_POINT, {
      params: {
        organizationId,
        role,
        roles,
        search,
        activeness,
        orgType,
        page,
        size,
        ctf,
        sort: sortField,
        order: sortDesc ? 'desc' : 'asc'
      }
    })
  },
  uploadUsers (batchUsersFile) {
    const config = { headers: { 'Content-Type': batchUsersFile.type } }
    return httpClient.post(`${END_POINT}/upload`, batchUsersFile, config)
  },
  batchCreateUsers (batchObject) {
    return httpClient.post(`${END_POINT}/create/batch`, batchObject)
  },
  createUser (user) {
    return httpClient.post(END_POINT, user)
  },
  editUser (id, user) {
    return httpClient.put(END_POINT + '/' + id, user)
  },
  checkEmail (email) {
    return httpClient.get(END_POINT + '/checkEmail', { params: { email: email } })
  },
  disableUser (id) {
    return httpClient.patch(END_POINT + '/' + id + '/changeStatus')
  },
  removeUser (id) {
    return httpClient.delete(END_POINT + '/' + id)
  },
  distributeVms (params) {
    return httpClient.get(`${END_POINT}/defence/distributionsVm`, { params })
      .then(data => {
        const result = []
        Object.keys(data).forEach(userId => {
          result.push({ userId: parseInt(userId), vmId: data[userId] })
        })
        return result
      })
  },
  loadUser (id) {
    return httpClient.get(END_POINT + '/' + id)
  },
  getCurrentUser (initialRequest) {
    return httpClient.get(END_POINT + '/current', { __isInitialRequest: initialRequest })
  },
  getUserProfileForCurrentUser () {
    return httpClient.get(END_POINT + '/profile')
  },
  getUserProfileForAdmin (id) {
    return httpClient.get(`${END_POINT}/${id}/profile`)
  },
  getAvatar (id) {
    return httpClient.get(`${END_POINT}/${id}/image`)
  },
  addAvatar (id, avatar) {
    return httpClient.post(`${END_POINT}/${id}/image`, avatar)
  },
  deleteAvatar (id) {
    return httpClient.delete(`${END_POINT}/${id}/image`)
  },
  getActiveAdmins (orgId) {
    return httpClient.get(`${END_POINT}/org-active-admins`, {
      params: {
        orgId,
      }
    })
  }
}
