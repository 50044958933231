<template>
  <CbrDialog
    v-model="dialog"
    :title="$t('user_card_hdr_user_profile')"
    max-width="1024px"
    id="user-profile-header"
  >
    <CbrForm slot="content" class="profile-form">
      <div class="profile-form__main">
        <AvatarPasswordEdit ref="avatar" v-model="dialog" :user="userProfile.user" />
        <div class="d-flex flex-column flex-grow-1">
          <div class="mb-auto">
            <div class="user-data__block user-name">
              <CbrHint :description="fullName" bottom no-icon>
                <div class="text-block">{{ fullName }}</div>
              </CbrHint>
            </div>
            <div class="user-data__block position-org-name">
              <CbrHint :description="positionOrgName" bottom no-icon>
                <div class="text-block">{{ positionOrgName }}</div>
              </CbrHint>
            </div>
            <div
              v-if="currentUser.role !== roles.USER.key && currentUser.role !== roles.OBSERVER.key"
              class="user-data__block user-email"
            >
              <CbrHint :description="userProfile.user?.email" bottom no-icon>
                <div class="text-block">{{ userProfile.user?.email }}</div>
              </CbrHint>
            </div>
          </div>
          <div class="mt-auto mb-8">
            <CbrTextArea
              id="user-details-input"
              :value="userProfile.user?.description"
              :placeholder="$t('user_card_lbl_user_status_plh')"
              disabled
              rows="3"
              hide-details
            />
          </div>
        </div>
      </div>
      <div v-if="areEventsShown && state.isLoaded" class="mt-4">
        <div class="user-events__header">{{ $t('user_card_hdr_records') }}</div>
        <div class="user-events__content">
          <UserEventsList
            v-if="state.isLoaded"
            :events="(isSelfProfile || isAdmin) && userProfile.events ? userProfile.events : []"
          />
        </div>
      </div>
      <Preloader v-if="state.isLoading" />
    </CbrForm>
  </CbrDialog>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from '@/store'
import { useStateLoading } from '@/composables/useStateLoading'
import usersService from '@/services/user.service'
import users from '@/helpers/users.helper'
import roles from '@/helpers/roles.helper'
import UserEventsList from '@/components/user/UserEventsList'
import AvatarPasswordEdit from '@/components/user/edit/AvatarPasswordEdit'

defineProps(['user'])
const store = useStore()
const { state, setStateLoading, setStateLoaded } = useStateLoading()
const userId = ref(0)
const userProfile = ref({})
const dialog = ref(false)
const isAdmin = computed(() => store.getters['account/isAdmin'])
const currentUserId = computed(() => store.getters['account/currentUserId'])
const userProfileShow = computed(() => store.getters['global/userProfileShow'])
const currentUser = computed(() => store.getters['account/user'])

const fullName = computed(() => userProfile.value.user
  ? `${userProfile.value.user.lastName} ${userProfile.value.user.firstName} ${userProfile.value.user.middleName}`
  : ''
)
const positionOrgName = computed(() => {
  let result = ''
  if (!userProfile.value.user) return result
  if (userProfile.value.user.position) {
    result = userProfile.value.user.position + ', '
  }
  return result + (userProfile.value.user.org?.name || userProfile.value.user.organization?.name)
})
const isSelfProfile = computed(() => userId.value === currentUserId.value)
const isNotAdminProfile = computed(() =>
  userProfile.value.user?.role !== roles.ADMIN.key && userProfile.value.user?.role !== roles.OBSERVER.key
)
const isNotAuditorProfile = computed(() => {
  return userProfile.value.user?.role !== roles.AUDITOR.key
})

const loadAndOn = async () => {
  setStateLoading()
  try {
    if (isSelfProfile.value) {
      userProfile.value = await usersService.getUserProfileForCurrentUser()
    } else {
      userProfile.value = await usersService.getUserProfileForAdmin(userId.value)
    }
  } catch (e) {
    dialog.value = false
  }
  setStateLoaded()
}

watch(() => userProfileShow.value, (newValue) => {
  if (newValue) {
    setStateLoading()
    userId.value = newValue.id
    userProfile.value.user = newValue
    dialog.value = true

    loadAndOn()
    users.showProfile(null)
  }
})

const areEventsShown = computed(() => {
  return isNotAuditorProfile.value && isNotAdminProfile.value
})
</script>

<style lang="scss" scoped>

.profile-form {
  width: 100%;
  &__main {
    padding-top: 16px;
    display: flex;
    gap: 36px;
    .user-data__block {
      display: list-item;
      margin-bottom: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 685px;
    }
    .user-name {
      color: $StaticLight;
      font-size: 24px;
      line-height: normal;
    }
    .text-block {
      display: inline-block;
      max-width: 685px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .position-org-name {
      color: rgba($StaticLight, 0.7);
      font-size: 18px;
    }
    .user-email {
      color: $ActionColor;
      font-size: 18px;
      line-height: normal;
    }
  }
  .user-events {
    &__header {
      width: 100%;
      height: 36px;
      background: linear-gradient(90deg, rgba($base-color, 0.15) 0%, rgba($base-color, 0) 100%);
      display: block;
      text-align: start;
      font-size: 18px;
      line-height: 36px;
      padding: 0px 12px;
      color: $base-color-text;
    }
    &__content {
      width: 100%;
      @include cut-corners(0px, false, 8px, false, 1px, rgba($base-color, 0.25));
      display: block;
      border-top: none;
      padding: 0;
    }
  }
}
</style>
