<template>
  <div class="cbr-content-block">
    <p
      v-if="label"
      class="cbr-content-block__label"
      :class="{ 'cbr-content-block__label--clickable': clickable }"
      @click="onLabelClick"
    >
      {{ label }}
    </p>
    <div
      class="cbr-content-block__wrapper"
      :class="getContentClasses"
      :style="{height: maxHeight}"
    >
      <div class="cbr-content-block__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
  withBorder: {
    type: Boolean,
    default: false,
  },
  background: {
    type: String,
    default: 'dark',
    validator: (value) => {
      return ['dark', 'transparent'].includes(value)
    }
  },
  maxHeight: String
})
const emit = defineEmits(['click:content-block-label'])

const getBackgroundClass = computed(() => {
  const backgroundClassesMap = {
    dark: 'background--dark',
    transparent: 'background--transparent'
  }

  return backgroundClassesMap[props.background]
})

const getBorderClass = computed(() => {
  return props.withBorder ? 'cbr-content-block__wrapper--bordered' : 'cbr-content-block__wrapper--no-border'
})

const getContentClasses = computed(() => [getBackgroundClass.value, getBorderClass.value])

const onLabelClick = () => {
  if (!props.clickable) return
  emit('click:content-block-label')
}
</script>

<style lang="scss" scoped>
.cbr-content-block {
  position: relative;
  width: 100%;

  &__label {
    position: absolute;
    left: 24px;
    top: -12px;
    margin: 0;
    padding: 0 8px;
    z-index: 2;
    backdrop-filter: blur(3px);
    color: $base-color;
    font-family: $primary-font-family;
    font-size: $font-size-medium;

    &--clickable {
      cursor: pointer;
    }
  }

  &__wrapper {
    width: 100%;
    @include cut-corners(9px, true, 9px, false, 1px, rgba($base-color, .15));
    display: flex;

    &--bordered {
      @include cut-corners(9px, true, 9px, false, 1px, rgba($base-color, .15));
      display: flex;
    }
    &--no-border {
      @include cut-corners(9px, true, 9px, false, 0, transparent);
      display: flex;
    }
  }

  &__content {
    overflow-y: auto !important;
    width: 100%;
    height: 100%;
    padding: 4px !important;
    color: $base-color-text;
    @include scrollbar-small;
    overflow-wrap: break-word;
  }

  .background {
    &--dark {
      background-color: rgba($base-color-dark, 0.35);
      backdrop-filter: blur(12.5px);
    }
    &--transparent {
      background-color: transparent;
    }
  }
}
</style>
