<template>
  <div class="languages">
    <CbrCombobox
        v-if="props.combobox"
        :items="langs"
        width="300px"
        :preselect="currLang"
        :clearable="false"
        @input="setLanguage($event)"
        :prepend-item-icon-fn="getLanguageIcon"
    />
    <CbrButton
        v-else
        v-for="lng in langs"
        :key="lng.key"
        :text="lng.code"
        :border="currLang !== lng"
        width="63px" style="height: 30px"
        @click="setLanguage(lng)"
    />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, getCurrentInstance } from 'vue'
import moment from 'moment'
import { i18n, loadLanguageAsync } from '@/i18n-setup'

const props = defineProps({
  combobox: {
    type: Boolean,
    default: () => false
  }
})

const vueCurrentInstance = getCurrentInstance().proxy

const lang = ref('')
const active = ref(false)
const langs = [
  {
    key: 'ru',
    icon: 'ru',
    code: 'РУС',
    name: 'Русский'
  // }, {
  //   key: 'uz',
  //   icon: 'uzb',
  //   code: 'O\'ZB',
  //   name: 'O\'zbek'
  }, {
    key: 'en',
    icon: 'eng',
    code: 'ENG',
    name: 'English'
  }
]
const currLang = computed(() => langs.find(l => l.key === i18n.locale))
const setLanguage = (language) => {
  const lng = language.key
  // set lang for vuetify components
  vueCurrentInstance.$vuetify.lang.current = lng
  // set lang for dates
  moment.locale(lng)
  window.localStorage.setItem('lang', lng)
  lang.value = lng
  active.value = !active.value
  loadLanguageAsync(lng)
  moment.updateLocale(lng, i18n.t('moment_locale'))
}
const getLanguageIcon = (item) => `$${item.icon}`

onMounted(() => {
  lang.value = window.localStorage.getItem('lang') || 'ru'
})
</script>

<style scoped lang="scss">
.languages {
  position: relative;
  display: flex;
  width: 300px;
  height: 40px;
  margin-bottom: 80px;
}
</style>
