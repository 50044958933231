export default {
  home: {
    iconName: 'uikit/home'
  },
  information: {
    iconName: 'uikit/info'
  },
  cbrInformation: {
    iconName: 'uikit/cbr-information'
  },
  inputReset: {
    iconName: 'uikit/input-reset'
  },
  sorting: {
    iconName: 'uikit/sorting'
  },
  sortingActive: {
    iconName: 'uikit/sorting-active'
  },
  menuOpen: {
    iconName: 'uikit/menu_open'
  },
  menuOpenTriangle: {
    iconName: 'uikit/menu_open_triangle'
  },
  required: {
    iconName: 'uikit/required_m'
  },
  organization: {
    iconName: 'uikit/organization'
  },
  organizationBlockUser: {
    iconName: 'uikit/organization-block-user'
  },
  organizationInternal: {
    iconName: 'uikit/organization-internal'
  },
  script: {
    iconName: 'uikit/script'
  },
  circlePlus: {
    iconName: 'uikit/circle_plus'
  },
  person: {
    iconName: 'uikit/Person'
  },
  cbrSettings: {
    iconName: 'uikit/btn/settings'
  },
  cbrLog: {
    iconName: 'uikit/btn/log'
  },
  cbrBasket: {
    iconName: 'uikit/btn/delete'
  },
  organisationBlockUser: {
    iconName: 'uikit/organization-block-user'
  },
  cbrUploadXls: {
    iconName: 'uikit/btn/upload-xls'
  },
  cbrNewUser: {
    iconName: 'uikit/btn/new-user'
  },
  cbrPlusCircle: {
    iconName: 'uikit/btn/plus-circle'
  },
  cbrPlusCircle18: {
    iconName: 'uikit/btn/cbr-plus-circle-18'
  },
  cbrError: {
    iconName: 'uikit/cbr-error'
  },
  cbrAlarmHint: {
    iconName: 'uikit/cbr-alarm-hint'
  },
  cbrFile: {
    iconName: 'uikit/cbr-file'
  },
  cbrSave: {
    iconName: 'uikit/btn/cbr-save'
  },
  cbrPlus: {
    iconName: 'uikit/btn/plus'
  },
  cbrInfo: {
    iconName: 'uikit/cbr-info'
  },
  cbrDropFilePlus: {
    iconName: 'uikit/drop-file-plus'
  },
  cbrDownload: {
    iconName: 'uikit/btn/download'
  },
  cbrClose: {
    iconName: 'uikit/btn/cbr-close'
  },
  cbrSend: {
    iconName: 'uikit/btn/send'
  },
  cbrEdit: {
    iconName: 'uikit/btn/edit'
  },
  cbrAttack: {
    iconName: 'uikit/attack'
  },
  datepicker_arrow_right: {
    iconName: 'uikit/datepicker_arrow_right'
  },
  datepicker_arrow_left: {
    iconName: 'uikit/datepicker_arrow_left'
  },
  cbrRefresh: {
    iconName: 'uikit/btn/refresh'
  },
  cbrCopyLink: {
    iconName: 'uikit/btn/cbr-copy-link'
  },
  cbrOpenEmail: {
    iconName: 'uikit/open_email'
  },
}
