var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',_vm._b({attrs:{"headers":_setup.props.headers,"items":_setup.props.items,"server-items-length":_setup.props.serverItemsLength,"options":_setup.options,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 25, 50, 100]
      },"loading-text":_setup.props.loadingText,"no-data-text":_setup.props.noDataText,"hide-default-header":_vm.hideDefaultHeader,"hide-default-footer":"","disable-filtering":_vm.disableFiltering,"fixed-header":_vm.fixedHeader},on:{"update:options":function($event){_setup.options=$event},"click:row":function($event){return _vm.$emit('click:row', $event)}},scopedSlots:_vm._u([{key:"top",fn:function({ pagination, options, updateOptions }){return [(!Object.keys(_vm.$attrs).includes('disable-pagination'))?_c('div',{staticClass:"cbr-top-panel"},[_vm._t("topTitle",null,{"pagination":pagination}),_c('CbrPagination',{staticClass:"ml-auto",attrs:{"value":options.page,"length":Math.ceil(pagination.itemsLength / pagination.itemsPerPage),"total-visible":7,"pagination":pagination,"options":options,"perPageOptions":[5, 10, 25, 50, 100]},on:{"update":function($event){return updateOptions({ page: $event })},"updateOptions":function($event){return updateOptions({ itemsPerPage: $event })}}})],2):_vm._e()]}},(_vm.hideDefaultHeader && !Object.keys(_vm.$attrs).includes('disable-header'))?{key:"header",fn:function(data){return [_c('thead',[_c('tr',{staticClass:"cbr-data-table__table-header"},_vm._l((_setup.props.headers),function(header){return _c('th',{key:header.value,staticClass:"pointer",attrs:{"width":header.width},on:{"click":function($event){_setup.sortData(
                header.value,
                !_setup.isItemSortDesc(header),
                header.sortModifier,
                header.sortable
              )}}},[(header.icon)?_c('div',{staticClass:"cbr-data-table__btn-container"},[_c('CbrIcon',[_vm._v(_vm._s(header.icon))])],1):_c('span',{staticClass:"cbr-data-table__th"},[(header.sortable)?_c('CbrSortPicker',{attrs:{"is-desc":_setup.isItemSortDesc(header)}}):_vm._e(),_vm._v(" "+_vm._s(header.text)+" ")],1)])}),0)])]}}:null,_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}}),{key:"no-data",fn:function(){return [_vm._t("no-data")]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }