const stringHelper = {
  sanitize (string) {
    return this.deleteMultipleSpaces(string).trim()
  },
  deleteMultipleSpaces (string) {
    return string.replace(/\s+/g, ' ')
  },
  // Удаляет пустые строк, строки состоящие из незначащих символов, пробелы слева и справа у неаустых строк
  deleteEmptyLinesAndSpaces (string) {
    return !string ? string : string
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0)
      .join('\n')
  },
  replaceAt (string, index, replace) {
    return string.substring(0, index) + replace + string.substring(index + 1)
  },
  isInclude (haystack, needle) {
    return haystack.toUpperCase().indexOf(needle.toUpperCase()) !== -1
  },
  showIfLength (string, length) {
    return string && string.length > length ? string : ''
  },
  isLatin (str) {
    return /[a-z]/gi.test(str)
  },
  isCyrillic (str) {
    return /[А-Я-Ё]/gi.test(str)
  },
  getSortValue (a, b) {
    let res = true
    const length = a.length > b.length ? a.length : b.length

    for (let i = 0; i < length; i++) {
      if (a[i] === undefined || b[i] === undefined) {
        res = a[i] !== undefined
        break
      }

      const aChar = a[i].toString().toUpperCase()
      const bChar = b[i].toString().toUpperCase()

      if (a[i] !== b[i]) {
        if (stringHelper.isCyrillic(aChar) && stringHelper.isLatin(bChar)) {
          res = false
          break
        } else if (stringHelper.isLatin(aChar) && stringHelper.isCyrillic(bChar)) {
          res = true
          break
        } else {
          res = a.toUpperCase() > b.toUpperCase()
          break
        }
      }
    }

    return res
  }
}

export default stringHelper
