<template>
  <div class="event-log-container">
    <div class="event-log-tabs" v-if="!isUser">
      <div class="event-log-filters">
        <CbrCheckBox :checked="isSelected(filter)"
            v-for="filter in filters"
            :key="filter.value"
            :id="filter.id"
            :label="filter.title"
            @change="selectFilter(filter)"
        />
      </div>
    </div>
    <div class="event-log-content-container" :style="calcHeight">
      <div class="event-log-content">
        <LogLine
          v-for="(message, i) in filteredMessages"
          :message="message"
          :is-observer="false"
          :started-at="props.startedAt"
          :key="i"
        ></LogLine>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import LogLine from '@/views/event/log/LogLine'
import { i18n } from '@/i18n-setup.js'
import { useStore } from '@/store'
import _ from 'lodash'

const store = useStore()

const props = defineProps({
  startedAt: Number,
  eventId: Number,
  eventLogData: {
    type: Array,
    default: () => []
  },
  visibility: Boolean
})

const filters = ref([
  {
    value: 'HIGH',
    id: 'event-log-high',
    title: i18n.t('event_screen_popup_history_chk_high')
  },
  {
    value: 'MEDIUM',
    id: 'event-log-medium',
    title: i18n.t('event_screen_popup_history_chk_medium')
  },
  {
    value: 'LOW',
    id: 'event-log-low',
    title: i18n.t('event_screen_popup_history_chk_low')
  }
])
const selectedFilters = ref(['HIGH', 'MEDIUM'])
const scrolled = ref(false)

watch(() => props.visibility, (val) => {
  if (val) {
    sortEvents()
    scrollMessagesToBottom()
  }
})

onMounted(() => {
  const messages = document.querySelector('.event-log-content-container')
  messages.addEventListener('scroll', e => {
    scrolled.value = messages.scrollTop + messages.clientHeight + 1 < messages.scrollHeight
  })
  restoreFilters()
})

const isUser = computed(() => store.getters['account/isUser'])
const currentUserId = computed(() => store.getters['account/currentUserId'])
const filteredMessages = computed(() => {
  if (isUser.value) {
    return _.uniqBy(props.eventLogData, 'timestamp')
  } else {
    return _.uniqBy(props.eventLogData, 'timestamp')
      .filter(m => selectedFilters.value.indexOf(m.priority) !== -1)
  }
})
const calcHeight = computed(() => { return { height: isUser.value ? '100%' : 'calc(100% - 50px)' } })

const isSelected = (filter) => {
  return selectedFilters.value.indexOf(filter.value) !== -1
}
const selectFilter = (filter) => {
  if (!isSelected(filter)) {
    selectedFilters.value.push(filter.value)
  } else {
    selectedFilters.value = selectedFilters.value.filter(value => value !== filter.value)
  }
  storeFilters()
}
const storeFilters = () => {
  localStorage.setItem(filtersLocalStorageKey(), JSON.stringify(selectedFilters.value))
}
const filtersLocalStorageKey = () => {
  return `${props.eventId}-${currentUserId.value}-eventLogFilters`
}
const sortEvents = () => {
  props.eventLogData.sort((a, b) => {
    if (a.timestamp > b.timestamp) {
      return 1
    }
    if (a.timestamp < b.timestamp) {
      return -1
    }
    return 0
  })
}
const scrollMessagesToBottom = () => {
  nextTick(() => {
    if (!scrolled.value) {
      const messages = document.querySelector('.event-log-content-container')
      messages.scrollTop = messages.scrollHeight
    }
  })
}
const restoreFilters = () => {
  const jsonFilter = localStorage.getItem(filtersLocalStorageKey())
  if (jsonFilter) {
    selectedFilters.value = JSON.parse(jsonFilter)
  }
}

defineExpose({
  scrollMessagesToBottom
})
</script>

<style lang="scss">
.event-log-container { // body
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
  width: 100%;

  .event-log-content-container {
    overflow-y: auto;
    @include scrollbar();

    display: flex;
    padding: 10px 0;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    background: $PopupDarkBack;

    .event-log-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      flex: 1 0 0;
      align-self: stretch;
    }
  }
}

.event-log-tabs {
  display: flex;
  align-items: center;
  min-height: 37px;
  width: 100%;
  background: #0B2A3A;
  opacity: 0.9;
  color: #00F0FF;

  .event-log-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    font-size: 14px;
    line-height: 16px;
    padding: 0 30px;
  }
}
</style>
