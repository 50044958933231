<template>
  <div
    v-if="isShow"
    :style="containerStyles"
    class="console-container"
    :class="{ fixed }"
    ref="container"
  >
    <VueDraggableResizable
      v-if="fixed"
      :onDrag="handleDrag"
      :draggable="draggable"
      :min-width="500"
      :w="width"
      class="user-console-container"
    >
      <Console
        :event="event"
        @mousedown="handleDown"
        @mouseup="handleUp"
        @changeFullscreen="changeFullscreen()"
        :isFullscreen="isFullscreen"
        @close="handleClose()"
        :fixed="fixed"
        :guacamole-mode="guacamoleMode"
        :isReloadingVM="isReloadingVM"
        :user="user"
        @reloading-vm="emit('reloading-vm', $event)"
      />
    </VueDraggableResizable>
    <div class="user-console-full-container" v-else>
      <Console
        :event="event"
        @mousedown="handleDown"
        @mouseup="handleUp"
        @close="emit('close')"
        :fixed="fixed"
        :guacamole-mode="guacamoleMode"
        :isReloadingVM="isReloadingVM"
        :user="user"
        @reloading-vm="emit('reloading-vm', $event)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import Console from '@/components/event/console/Console'
import VueDraggableResizable from 'vue-draggable-resizable'

const props = defineProps({
  user: Object,
  event: Object,
  guacamoleMode: Boolean,
  isReloadingVM: Boolean,
  fixed: Boolean
})
const emit = defineEmits(['reloading-vm', 'close'])

const width = ref('auto')
const height = ref('auto')
const adminVmOffset = ref(0)
const overflowLimit = ref(50)
const windowSizes = reactive({
  width: 0,
  height: 0
})
const containerStyles = ref({})
const draggable = ref(false)
const x = ref(0)
const y = ref(0)
const isFullscreen = ref(false)

const isShow = computed(() => {
  const isShow = !!props.user?.id
  if (isShow) {
    nextTick(() => setContainerStyles())
  }
  return isShow
})

function changeFullscreen () {
  isFullscreen.value = !isFullscreen.value
  nextTick(() => setContainerStyles())
}
function handleClose () {
  isFullscreen.value = false
  x.value = 0
  y.value = 0
  emit('close')
}
function setWindowSizes () {
  windowSizes.width = window.innerWidth
  windowSizes.height = window.innerHeight
}
function handleUp () {
  draggable.value = false
}
function handleDown () {
  if (!isFullscreen.value) {
    draggable.value = true
    nextTick(() => setContainerStyles())
  }
}
function handleDrag (nextX, nextY) {
  x.value = nextX
  y.value = nextY
  if (
    width.value + nextX + adminVmOffset.value < overflowLimit.value || // left limit
    windowSizes.width - nextX - adminVmOffset.value < overflowLimit.value || // right limit
    nextY < -60 || // top limit
    windowSizes.height - nextY < overflowLimit.value + 150 // bottom limit
  ) {
    return false
  }
}
const containerTemplateRef = ref()
function setContainerStyles () {
  const widthOffset = 20

  if (window.innerWidth < 1230 || !props.fixed || isFullscreen.value) {
    adminVmOffset.value = 0
  } else {
    adminVmOffset.value = 530
  }
  width.value = Math.round(
    window.innerWidth -
    (props.fixed && !isFullscreen.value ? adminVmOffset.value + widthOffset : 0)
  )
  height.value = props.fixed && !isFullscreen.value ? containerTemplateRef.value?.offsetHeight : window.innerHeight
  let topOffset = 0
  let rightOffset = 0
  if (props.fixed) {
    topOffset = 80
    rightOffset = adminVmOffset.value
  }

  if (isFullscreen.value) {
    topOffset = 0
    rightOffset = adminVmOffset.value + x.value
  }

  containerStyles.value = {
    width: `${width.value}px`,
    height: `${height.value}px`,
    top: `${topOffset}px`,
    right: `${rightOffset}px`
  }
  if (isFullscreen.value) {
    containerStyles.value['z-index'] = 150
  }
}

onMounted(() => {
  window.addEventListener('resize', onResize)
  setWindowSizes()
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})
function onResize () {
  setWindowSizes()
  setContainerStyles()
}

</script>

<style lang="scss" scoped>
.console-container {
  position: absolute;
  background-color: black;

  .user-console-container {
    position: relative;
    z-index: 1;
  }

  .user-console-full-container {
    @extend .user-console-container;
    height: 100% !important;
  }
}
</style>
