import { i18n } from '@/i18n-setup.js'

const control = {
  status: {
    MUMBLE: {
      color: 'orange'
    },
    UP: {
      color: 'green'
    },
    DOWN: {
      color: 'red'
    }
  },
  errors: {
    MAX_LENGTH_1024: 'Введенное значение не должно быть более 1024 символов.',
    MAX_LENGTH_64: 'Введенное значение не должно быть более 64 символов.',
    REQUIRED: i18n.t('event_creation_editing_lbl_error_event_name_empty')
  },
  COMMAND_HINT: 'type: RAW\n' +
    'osType: LINUX\n' +
    'vmId: user1\n' +
    'execPath: bash\n' +
    'arguments:\n' +
    '   - /_test_scenario_script/three_states.sh'
}

export default control
