import router from '@/router'
import { i18n } from '@/i18n-setup'

export default {
  show403ErrorPage (action = null, text = i18n.t('user_panel_popup_no_access_rights_txt_page_view')) {
    this.showErrorPage(action, text)
  },
  show404ErrorPage (action, text = 'Страница не найдена') {
    this.showErrorPage(action, text)
  },
  showNoConnectionErrorPage () {
    this.showErrorPage(null, 'Отсутствует подключение к серверу')
  },
  showErrorPage (action, text) {
    router.push({
      name: 'error',
      params: {
        errorText: text,
        action: action
      }
    })
  },
  getFileName (response) {
    return response.headers.get('Content-Disposition')?.replace('*=UTF-8\'\'', '=').split('filename=')[1]
  },
  processRoute (routeParams, mouseEvent) {
    if (mouseEvent?.which === 3) return
    if (mouseEvent?.ctrlKey || mouseEvent?.which === 2) {
      window.open(router.resolve(routeParams).href, '_blank')
    } else {
      router.push(routeParams)
    }
  },
  generateColorObjectOrString (color) {
    if (!color) {
      return 'red'
    }
    const rgbRegex = new RegExp(/\s*rgb\((\d{1,3}),\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)\s*,?/)
    const rgbaRegex = new RegExp(/\s*rgba\((\d{1,3}),\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(1|0)(\.[1-9])?\s*\)/)
    const rgbResult = color.match(rgbRegex)
    const rgbaResult = color.match(rgbaRegex)
    if (rgbResult) {
      return { r: rgbResult[1], g: rgbResult[2], b: rgbResult[3] }
    } else if (rgbaResult) {
      return { r: rgbaResult[1], g: rgbaResult[2], b: rgbaResult[3], a: rgbaResult[4] + (rgbaResult[5] || '') }
    } else {
      return color
    }
  },
  generateColorString (color) {
    if (typeof color === 'string') {
      return color
    } else {
      return `rgba(${color.r},${color.g},${color.b},${color.a || 1})`
    }
  }
}
