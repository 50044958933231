<template>
  <v-snackbar
    :vertical="true"
    v-model="visible"
    :multi-line="true"
    :timeout="-1"
    absolute
    bottom
    right
  >
    <h3>{{ getTypeErrors }}</h3>
    <div class="pt-2">{{ getMsgError }}</div>

    <template v-slot:action="{ attrs }">
      <CbrButton
        v-bind="attrs"
        error
        @click="visible = false"
        :text="$t('event_screen_popup_hint_btn_close')"
      />
    </template>
  </v-snackbar>
</template>

<script setup>
import { computed } from 'vue'
import errors from '@/helpers/errors.helper'
import { useStore } from '@/store'
import { i18n } from '@/i18n-setup'

const store = useStore()

const getTypeErrors = computed(() => {
  if (!visible.value) return ''
  return errors[store.state.global.errorRequestType]?.text || i18n.t('event_card_lbl_error')
})
const getMsgError = computed(() => {
  return store.state.global.errorRequestMsg
})
const visible = computed({
  get: () => {
    return store.state.global.errorRequestVisible
  },
  set: (m) => {
    return store.commit('global/setErrorRequestVisible', { status: m })
  }
})
</script>

<style scoped>
.v-snack--absolute {
  z-index: 300;
}
</style>
