import { commonEn, momentLocale } from './common/common.en'
import { eulaEn } from '@/locales/eula/eula.en'
import { taskEn } from './task/task.en'
import { mapEn } from './map/map.en'
import { authEn } from '@/locales/auth/auth.en'
import { eventEn } from '@/locales/event/event.en'
import { scoreboardEn } from '@/locales/scoreboard/scoreboard.en'

export default {
  ...commonEn,
  ...taskEn,
  ...mapEn,
  ...authEn,
  ...eventEn,
  ...scoreboardEn,

  eula: eulaEn,
  moment_locale: momentLocale,

  tech_map_hdr_select_tech: 'Select technique',
  tech_map_btn_accept: 'Confirm',
  tech_map_btn_cancel: 'Cancel',
  tech_map_edit_filter_plh: 'Enter keywords (e.g. scan port user exploit)…',
  tech_card_hint_tactic: 'Tactic / group of techniques',
  tech_card_lbl_targets: 'Targets:',
  tech_card_lbl_phase: 'Phase (killchain):',
  tech_card_lbl_desc: 'Description:',
  tech_card_lbl_tags: 'Tags:',
  tech_card_lbl_iocs: 'Indicators of compromise:',
  login_form_incorrect_email: 'entered incorrect E-mail',
  user_card_lbl_error_photo: 'Unable to upload photo',
  user_card_popup_edit_lbl_error_photo: 'Unable to upload photo',
  user_panel_popup_create_user_lbl_error_photo: 'Unable to upload photo',
  user_card_lbl_error_upload_smaller_photo: 'Please select image less than 256kb',
  user_card_popup_edit_lbl_error_upload_smaller_photo: 'Please select image less than 256kb',
  user_panel_popup_create_user_lbl_error_upload_smaller_photo: 'Please select image less than 256kb',
  user_card_lbl_error_photo_type: 'Incorrect image format selected',
  user_panel_lbl_number_users: 'Users:',
  authentication_change_pwd_lbl_more_characters: '10 or more characters',
  event_card_hdr_column_email: 'E-mail',
  event_creation_editing_lbl_personal: 'Personal score',
  event_creation_editing_popup_observes_hdr_column_email: 'E-mail',
  event_creation_editing_popup_participants_hdr_column_email: 'E-mail',
  bulk_user_uploading_popup_grid_users_hdr_column_email: 'E-mail',
  org_panel_popup_admins_hdr_column_email: 'E-MAIL',
  auth_popup_password_change_edit_email: 'Email*',
  user_authorization_lst_choose_language_eng: 'English',
  scoring_popup_send_report_error_btn_confirm: 'OK',
  event_card_hdr_column_assesment_hint: 'Rate',
  event_card_hdr_column_VM: 'VM',
  event_creation_editing_popup_participants_hdr_column_vm: 'VM',
  event_creation_editing_popup_participants_lbl_assigned_vm: 'Assigned VM:',
  event_creation_editing_popup_participants_lbl_participate: 'Participating',
  event_creation_editing_popup_participants_lbl_ready: 'Ready:',
  event_creation_editing_popup_participants_lbl_new: 'New:',
  event_creation_editing_popup_participants_lbl_infra: 'Slot',
  event_creation_editing_popup_participants_lbl_error_add_users: 'More participants than VM available',
  event_creation_editing_hdr_process_automation: 'Process automation',
  event_creation_editing_btn_auto_publ_grade: 'Auto-grading',
  event_creation_editing_btn_completion_status_of_technician: 'Technician completion status',
  event_creation_editing_btn_report_prefilling: 'Report prefilling',
  event_creation_type_training: 'Teaching/training',
  event_creation_type_training_title: 'Practical and control classes on detection, investigation and protection from spacecraft. The system automatically attacks the infrastructure according to the selected scenario.',
  event_creation_type_flag: 'Flag capture',
  event_creation_type_flag_title: 'A competition in which teams must penetrate the infrastructure of opponents and place a flag while protecting their infrastructure from penetration.',
  event_creation_type_opposition: 'Confrontation',
  event_creation_type_opposition_title: 'Teams must protect their infrastructure and attack the infrastructure of opponents in order to cause denial of service of critical services',
  event_creation_ctf_team_title: 'Team',
  navigation_panel_popup_menu_btn_admin_panel: 'Admin panel',
  user_card_lbl_user_role_admin: 'Administrator',
  user_panel_lst_role_admin: 'Admin',
  org_panel_lst_activity_active: 'Active',
  user_panel_lst_activity_active: 'Active',
  event_creation_editing_btn_attacks: 'Attacks',
  scoring_scoreboard_hdr_column_scores: 'Score',
  scoring_scoreboard_hdr_column_company: 'Оrganization',
  event_card_hdr_column_scores: 'Score',
  org_panel_popup_unfinish_ev_user_btn_block: 'Block',
  event_card_lbl_event_status_in_progress: 'In progress!',
  auth_popup_password_change_txt_get_instruction: 'Enter the e-mail address to which instructions for restoring access to the system will be sent:',
  user_panel_edit_enter_user_name_plh: 'Enter user name',
  event_creation_editing_edit_event_name: 'Please enter event name',
  missions_list_edit_search_mission_plh: 'Enter mission name or mission ID',
  org_panel_edit_enter_org_plh: 'Enter organization',
  event_creation_editing_edit_event_goals_plh: 'Enter event\'s goals and objectives',
  event_screen_popup_phase_task_lbl_vector: 'Vector',
  event_screen_popup_hint_lbl_vector: 'Vector',
  scoring_popup_settings_tgl_display_places_on_plh: 'On',
  event_creation_editing_btn_attacks_on_plh: 'On',
  event_creation_editing_btn_noises_on_plh: 'On',
  event_creation_editing_btn_event_completion_on_plh: 'On',
  event_creation_editing_btn_scoring_on_plh: 'On',
  event_creation_editing_btn_auto_publ_grade_on_plh: 'On',
  event_card_lbl_demo_stand: 'Stand',
  event_card_txt_demo_stand_plh: 'Config not loaded',
  event_card_txt_demo_stand_err: 'Wrong file format!',
  event_creation_editing_lbl_upload_file_stand_plh: 'Upload file',
  event_creation_editing_btn_upload_file_stand: 'Upload',
  org_panel_popup_create_org_chk_internal_org: 'Internal organization',
  org_panel_popup_edit_org_chk_internal_org: 'Internal organization',
  user_authorization_btn_enter: 'Enter',
  scoring_participant_report_edit_recovery_desc: 'Recovery',
  auth_popup_password_change_lbl_password_recovery: 'Password recovery',
  scoring_participant_report_crumbs_all_events: 'All events',
  event_creation_editing_crumbs_all_events: 'All events',
  scoring_report_evaluation_crumbs_all_events: 'All events',
  event_card_crumbs_all_events: 'All events',
  event_card_log_crumbs_all_events: 'All events',
  event_creation_editing_crumbs_all_missions: 'All missions',
  event_creation_editing_crumbs_missions_list: 'Missions list',
  mission_card_crumbs_all_missions: 'All missions',
  missions_list_hdr_all_missions: 'All missions',
  missions_list_tabs_publicated: 'Published',
  missions_list_tabs_in_progress: 'In development',
  missions_list_remove_popup_title: 'Delete mission',
  missions_list_remove_popup_first_line: 'Do you really want to delete the mission?',
  missions_list_copy_title: 'Copy of',
  user_panel_lst_org_all_plh: 'All organizations',
  user_panel_lst_role_all_plh: 'All roles',
  event_popup_event_end_txt_end: 'Are you sure that you want to end event?',
  event_card_popup_reboot_txt_question_descr: '*Operation will take some time, virtual machine will be available in 1-2 minutes.',
  scoring_popup_del_tech_txt_ask_del: 'Are you sure you want to remove the report for this technique?',
  scoring_popup_add_tech_lst_tech_type_plh: 'Technique',
  scoring_popup_settings_lst_select_event_plh: 'Select event',
  scoring_popup_settings_name_not_found: 'No name',
  scoring_popup_settings_status_not_found: 'Status unknown',
  scoring_popup_settings_time_not_found: 'time not found',
  scoring_popup_settings_date_not_found: 'date not found',
  event_creation_editing_lst_organization_plh: 'Select organization',
  bulk_user_uploading_lst_error_select_organization: 'Select organization',
  scoring_popup_add_tech_lst_attack_type_plh: 'Tactic',
  bulk_user_uploading_btn_select_file: 'Select file .XLSX',
  user_authorization_lst_choose_language: 'Choose language:',
  scoring_popup_settings_tgl_display_places_off_plh: 'Off',
  event_creation_editing_btn_attacks_off_plh: 'Off',
  event_creation_editing_btn_noises_off_plh: 'Off',
  event_creation_editing_btn_event_completion_off_plh: 'Off',
  event_creation_editing_btn_scoring_off_plh: 'Off',
  event_creation_editing_btn_auto_publ_grade_off_plh: 'Off',
  event_screen_popup_history_chk_high: 'High',
  auth_popup_password_change_edit_next: 'Next',
  event_card_log_edit_date_time: 'Starts',
  event_creation_editing_edit_event_date_time: 'Starting date and time',
  user_card_hdr_column_start_date: 'Start date',
  org_panel_popup_unfinish_event_hdr_column_start_date: 'Start date',
  org_panel_popup_unfinish_ev_user_hdr_column_start_date: 'Start date',
  user_card_hdr_column_end_date: 'End date',
  org_panel_hdr_column_org_type: 'Type',
  org_panel_hdr_column_creation_date: 'Creation date',
  user_panel_hdr_column_creation_date: 'Creation date',
  event_creation_editing_finished: 'The event was completed or canceled',
  authentication_change_pwd_lbl_create_safe_password: 'Create secure password to protect your data',
  event_creation_editing_lbl_no_scoring: 'Scoring is impossible.',
  event_creation_editing_lbl_no_report_first: 'No reference report for event.',
  event_creation_editing_lbl_no_report_second: ' Scoring is impossible.',
  event_creation_editing_btn_teams_add: 'Add team',
  event_creation_editing_popup_observes_hdr_add_observes: 'Add observers',
  scoring_participant_report_btn_add_tech: 'Add technique',
  event_creation_editing_popup_participants_hdr_add_participants: 'Add participants',
  event_card_hdr_column_position: 'Position',
  event_creation_editing_popup_observes_hdr_column_position: 'Position',
  bulk_user_uploading_popup_grid_users_hdr_column_position: 'Position',
  user_card_popup_edit_edit_position: 'Position',
  user_panel_hdr_column_position: 'Position',
  user_panel_popup_create_user_edit_position_plh: 'Position',
  bulk_user_uploading_popup_users_lbl_error_line_duplication_hint: 'Duplicates line:',
  user_authorization_btn_forgot_password: 'Forgot password?',
  event_creation_editing_btn_event_completion: 'Сompletion of event',
  bulk_user_uploading_popup_users_btn_upload: 'Upload',
  user_panel_btn_upload: 'Upload',
  user_card_lbl_upload_photo: 'Upload photo',
  user_card_popup_edit_btn_upload_photo: 'Upload photo',
  user_panel_popup_create_user_btn_upload_photo_plh: 'Upload photo',
  user_panel_popup_confirm_del_hdr_user_del: 'Remove user',
  user_panel_popup_confirm_del_lbl_msg: 'Are you sure you want to remove user?',
  user_panel_popup_del_blocked_lbl_msg: 'The user is participating in the event and cannot be deleted!',
  bulk_user_uploading_btn_upload: 'Upload',
  bulk_user_uploading_hdr_uploading_users: 'Loading users',
  bulk_user_uploading_popup_deleted_lines_hdr_uploading_users: 'Upload users',
  bulk_user_uploading_popup_created_users_hdr_uploading_users: 'Upload users',
  mission_card_lst_task: 'Task',
  event_screen_popup_hint_btn_close: 'Close',
  event_creation_editing_popup_participants_lbl_error_search_no_content: 'No records with similar name found',
  event_creation_editing_edit_error_event_time_false_data: 'Please fill in required data and select type of event',
  event_creation_editing_lbl_complete_fields: 'Сomplete all fieldsmarked with an * and event settings',
  event_creation_editing_lbl_fill_list_participants: 'Complete participants list',
  event_card_btn_start: 'Start',
  id_scoring_popup_failed_data_txt_error: 'Failed to get report data',
  events_list_btn_start: 'Start',
  scoring_participant_report_edit_protection_desc: 'Protection',
  event_creation_editing_lbl_protection: 'Protection',
  events_list_lbl_protection: 'Protection',
  events_list_lbl_battle: 'Battle',
  missions_list_lbl_protection: 'Protection',
  event_screen_popup_particip_hdr_defenders: 'Defenders',
  authentication_change_pwd_lbl_punctuation_marks: 'Punctuation marks',
  scoring_report_evaluation_tag_tech_status_changed: 'Changed',
  scoring_report_evaluation_tag_tech_status_under_evaluation: 'On evaluation',
  scoring_report_evaluation_tag_tech_status_rated: 'Rated',
  scoring_report_evaluation_tag_ioc_status_changed: 'Changed',
  event_card_btn_change: 'Edit',
  event_card_btn_edit: 'Edit',
  event_creation_editing_popup_edit_notsuccessfully_hdr_edit: 'EDIT EVENT',
  event_creation_editing_popup_edit_notsuccessfully_hdr_event_1: 'The event has changed its status. The changes have not been saved.',
  event_creation_editing_popup_edit_notsuccessfully_hdr_event_2: 'Refresh the page to continue.',
  event_creation_editing_popup_edit_notsuccessfully_btn_refresh: 'Refresh page',
  bulk_user_uploading_lbl_drag_file: 'drag-and-drop file',
  bulk_user_uploading_popup_users_lbl_drag_updated_file: 'Drag-and-drop new file',
  bulk_user_uploading_popup_users_txt_error_delete_erroneous_lines: 'There are duplicate lines or lines with errors.\nLines with errors will be removed while loading.',
  org_panel_popup_unfinish_event_hdr_events: 'Unfinished events:',
  bulk_user_uploading_popup_users_lbl_error_blank_fields_hint: 'Mandatory fields missed:',
  bulk_user_uploading_popup_grid_users_hdr_column_name: 'First name',
  event_screen_edit_login_plh: 'User\'s name',
  user_card_popup_edit_edit_name: 'First name*',
  user_panel_popup_create_user_edit_name_plh: 'First name',
  event_card_link_crib: 'Crib',
  event_card_popup_crib_hdr: 'Crib',
  event_card_popup_crib_event: 'Event',
  event_card_link_info: 'Information',
  event_card_link_info_subtitle: 'About mission',
  event_screen_popup_history_hdr_event_history: 'Event\'s history',
  mission_card_lbl_mission_conditions: 'Baseline',
  map_close_dialog_title: 'Map close',
  map_close_dialog_question: 'Are you sure you want to close the map?',
  create_config_hdr_create_config: 'New Visualization',
  create_config_lbl_unsaved_files: 'When creating a new visualization scheme, the current one will be cleared.\nAre you sure you want to clear the current schema?', // newIdLoc
  create_config_btn_clear: 'Clear', // newIdLoc
  create_config_btn_cancel: 'Cancel', // newIdLoc
  event_card_link_map: 'Infrastructure',
  mission_card_link_map: '2D Map',
  mission_card_link_map_3d: '3D Map',
  event_screen_popup_particip_hdr_team: 'Team',
  scoring_report_empty_report: 'The report is empty',
  scoring_report_evaluation_edit_teacher_comment_plh: 'Comment',
  scoring_participant_report_edit_comment: 'Comment to teacher',
  scoring_report_evaluation_edit_teacher_comment: 'Teacher\'s comment',
  scoring_report_evaluation_edit_participant_comment: 'Participant\'s comment',
  scoring_report_evaluation_edit_participant_comment_plh: 'No comment',
  user_authorization_edit_login_plh: 'Login/Email',
  user_card_popup_edit_edit_login: 'Login/Email',
  user_panel_popup_create_user_edit_login_plh: 'Login/E-mail*',
  user_panel_lst_activity_all_plh: 'All states',
  mission_card_lbl_max_participants: 'max.',
  event_card_popup_materials_hdr_manuals: 'Manuals',
  mission_card_link_manuals: 'Manuals',
  event_screen_popup_manuals_hdr_manuals: 'Manuals',
  event_card_link_manuals: 'Manuals',
  event_card_manuals_zip: 'Manuals.zip',
  user_card_hdr_column_event: 'Event',
  org_panel_popup_unfinish_event_hdr_column_event: 'Event',
  org_panel_popup_unfinish_ev_user_hdr_column_event: 'Event',
  event_popup_event_ended_lbl_notification: 'Event is completed',
  event_card_lbl_event_creator: 'Created by',
  scoring_popup_settings_lst_select_event: 'Event / Team',
  navigation_panel_popup_menu_btn_lk: 'Personal area',
  navigation_panel_popup_menu_btn_events: 'Events',
  events_list_lbl_duration_min: 'min.',
  mission_card_lbl_duration_min: 'min.',
  missions_list_lbl_duration_min: 'min.',
  round: 'no rounds | {n} round | {n} rounds',
  navigation_panel_popup_menu_btn_missions: 'Missions',
  navigation_panel_popup_menu_btn_tasks: 'Tasks',
  user_card_hdr_column_mission: 'Mission',
  bulk_user_uploading_lst_default_tz_plh: 'Moscow, Sochi (GMT + 03: 00) *',
  event_card_hdr_observes: 'Observers',
  event_creation_editing_lbl_observes: 'Observers',
  event_creation_editing_popup_observes_lbl_number_observes: 'Observers',
  user_card_lbl_user_role_observer: 'Observer',
  user_card_lbl_user_role_auditor: 'Auditor',
  user_card_lbl_user_role_infrastructure_architect: 'Infrastructure architect',
  user_card_lbl_user_role_researcher: 'Researcher',
  user_card_lbl_user_role_screenwriter_methodologist: 'Screenwriter-methodologist',
  event_creation_editing_lbl_event_name: 'Event name',
  event_editing_popup_assignment_VM_txt_reload_page_first_line: 'New virtual machine is assigned.',
  event_editing_popup_assignment_VM_txt_reload_page_second_line: 'Reload page.',
  event_editing_popup_assignment_VM_hdr_new_VM: 'Virtual machine assignment',
  scoring_popup_settings_hdr_scoreboard_settings: 'Scoreboard settings',
  event_card_edit_start: 'It starts in',
  scoring_report_evaluation_edit_protection_plh: 'Not specified',
  scoring_report_evaluation_edit_recovery_plh: 'Not specified',
  user_panel_popup_create_user_lbl_error_surname_empty: 'Mandatory field missed',
  user_panel_popup_create_user_lbl_error_name_empty: 'Mandatory field missed',
  user_panel_popup_create_user_lbl_error_patronymic_empty: 'Mandatory field missed',
  user_panel_popup_create_user_lbl_error_login_empty: 'Mandatory field missed',
  user_panel_popup_create_user_lbl_error_org_empty: 'Mandatory field missed',
  user_panel_popup_create_user_lbl_error_role_empty: 'Mandatory field missed',
  event_creation_editing_lbl_error_event_name_empty: 'Mandatory field missed',
  event_creation_editing_lbl_error_event_goals_empty: 'Mandatory field missed',
  event_creation_editing_lbl_error_event_participants_empty: 'At least one participant must be added',
  event_creation_editing_lbl_upload_file_stand_error: 'Upload stand config file or shutdown stand!',
  event_creation_editing_lbl_slot_not_ready: 'Slot not ready! Select another slot!',
  event_creation_editing_lbl_reserve: 'Reserve VMs of participants',
  org_panel_popup_create_org_lbl_error_blank_field: 'Mandatory field missed',
  org_panel_popup_edit_org_lbl_error_blank_field: 'Mandatory field missed',
  event_creation_editing_popup_participants_hdr_column_vm_not_assigned: 'Not assigned',
  org_panel_lst_activity_not_filter_plh: 'No filter by activity',
  org_panel_lst_activity_inactive: 'Inactive',
  user_panel_lst_activity_inactive: 'Inactive',
  bulk_user_uploading_popup_users_lbl_error_wrong_format_hint: 'Invalid field format',
  user_authorization_lbl_error_login_wrong: 'Enter login or email',
  user_authorization_lbl_error_password_wrong: 'Enter password',
  org_panel_popup_create_org_txt_deleted_data_lost: 'Unsaved data will be lost. Are you sure you want to close the window?',
  event_card_hdr_column_status_no_report: 'No report',
  event_screen_popup_history_chk_low: 'Low',
  scoring_report_evaluation_tag_tech_status_new: 'New',
  scoring_report_evaluation_tag_ioc_status_new: 'New',
  event_card_hdr_column_status_new: 'New',
  authentication_change_pwd_lbl_new_password_plh: 'New password*',
  scoring_popup_add_tech_hdr_discovered: 'New attacking technique was detected',
  org_panel_popup_admins_hdr_detected_admins: 'Users with "Administrator" role were found:',
  bulk_user_uploading_popup_users_btn_update_file: 'Update file',
  event_screen_link_refresh: 'Refresh',
  event_screen_lbl_reboot: 'The virtual machine is being rebooted...\nThis operation will take 1-2 minutes.\nPlease wait.',
  event_card_hdr_column_updated: 'Updated minutes ago',
  event_card_lbl_training: 'Training',
  event_creation_editing_btn_training: 'Training',
  events_list_lbl_training: 'Training',
  event_card_lbl_event_status_waiting: 'Waiting',
  event_editing_popup_assignment_VM_hdr_confirm: 'OK',
  event_popup_event_ended_btn_confirm: 'OK',
  event_popup_event_end_btn_confirm: 'Ok',
  auth_popup_password_change_btn_confirm: 'ОК',
  authentication_change_pwd_btn_confirm: 'OK',
  bulk_user_uploading_popup_deleted_lines_btn_confirm: 'OK',
  bulk_user_uploading_popup_created_users_btn_confirm: 'OK',
  org_panel_popup_unfinish_event_btn_confirm: 'OK',
  org_panel_popup_admins_btn_confirm: 'OK',
  org_panel_popup_create_org_btn_confirm: 'OK',
  org_panel_popup_edit_org_btn_confirm: 'OK',
  user_panel_popup_create_user_btn_confirm: 'Close',
  mission_card_lbl_mission_descr: 'Mission\'s description',
  scoring_participant_report_edit_protection_desc_plh: 'Describe methods of protection against attacking technique',
  scoring_participant_report_edit_recovery_desc_plh: 'Describe post attack recovery procedure',
  scoring_report_evaluation_btn_publish: 'Publish',
  scoring_report_evaluation_new_report_label: 'The report has not been generated yet, add the detected technique',
  scoring_report_evaluation_btn_refresh_report: 'Refresh report',
  scoring_report_evaluation_btn_publish_all: 'Submit all',
  scoring_report_evaluation_preloader_line_1: 'Submitting report.',
  scoring_report_evaluation_preloader_line_2: 'Editing is temporarily unavailable',
  scoring_report_evaluation_btn_no_change: 'No change',
  scoring_report_evaluation_btn_publishing: 'Report is publishing...',
  scoring_report_evaluation_btn_published: 'Report submitted',
  event_creation_editing_popup_observes_lst_org_plh: 'Organizations',
  org_panel_edit_error_org_not_found: 'No organizations found',
  scoring_scoreboard_hdr_column_org: 'Organization',
  event_creation_editing_lst_organization: 'Organization',
  event_creation_editing_popup_observes_hdr_column_organization: 'Organization',
  bulk_user_uploading_popup_grid_users_hdr_column_organization: 'Organization',
  user_panel_hdr_column_org: 'Organization',
  org_panel_popup_admins_txt_cant_block_org: 'Organization can\'t be blocked because of active admin records',
  org_panel_popup_unfinish_event_txt_cant_block_org: 'Organization can\'t be blocked because of unfinished events for it',
  org_panel_popup_unfinish_event_txt_cant_block_org_ext: 'Organization {org} cannot be blocked because it contains users participating in unfinished events',
  bulk_user_uploading_lst_default_organization_plh: 'Default organization *',
  org_panel_popup_edit_org_lbl_org_create_success: 'Organization was changed successfully',
  org_panel_popup_create_org_lbl_org_create_success: 'Organization was created successfully',
  org_panel_hdr_column_org: 'Organization',
  user_card_popup_edit_lst_organization: 'Organization',
  org_panel_popup_create_org_edit_org_plh: 'Organization*',
  org_panel_popup_edit_org_edit_org_plh: 'Organization*',
  user_panel_popup_create_user_lst_org_plh: 'Organization*',
  scoring_report_evaluation_lbl_participant_comm: 'Participant\'s answers',
  event_creation_editing_popup_observes_btn_cancel: 'Cancel',
  event_popup_event_end_btn_cancel: 'Cancel',
  event_card_lbl_finished: 'Completed',
  event_card_lbl_canceled: 'Canceled',
  event_card_lbl_event_status_canceled: 'Canceled',
  events_list_lbl_canceled: 'Canceled',
  event_card_btn_cancel: 'Cancel',
  event_card_popup_reboot_btn_cancel: 'Cancel',
  event_card_btn_finish: 'Finish',
  auth_popup_password_change_btn_cancel: 'Cancel',
  user_card_popup_edit_btn_cancel: 'Cancel',
  user_panel_popup_create_user_btn_cancel: 'Cancel',
  events_list_btn_cancel: 'Cancel',
  scoring_popup_del_tech_btn_cancel: 'Cancel',
  scoring_popup_settings_btn_cancel: 'Cancel',
  org_panel_popup_unfinish_ev_user_btn_confirm: 'Cancel',
  org_panel_popup_create_org_btn_cancel: 'Cancel',
  org_panel_popup_edit_org_btn_cancel: 'Cancel',
  scoring_popup_settings_tgl_display_places: 'Display places',
  scoring_participant_report_btn_send: 'Send report',
  scoring_popup_send_report_error_txt_no_sending: 'Report submission is not available. Debriefing is in progress or event completed',
  scoring_participant_report_btn_not_send: 'Send not available',
  scoring_participant_report_btn_not_send_hint: 'Scoring in progress or event ended',
  bulk_user_uploading_popup_grid_users_hdr_column_patronymic: 'Middle name',
  user_card_popup_edit_edit_patronymic: 'Middle name',
  user_panel_popup_create_user_edit_patronymic_plh: 'Middle name',
  event_card_link_report: 'Report',
  event_card_link_participant_report: 'Participant\'s report',
  event_card_link_participant_report_subtitle: 'About attack progress',
  scoring_participant_report_hdr_breadcrumbs: 'Participant\'s report',
  event_card_hdr_column_reports: 'Reports',
  event_card_hdr_column_status_rating: 'Review',
  event_card_hdr_column_status_rated: 'Scored',
  scoring_report_evaluation_lbl_ioc_not_matched: '* No match!\nIoC from another technique',
  scoring_report_evaluation_lbl_teacher_comm: 'Teacher’s assessment and comments',
  event_card_lbl_error: 'Error',
  events_list_lbl_error: 'Error',
  user_authorization_lbl_error: 'Error',
  events_list_lbl_errors: 'Errors',
  authentication_change_pwd_lbl_error_different_passwords: 'Password mismatch',
  user_authorization_edit_password_plh: 'Password',
  user_authorization_chk_accept_eula: 'I accept the term of',
  user_authorization_chk_accept_eula_link: 'End User License Agreement',
  user_authorization_chk_accept_eula_title: 'End User License Agreement',
  eula_btn_go_to_login: 'Go to login page',
  bulk_user_uploading_popup_grid_users_hdr_column_password: 'Password',
  event_screen_edit_password_plh: 'Password',
  authentication_change_pwd_lbl_password_changed: 'Password changed successfully!',
  event_card_lbl_pause_state: 'Pause',
  event_card_lbl_pause: 'Pause at the end of round',
  event_card_popup_reboot_btn_confirm: 'Reboot',
  event_card_popup_reboot_hdr_vm: 'Reboot',
  auth_popup_password_change_lbl_confirmation_letter: 'Confirmation letter with a link has been sent to your email',
  authentication_change_pwd_lbl_repeat_password_plh: 'Repeat password*',
  event_card_btn_prepare: 'Prepare',
  events_list_btn_prepare: 'Prepare',
  event_card_lbl_event_status_preparation: 'Preparation',
  events_list_lbl_preparation: 'Preparation',
  event_screen_popup_hint_hdr_hint: 'Hint',
  scoring_popup_settings_btn_confirm: 'Confirm',
  event_creation_editing_popup_observes_edit_search_observes_plh: 'Searching observers',
  org_panel_edit_search_org: 'Search organization',
  missions_list_lbl_search_mission: 'Search mission',
  user_panel_edit_search_user: 'Search user',
  event_creation_editing_popup_participants_edit_search_participants_plh: 'Searching participants',
  events_list_btn_show: 'Show',
  event_card_log_lbl_polygon: 'Polygon',
  event_creation_editing_lbl_polygon: 'Polygon',
  missions_list_lbl_polygon: 'Polygon:',
  event_card_btn_get_report: 'Get report',
  events_list_btn_get_report: 'Get report',
  event_screen_popup_phase_task_btn_get_hint: 'Get Hint',
  org_panel_popup_unfinish_ev_user_txt_user_cant_enter: 'Users of can\'t log in while organization is blocked. Are you sure you want to block organization?',
  user_panel_lst_error_user_not_found: 'No users found. Try resetting filters',
  bulk_user_uploading_popup_users_lbl_error_user_hint: 'User already exists in the system',
  bulk_user_uploading_popup_users_lbl_error_org_hint: 'Organization doesn\'t exist in the system',
  scoring_participant_report_edit_last_sent: 'Last report was sent',
  event_card_log_lbl_teacher: 'Teacher',
  events_list_lbl_teacher: 'Teacher',
  user_card_lbl_user_role_teacher: 'Teacher',
  user_panel_lst_role_teacher: 'Teacher',
  event_creation_editing_lst_error_no_teacher: 'Teachers are not indicated for the organization',
  scoring_participant_report_edit_comment_plh: 'If necessary, provide additional info about detected attacking technique and leave comments to teacher',
  event_screen_lbl_error_console: 'Virtual desktop login error',
  event_card_btn_view: 'View',
  event_card_btn_join: 'Join',
  event_card_btn_connect: 'Connect',
  event_card_btn_connect_hint: 'Connect to virtual desktop',
  event_creation_editing_lbl_mission_time_hint: 'Event duration',
  authentication_change_pwd_lbl_uppercase_letters: 'Uppercase letters',
  user_card_hdr_user_profile: 'User profile',
  user_authorization_lbl_rostelecom: 'Developed by Rostelecom',
  event_creation_editing_popup_participants_btn_assign_vm: 'Assign VM',
  event_creation_editing_crumbs_edit_event: 'Edit event',
  org_panel_popup_edit_org_hdr_create_org: 'Edit organization',
  user_card_popup_edit_hdr_edit_user: 'Edit user',
  scoring_scoreboard_hdr_results: 'Results',
  bulk_user_uploading_popup_grid_users_hdr_column_role: 'Role',
  user_panel_hdr_column_role: 'Role',
  user_card_popup_edit_lst_role: 'Role',
  user_panel_popup_create_user_lst_role_plh: 'Role*',
  user_authorization_lst_choose_language_rus: 'Русский',
  user_card_lbl_reset_pwd: 'Reset password',
  user_card_popup_edit_edit_reset_pwd: 'Reset password',
  teacher_report_new_user_version_title: 'Participant\'s report is out of date!',
  teacher_report_new_user_version_content: 'Publish the report to update.',
  teacher_report_new_user_version_exists: 'There are updates on the report',
  event_card_popup_materials_btn_download: 'Download all',
  event_screen_popup_manuals_btn_download_all: 'Download all',
  event_card_btn_download: 'Download scores',
  event_card_btn_download_lms: 'LMS download',
  event_card_btn_download_report: 'Download report',
  scoring_report_evaluation_btn_refresh: 'Update data',
  scoring_report_evaluation_btn_download: 'Download reference',
  event_creation_editing_link_download_report: 'Download reference report',
  event_creation_editing_btn_scoring: 'Scoring',
  event_creation_editing_lbl_scoring_off: 'Scoring is disabled, results and skills will not be assessed',
  events_list_btn_all: 'All',
  events_list_btn_wo_error: 'No errors',
  events_list_btn_w_error: 'Errors',
  event_card_lbl_complexity: 'Complexity',
  mission_card_lbl_complexity: 'Complexity',
  mission_card_lbl_import: 'Import',
  mission_card_lbl_export: 'Export',
  mission_card_popup_import_hdr_mission: 'Mission import',
  mission_card_popup_import_txt_successful: 'Configuration files imported successfully.',
  mission_card_popup_import_txt_error: 'Mission cannot be imported.',
  mission_card_popup_import_txt_error413: 'Error! The file is too large!<br>The file size must not exceed 50 MB<br>(52 428 800 bytes)',
  authentication_change_pwd_hdr_change_password: 'Change password',
  authentication_change_pwd_hdr_changed_password: 'Changed password',
  user_card_hdr_records: 'Records',
  event_creation_editing_crumbs_create_event: 'Event creation',
  event_creation_editing_lbl_missions_not_found: 'Missions are not found',
  org_panel_popup_create_org_hdr_create_org: 'Create organization',
  user_panel_popup_create_user_hdr_creation: 'User creation',
  bulk_user_uploading_popup_created_users_lbl_created_users: 'Created users:',
  scoring_popup_add_tech_btn_create: 'Create',
  user_panel_btn_create: 'Create',
  missions_list_btn_clone_mission: 'Clone mission',
  org_panel_btn_create_org: 'Create organization',
  org_panel_popup_create_org_btn_create: 'Create',
  event_creation_editing_popup_observes_btn_save: 'Save',
  user_card_popup_edit_edit_save: 'Save',
  user_panel_popup_create_user_btn_save: 'Save',
  org_panel_popup_create_org_btn_save: 'Save',
  org_panel_popup_edit_org_btn_save: 'Save',
  event_screen_popup_history_chk_medium: 'Medium',
  event_card_hdr_column_status: 'Status',
  event_card_lbl_event_status: 'State',
  user_card_lbl_user_status_plh: 'Status',
  user_card_popup_edit_edit_status_plh: 'State',
  user_panel_popup_create_user_edit_status_plh: 'State',
  authentication_change_pwd_lbl_lowercase_letters: 'Lowercase letters',
  scoring_scoreboard_hdr_results_table: 'Scoreboard',
  navigation_panel_popup_menu_btn_scoreboard: 'Scoreboard',
  navigation_panel_popup_menu_btn_dashboard: 'Dashboard',
  event_screen_hint_btn_participants_list: 'Participants list',
  event_screen_hint_btn_history_log: 'Event history',
  event_screen_hint_btn_tasks: 'Mission step tasks',
  navigation_panel_popup_menu_btn_event: 'Event card',
  navigation_panel_popup_menu_btn_visualization_editor: 'Visualization editor',
  navigation_panel_popup_menu_btn_3d_map: '3D Map editor',
  event_screen_hint_btn_materials: 'Mission materials',
  event_screen_hint_btn_topology: '2D Visualization',
  event_screen_hint_btn_topology_3d: '3D Visualization',
  event_screen_hint_btn_scoreboard: 'Participant results board',
  event_screen_hint_btn_participants_report: 'Participant report',
  event_screen_popup_phase_task_hdr_current_task: 'Current task',
  scoring_popup_add_tech_lst_select_attack_type: 'Attack technique',
  event_creation_editing_lbl_event_type: 'Event type',
  authentication_change_pwd_hdr_requirements: 'Password requirements',
  org_panel_popup_unfinish_ev_user_hdr_events: 'Users participate in unfinished events',
  scoring_popup_del_tech_hdr_delete: 'DELETE TECHNIQUE',
  bulk_user_uploading_popup_deleted_lines_lbl_number_deleted: 'Removed extra lines and entries with errors:',
  scoring_popup_del_tech_btn_del_tech: 'Delete',
  org_panel_popup_create_org_btn_delete: 'Delete',
  user_card_lbl_user_role_participant: 'Participant',
  user_panel_lst_role_participant: 'Participant',
  event_creation_editing_popup_participants_lbl_number_participants: 'Participants',
  event_card_hdr_participants: 'Participants of defense',
  event_card_lbl_exercises: 'Exercises',
  event_creation_editing_btn_exercises: 'Exercises',
  events_list_lbl_exercises: 'Exercises',
  user_panel_popup_create_user_lbl_created_success: 'Account created successfully!',
  bulk_user_uploading_popup_grid_users_hdr_column_surname: 'Last name',
  user_card_popup_edit_edit_surname: 'Last name',
  user_panel_popup_create_user_edit_surname_plh: 'Last name',
  org_panel_lst_all_statuses: 'All statuses',
  org_panel_lst_filters: 'Filters',
  user_panel_lst_filters: 'Filters',
  event_card_hdr_column_full_name: 'Full name',
  event_creation_editing_popup_observes_hdr_column_full_name: 'Full name',
  org_panel_popup_admins_hdr_column_full_name: 'Full name',
  org_panel_popup_unfinish_ev_user_hdr_column_full_name: 'Full name',
  user_panel_hdr_column_full_name: 'Full name',
  event_creation_editing_edit_event_goals: 'Event\'s goals and objectives',
  event_card_lbl_goals: 'Goals and objectives',
  authentication_change_pwd_lbl_lowercase_numbers: 'Numbers',
  user_card_popup_edit_lst_tz: 'Time zone',
  user_panel_popup_create_user_lst_tz_plh: 'Time zone',
  event_creation_editing_btn_noises: 'Noises',
  mission_card_lst_phase: 'Phase',
  event_screen_popup_phase_task_lbl_phase: 'Phase',
  event_screen_popup_hint_lbl_phase: 'Phase',
  user_state_OFFLINE: 'Offline',
  user_state_ONLINE: 'Online',
  user_state_CONNECTED: 'Connected',
  ///
  cf_event_creation_editing_crumbs_create_event: 'Event creation',
  ad_event_creation_editing_crumbs_create_event: 'Event creation',
  cf_event_creation_editing_crumbs_edit_event: 'Edit event',
  ad_event_creation_editing_crumbs_edit_event: 'Edit event',
  cf_event_creation_editing_popup_delete_team_hdr_delete: 'Deleting Team',
  cf_event_creation_editing_popup_delete_team_txt_delete: 'Are you sure you want to remove the entered data?',
  cf_event_creation_editing_lbl_targets: 'Goals (uri)',
  ad_event_creation_editing_lbl_targets: 'Protected services',
  cf_event_creation_editing_edit_targets_plh: 'Enter targets as a set of URI strings <host-ip>/<file path>. Examples:\n' +
    '10.{{octet}}.12.1/etc/sysctl.conf\n' +
    '11.{{octet}}.32.107/c:\\WINDOWS\\file.txt\n' +
    '192.168.17.14/tmp/hasd677823476.sig',
  ad_event_creation_editing_edit_targets_plh: 'Enter targets as a set of URI strings <host-ip>:<port>. For example:\n' +
    '10.{{octet}}.12.1:80\n' +
    '10.1.12.32:80\n' +
    '192.168.1.14:22\n',
  cf_event_card_lbl_flag: 'Flag',
  cf_event_card_hdr_column_credited: 'Credited',
  cf_event_card_hdr_column_attacker: 'Attacker',
  cf_event_card_hdr_column_victim: 'Victim',
  cf_event_card_hdr_column_flag: 'Flag set',
  cf_event_card_hdr_column_uri: 'Target (URI)',
  cf_event_card_btn_publish: 'Publish',
  ad_event_card_btn_publish: 'Apply correction',
  cf_event_card_btn_card: 'Card',
  cf_event_card_btn_arbitration: 'Arbitration',
  ad_event_card_btn_services: 'Services',
  cf_event_creation_editing_lbl_uncorrect_time: 'The start time must be greater than current time',
  ad_event_creation_editing_edit_service_unavailability: 'Service unavailability count, min',
  ad_event_creation_editing_edit_starting_score: 'Initial number of points',
  cf_event_card_hdr_column_command: 'Team', // newIdLoc
  cf_event_card_hdr_column_attack: 'Attack, points',
  cf_event_card_hdr_column_defense: 'Defense, points',
  cf_event_card_hdr_column_total: 'Total, points',
  ad_event_card_hdr_column_command: 'Team',
  ad_event_card_hdr_column_override: 'Override, points',
  cf_event_card_p_hdr_your_team: 'Your team',
  cf_event_card_p_hdr_targets: 'Your targets',
  ad_event_card_p_hdr_services: 'Your protected services:',
  cf_event_card_p_hdr_column_victim: 'Team victim',
  cf_event_card_p_hdr_column_uri: 'Target (URI)',
  ad_event_card_btn_verification_cheker: 'Launch checker',
  ad_event_card_hdr_column_team: 'Team',
  ad_event_card_hdr_column_service: 'Service',
  ad_event_card_hdr_column_state: 'State',
  ad_event_card_hdr_column_verification: 'Check',
  cf_scoreboard_hdr_column_team: 'Team',
  cf_scoreboard_hdr_column_attack: 'Attack',
  cf_scoreboard_hdr_column_defense: 'Defense',
  cf_scoreboard_hdr_column_total: 'Total',
  cf_event_creation_editing_edit_team_name_plh: 'Enter team name',
  cf_event_popup_hdr_debriefing: 'DEBRIEF',
  cf_event_popup_lbl_debriefing: 'Do you really want to debrief?',
  cf_event_popup_btn_cancel: 'Cancel',
  cf_event_popup_btn_debrief: 'Debrief',

  cf_popup_can_hdr_cancel: 'CANCEL EVENT',
  cf_popup_can_lbl_cancel: 'Are you sure you want to cancel event?', // newIdLoc
  cf_popup_can_btn_cancel: 'CANCEL',
  cf_popup_can_btn_cancel_app: 'ABORT',

  cf_popup_fin_hdr_finish: 'COMPLETION OF EVENT',
  cf_popup_fin_lbl_finish: 'Do you wish finish to finish event?',
  cf_popup_fin_btn_cancel: 'CANCEL',
  cf_popup_fin_btn_finish: 'FINISH',
  ///
  mission_cnstrctr_crumbs_constructor: 'Constructor',
  mission_cnstrctr_error_hint_title: 'All information is contained in the \'Checking\' tab',
  mission_cnstrctr_tab_mission: 'Mission',
  mission_cnstrctr_tab_scenario: 'Scenario',
  mission_cnstrctr_tab_configuration: 'Configuration',
  mission_cnstrctr_tab_reference: 'Reference',
  mission_cnstrctr_tab_noises: 'Noises',
  mission_cnstrctr_tab_verification: 'Verification',
  mission_cnstrctr_mission_edit_name: 'Mission name',
  mission_cnstrctr_mission_edit_name_plh: 'Enter the public name of the mission',
  mission_cnstrctr_mission_rating_complexity: 'Complexity',
  mission_cnstrctr_mission_tag_empty: 'Empty',
  mission_cnstrctr_mission_edit_goals: 'Mission objectives',
  mission_cnstrctr_mission_edit_description: 'Mission Description',
  mission_cnstrctr_mission_edit_comment: 'Comment',
  mission_cnstrctr_mission_hdr_vizualization: '2D Visualization',
  mission_cnstrctr_mission_hdr_vizualization_3d: '3D Visualization',
  mission_cnstrctr_mission_hdr_map: 'Infrastructure map',
  mission_cnstrctr_mission_hdr_manuals: 'Materials',
  mission_cnstrctr_mission_uploading_btn_select_file: 'Select file',
  mission_cnstrctr_mission_remove_file_popup_title: 'Delete file',
  mission_cnstrctr_mission_file_size_warning: 'Please select a{type} file no larger than 50MB',
  mission_cnstrctr_mission_remove_file_popup: 'Do you really want to delete the file?',
  mission_cnstrctr_mission_edit_goals_plh: 'Enter mission objectives',
  mission_cnstrctr_mission_edit_description_plh: 'Enter a description of the mission',
  mission_cnstrctr_mission_edit_comment_plh: 'Write a comment',
  mission_cnstrctr_mission_lbl_not_specified: 'Not specified',
  mission_cnstrctr_reference_lbl_scoring_reference: 'Reference',
  mission_cnstrctr_reference_btn_upload: 'Upload attack classification',
  mission_cnstrctr_reference_lbl_errors: 'Upload attack classification file',
  mission_cnstrctr_reference_hint_errors: 'Errors detected',
  mission_cnstrctr_reference_btn_download_scoring_reference: 'Download attack classification',
  mission_cnstrctr_reference_btn_delete_scoring_reference: 'Delete attack classification',
  mission_cnstrctr_reference_scoring_reference_error: 'The attack classification contains errors',
  mission_cnstrctr_reference_popup_error_upload_scoring_reference_hdr_error_upload_scoring_reference: 'Error loading attack classification',
  mission_cnstrctr_reference_popup_error_upload_scoring_reference_lbl_error_upload_scoring_reference: 'The attack classification contains errors and has not been uploaded! Check that the attack classification matches the operational documentation.',
  mission_cnstrctr_reference_popup_error_upload_scoring_reference_btn_error_upload_scoring_reference_ok: 'OK',
  mission_cnstrctr_reference_lbl_need_upload: 'Before reference report development you must upload attack classification',
  mission_cnstrctr_reference_hdr_column_name_ioc_plh: 'Select a new IoC...',
  mission_cnstrctr_reference_hdr_column_name_ioc: 'Indicator (IOC)',
  mission_cnstrctr_reference_hdr_column_type_ioc: 'IOC type',
  mission_cnstrctr_reference_hdr_column_points: 'Points',
  mission_cnstrctr_reference_hdr_column_hint: 'Regex / Hint',
  mission_cnstrctr_reference_hdr_column_skills: 'Skills',
  mission_cnstrctr_reference_lbl_ref_loaded_first_line: 'The attack classification has been loaded,',
  mission_cnstrctr_reference_lbl_ref_loaded_second_line: 'to continue the work аdd technique',
  mission_cnstrctr_mission_remove_tech_popup_title: 'Delete technique',
  mission_cnstrctr_mission_remove_tech_popup_txt: 'Are you sure you want to delete selected technique?',
  mission_cnstrctr_reference_btn_delete_scoring_reference_line: 'Are you sure that you want to delete the attack classification?\nIt will lead to clean up of the reference report.',
  mission_cnstrctr_reference_popup_upload_scoring_reference_hdr_upload: 'Upload attack classification',
  mission_cnstrctv_reference_popup_upload_scoring_reference_txt_warning: 'Are you sure that you want to upload a new attack classification?\nIt will lead to delete the current attack classification and clean up of the reference report.',
  mission_cnstrctr_reference_chk_skeletal: 'Skeletal',
  mission_cnstrctr_scenario_popup_delete_script_hdr_delete: 'Script deletion',
  mission_cnstrctr_scenario_popup_delete_script_txt: 'Are you sure you want to remove the script from the scenario?',
  mission_cnstrctr_scenario_hdr_phases: 'Stages',
  mission_cnstrctr_scenario_hdr_phases_plh: 'Add a stage by clicking [+]',
  mission_cnstrctr_scenario_link_scripts: 'Scripts',
  mission_cnstrctr_scenario_link_scripts_plh1: 'There is no selected stage.',
  mission_cnstrctr_scenario_link_scripts_plh2: 'The script cannot be added.',
  mission_cnstrctr_scenario_link_scripts_no_data: 'Missing data',
  mission_cnstrctr_scenario_btn_command: 'Command',
  mission_cnstrctr_scenario_btn_command_plh: 'No scripts added, add a script',
  mission_cnstrctr_scenario_hdr_column_script: 'Script',
  mission_cnstrctr_scenario_hdr_column_attacking: 'Attacker',
  mission_cnstrctr_scenario_hdr_column_key: 'K',
  mission_cnstrctr_scenario_hdr_column_type: 'Type',
  mission_cnstrctr_scenario_hdr_column_tags: 'Tags',
  mission_cnstrctr_scenario_hdr_script: 'Script',
  mission_cnstrctr_scenario_lbl_script_plh: 'Select a script\nin the central panel',
  mission_cnstrctr_scenario_edit_script_name: 'Name',
  mission_cnstrctr_scenario_edit_rounds: 'Duration of round(s)',
  mission_cnstrctr_scenario_edit_attacking_vm: 'Attacker (vmid)',
  mission_cnstrctr_scenario_edit_target_name: 'Target name',
  mission_cnstrctr_scenario_btn_key_attack: 'Key attack',
  mission_cnstrctr_scenario_edit_executable_module: 'Executable module (bin)',
  mission_cnstrctr_scenario_edit_command_line: 'Command line',
  mission_cnstrctr_scenario_scripts_list_field_search_plh: 'Enter keywords separated by space and press enter',
  mission_cnstrctr_scenario_popup_create_phase_hdr_create: 'Creating a stage',
  mission_cnstrctr_scenario_popup_edit_phase_hdr_edit: 'Stage editing',
  mission_cnstrctr_scenario_popup_clone_phase_hdr_edit: 'Stage cloning',
  mission_cnstrctr_scenario_popup_create_phase_edit_name: 'Stage name',
  mission_cnstrctr_scenario_popup_create_phase_edit_name_plh: 'The name of the stage is displayed to the participants',
  mission_cnstrctr_scenario_popup_create_phase_edit_rounds: 'Stage Duration, Round(s):',
  mission_cnstrctr_scenario_popup_create_phase_edit_rounds_d: 'Stage Duration',
  mission_cnstrctr_scenario_popup_create_phase_edit_rounds_r: 'round(s)',
  mission_cnstrctr_scenario_popup_create_phase_edit_descr: 'Stage description',
  mission_cnstrctr_scenario_popup_create_phase_edit_descr_plh: 'Description is displayed to participants',
  mission_cnstrctr_scenario_popup_create_phase_edit_hint: 'Hint',
  mission_cnstrctr_scenario_popup_create_phase_edit_hint_plh: 'The hint is available to the participants of the event',
  mission_cnstrctr_scenario_popup_create_phase_edit_name_error: 'Enter a unique stage name',
  mission_cnstrctr_scenario_popup_create_script_edit_name_error: 'Enter a unique script name',
  mission_cnstrctr_scenario_popup_delete_phase_hdr_delete: 'Deleting a stage',
  mission_cnstrctr_scenario_popup_delete_phase_lbl_warning: 'Are you sure you want to delete the stage?',
  mission_cnstrctr_scenario_script_detail_target_plh: 'Displayed in event history',
  mission_cnstrctr_scenario_script_detail_directory_plh: 'Specify the absolute path to the directory',
  mission_cnstrctr_scenario_script_detail_bin_plh: 'Specify the name and path (for example: cmd.exe )',
  mission_cnstrctr_validation_check_bin: 'Check',
  mission_cnstrctr_validation_check_error: 'Errors detected! Mission not published',
  mission_cnstrctr_unsaved_changes: 'There are unpublished changes',
  mission_cnstrctr_lbl_mission_saved: 'Mission published',
  mission_cnstrctr_lbl_mission_errors: 'Errors',
  mission_cnstrctr_lbl_mission_warnings: 'warnings',
  mission_cnstrctr_verification_lbl_errors: 'Mission contains errors!',
  mission_cnstrctr_noises_hdr_code: 'YAML-code',
  mission_cnstrctr_btn_export: 'Export',
  mission_cnstrctr_btn_import: 'Import',
  mission_cnstrctr: {
    scenario: {
      lst: {
        configuration: 'Slot'
      },
      edit: {
        timeout: 'Timeout',
        sec: 'sec'
      },
      btn: {
        clear: 'Clear'
      }
    }
  },
  ///
  user_creation_editing_popup_delete_txt_data_lost: 'Are you sure you want to cancel',
  user_creation_editing_popup_delete_txt_data_lost_create: 'user creation?',
  user_creation_editing_popup_delete_txt_data_lost_edit: 'data edition?',
  user_creation_editing_popup_create_data_unsaved_data: 'All entered data will be lost.',
  user_creation_editing_popup_edit_data_unsaved_data: 'Unsaved data will be lost.',
  user_creation_editing_popup_delete_btn_yes: 'Yes',
  user_creation_editing_popup_delete_btn_no: 'No',
  org_creation_editing_popup_delete_txt_data_lost_create: 'organization creation?',
  org_creation_editing_popup_delete_txt_data_lost_edit: 'organization edition?',
  event_creation_editing_popup_delete_txt_data_lost_create: 'event creation?',
  missions_list_add_btn: 'Add mission',
  missions_list_create_mission: 'Mission creation',
  missions_list_clone_mission: 'Clone mission',
  missions_list_create_mission_name: 'Mission name',
  missions_list_create_mission_plh: 'Enter mission name',
  missions_list_create_mission_error_already_exist: 'Mission with this name already exists',
  records_found: 'Records found',
  scripts_list_breadcrumbs_admin: 'Administration',
  scripts_list_breadcrumbs_lib: 'Script Library',
  scripts_list_field_search_label: 'Search for scripts',
  scripts_list_field_search_plh: 'Enter keywords separated by space and press enter',
  scripts_list_add_btn: 'Add script',
  scripts_list_loading: 'Loading scripts',
  scripts_list_no_data: 'Scripts not found',
  scripts_list_empty_0: 'There are no scripts in the library.',
  scripts_list_empty_1: 'No results were found for your request, please try to soften your search criteria.',
  scripts_list_empty_2: 'Check for typos in your keywords. ',
  scripts_list_hdr_column_name: 'Title',
  scripts_list_hdr_column_type: 'Type',
  scripts_list_hdr_column_tags: 'Tags',
  scripts_list_hdr_column_update_user: 'Modified',
  scripts_list_hdr_column_update_date: 'Modified date',
  script_popup_hdr_create: 'Add script',
  script_popup_hdr_edit: 'Edit script',
  script_popup_hdr_create_title: 'The script was created successfully',
  script_popup_hdr_edit_title: 'The script was successfully modified',
  script_popup_field_name: 'Title',
  script_popup_field_type: 'Type',
  script_popup_field_tags: 'Keywords',
  script_popup_field_bin: 'Executable module (bin)',
  script_popup_field_command: 'Command line',
  script_popup_field_target: 'Target Directory',
  script_popup_field_description: 'Description',
  script_popup_field_name_plh: 'Enter script name (max 50 characters) ...',
  script_popup_field_tags_plh: 'Enter keywords to search for, for example: Windows Drupal SQLi Attack CVE-12345-1234',
  script_popup_field_bin_plh: 'Path and name of the executable module executing the command line on the attacking machine',
  script_popup_field_command_plh: 'Specify a generic command line that executes the script, {{}} macros are allowed ...',
  script_popup_field_target_plh: 'Specify the directory on the target machine where the script will be placed ...',
  script_popup_field_description_plh: 'Enter a description of the script, what it does and how to use it (including input parameters)',
  script_popup_field_modules: 'Modules',
  script_popup_field_upload_description: 'Add executable modules (files) to the script',
  script_popup_uploading_btn_select_file: 'Select files',
  script_popup_uploading_lbl_drag_file: 'or drag it here',
  script_popup_uploading_lbl_drag_file_mission: 'Drag here or',
  script_popup_btn_cancel: 'Cancel',
  script_popup_btn_save: 'Save',
  script_remove_popup_title: 'Remove script',
  script_remove_popup_txt: 'Are you sure you want to removet\nhis script from the system?',
  script_remove_popup_ok_btn: 'Remove',
  script_remove_popup_cancel_btn: 'Cancel',
  script_remove_module_popup_title: 'Remove module',
  script_remove_module_popup: 'Do you really want to remove this module from the system?',
  script_types_all: 'All',
  script_type_attack: 'Attack',
  script_type_dobrobot: 'Dobrobot',
  script_type_noise: 'Noise',
  script_type_command: 'Command',
  ///
  user_panel_popup_create_user_btn_continue: 'Continue',
  user_panel_popup_create_user_btn_delete: 'Delete',
  user_panel_popup_create_user_txt_unsaved_data: 'Unsaved data will be lost. Are you sure you want to close the window?',
  event_creation_editing_popup_delete_event_hdr: 'Warning',
  event_creation_editing_popup_delete_event_txt_data_lost: 'Unsaved data will be lost. Are you sure you want to close the window?',
  event_creation_editing_popup_delete_event_btn_delete: 'Close',
  event_creation_editing_popup_change_org_hdr_change: 'Change organization',
  event_creation_editing_popup_change_org_txt_reset_users: 'Selected participants will be reset.',
  event_creation_editing_popup_change_org_txt_reset_users_second: 'Do you want to change organization?',
  event_creation_editing_popup_change_org_btn_cancel: 'Cancel',
  event_creation_editing_popup_change_org_btn_change: 'Change',
  event_creation_editing_popup_creation_hdr_event: 'Event creation',
  event_creation_editing_popup_creation_btn_confirm: 'OK',
  user_card_popup_edit_txt_error_correct_email: 'Entered value must be a valid email',
  user_panel_popup_create_user_lbl_error_email: 'User with this email already exists',
  event_card_hdr_stop_event: 'Stopping the event',
  event_card_hdr_stop_event_sure: 'Do you really want to stop the event?',
  event_card_hdr_cancel_event: 'Cancel event',
  event_card_hdr_cancel_event_sure: 'Are you sure you want to cancel event?',
  event_card_btn_cancel_no: 'No',
  event_card_btn_cancel_yes: 'Yes',
  event_card_hdr_column_reboot_vm_hint: 'Reboot VM',
  event_creation_editing_popup_delete_event_txt_data_lost_first: 'Unsaved data will be lost.',
  event_creation_editing_popup_delete_event_txt_data_lost_next: 'Are you sure you want to close the window?',
  event_creation_editing_popup_error_edit_lbl_back_list_events: 'Back to list of events',
  user_panel_lst_user_loading: 'Loading users',
  event_creation_editing_lbl_error_fio_chrctrs: 'The entered value contains an invalid character',
  event_creation_editing_lbl_error_event_name_chrctrs: 'Entered value must not exceed 255 characters',
  event_creation_editing_lbl_error_event_goals_chrctrs: 'Entered value must not exceed 3000 characters',
  event_creation_editing_lbl_error_event_vms: 'Virtual machines not found',
  event_creation_editing_popup_error_edit_hdr_event_stop: 'EVENT WAS COMPLETED OR CANCELED',
  bulk_user_uploading_popup_users_lbl_error_file_format: 'Invalid file format (expected <b>XLSX</b>) or exceeded max. file size or required fields are missing in the file (see documentation)',
  bulk_user_uploading_popup_users_org_and_time_not_selected: 'Choose organization and time zone',
  bulk_user_uploading_popup_time_not_selected: 'Choose time zone',
  bulk_user_uploading_popup_users_org_not_selected: 'Choose organization',
  auth_popup_password_change_txt_get_instruction_first: 'Instructions on how to restore access will be sent to you.',
  auth_change_pwd_lbl_password_changed: 'Password changed successfully!',
  auth_change_pwd_btn_confirm: 'OK',
  auth_popup_password_change_txt_get_instruction_next: 'Enter your email',
  ///
  infra_popup_log_crumbs_administration: 'Administration',
  infra_popup_log_crumbs_infrastructures: 'Infrastructures',
  infra_popup_log_btn_download_log: 'Download log',
  infra_popup_deploy_hdr_deploy: 'Deployment of infrastructure',
  infra_popup_deploy_lbl_slot: 'Slot',
  infra_popup_deploy_lbl_purpose: 'Purpose',
  infra_popup_deploy_lbl_purpose_plh: 'Enter purpose of infrastructure\'s deployment',
  infra_popup_deploy_lst_playbook_infra: 'Playbook of infrastructure',
  infra_popup_deploy_lbl_playbook_infra_plh: 'Choose playbook…',
  infra_popup_deploy_hint_descr: 'Description',
  infra_popup_deploy_hint_error: 'Error!',
  infra_popup_deploy_hint_error_missed: 'Mandatory field missed',
  infra_popup_deploy_hdr_column_parameter: 'Parameter',
  infra_popup_deploy_hdr_column_value: 'Value',
  infra_popup_deploy_lbl_choose_playbook: 'Choose playbook of infrastructure…',
  infra_popup_deploy_hint_descr_parameter: 'Description of playbook\'s parameter',
  infra_popup_deploy_lst_playbook_vm: 'Playbook of participant VM',
  infra_popup_deploy_lst_playbook_vm_plh: 'Choose playbook…',
  infra_popup_deploy_edit_amount_vm: 'Number of participants\' VM',
  infra_popup_deploy_hint_error_vm: 'Error!',
  infra_popup_deploy_hint_error_vm_value: 'Value must be integer and be between 0 and 999',
  infra_popup_deploy_btn_cancel: 'Cancel',
  infra_popup_deploy_btn_create: 'Create',
  infra_popup_deploy_confirm_hdr_deploy: 'Deployment of infrastructure',
  infra_popup_deploy_confirm_txt_deploy: 'Are you sure that you want to deploy infrastructure?',
  infra_popup_deploy_confirm_btn_no: 'No',
  infra_popup_deploy_confirm_btn_yes: 'Yes',
  infra_popup_deploy_cancel_hdr_deploy: 'Cancellation of infrastructure\'s deployment',
  infra_popup_deploy_cancel_txt_deploy: 'Are you sure that you want to cancel deployment of infrastructure?',
  infra_popup_deploy_cancel_btn_no: 'No',
  infra_popup_deploy_cancel_btn_yes: 'Yes',
  infra_popup_view_hdr_view: 'View of infrastructure',
  infra_popup_view_lbl_slot: 'Slot',
  infra_popup_view_lbl_purpose: 'Purpose',
  infra_popup_view_lbl_playbook_of_infrastructure: 'Playbook of infrastructure',
  infra_popup_view_hint_description: 'Description',
  infra_popup_view_hdr_column_parameter: 'Parameter',
  infra_popup_view_hdr_column_value: 'Value',
  infra_popup_view_hint_descr: 'Description of playbook\'s parameter',
  infra_popup_view_lbl_playbook_vm: 'Playbook of participant VM',
  infra_popup_view_lbl_amount_vm: 'Number of participants\' VM',
  infra_popup_view_btn_close: 'Close',
  infra_list_crumbs_administration: 'Administration',
  infra_list_crumbs_infrastructures: 'Infrastructures',
  infra_list_edit_lbl_search: 'Search for slots',
  infra_list_edit_search: 'Enter keywords separated by space',
  infra_list_hdr_column_slot: 'Slot',
  infra_list_hdr_column_purpose: 'Purpose',
  infra_list_hdr_column_octet_infra: 'Octet',
  infra_list_hdr_column_state: 'State',
  infra_list_hdr_column_state_waiting: 'Waiting…',
  infra_list_hdr_column_state_deploying: 'Deploying…',
  infra_list_hdr_column_state_error_deployment: 'Error! (deployment)',
  infra_list_hdr_column_state_error_destruction: 'Error! (destruction)',
  infra_list_hdr_column_state_ready: 'Ready',
  infra_list_hdr_column_state_occupied: 'Occupied ',
  infra_list_hdr_column_state_destruction: 'Destruction…',
  infra_list_hdr_column_state_empty: 'Empty',
  infra_list_hdr_column_playbook: 'Playbook',
  infra_list_hdr_column_changed_by: 'Changed by',
  infra_list_hdr_column_changed_at: 'Changed at',
  infra_list_popup_destroy_confirm_hdr_destroy: 'Destruction of infrastructure',
  infra_list_popup_destroy_confirm_lbl_destroy: 'Are you sure that you want to destroy infrastructure?',
  infra_list_popup_destroy_confirm_btn_no: 'No',
  infra_list_popup_destroy_confirm_btn_yes: 'Yes',
  infra_list_lbl_no_infras: 'No infrastructures available',
  infra_list_lbl_change_search: 'No results were found, please change search criteria',
  infra_list_hint_unmanageable_hdr: 'Unmanageable slot!',
  infra_list_hint_unmanageable_desc: 'Contact your System administrators to configure this unmanageable slot.',
  infra_list_hint_parameters: 'Parameters',
  infra_list_hint_log: 'Log',
  infra_list_hint_destroy: 'Destroy',
  infra_list_hint_deploy: 'Deploy',
  infra_list_popup_list_vm_hdr_list: 'Connecting to VM',
  infra_list_popup_list_vm_txt_text: 'Select VM to connect to it desktop:',
  infra_list_popup_list_vm_btn_cancel: 'Cancel',
  infra_list_popup_list_vm_btn_connect: 'Connect',
  audit_journal_hdr_audit: 'Audit journal',
  audit_journal_edit_date_time: 'Date and time',
  audit_journal_lst_severity: 'Severity',
  audit_journal_btn_apply: 'Apply',
  audit_journal_btn_download: 'Download messages',
  audit_journal_hdr_column_date_time: 'Date and time',
  audit_journal_hdr_column_message: 'Message',
  audit_journal_hint_message: 'Message',
  audit_journal_hdr_column_severity: 'Severity',
  audit_journal_hdr_column_subject_id: 'Subject ID',
  audit_journal_hdr_column_subject_ip: 'Subject IP',
  audit_journal_lbl_paging: 'Items per page:',
  audit_journal_lbl_paging_out_of: 'of',
  audit_journal_lbl_error_no_results: 'No results were found, please change search criteria',
  audit_journal_lst_severity_all: 'All',
  audit_journal_lst_severity_low: 'Low',
  audit_journal_lst_severity_medium: 'Medium',
  audit_journal_lst_severity_high: 'High',
  audit_journal_lst_severity_critical: 'Critical',
  ///
  admin_panel_hint_user_management: 'Users management',
  admin_panel_hint_organization_management: 'Organizations management',
  admin_panel_hint_script_library: 'Scripts & attacks library',
  admin_panel_hint_infra: 'Infrastructures management',
  admin_panel_hint_scoreboard: 'Scoreboard management',
  audit_panel_hint_audit_journal: 'Audit journal',
  event_card_tab_bar_hint_event_card: 'Event card',
  event_card_tab_bar_hint_log: 'Log',
  event_card_tab_bar_hint_attack_and_noise_management: 'Attack and noise management',
  ///
  dashboard_lbl_organizations: 'Organization',
  dashboard_lbl_choose_an_organization: 'Choose an organization',
  dashboard_lst_organizations_no_found: 'No organizations found',
  dashboard_lst_all_organizations: 'All organizations',
  dashboard_lbl_type_event: 'Type',
  dashboard_lbl_period: 'Period', // newIdLoc
  dashboard_lbl_select_type_event: 'Select type',
  dashboard_lst_type_event_not_found: 'Event types not found',
  dashboard_lst_all_types_event: 'All types of events',
  dashboard_lst_capture_the_flag: 'Capture the flag',
  dashboard_lst_researches: 'Researches',
  dashboard_lst_training: 'Training',
  dashboard_lst_confrontation: 'Confrotation',
  dashboard_lst_exercises: 'Exercises',
  dashboard_btn_apply: 'Apply',
  dashboard_lbl_statics_status_of_events: 'Event statuses',
  dashboard_lbl_statics_status_of_events_planned: 'Planned',
  dashboard_lbl_statics_status_of_events_in_process: 'In process',
  dashboard_lbl_statics_status_of_events_finished: 'Finished',
  dashboard_lbl_finish_events_of_types: 'Completed events',
  dashboard_lbl_statics_finish_events_of_types_capture_the_flag: 'Capture the flag',
  dashboard_lbl_statics_finish_events_of_types_researches: 'Researches',
  dashboard_lbl_statics_finish_events_of_types_training: 'Training',
  dashboard_lbl_statics_finish_events_of_types_confrontation: 'Confrotation',
  dashboard_lbl_statics_finish_events_of_types_exercises: 'Exercises',
  dashboard_lbl_statics_members: 'Members',
  dashboard_lbl_statics_events: 'Events',
  dashboard_lbl_statics_events_event: 'Event',
  dashboard_lbl_statics_events_start: 'Start',
  dashboard_lbl_statics_events_members: 'Members',
  dashboard_lbl_statics_events_status: 'Status',
  dashboard_lbl_statics_events_unit: 'unit',
  ///
  integrity_control_lbl_integrity_control: 'Integrity control',
  integrity_control_edit_search_object_plh: 'Enter search term…',
  integrity_control_btn_full_update: 'Full update ICD',
  integrity_control_lbl_update_in_progress: 'Update in progress…',
  integrity_control_hdr_update_in_progress: 'Full update of integrity control database is in progress…',
  integrity_control_hdr_column_name_object: 'Object',
  integrity_control_hdr_column_status: 'State',
  integrity_control_hdr_column_service: 'Service',
  integrity_control_hdr_column_date_edit: 'Edit',
  integrity_control_hdr_column_editor: 'Editor',
  integrity_control_btn_edit_object: 'Edit',
  integrity_control_btn_update_object: 'Update',
  integrity_control_hint_indicator_lamp_hint_integrity_control: 'Integrity control',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_object: 'Integrity of objects violated',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_object_details: 'See details:',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_object_path: 'Menu > Integrity control',
  integrity_control_hint_indicator_lamp_hint_integrity_empty_database: 'Integrity control database is empty',
  integrity_control_hint_indicator_lamp_hint_integrity_empty_database_details: 'Needs full update ICD:',
  integrity_control_hint_indicator_lamp_hint_integrity_empty_database_path: 'Menu > Integrity control > Full update ICD',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_database: 'Integrity of ICD violated',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_database_details: 'It is necessary to take measures according to internal regulations:',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_database_path: 'Menu > Integrity control',
  integrity_control_hint_indicator_lamp_hint_integrity_edit_object: 'Some objects are in edit mode',
  integrity_control_hint_indicator_lamp_hint_integrity_edit_object_details: 'More:',
  integrity_control_hint_indicator_lamp_hint_integrity_edit_object_path: 'Menu > Integrity control',
  integrity_control_lbl_empty_database: 'There is none object under integrity control.\nIt is necessary to execute full update of ICD!',
  integrity_control_lbl_empty_filter_data: 'No integrity control objects found. Change your search criteria.',
  integrity_control_popup_hdr_full_update: 'Full update of integrity control database',
  integrity_control_popup_txt_full_update: 'Are you sure you want to completely upgrade integrity control database?',
  integrity_control_popup_btn_full_update_yes: 'Update',
  integrity_control_popup_btn_full_update_no: 'Cancel',
  integrity_control_lbl_violation: 'Violated',
  integrity_control_lbl_edited: 'Edited',
  integrity_control_lbl_checked: 'Object checked',
  integrity_control_hint_integrity_control_database_management: 'Integrity control database management',
  ///
  navigation_panel_lbl_cybermir: 'Cybermir',
  user_authorization_lbl_cybermir: 'Cybermir',
  user_authorization_lbl_solar: 'Solar',
  org_panel_lbl_not_active_user_hint: 'User is inactive because his organization is blocked',
  event_creation_editing_lbl_participants_loading: 'Loading…',
  event_creation_editing_lbl_observes_loading: 'Loading…',
  event_screen_popup_particip_hdr_id_hint: 'ID copied to clipboard',
  user_panel_popup_no_block_hdr_block_not_possible: 'Blocking is not possible',
  user_panel_popup_no_block_txt_last_admin: 'User is the last active "admin"',
  user_panel_popup_no_block_btn_confirm: 'OK',
  org_panel_popup_block_org_hdr_block: 'Block organization',
  org_panel_popup_block_org_txt_warning: 'Users of this organization can not log in while organization is blocked. Are you sure you want to block organization?',
  org_panel_popup_block_org_btn_block: 'Block ',
  org_panel_popup_block_org_btn_cancel: 'Cancel ',
  user_card_hdr_no_records: 'Events are missing or hidden',
  user_card_popup_edit_btn_reset_photo: 'Reset photo',
  auth_popup_invalid_link_hdr_error: 'ERROR',
  auth_popup_invalid_link_txt_warning: 'We cannot reset your password!\n\nYou may have previously used this link to change your password.\n\nRepeat the password recovery procedure from the beginning.',
  auth_popup_invalid_link_btn_back: 'Back to login page',
  events_list_edit_search_event_plh: 'Search for event',
  user_panel_popup_no_access_rights_txt_page_view: 'YOU DO NOT HAVE ACCESS RIGHTS TO VIEW THIS PAGE',
  user_panel_popup_no_access_rights_hdr_error: 'ERROR',
  user_panel_popup_no_access_rights_link_back_to_events: 'BACK TO LIST OF EVENTS',
  org_panel_lbl_loading_org: 'Loading organizations',
  event_card_hdr_attack_noise_management: 'Attack and noise management',
  event_card_hdr_attack_noise_no_data: 'No attack or noise data',
  org_panel_popup_create_org_lbl_error_org_name_chrctrs: 'Entered value must not exceed 255 characters',
  org_panel_popup_create_org_lbl_error_org_already_exist: 'Organization with this name already exists',
  event_card_lbl_event_status_starting_soon: 'Starting soon',
  event_screen_popup_map_hdr_map: 'Event map',
  event_screen_popup_map_btn_download: 'Download',
  event_screen_popup_map_btn_print: 'Print',
  user_card_popup_pwd_hdr_reset: 'Password reset',
  user_card_popup_pwd_txt_reset_sent: 'An email with instructions has been sent to',
  user_card_popup_pwd_btn_confirm: 'OK',
  user_card_popup_edit_lbl_error_email_exist: 'User with this email already exists',
  user_card_popup_changes_saved_hdr_edit_user: 'Edit user',
  user_card_popup_changes_saved_lbl_data: 'User data changed successfully',
  user_card_popup_changes_saved_btn_confirm: 'ОК',
  event_card_lbl_validation_error: 'Validation error!',
  event_card_lbl_debriefing: 'Debriefing',
  event_card_lbl_cleaning_up: 'Finishing',
  event_screen_lbl_error_server: 'Server connection error!',
  event_screen_lbl_error_server_no_connection: 'No connection to the server',
  event_creation_editing_edit_error_event_time_overdue: 'Time is overdue. Nearest available start time: +1 hour to the current time',
  attack_vector: 'Attack vector',
  event_creation_editing_hdr_infa: 'Infrastructure',
  event_creation_editing_lst_mission: 'Select mission slot',
  autostart: 'Process automation',
  automatic_completion: 'Automatic completion',
  datetime_d: 'd',
  datetime_h: 'h',
  datetime_m: 'm',
  events_bot_list_name: 'Name (ID)',
  events_bot_list_type: 'Type',
  events_bot_list_attacker: 'Attacker (vmID)',
  events_bot_list_target: 'Target',
  access_error: 'Access error',
  of_mission: 'of mission',
  of_event: 'of event',
  moscow_zone_title: 'Moscow, Sochi',
  vladivostok_zone_title: 'Vladivostok, Chamorro',
  date_placeholder: 'DD/MM/YY',
  event_creation_editing_observes_no_records: 'No records found',
  user_profile_assign: 'Assign',
  events_list_no_events_available: 'No events available',
  infrastructure_no_data_text: 'No events found',
  infrastructure_loading_text: 'Loading events',
  toolbar_results_table: 'Results table',
  teacher_comment_placeholder: 'Commentary...',
  scoring_report_evaluation_popup_example_hdr_reference_report: 'Example of reference report',
  entered_value_must_not_exceed: 'Entered value must not exceed',
  characters: 'characters',
  event_card_lbl_publish: 'Published',
  command_sent_to_server: 'The command was sent to the server',
  command_sending_error: 'Command sending error',
  enter_value: 'Enter value...',
  search_lbl: 'Search...',
  select_file: 'Select file',
  select_files: 'Select files',
  file_input_select_file: 'Select file',
  file_input_select_files: 'Select files',
  file_input_drag_file: 'or drag & drop it here',
  file_input_drag_files: 'or drag & drop them here',
  event_screen_reload_vm: 'Restart the virtual machine',
  event_screen_chk_remocon_switcher: 'Full control',
  event_screen_chk_remocon_title: 'CHANGE CONTROL',
  event_screen_chk_remocon_to_guacamole_header: 'Are you sure you want to switch to full desktop control mode?',
  event_screen_chk_remocon_to_guacamole_text: '* Desktop authorization required.',
  event_screen_chk_remocon_to_spice_header: 'Are you sure you want to switch to restricted desktop control mode?',
  event_screen_chk_remocon_to_spice_text: 'This mode is used when there are technical problems with the remote desktop connection.\n\n* Desktop authorization required, some functionality will be restricted (buffer link between browser and desktop)',
  event_screen_chk_remocon_btn_confirm: 'Confirm',
  event_screen_chk_remocon_btn_cancel: 'Cancel',
  unsaved_data_will_be_lost: 'Unsaved data will be lost. Are you sure you want to continue?',
  incorrect_file_format: 'Incorrect file format. Valid formats: *.svg or *.png, *.jpeg',
  event_duration_hint: 'Event duration',
  mission_rounds_hint: 'Number of rounds',
  mission_duration_hint: 'Mission duration',
  mission_mode_hint: 'Mission Mode',
  mission_difficulty_hint: 'Mission difficulty',
  number_of_participants_hint: 'Number of participants',
  event_type_hint: 'Event type',
  event_mode_hint: 'Event mode',
  personal_account: {
    lbl: {
      user_info: 'Personal information',
      user_events: 'Events',
      user_results: 'Results',
      user_status: 'Status',
      last_time: 'Was {dt} in {tm}',
      registration: 'Date of registration',
      password: 'Password',
      edited_now: 'Just edited',
      cancel_pass: 'Cancel',
      save_pass: 'Save',
      requirements_pass: 'Password requirements',
      pass_protection: 'To protect your data, you must create a secure password',
      symbols: '10 or more symbols',
      uppercase: 'Capital Latin letters',
      lowercase: 'Latin lowercase letters',
      numbers: 'Numbers',
      special_symbols: 'Special symbols: (!$%&’()+,-/:;⇔?@[]_{|}~`)',
      old_pass: 'Old password',
      new_pass: 'New password',
      repeat_pass: 'Repeat your password',
      records_detected: 'Records detected',
      today_events: 'Today',
      coming_events: 'Coming events',
      no_any_events: 'You have not yet participated in any events \n and you have no planned cyber exercises',
      visit_site: 'Visit our site',
      no_events_today: 'You have no events for today',
      no_coming_events: 'No new events are planned in the near future',
      leave_request: 'and leave a request',
      place: 'Your place',
      flag: 'Flags set',
      iocs: 'IoC detected',
      server_availability: 'Availability of services',
      server_unavailability: 'Unavailability of services ',
      events: 'Statuses by events',
      events_planned: 'Scheduled',
      events_process: 'In progress',
      events_finished: 'Completed',
      type: 'Participated',
      no_scores: 'You don\'t have any results yet,\nsince you haven\'t participated in any event.',
      no_personal_score: 'You haven\'t participated\nin',
      no_rating_event: 'You have no results yet,\nas you have not participated in any',
      by_personal: 'personal',
      by_team: 'team',
      by_rating: 'rating',
      by_events: 'events yet',
      by_event: 'event',
      total: 'Total',
    },
    btn: {
      edit: 'Edit',
      forgot_pass: 'Forgot your password?',
      nearest: 'Nearest',
      all: 'All',
      more: 'More',
      cal_today: 'Today',
      cal_tomorrow: 'Tomorrow',
      cal_all: 'All time',
      personal: 'Personal',
      team: 'Team',
    },
    hdr: {
      rating: 'Rating',
      skills: 'Skills',
      hdr_no_scores: 'Skill data temporarily unavailable',
    },
    link: {
      coming_events: 'Find out about upcoming events',
      visit_site: 'Visit our website',
    }
  },
  user_panel_popup_no_block_prtcpnt_or_tchr_hdr_unfinished_events: 'There are unfinished events',
  user_panel_popup_no_block_prtcpnt_or_tchr_hdr_unfinished_event: 'There is unfinished event',
  user_panel_popup_no_block_prtcpnt_or_tchr_hdr_column_event: 'Events',
  user_panel_popup_no_block_prtcpnt_or_tchr_hdr_column_mission: 'Mission',
  user_panel_popup_no_block_prtcpnt_or_tchr_hdr_column_start_date: 'Start date',
  user_panel_popup_no_block_prtcpnt_or_tchr_txt_warnings: 'User cannot be blocked because he has unfinished events',
  user_panel_popup_no_block_prtcpnt_or_tchr_txt_warning: 'User cannot be blocked because he have unfinished event',
  user_panel_popup_no_block_prtcpnt_or_tchr_btn_confirm: 'OK',
  ///
  script_control_vm_name: 'Virtual machine name',
  script_control_timeout: 'Timeout',
  script_control_running: 'Script running',
  script_control_start: 'Start script',
  script_control_result: 'Execution result',
  script_control_status: 'Execution status',
  events_list_item_start: 'Start',
  events_list_item_today: 'Today',
  events_list_item_near: 'Coming soon',
  events_list_item_running: 'Already running',
  events_list_item_summing_up: 'Summing up',
  events_list_item_start_date: 'Start date',
  events_list_item_begin: 'Begins',
  events_list_item_begin_through: 'Starts in',

  event_creation_editing: {
    popup_prtcpnts: {
      hdr: {
        prtcpnts_mngmnt: 'Participant Management',
      },
    },
    popup_participants: {
      lbl: {
        infra: 'Slot'
      },
      hdr_column: {
        full_name: 'Full name',
        email: 'E-mail',
        position: 'Position'
      },
      btn: {
        cancel: 'Cancel',
        save: 'Save',
      }
    },
    popup_observes: {
      lst: {
        org_plh: 'Organizations'
      },
      hdr: {
        observes_mngmnt: 'Management of observes'
      },
      lbl: {
        observes: 'Full name',
      },
      hdr_column: {
        email: 'E-mail',
        position: 'Position',
        organization: 'Organization'
      },
      btn: {
        cancel: 'Cancel',
        save: 'Save',
      }
    },
    edit: {
      mission: 'Mission', // newIdLoc
      mission_plh: 'Select mission', // newIdLoc
      round_duration: 'Round duration, min.',
      error_event_time_plus_one_hour: 'Nearest available start time: +1 hour to the current time',
    },
    lbl: {
      error_round_duration_false: 'Enter an integer or decimal number between {min} and {max}',
      mission_duration: 'min.',
      playbook: 'Playbook',
      reserve: 'Additional VM Participants',
      rounds: 'round(s)',
      participants: 'Participants',
      observes: 'Observes',
      error_event_teams_empty: 'At least two teams must be added',
    },
    lst: {
      playbook: 'Select playbook participant',
      teacher: 'Teacher',
      teacher_plh: 'Select teacher',
      infa: 'Select infrastructure slot',
    },
    hdr: {
      infa: 'Infrastructure slot',
    },
    btn: {
      scoring: 'Scoring',
      participant: {
        add: 'Add',
        change: 'Change'
      },
      observer: {
        add: 'Add',
        change: 'Change'
      },
      create_event: 'Create event',
      save_changes: 'Save changes',
    },
  },
  calendar: { // newIdLoc
    lbl: {
      select: 'Select',
      cancel: 'Cancel',
      today: 'Today',
      tomorrow: 'Tomorrow',
      specify: 'Specify',
      start_time: 'start time'
    }
  },
  event_editing: {
    popup_assignment_VM_block: {
      txt: {
        block: 'Selected VM is assigned to another user, reset assignment and continue?'
      },
      btn: {
        reset: 'Reset'
      }
    }
  },
  scoring: {
    participant_report: {
      popup_saving: {
        hdr: {
          correct_errors: 'Ошибка сохранения'
        },
        txt: {
          correct_errors: 'All errors should be corrected\nbefore saving the report'
        }
      }
    }
  },
  event: {
    popup_event_debriefed: {
      hdr: {
        notification: 'End of event'
      },
      txt: {
        notification: 'The event has ended.\nThe results are being summed up.'
      },
      btn: {
        ok: 'Refresh'
      }
    }
  },
  change_event_status_popup: {
    pause: {
      hdr: {
        notification: 'End of event'
      },
      txt: {
        warning: 'The event has been paused.'
      }
    }
  },

  // NEW
  event_creation_editing_popup_creation_lbl_successfully_c: 'Event was successfully created!', // newIdLoc
  event_creation_editing_popup_creation_lbl_successfully_u: 'Event was successfully updated!', // newIdLoc
  event_creation_editing_edit_team_name: 'Team #{number}', // newIdLoc
  event_creation_editing_popup_participants_backup_warn: 'There are more participants than VM available', // newIdLoc
  event_creation_editing_popup_participants_backup_vms: 'Reserve', // newIdLoc

  bulk_user_uploading_popup_users_upload_lbl: 'User list', // newIdLoc
  bulk_user_uploading_popup_users_files_block_plh: 'Add a file with a list of users', // newIdLoc

  user_card_popup_edit_input_surname_plh: 'Enter last name', // newIdLoc
  user_card_popup_edit_input_email_plh: 'Enter E-Mail', // newIdLoc
  user_card_popup_edit_input_name_plh: 'Enter first name', // newIdLoc
  user_card_popup_edit_input_middle_name_plh: 'Enter middle name', // newIdLoc
  user_card_popup_edit_input_position_plh: 'Enter position', // newIdLoc

  user_panel_popup_no_block_not_possible_popup_footer_block_btn: 'Block', // newIdLoc
  user_panel_popup_no_block_not_possible_popup_footer_cancel_btn: 'Cancel', // newIdLoc
  user_panel_popup_no_block_prtcpnt_for_observer_txt_warning: 'The blocked user will not be able to log in.\n Do you really want to block the user?', // newIdLoc
  user_panel_popup_no_block_hdr_remove_not_possible: 'It is not possible to delete a user', // newIdLoc
  user_panel_popup_no_block_observer_hdr_block_not_possible: 'Account lockout', // newIdLoc
  user_panel_popup_block_hdr: 'Account lockout', // newIdLoc
  user_panel_popup_block_error_txt: 'The blocked user will not be able to log in.\n Do you really want to block the user?', // newIdLoc
  user_panel_popup_block_footer_cancel_btn: 'Cancel', // newIdLoc
  user_panel_popup_block_footer_block_btn: 'Block', // newIdLoc

  user_creation_editing_popup_delete_title_hdr: 'Removal from Event', // newIdLoc
  user_creation_editing_popup_delete_first_line_txt: 'The user will be removed from all unfinished events', // newIdLoc
  user_creation_editing_popup_delete_second_line_txt: 'Are you sure you want to change the role?', // newIdLoc

  file_input_error_lbl: 'Download error', // newIdLoc

  event_card_btn_remove: 'Remove', // newIdLoc

  events_list_btn_confirm: 'Confirm', // newIdLoc
  combobox_txt_no_data: 'There are no results for', // newIdLoc
  combobox_txt_select_all: 'Select all', // newIdLoc
  combobox_txt_multiple_2_4_items: 'Items', // newIdLoc
  combobox_txt_multiple_5_and_more_items: 'Items', // newIdLoc

  scoring_participant_report_hdr_warning_changes_hint: 'There are unsent changes in report',
  scoring_participant_report_txt_warning_changes_hint: 'Submit the report as you complete it or as recommended by event organizers',

  scoring_participant_report_popup_saving_hdr_correct_errors: 'Save error',
  scoring_participant_report_popup_saving_txt_correct_errors: 'All errors should be corrected before saving the report',
  scoring_scoreboard_txt_no_events_selected: 'Select the events for which you want to display results in the participant scoreboard', // newIdLoc
  scoring_scoreboard_hdr_column_event_team: 'Event/Team', // newIdLoc
  scoring_scoreboard_hdr_column_full_name: 'Full name', // newIdLoc
  scoring_popup_settings_btn_save: 'Save', // newIdLoc

  event_popup_summarizing_content_txt: 'Do you really want to sum it up?', // newIdLoc
  event_popup_summarizing_btn_cancel: 'Cancel', // newIdLoc
  event_popup_summarizing_btn_summrize: 'Summarize', // newIdLoc

  cf_popup_can_btn_yes: 'Yes', // newIdLoc
  cf_popup_can_btn_cancel_no: 'No', // newIdLoc

  event_creation_editing_lbl_error: 'Error!', // newIdLoc
  event_creation_editing_lbl_targets_errors: 'Errors found', // newIdLoc
  event_creation_editing_lbl_targets_errors_ctf: 'Invalid targets (URI):', // newIdLoc
  event_creation_editing_lbl_targets_errors_res: 'Invalid protected services:', // newIdLoc
  event_creation_editing_lbl_targets_errors_ctf_empty: 'Targets (URI) missed', // newIdLoc
  event_creation_editing_lbl_targets_errors_res_empty: 'Protected services missed', // newIdLoc

  mission_cnstrctr_upload_file_error_repeating_name: 'A file with the same name already exists', // newIdLoc
  mission_cnstrctr_upload_file_error_no_more_50mb: 'Select a file no larger than 50 MB in size', // newIdLoc
  mission_cnstrctr_upload_file_error_invalid_file_format: 'Invalid file format', // newIdLoc

  event_creation_editing_lbl_scoring_off_attacks: 'Scoring is only possible when attacks are enabled.', // newIdLoc

  test_animation_sidebar_hdr: 'Animation testing', // newIdLoc
  test_animation_round_title: 'Round', // newIdLoc
  test_animation_vector_title: 'Vector', // newIdLoc
  test_animation_phase_title: 'Phase', // newIdLoc

  event_creation_editing_popup_title_delete: 'Deleting an event', // newIdLoc

  event_card_popup_reboot_txt_question: 'Are you sure you want to reboot this machine?', // newIdLoc

  event_scoreboard_table_hdr_team: 'Command', // newIdLoc
  event_scoreboard_table_hdr_defence: 'Defence', // newIdLoc
  event_scoreboard_table_hdr_total: 'Total', // newIdLoc
  event_scoreboard_table_hdr_attack: 'Attack', // newIdLoc

  event_creation_editing_popup_delete_event_hdr_warning: 'Warning', // newIdLoc
  event_creation_editing_popup_delete_event_txt_cancel: 'You leave page and all unsaved data will be lost. Continue?', // newIdLoc
  event_creation_editing_popup_delete_event_btn_cancel: 'Cancel', // newIdLoc
  event_creation_editing_popup_delete_event_btn_continue: 'Continue', // newIdLoc

}
