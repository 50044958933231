import errors from '@/helpers/errors.helper'
const mutations = {
  setVersion (state, payload) {
    state.version = payload
  },
  registerUnload (state) {
    state.beforeunload = (event) => {
      event.returnValue = 'Несохраненные данные будут утеряны. Вы действительно хотите удалить данные?'
    }
    window.addEventListener('beforeunload', state.beforeunload)
  },
  unregisterUnload (state) {
    if (state.beforeunload != null) {
      window.removeEventListener('beforeunload', state.beforeunload)
      state.beforeunload = null
    }
  },
  setErrorRequestVisible (state, payload) {
    const { status, type, msg } = payload
    state.errorRequestVisible = status
    state.errorRequestType = type || errors.NONE.key
    state.errorRequestMsg = msg || ''
  },
  setHasUnpublishedData (state, payload) {
    state.hasUnpublishedData = payload
  },
  setHasBeenMissionPublished (state, payload) {
    state.hasBeenMissionPublished = payload
  },
  setEditingMission (state, payload) {
    state.editingMission = payload
  },
  setScoreBoardDialog (state, status) {
    state.scoreBoardDialog = status
  },
  setCurrentEvent (state, event) {
    state.currentEvent = event
  },
  setCurrentMission (state, mission) {
    state.currentMission = mission
  },
  setBtnPause (state, payload) {
    state.btnPause = payload
  },
  userProfileShow (state, payload) {
    state.bus.userProfileShow = payload
  },
  showCreateEditUserForm (state, payload) {
    state.bus.showCreateEditUserForm = payload
  },
  refreshMissionTabs (state, payload) {
    state.refreshMissionTabs = payload
  },
  showMaterials (state, payload) {
    state.bus.showMaterials = payload
  },
  showNetworkMap (state, payload) {
    state.bus.showNetworkMap = payload
  },
  showScoreBoard (state, payload) {
    state.bus.showScoreBoard = payload
  },
  showEventLog (state, payload) {
    state.bus.showEventLog = payload
  },
  showReport (state, payload) {
    state.bus.showReport = payload
  },
  showUserDialog (state, payload) {
    state.bus.showUserDialog = payload
  },
  userUpdate (state, payload) {
    state.bus.userUpdate = payload
  },
  // guacamoleMode (state, payload) {
  //   state.bus.guacamoleMode = payload
  // },
  dashboardFilters (state, payload) {
    state.dashboardFilters = payload
  },
  eventTypes (state, payload) {
    state.eventTypes = payload
  },
  setIntegrityState (state, payload) {
    state.integrityState = payload
  },
  integrityUpdatedAll (state, payload) {
    state.integrityUpdatedAll = payload
  },
  addHandlerForWindowOnunload (state, { id, handler }) {
    state.windowOnunloadHandlers[id] = handler
  },
  removeHandlerForWindowOnunload (state, { id, handler }) {
    delete state.windowOnunloadHandlers[id]
  },
  setIsVisibilityChangeHandlerRegistered (state, payload) {
    state.isVisibilityChangeHandlerRegistered = payload
  },
  setCommandScoreboard (state, payload) {
    state.commandScoreboard = payload
  }
}
export default mutations
