var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{ref:"buttonTemplateRef",staticClass:"cbr-button",class:[
      { 'cbr-button-error': _vm.error },
      { 'cbr-button-border': _vm.border },
      { 'cbr-button-warning': _vm.warning },
      { 'cbr-button-transparent': _vm.transparent },
      { 'cbr-button-icon': !_vm.text },
      { 'px-0': !_vm.text },
      { 'pl-0': _vm.cbrIcon },
      _vm.size
    ],style:({ width: _vm.width ? _vm.width : 'auto' }),attrs:{"disabled":_vm.disabled,"tabindex":0,"type":"button"},on:{"click":function($event){return _setup.emit('click')}}},[(_vm.icon)?_c('CbrIcon',{class:{icon: !!_vm.text, cbrIcon: _vm.cbrIcon, mdiIconColor: _vm.mdiIconColor},attrs:{"size":_vm.iconSize,"color":_vm.mdiIconColor}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),(_vm.text)?_c('span',{staticClass:"cbr-button__text",class:_vm.size},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }