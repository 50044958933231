import colors from 'vuetify/es5/util/colors'

const theme = {
  primary: '#00F0FF',
  secondary: colors.teal.darken4,
  background: colors.teal.darken3,
  accent4: '#1B8912',
  accent3: '#18FB80',
  accent2: '#03AF52',
  accent1: '#c2ffd7',
  green_haze: '#009343',
  candle_light: '#FFDB1F',
  tan: '#D6B291',
  sorrel_brown: '#CBAB8D',
  pacific_blue: '#099EBF',
  coral_red: '#FF3D3D',
  bunker: '#121D23',
  bright_turquoise: '#00E2E2',
  bright_turquoise_1: '#00FFC2',
  cyan_aqua: '#00F0FF',
  aquamarine: '#84FFE2',
  aquamarine_1: '#00F0FF',
  elephant: '#0B2A3A',
  firefly: '#09212C',
  firefly_light: '#0A222F',
  black_pearl_dark: '#061826',
  black_pearl: '#071B2A',
  mona_lisa: '#FF9191',
  error: '#FF9191',
  red_bright: '#FF0000',
  red_dark: '#FC9494',
  curious_blue: '#1494E9',
  hippie_blue: '#628FAD',
  light_red: '#CC7F81',
  medium_red: '#FF6969',
  aero_blue: '#C2FFD7',
  tiber: '#09293A',
  loafer: '#F1F8E9',
  tundora: '#4D4D4D',
  pattens_blue: '#E0F4FF',
  scorpion: '#5B5B5B',
  white_haze: '#F3F9F7',
  yellow: '#FFC700',
  orange: '#AA5506',
  no_rezult: '#0c5a65',
  input_background: '#0A2B37'
}

export default theme
