<template>
  <div class="cbr-date-picker" :class="size">
    <div
      v-if="props.title"
      class="cbr-date-picker__title"
      :class="{
        'cbr-date-picker__title--invalid': error,
      }"
    >
      <CbrHint
        v-if="props.infoHint"
        :title="props.infoHint.title"
        :sub-title="props.infoHint.subTitle"
        :description="props.infoHint.description"
        info
        bottom
        content-class="move-right"
      >
        <CbrIcon class="cbr-date-picker__label-icon"> $information </CbrIcon>
      </CbrHint>
      <span class="cbr-date-picker__title-text">{{ props.title }}
        <span class="cbr-date-picker__title-required" v-if="required">*</span>
      </span>
      <slot name="titleRight"/>
    </div>

    <v-menu
      v-model="isCalendarOpen"
      offset-y
      :transition="false"
      :close-on-content-click="false"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="cbr-date-picker__control"
          :style="getControlStyle"
          v-on="on"
          v-bind="attrs"
        >
          <div
            class="cbr-date-picker__control-date-icon"
            :class="{
              'cbr-date-picker__control-date-icon--error': props.error,
            }"
          >
            <Icon
              icon-name="uikit/datepicker"
              :width="getIconSize"
              :height="getIconSize"
            />
          </div>
          <div
            class="cbr-date-picker__control-date-time-wrapper"
            :class="{
              'cbr-date-picker__control-date-time-wrapper--error': props.error,
              'cbr-date-picker__control-date-time-wrapper--active': isCalendarOpen
            }"
          >
            <div
              class="cbr-date-picker__control-date"
              :class="{
                'cbr-date-picker__control-date--filled': props.date
              }"
            >
              <CbrHint
                v-if="!withTime && error && errorHint"
                :title="errorHint.title"
                :sub-title="errorHint.subTitle"
                :description="errorHint.description"
                error
                bottom
                content-class="move-left"
              >
                <CbrIcon class="error-icon"> mdi-alert-circle </CbrIcon>
              </CbrHint>
              <CbrIcon v-else-if="!withTime && error"  class="error-icon"> mdi-alert-circle </CbrIcon>
              <p v-else style="margin: 0">{{ getDateText }}</p>
            </div>
            <div
              v-if="props.withTime"
              class="cbr-date-picker__control-time"
              :class="{
                'cbr-date-picker__control-time--filled': props.time
              }"
            >
              <CbrHint
                v-if="error && errorHint"
                :title="errorHint.title"
                :sub-title="errorHint.subTitle"
                :description="errorHint.description"
                error
                bottom
                content-class="move-left"
              >
                <CbrIcon class="error-icon"> mdi-alert-circle </CbrIcon>
              </CbrHint>
              <CbrIcon v-else-if="error"  class="error-icon"> mdi-alert-circle </CbrIcon>
              <p v-else style="margin: 0">{{ getTimeText }}</p>
            </div>
          </div>
        </div>
      </template>
      <div class="calendar__wrapper">
        <VDatePicker
          v-model="localDate"
          class="cbr-date-picker__calendar calendar"
          :first-day-of-week="$i18n.locale === 'en' ? 7 : 1"
          :weekday-format="customWeekday"
          :allowed-dates="props.allowedDates"
          :header-date-format="customHeaderDateFormat"
          :max="max"
          no-title
          prev-icon="$datepicker_arrow_left"
          next-icon="$datepicker_arrow_right"
          show-adjacent-months
        />
        <div
          v-if="props.withTime"
          class="cbr-date-picker__time-picker"
        >
          <div class="cbr-date-picker__time-picker-text-wrapper">
            <p class="cbr-date-picker__time-picker-text">{{ $t('calendar.lbl.specify') }}</p>
            <p class="cbr-date-picker__time-picker-text">{{ $t('calendar.lbl.start_time') }}</p>
          </div>
          <div class="cbr-date-picker__time-picker-spinners">
            <div class="cbr-date-picker__time-picker-spinner">
              <div
                class="cbr-date-picker__time-picker-arrow"
                @click="onHoursUpButtonClick"
                @mousedown="onHoursUpMouseDown"
                @mouseup="onMouseUp"
              >
                <Icon
                  icon-name="uikit/up_arrow"
                  width="24px"
                  height="24px"
                />
              </div>
              <span class="cbr-date-picker__time-picker-spinner-value">{{ hours }}</span>
              <div
                class="cbr-date-picker__time-picker-arrow"
                @click="onHoursDownButtonClick"
                @mousedown="onHoursDownMouseDown"
                @mouseup="onMouseUp"
              >
                <Icon
                  icon-name="uikit/down_arrow"
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
            <span class="cbr-date-picker__time-picker-spinners-separator">:</span>
            <div class="cbr-date-picker__time-picker-spinner">
              <div
                class="cbr-date-picker__time-picker-arrow"
                @click="onMinutesUpButtonClick"
                @mousedown="onMinutesUpMouseDown"
                @mouseup="onMouseUp"
              >
                <Icon
                  icon-name="uikit/up_arrow"
                  width="24px"
                  height="24px"
                />
              </div>
              <span class="cbr-date-picker__time-picker-spinner-value">{{ minutes }}</span>
              <div
                class="cbr-date-picker__time-picker-arrow"
                @click="onMinutesDownButtonClick"
                @mousedown="onMinutesDownMouseDown"
                @mouseup="onMouseUp"
              >
                <Icon
                  icon-name="uikit/down_arrow"
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="calendar__buttons">
          <div class="calendar__buttons-top">
            <CbrButton
              class="calendar__button"
              :text="$t('calendar.lbl.today')"
              size="small"
              border
              @click="onSetTodayButtonClick"
            />
            <CbrButton
              :text="$t('calendar.lbl.tomorrow')"
              size="small"
              border
              @click="onSetTomorrowButtonClick"
            />
          </div>
          <div class="calendar__buttons-bottom">
            <CbrButton
              class="calendar__button"
              :text="getSaveButtonText"
              size="medium"
              :border="!isSaveButtonShown"
              @click="onSaveButtonClick"
            />
          </div>
        </div>
      </div>
    </v-menu>

  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import moment from 'moment'
import { i18n } from '@/i18n-setup'

const MOUSE_DOWN_INTERVAL = 100
const TIME_BEFORE_MOUSE_DOWN_HANDLER = 500

const props = defineProps({
  date: {
    type: String,
    default: null
  },
  // in HH:MM format
  time: {
    type: String,
    default: null
  },
  title: {
    type: String,
    default: null
  },
  infoHint: {
    type: Object,
    default: null
  },
  size: {
    type: String,
    default: 'medium',
    validator: (value) => {
      return ['small', 'medium'].includes(value)
    }
  },
  width: {
    type: [String, Number],
    default: null
  },
  withTime: {
    type: Boolean,
    default: false
  },
  allowedDates: {
    type: Function,
    default: () => true
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorHint: {
    type: Object,
    default: null
  },
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  max: {
    type: [String, Date],
    default: null
  }
})
const emit = defineEmits(['update-date', 'update-time'])

const isCalendarOpen = ref(false)

/**
 * Форматирует строку даты в формат YYYY-MM-DD
 * @param {string} date - Строка даты в формате DD/MM/YYYY или YYYY-MM-DD
 * @returns {string} date - Строка даты в формате YYYY-MM-DD
 */
function formatDateToYYYY_MM_DD (date) {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/
  if (isoDateRegex.test(date)) {
    return date
  }

  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/
  if (!dateRegex.test(date)) {
    return date
  }

  const [, day, month, year] = date.match(dateRegex)
  return `${year}-${month}-${day}`
}

const localDate = ref(props.date ? formatDateToYYYY_MM_DD(props.date) : null)
watch(() => props.date, (value) => {
  if (value) {
    localDate.value = formatDateToYYYY_MM_DD(value)
  } else {
    localDate.value = null
  }
})

const hours = ref(props.time?.split(':')[0] ?? '00')
const minutes = ref(props.time?.split(':')[1] ?? '00')

let mouseDownTimerId = ref(null)
const isMouseDown = ref(false)
function setMouseDownTimeout (cb) {
  mouseDownTimerId.value = setTimeout(cb, TIME_BEFORE_MOUSE_DOWN_HANDLER)
}
function clearMouseDownTimeout () {
  clearTimeout(mouseDownTimerId.value)
  mouseDownTimerId.value = null
}

const changeTimerId = ref(null)
function onHoursUpButtonClick () {
  if (!isMouseDown.value) {
    const hoursNumber = Number(hours.value) + 1
    setHours(hoursNumber)
  }
}
function onHoursDownButtonClick () {
  if (!isMouseDown.value) {
    const hoursNumber = Number(hours.value) - 1
    setHours(hoursNumber)
  }
}
function onHoursUpMouseDown () {
  isMouseDown.value = true

  const hoursNumber = Number(hours.value) + 1
  setHours(hoursNumber)

  setMouseDownTimeout(() => {
    changeTimerId.value = setInterval(() => {
      const hoursNumber = Number(hours.value) + 1
      setHours(hoursNumber)
    }, MOUSE_DOWN_INTERVAL)
  })
}
function onHoursDownMouseDown () {
  isMouseDown.value = true

  const hoursNumber = Number(hours.value) - 1
  setHours(hoursNumber)

  setMouseDownTimeout(() => {
    changeTimerId.value = setInterval(() => {
      const hoursNumber = Number(hours.value) - 1
      setHours(hoursNumber)
    }, MOUSE_DOWN_INTERVAL)
  })
}
function setHours (hoursNumber) {
  if (hoursNumber === 24) {
    hours.value = '00'
    return
  }
  if (hoursNumber === -1) {
    hours.value = '23'
    return
  }
  if (hoursNumber < 10) {
    hours.value = `0${hoursNumber}`
    return
  }
  hours.value = hoursNumber
}
function onMinutesUpButtonClick () {
  if (!isMouseDown.value) {
    const minutesNumber = Number(minutes.value) + 1
    setMinutes(minutesNumber)
  }
}
function onMinutesDownButtonClick () {
  if (!isMouseDown.value) {
    const minutesNumber = Number(minutes.value) - 1
    setMinutes(minutesNumber)
  }
}
function onMinutesUpMouseDown () {
  isMouseDown.value = true

  const minutesNumber = Number(minutes.value) + 1
  setMinutes(minutesNumber)

  setMouseDownTimeout(() => {
    changeTimerId.value = setInterval(() => {
      const minutesNumber = Number(minutes.value) + 1
      setMinutes(minutesNumber)
    }, MOUSE_DOWN_INTERVAL)
  })
}
function onMinutesDownMouseDown () {
  isMouseDown.value = true

  const minutesNumber = Number(minutes.value) - 1
  setMinutes(minutesNumber)

  setMouseDownTimeout(() => {
    changeTimerId.value = setInterval(() => {
      const minutesNumber = Number(minutes.value) - 1
      setMinutes(minutesNumber)
    }, MOUSE_DOWN_INTERVAL)
  })
}
function setMinutes (minutesNumber) {
  if (minutesNumber === 60) {
    minutes.value = '00'
    return
  }
  if (minutesNumber === -1) {
    minutes.value = '59'
    return
  }
  if (minutesNumber < 10) {
    minutes.value = `0${minutesNumber}`
    return
  }
  minutes.value = minutesNumber
}
function onMouseUp () {
  setTimeout(() => {
    isMouseDown.value = false
  })
  clearMouseDownTimeout()
  clearInterval(changeTimerId.value)
}

function onSetTodayButtonClick () {
  const today = moment().format('YYYY-MM-DD')
  localDate.value = today
  emit('update-day', today)
}
function onSetTomorrowButtonClick () {
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')
  localDate.value = tomorrow
  emit('update-day', tomorrow)
}

const getControlStyle = computed(() => {
  const styles = {}
  if (props.width) {
    styles.width = typeof props.width === 'string' && props.width.includes('px') ? props.width : `${props.width}px`
  }
  return styles
})

const getDateText = computed(() => {
  if (props.date && moment(formatDateToYYYY_MM_DD(props.date)).isValid()) {
    return moment(formatDateToYYYY_MM_DD(props.date)).format('DD/MM/YYYY')
  }
  return i18n.locale === 'en' ? 'DD/MM/YYYY' : 'ДД/ММ/ГГГГ'
})
const getTimeText = computed(() => {
  if (props.date && props.time && moment(formatDateToYYYY_MM_DD(props.date) + ' ' + props.time).isValid()) {
    return props.time
  }
  return i18n.locale === 'en' ? 'HH:MM' : 'ЧЧ:ММ'
})

function customHeaderDateFormat (date) {
  const currentDate = new Date(date)
  const month = currentDate.toLocaleString(i18n.locale, { month: 'long' })
  const year = currentDate.toLocaleString(i18n.locale, { year: 'numeric' })
  return month + '\n' + year
}
const customWeekday = (isoDate) => {
  return new Date(isoDate).toLocaleString(i18n.locale, { weekday: 'short' })
}

const isSaveButtonShown = computed(() => {
  return localDate.value !== props.date || (props.time && `${hours.value}:${minutes.value}` !== moment(props.date + ' ' + props.time, 'DD/MM/YYYY HH:mm').format('HH:mm'))
})
const getSaveButtonText = computed(() => {
  return isSaveButtonShown.value ? `${i18n.t('calendar.lbl.select')} ${moment(localDate.value, 'YYYY-MM-DD').format('DD MMMM')}` : `${i18n.t('calendar.lbl.cancel')}`
})
function onSaveButtonClick () {
  if (isSaveButtonShown.value) {
    emit('update-date', moment(localDate.value, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    emit('update-time', `${hours.value}:${minutes.value}`)
  }
  isCalendarOpen.value = false
}

const getIconSize = computed(() => {
  const sizesMap = {
    small: '16px',
    medium: '24px'
  }
  return sizesMap[props.size]
})
</script>

<style scoped lang="scss">
$cornerSizeSmall: 5px;
$cornerSizeMedium: 5px;

$fontSizeSmall: 16px;
$fontSizeMedium: 18px;

$fieldHeightSmall: 24px;
$fieldHeightMedium: 36px;

.cbr-date-picker {
  position: relative;

  &.small {
    ::v-deep .cbr-date-picker__control-date-icon {
      width: $fieldHeightSmall;
      height: $fieldHeightSmall;
    }
    ::v-deep .cbr-date-picker__title {
      font-size: $fontSizeSmall;
    }
    ::v-deep .cbr-date-picker__control {
      font-size: $fontSizeSmall;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    color: $base-color;
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 1em;
    &--invalid {
      color: $base-color-error-text;
    }
  }
  &__label-icon {
    margin-right: 0.2em;
    min-width: fit-content;
    ::v-deep path {
      fill: $base-color;
      opacity: 0.5;
    }
    &:hover ::v-deep path {
      opacity: 1;
    }
  }
  &__title-required {
    color: $base-color-error-text;
  }

  &__control {
    display: flex;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
  &__control-date-icon {
    @include cut-corners(5px, true, 0px, false, 0px, transparent);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 36px;
    height: 36px;
    background-color: $ActionColor;
    &--error {
      background-color: $AlarmTextColor;
    }
  }
  &__control-date-time-wrapper:not(.cbr-date-picker__control-date-time-wrapper--error):not(.cbr-date-picker__control-date-time-wrapper--active) {
    @include cut-corners(0px, true, 5px, false, 2px, rgba($ActionColor, .6));
    padding: 0;
    display: flex;
    flex: 1;
    &:hover {
      border-color: rgba($ActionColor, .8);
    }
  }
  &__control-date-time-wrapper--active {
    @include cut-corners(0px, true, 0px, false, 2px, $ActionColor);
    padding: 0;
    display: flex;
    flex: 1;
  }
  &__control-date-time-wrapper--error {
    @include cut-corners(0px, true, 5px, false, 2px, $AlarmTextColor);
    padding: 0;
    display: flex;
    flex: 1;
  }
  &__control-date {
    flex: 1;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba($StaticLight, .3);
    &--filled {
      color: $StaticLight;
    }
  }
  &__control-time {
    width: 90px;
    border-color: rgba($ActionColor, .6);
    border-left-width: 2px;
    border-style: solid;
    border-right: none;
    border-top: none;
    border-bottom: none;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba($StaticLight, .3);
    &--filled {
      color: $StaticLight;
    }
  }
  &__time-picker {
    background: linear-gradient(180deg, rgba(0, 255, 240, 0.05) 0%, rgba(0, 255, 240, 0.00) 100%);
    height: 80px;
    border-top: 1px solid rgba($StaticLight, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 4px 36px 0 36px;
  }
  &__time-picker-text {
    margin: 0;
    color: rgba($StaticLight, .8);
    text-align: right;
    font-size: .75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__time-picker-text-wrapper {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__time-picker-spinners {
    display: flex;
    align-items: center;
    gap: 12px
  }
  &__time-picker-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px
  }
  &__time-picker-spinners-separator, &__time-picker-spinner-value {
    color: $StaticLight;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
  }
  &__time-picker-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    cursor: pointer;
  }
}

.calendar {
  width: 100%;
  background-color: transparent !important;

  &__wrapper {
    padding: 16px 26px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    background: rgba(0, 255, 240, 0.05) !important;
    backdrop-filter: blur(12.5px);
    border: 1px solid rgba($ActionColor, .6) !important;
    border-radius: 0 !important;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }
  &__buttons-top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
  }
  &__buttons-bottom {
    display: flex;
  }
  &__button {
    flex: 1;
  }
}
.error-icon {
  color: $base-color-error-text !important;
  font-size: 1.2em !important;
  &:hover {
    cursor: auto;
  }
}
::v-deep .v-picker__title {
  background-color: transparent !important;
  border: none !important;
  //background: rgba(0, 255, 240, 0.05) !important;
}
::v-deep .v-date-picker-table__current.accent--text {
  //border: 2px solid rgba(0, 255, 240, 0.40) !important;
  border-radius: 0 !important;
  border-width: 0 !important;
  color: $StaticLight !important;
}
::v-deep .v-btn {
  color: $StaticLight;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
::v-deep .v-btn:hover {
  border-radius: 0;
  background-color: rgba($ActionColor, 0.1);
}
::v-deep .v-btn--active.accent {
  border-radius: 0 !important;
  background-color: $ActionColor !important;
  color: $StaticDark !important;
}
::v-deep .v-btn--disabled .v-btn__content {
  color: rgba($StaticLight, .2) !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
::v-deep th {
  text-transform: capitalize;
  color: $StaticLight !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
::v-deep .v-date-picker-header__value button {
  color: $StaticLight !important;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  white-space: pre-wrap;
  margin-top: -10px;
}
::v-deep .v-date-picker-header__value button::first-line {
  color: $ActionColor !important;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}
::v-deep .v-icon {
  height: 16px !important;
}
::v-deep .v-date-picker-years {
  color: $StaticLight;
  @include scrollbar;
}
</style>
