<template>
  <div v-if="changeIsShow">
    <v-card :loading="state.isLoading" loader-height="10" tile flat>
      <CbrDialog
        :value="isShow"
        @input="showCloseIfNeeded"
        title-id="create-edit-user-header"
        :title="successDialogTitle"
        persistent
        max-width="1024px"
        id="create-edit-user-body"
      >
        <CbrForm slot="content" ref="form" @submit.prevent="true" class="user-form">
          <div class="user-form__main">
            <AvatarPasswordEdit
              ref="avatar"
              :instant="false"
              v-model="changeIsShow"
              :user="user"
              @uploaded="isLoadedNewAvatar = true"
            />
            <div class="user-form__col">
              <CbrTextField
                v-model.trim.lazy="lastName"
                id="last-name"
                :title="i18n.t('user_card_popup_edit_edit_surname')"
                :placeholder="i18n.t('user_card_popup_edit_input_surname_plh')"
                :error-hint="errorHint(lastNameErrors)"
                hide-details
                @blur="v$.lastName.$touch()"
              />
              <CbrTextField
                v-model.trim.lazy="firstName"
                :title="i18n.t('user_panel_popup_create_user_edit_name_plh')"
                :placeholder="i18n.t('user_card_popup_edit_input_name_plh')"
                id="first-name"
                :error-hint="errorHint(firstNameErrors)"
                hide-details
                @blur="v$.firstName.$touch()"
                required
              />
              <CbrTextField
                v-model.trim.lazy="middleName"
                :placeholder="i18n.t('user_card_popup_edit_input_middle_name_plh')"
                :error-hint="errorHint(middleNameErrors)"
                hide-details
                id="middle-name"
                :title="i18n.t('user_card_popup_edit_edit_patronymic')"
                @blur="v$.middleName.$touch()"
              />
              <CbrTextField
                id="occupation"
                :error-hint="errorHint(textErrorsForField('position'))"
                hide-details
                :placeholder="i18n.t('user_card_popup_edit_input_position_plh')"
                @blur="v$.position.$touch()"
                v-model.trim.lazy="position"
                :title="i18n.t('event_card_hdr_column_position')"
              />
            </div>
            <div class="user-form__col">
              <CbrTextField
                v-model.trim.lazy="email"
                id="create-edit-user-email"
                :title="i18n.t('user_card_popup_edit_edit_login')"
                @blur="v$.email.$touch()"
                :error-hint="errorHint(emailErrors)"
                hide-details
                :placeholder="i18n.t('user_card_popup_edit_input_email_plh')"
                required
              />
              <CbrOrganizationSelector
                id="organization-value"
                v-model="organization"
                :preselect="organization"
                :error-messages="organizationErrors"
                hide-details
                :placeholder="i18n.t('user_card_popup_edit_lst_organization')"
                :title="i18n.t('user_card_popup_edit_lst_organization')"
                :disabled="!isInternal || (userData.id && !isSystemAdmin)"
                :clearable="false"
                @blur="v$.organization.$touch()"
                menu-max-width="324px"
                required
              />
              <CbrCombobox
                :placeholder="i18n.t('user_card_popup_edit_lst_role')"
                id="create-edit-user-role-container"
                id-error="role-error"
                :error-messages="textErrorsForField('role')"
                hide-details
                @blur="v$.role.$touch()"
                @change="changeRole"
                :items="rolesForSelector"
                :title="i18n.t('user_card_popup_edit_lst_role')"
                :preselect="role ? { key: role, name: helpers.roles.getRoleName(role) } : null"
                select-only
                menu-max-width="324px"
                item-text="name"
                item-value="key"
                required
              />
              <CbrCombobox
                :placeholder="i18n.t('user_card_popup_edit_lst_tz')"
                :title="i18n.t('user_card_popup_edit_lst_tz')"
                id="create-edit-user-timezone-input"
                :items="timezones"
                :preselect="userTimezone"
                :value="userTimezone"
                item-text="title"
                menu-max-width="324px"
                hide-details
                select-only
                @change="changeTimezone"
                @blur="v$.timezone.$touch()"
              />
            </div>
          </div>
          <CbrTextArea
            id="user-details-input"
            v-model.trim.lazy="description"
            :error-hint="errorHint(textErrorsForField('description'))"
            :placeholder="i18n.t('user_card_lbl_user_status_plh')"
            rows="3"
            hide-details
            @blur="v$.description.$touch()"
          />
          <div v-if="user.id" class="user-form__errors">
            {{ commonErrors }}
          </div>
        </CbrForm>
        <template slot="footer">
          <CbrButton
            @click="showCloseIfNeeded"
            id="create-edit-user-btn-cancel"
            :text="i18n.t('event_card_btn_cancel')"
            border
          />
          <CbrButton
            :disabled="v$.$invalid"
            id="create-edit-user-btn-save"
            :text="id
              ? i18n.t('event_creation_editing_popup_observes_btn_save')
              : i18n.t('user_panel_btn_create')"
            @click="checkAndSaveUser"
          />
        </template>
      </CbrDialog>
    </v-card>
    <SuccessDialog
      :title="successDialogTitle"
      :text="successDialogText"
      id="create-edit-user-success-popup"
      title-id="create-edit-user-success-popup-header"
      content-id="create-edit-user-success-popup-message"
      button-id="create-edit-user-success-popup-ok"
      v-model="successDialog"
      @success="successAndClose"
    />
    <WarningDialog
      id="create-edit-user-cancel-popup"
      title-id="create-edit-user-cancel-popup-header"
      content-id="create-edit-user-cancel-popup-message"
      :title="successDialogTitle"
      v-model="closeDialog"
      :full-text="warningDialogText"
      max-width="592px"
    >
      <template slot="footer">
        <CbrButton
          @click="close"
          :text="i18n.t('user_creation_editing_popup_delete_btn_yes')"
          id="create-edit-user-cancel-popup-btn-yes"
          error
        />
        <CbrButton
          :text="i18n.t('user_creation_editing_popup_delete_btn_no')"
          @click="closeDialog = false"
          id="create-edit-user-cancel-popup-btn-no"
        />
      </template>
    </WarningDialog>
    <!-- local-- -->
    <WarningDialog
      :title="i18n.t('user_creation_editing_popup_delete_title_hdr')"
      :first-line-text="i18n.t('user_creation_editing_popup_delete_first_line_txt')"
      :second-line-text="i18n.t('user_creation_editing_popup_delete_second_line_txt')"
      v-model="isShowDeleteUserFromEventDialog"
    >
      <template slot="footer">
        <CbrButton
          warning
          @click="isShowDeleteUserFromEventDialog = false"
          :text="i18n.t('event_creation_editing_popup_observes_btn_cancel')"
          class="mr-2"
        />
        <CbrButton
          error
          @click="deleteUserFromUnfinishedEvents"
          :text="i18n.t('event_creation_editing.btn.participant.change')"
          class="ml-2"
        />
      </template>
    </WarningDialog>
  </div>
</template>

<script setup>
import { useStateLoading } from '@/composables/useStateLoading'
import { computed, defineAsyncComponent, nextTick, onMounted, reactive, ref, watch } from 'vue'
import usersService from '@/services/user.service'
import { maxLength, required } from 'vuelidate/lib/validators'
import users from '@/helpers/users.helper'
import { useStore } from '@/store'
import { i18n } from '@/i18n-setup'
import roles from '@/helpers/roles.helper'
import events from '@/helpers/events.helper'
import eventService from '@/services/event.service'
import moment from 'moment'
import helpersPlugin from '@/plugins/helpers'
import AvatarPasswordEdit from '@/components/user/edit/AvatarPasswordEdit'
import { useVuelidate } from '@vuelidate/core'
import { helpers as vuelidateHelpers } from '@vuelidate/validators'

const isSystemAdmin = computed(() => store.getters['account/isSystemAdmin'])
const CbrOrganizationSelector = defineAsyncComponent(() => import('@/components/kit/CbrOrganizationSelector'))
const { helpers } = helpersPlugin
const { state, setStateLoaded } = useStateLoading()
const store = useStore()
const { withAsync } = vuelidateHelpers
const validations = {
  firstName: {
    required,
    validSymbols: (value) => checkValidSymbols(value),
    maxLength: maxLength(255)
  },
  middleName: {
    validSymbols: (value) => checkValidSymbols(value),
    maxLength: maxLength(255)
  },
  lastName: {
    validSymbols: (value) => checkValidSymbols(value),
    maxLength: maxLength(255)
  },
  email: {
    required,
    maxLength: maxLength(255),
    email: (v) => users.isValidEmail(v),
    isUnique: withAsync(function (value) {
      if (value === '') return true
      if (value === initialEmail.value) return true
      return usersService.checkEmail(value)
        .then(res => true)
        .catch(() => false)
    })
  },
  role: {
    required,
    maxLength: maxLength(255)
  },
  organization: {
    required
  },
  position: {
    maxLength: maxLength(255)
  },
  description: {
    maxLength: maxLength(60)
  },
  timezone: {}
}

const userData = reactive({
  id: null,
  description: '',
  email: '',
  firstName: '',
  lastName: '',
  middleName: '',
  position: '',
  role: ''
})
const isShow = ref(false)
const firstName = ref('')
const middleName = ref('')
const lastName = ref('')
const email = ref('')
const role = ref('')
const organization = ref({})
const position = ref('')
const statusError = ref(null)
const valid = ref(false)
const successDialog = ref(false)
const closeDialog = ref(false)
const initialEmail = ref('')
const user = ref({})
const isShowProfile = ref(true)
const userUnfinishedEventsCount = ref(0)
const userTimezone = ref(null)
const isShowDeleteUserFromEventDialog = ref(false)
const userUnfinishedEvents = ref([])
const updatedUserData = ref(null)
const description = ref('')
const isLoadedNewAvatar = ref(false)
const validationData = {
  firstName,
  middleName,
  lastName,
  email,
  role,
  organization,
  position,
  description
}
const v$ = useVuelidate(validations, validationData)
const changeIsShow = computed({
  get: () => isShow.value,
  set: () => showCloseIfNeeded()
})
const organizationErrors = computed(() => {
  const err = []
  if (!v$.value.organization.$dirty) {
    return err
  }
  v$.value.organization.required.$invalid &&
    err.push(i18n.t('event_creation_editing_lbl_error_event_name_empty'))
  return err
})

const commonErrors = computed(() => {
  let err = ''
  if (statusError.value && statusError.value.violations) {
    err = statusError.value.violations.find(v => v.path === 'role' || v.path === 'org')?.message
  }
  return err
})
const id = computed(() => {
  return userData ? userData.id : null
})
const emailErrors = computed(() => {
  const err = textErrorsForField('email')
  if (!v$.value.email.$dirty) return err
  v$.value.email.email.$invalid && !v$.value.email.required.$invalid &&
    err.push(i18n.t('user_card_popup_edit_txt_error_correct_email'))
  !v$.value.email.$pending &&
  v$.value.email.isUnique.$invalid &&
  err.push(i18n.t('user_panel_popup_create_user_lbl_error_email'))
  return err
})
const firstNameErrors = computed(() => textErrorsForField('firstName'))
const middleNameErrors = computed(() => textErrorsForField('middleName'))
const lastNameErrors = computed(() => textErrorsForField('lastName'))
const isInternal = computed(() => store.getters['account/isInternal'])
const userRole = computed(() => store.getters['account/userRole'])
const rolesForSelector = computed(() => {
  if (roles.teacherRoles().includes(userRole.value)) {
    return roles.all().filter(r => ![roles.ADMIN.key, ...roles.teacherRoles(), roles.AUDITOR.key].includes(r.key))
  } else if (!isInternal.value) {
    return roles
      .all().filter(r =>
        ![
          roles.AUDITOR.key,
          roles.INFRASTRUCTURE_ARCHITECT.key,
          roles.RESEARCHER.key,
          roles.SCREENWRITER_METHODOLOGIST.key
        ].includes(r.key))
  } else { return roles.all() }
})

const warningDialogText = computed(() => {
  const firstLine = id.value == null
    ? i18n.t('user_creation_editing_popup_create_data_unsaved_data')
    : i18n.t('user_creation_editing_popup_edit_data_unsaved_data')
  const secondLine = id.value == null
    ? i18n.t('user_creation_editing_popup_delete_txt_data_lost_create')
    : i18n.t('user_creation_editing_popup_delete_txt_data_lost_edit')
  return `${firstLine}\n${i18n.t('user_creation_editing_popup_delete_txt_data_lost')} ${secondLine}`
})
const successDialogTitle = computed(() => {
  return id.value == null
    ? i18n.t('user_panel_popup_create_user_hdr_creation')
    : i18n.t('user_card_popup_edit_hdr_edit_user')
})
const successDialogText = computed(() => {
  return id.value == null
    ? i18n.t('user_panel_popup_create_user_lbl_created_success')
    : i18n.t('user_card_popup_changes_saved_lbl_data')
})
const timezones = computed(() => helpers.date.getTimeZonesList())

const showCreateEditUserForm = computed(() => store.getters['global/showCreateEditUserForm'])
watch(() => showCreateEditUserForm.value, (user) => {
  if (user) {
    isLoadedNewAvatar.value = false
    Object.assign(userData, user)
    isShow.value = true
    store.commit('global/showCreateEditUserForm', null)
  }
})
watch(() => isShow.value, () => {
  if (!isShow.value) {
    Object.assign(userData, {
      createdDate: null,
      createdTime: null,
      description: '',
      email: '',
      firstName: '',
      id: null,
      isEnabled: '',
      lastName: '',
      middleName: '',
      organization: null,
      position: '',
      role: '',
      timeZone: null
    })
  }
  if (userData) {
    loadAndOn()
  } else {
    const initialData = getInitialFormData()
    firstName.value = initialData.firstName
    middleName.value = initialData.middleName
    lastName.value = initialData.lastName
    email.value = initialData.email
    role.value = initialData.role
    organization.value = initialData.organization
    position.value = initialData.position
    valid.value = initialData.valid
    successDialog.value = initialData.successDialog
    closeDialog.value = initialData.closeDialog
    initialEmail.value = initialData.initialEmail
    user.value = initialData.user
    isShowProfile.value = initialData.isShowProfile
    userTimezone.value = initialData.userTimezone
    description.value = initialData.description
    userUnfinishedEventsCount.value = initialData.userUnfinishedEventsCount
    userUnfinishedEvents.value = initialData.userUnfinishedEvents
    setStateLoaded()
  }
})
const userOrganization = computed(() => store.getters['account/userOrganization'])
function loadAndOn () {
  user.value = userData
  firstName.value = user.value.firstName
  middleName.value = user.value.middleName
  lastName.value = user.value.lastName
  role.value = user.value.role
  organization.value = user.value.organization
  position.value = user.value.position
  email.value = user.value.email
  initialEmail.value = user.value.email
  description.value = user.value.description
  helpers.date.getTimeZonesList().forEach(timezone => {
    if (timezone.value === userData.timeZone) {
      userTimezone.value = timezone
    }
  })
  if (userData.id) {
    events.getUserUnfinishedEventsCount(userData.id)
      .then(count => {
        userUnfinishedEventsCount.value = count
        setStateLoaded()
      })
      .catch(e => {
        setStateLoaded()
      })
  } else if (!isInternal.value) {
    organization.value = userOrganization.value
  }
}
const checkValidSymbols = (value) => {
  return /^[\p{L}\d '"-]*$/gu.test(value)
}
const textErrorsForField = (field) => {
  const errors = []
  const fieldValue = v$.value[field]
  if (!fieldValue.$dirty) return errors
  const { required, maxLength, validSymbols } = fieldValue
  if (required?.$invalid) {
    errors.push(i18n.t('event_creation_editing_lbl_error_event_name_empty'))
  }
  if (maxLength?.$invalid) {
    const maxLengthErrorMessage = field !== 'description'
      ? i18n.t('event_creation_editing_lbl_error_event_name_chrctrs')
      : `${i18n.t('entered_value_must_not_exceed')} 60 ${i18n.t('characters')}`
    errors.push(maxLengthErrorMessage)
  }
  if (validSymbols?.$invalid) {
    errors.push(i18n.t('event_creation_editing_lbl_error_fio_chrctrs'))
  }
  return errors
}
const errorHint = (errors) => {
  return errors.length ? { description: errors.join(', ') } : null
}
const unfinishedEvents = ref([])
const deleteUserFromUnfinishedEvents = () => {
  const key = events.getMemberEventKey(user.value.role)
  unfinishedEvents.value.forEach(event => {
    event[key] = event[key].filter(member => member.id !== id.value)
    eventService.updateEvent(event.id, event)
  })
  isShowDeleteUserFromEventDialog.value = false
  saveUser()
}
const avatar = ref(null)
const currentUserId = computed(() => store.getters['account/currentUserId'])
const form = ref()
const saveUser = async () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    const userBody = getUpdatedData()
    if (id.value !== null && id.value !== undefined) {
      usersService.editUser(id.value, userBody)
        .then(user => {
          if (isLoadedNewAvatar.value) {
            avatar.value.upload(user.id)
          }
          updatedUserData.value = user
          successDialog.value = true
          statusError.value = null
          if (id.value === currentUserId.value) {
            store.commit('account/setUserData', userBody)
          }
        })
        .catch(err => {
          statusError.value = err.response.data
          nextTick(() => {
            form.value.validate()
          })
        })
    } else {
      usersService.createUser(userBody)
        .then(user => {
          if (isLoadedNewAvatar.value) {
            avatar.value.upload(user.id)
          }
          updatedUserData.value = user
          successDialog.value = true
        })
        .catch(err => {
          statusError.value = err.response.data
          nextTick(() => {
            form.value.validate()
          })
        })
    }
  }
}
const checkAndSaveUser = async () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    if (id.value) {
      userUnfinishedEventsCount.value = await events.getUserUnfinishedEventsCount(id.value)
      v$.value.$touch()
      if (!v$.value.$invalid) {
        await saveUser()
      }
    } else {
      await saveUser()
    }
  }
}
const showCloseIfNeeded = () => {
  const updatedData = getUpdatedData()
  if (!user.value?.id && !Object.keys(updatedData).filter(k => !!updatedData[k]).length) {
    close()
  }
  if ((id.value == null && v$.value.$anyDirty) || !helpers.object.compareIfExists(user.value, updatedData, ['timeZone', 'organization'])) {
    closeDialog.value = true
  } else {
    close()
  }
  statusError.value = null
}

const successAndClose = async () => {
  store.commit('global/userUpdate', {
    isNew: !id.value,
    createdDate: userData
      ? moment(userData.createdTime).format('DD.MM.YYYY')
      : moment().format('DD.MM.YYYY'),
    ...updatedUserData.value
  })
  close()
}
const getInitialFormData = () => ({
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  role: '',
  organization: {},
  position: '',
  valid: false,
  successDialog: false,
  closeDialog: false,
  initialEmail: '',
  user: {},
  isShowProfile: true,
  userTimezone: '',
  description: '',
  userUnfinishedEventsCount: 0,
  userUnfinishedEvents: []
})
const sanitizeStringFields = (fields, realObject = null) => {
  const object = Object.assign({}, realObject)
  fields.forEach(field => {
    if (object[field] !== undefined && typeof object[field] === 'string') {
      object[field] = helpers.string.sanitize(object[field])
    }
  })
  return object
}

const getUpdatedData = () => {
  const sanitizedData = sanitizeStringFields(['firstName', 'middleName', 'lastName'], {
    firstName: firstName.value,
    middleName: middleName.value,
    lastName: lastName.value,
    email: email.value,
    role: role.value,
    organization: organization.value,
    position: position.value,
    timeZone: userTimezone.value?.value,
    description: description.value
  })
  return sanitizedData
}

const close = () => {
  const initialData = getInitialFormData()
  firstName.value = initialData.firstName
  middleName.value = initialData.middleName
  lastName.value = initialData.lastName
  email.value = initialData.email
  role.value = initialData.role
  organization.value = initialData.organization
  position.value = initialData.position
  valid.value = initialData.valid
  successDialog.value = initialData.successDialog
  closeDialog.value = initialData.closeDialog
  initialEmail.value = initialData.initialEmail
  user.value = initialData.user
  isShowProfile.value = initialData.isShowProfile
  userTimezone.value = initialData.userTimezone
  description.value = initialData.description
  userUnfinishedEventsCount.value = initialData.userUnfinishedEventsCount
  userUnfinishedEvents.value = initialData.userUnfinishedEvents
  v$.value.$reset()
  avatar.value.reset()
  isShow.value = false
}
const changeTimezone = (event) => {
  userTimezone.value = typeof event === 'string' ? null : event
}
const changeRole = (event) => {
  role.value = typeof event === 'string' ? '' : event.key
}
onMounted(() => {
  if (!id.value) {
    setStateLoaded()
  }
})
</script>

<style scoped lang="scss">
.user-form {
  width: 100%;
  &__main {
    padding: 16px 0;
    display: flex;
    gap: 36px;
  }
  &__col {
    width: 324px;
    & > div:not(:first-child) {
      margin-top: 18px;
    }
  }
  &__errors {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $base-color-error-text;
    line-height: 21px;
  }
}
</style>
