<template>
  <div
    class="switch d-flex align-center"
    @click="disabled ? null : $emit('input', !value)"
    :class="{ disabled, medium, dark, fade, on: value, off: !value }"
  >
    <div class="switch-btn d-flex flex-row align-center" :class="{ off: !value, medium, disabled }">
      <CbrIcon class="switch-btn-icon-on" v-if="value && !noIcons" :class="{ medium }">{{
        iconOn
      }}</CbrIcon>
      <div class="switch-btn-indicator" :class="{ on: value, off: !value, medium, disabled }"></div>
      <CbrIcon class="switch-btn-icon-off" v-if="!value && !noIcons" :class="{ medium }">{{
        iconOff
      }}</CbrIcon>
    </div>
    <div class="switch-label" v-if="hasLabel" :class="{ medium }">{{ labelText }}</div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { i18n } from '@/i18n-setup.js'

const props = defineProps({
  value: {
    type: Boolean,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  medium: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: '',
  },
  defaultLabels: {
    type: Boolean,
    default: false,
  },
  labelOn: {
    type: String,
    default: '',
  },
  labelOff: {
    type: String,
    default: '',
  },
  iconOn: {
    type: String,
    default: 'mdi-check'
  },
  iconOff: {
    type: String,
    default: 'mdi-close'
  },
  noIcons: {
    type: Boolean,
    default: false
  },
  dark: {
    type: Boolean,
    default: false
  },
  fade: {
    type: Boolean,
    default: false
  }
})

const hasLabel = computed(() => props.label || props.labelOn || props.labelOff || props.defaultLabels)
const labelText = computed(() => {
  if (props.label) {
    return props.label
  } else if (props.defaultLabels) {
    return props.value ? i18n.t('common_switch_on') : i18n.t('common_switch_off')
  } else if (props.labelOn || props.labelOff) {
    return props.value
      ? props.labelOn || i18n.t('common_switch_on')
      : props.labelOff || i18n.t('common_switch_off')
  } else return ''
})
</script>

<style lang="scss" scoped>

$color-switch-off: $base-color-error-text;
$color-switch-off-disabled: $base-color-error-text;

@mixin content($switch-btn-background: $base-color-dark, $switch-btn-border: $base-color-muted,
               $switch-indicator-on-background: $base-color-muted, $switch-indicator-off-background: $color-switch-off) {
    cursor: pointer;

    .switch-label {
      user-select: none;
      padding-left: 12px;
      color: $base-color-text;
      transition: color 0.3s;

      &.medium {
        font-size: $font-size-medium;
      }
    }

    .switch-btn {
      position: relative;
      background: $switch-btn-background;
      color: $switch-indicator-on-background;
      width: 48px;
      height: 24px;
      @include cut-corners(7px, true, 7px, false, 2px, $switch-btn-border);
      padding: 0 3px;

      &.medium {
        width: 72px;
        height: 36px;
        @include cut-corners(9px, true, 9px, false, 1px, $switch-btn-border);
        padding: 0 6px;
      }

      &.off {
        color: $switch-indicator-off-background;
        border-color: $color-switch-off;
      }

      &.disabled {
        color: $base-color-disabled;
        border-color: $base-color-disabled;
      }

      &.disabled.off {
        color: $color-switch-off-disabled;
        border-color: $color-switch-off-disabled;
      }

      i {
        font-size: 16px;
        color: inherit;
        transition: none;

        &.medium {
          font-size: 24px;
        }
      }

      &-icon-on {
        margin-right: auto;
        color: inherit;
        font-size: inherit;
      }

      &-icon-off {
        margin-left: auto;
        color: inherit;
        font-size: inherit;
      }

      div {
        width: 16px;
        height: 16px;
        @include cut-corners(4px, true, 4px, false);

        &.medium {
          width: 24px;
          height: 24px;
          @include cut-corners(5px, true, 5px, false);
        }
      }

      &-indicator {
        &.on {
          margin-left: auto;
          background: $switch-indicator-on-background;

          &.disabled {
            background: $base-color-disabled;
          }
        }

        &.off {
          margin-right: auto;
          background: $switch-indicator-off-background;

          &.disabled {
            background: $color-switch-off-disabled;
          }
        }
      }
    }

    &:hover &-label {
      color: $base-color-light;
    }

    &:hover &-btn {
      color: $base-color;
      border-color: $base-color;
    }

    &:hover &-btn.off {
      color: $base-color-error-text;
      border-color: $base-color-error-text;
    }

    &:hover &-btn.disabled {
      color: $base-color-disabled;
      border-color: $base-color-disabled;
    }

    &:hover &-btn.disabled.off {
      color: $color-switch-off-disabled;
      border-color: $color-switch-off-disabled;
    }

    &.disabled {
      cursor: auto;

      .switch-label {
        color: $base-color-off;
      }
    }
}

.switch {
  @include content();
}
.switch.on:not(.disabled):not(:hover) {
  opacity: 0.8;
}
.switch.dark.off {
  @include content(rgba($ErrorColor, 0.8), $ErrorColor, $StaticLight, $StaticLight);
  opacity: 0.8;
}
.switch.dark.on {
  @include content(rgba($ActionColor, 0.8), $ActionColor, $StaticLight, $StaticLight);
}
.switch.dark.off:not(.disabled):hover, .switch.dark.on:not(.disabled):hover {
  opacity: 1;
}
.switch.fade.off {
  @include content(rgba($StaticDark, 0.5), rgba($StaticDark, 0.5), rgba($StaticLight, 0.8), rgba($StaticLight, 0.8));
  opacity: 0.8;
  .switch-btn.off {
    border-color: rgba($StaticLight, 0.8);
  }
  &:not(.disabled):hover {
    opacity: 1;
  }
}
</style>
