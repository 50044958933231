import store from '@/store'
import { constructor } from './player'
import { defaultSceneParams } from '@/helpers/map.helper.js'

export default class Scene {
  constructor (ctx, zoom, transX, transY) {
    this.defaultState = {}
    this.ctx = ctx
    this.width = constructor.cvs.width
    this.height = constructor.cvs.height
    this.effect = []
    this.triggers = []
    this.Notification = defaultSceneParams.Notification
    this.NotificationColor = defaultSceneParams.NotificationColor
    this.translateX = transX
    this.translateY = transY
    this.progress = 0
    this.zoom = zoom
    this.id = 'scene'
    this.Draw()
  }

  Draw () {
    this.ctx.beginPath()
    this.ctx.fillStyle = this.NotificationColor
    this.ctx.font = 60 / this.zoom + 'px Rubik'
    this.ctx.textAlign = 'center'
    this.textWidth = this.ctx.measureText(this.Notification).width
    this.textHeight = parseInt(this.ctx.font)
    if (this.zoom === 1) {
      this.x = this.width / 2 - this.textWidth / 2
      this.y = 70
      this.ctx.fillText(this.Notification, this.x + this.textWidth / 2, this.y + this.textHeight / 2)
    } else {
      this.x = ((this.width / 2) - this.translateX) / this.zoom - this.textWidth / 2
      this.y = (70 - (this.translateY)) / this.zoom
      this.ctx.fillText(this.Notification, this.x + this.textWidth / 2, this.y + this.textHeight / 2)
    }
    this.ctx.closePath()
    const uniq = []
    for (const str of this.effect) {
      if (!uniq.includes(str)) {
        uniq.push(str)
      }
    }
    this.effect = uniq
    for (var i in store.state.map.sceneEffects) {
      for (var j in this.effect) {
        if (store.state.map.sceneEffects[i] === this.effect[j]) {
          this[store.state.map.sceneEffects[i]]()
        }
      }
    }
  }

  Lose () {
    const grad = this.ctx.createRadialGradient(
      ((this.width / 2) - (this.translateX)) / this.zoom,
      ((this.height / 2) - (this.translateY)) / this.zoom,
      (this.width / 2.5) / this.zoom,
      ((this.width / 2) - (this.translateX)) / this.zoom,
      ((this.height / 2) - (this.translateY)) / this.zoom,
      this.width / this.zoom
    )
    grad.addColorStop(0, 'rgba(255, 0, 0, 0)')
    grad.addColorStop(0.7, 'red')
    this.ctx.fillStyle = grad
    this.ctx.fillRect(-this.translateX / this.zoom, -this.translateY / this.zoom, this.width / this.zoom, this.height / this.zoom)
    this.ctx.closePath()
  }

  Progressbar () {
    var lineWidth = 1; var RectWidth = 500 / this.zoom; var RectHeight = 20 / this.zoom; var rectProgH = 0.4
    this.ctx.beginPath()
    if (this.zoom === 1) {
      this.ctx.rect((this.width / 2) - RectWidth / 2, (200), RectWidth, RectHeight)
    } else {
      this.ctx.rect((this.width / 2 / this.zoom) - RectWidth / 2 - this.translateX / this.zoom, (200 / this.zoom) - this.translateY / this.zoom, RectWidth, RectHeight)
    }
    this.ctx.strokeStyle = '#0A0A1F'
    this.ctx.fillStyle = '#535376'

    this.ctx.lineWidth = lineWidth
    this.ctx.stroke()
    this.ctx.fill()
    this.ctx.closePath()
    this.ctx.beginPath()
    this.progress += 0.05
    let sin = ((RectWidth - (RectWidth * rectProgH)) * Math.sin(this.progress))
    if (sin < 0) {
      sin = sin * (-1)
    }
    if (this.zoom === 1) {
      this.ctx.rect(((this.width / 2) - RectWidth / 2) + sin, (200), RectWidth * rectProgH, RectHeight)
    } else {
      this.ctx.rect(((this.width / 2 / this.zoom) - RectWidth / 2) + sin - this.translateX / this.zoom, (200 / this.zoom) - this.translateY / this.zoom, RectWidth * rectProgH, RectHeight)
    }
  }

  ProgressbarRed () {
    this.Progressbar()
    this.ctx.fillStyle = 'red'
    this.ctx.fill()
    this.ctx.closePath()
  }

  ProgressbarYellow () {
    this.Progressbar()
    this.ctx.fillStyle = '#FFDB00'
    this.ctx.fill()
    this.ctx.closePath()
  }
}
