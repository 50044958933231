<template>
  <CbrDialog
    id="map-materials"
    :value="value"
    :title="title"
    max-width="962px"
    full
    @input="$emit('input', $event)"
  >
    <template #content>
      <img style="width: 962px" :src="imgSrc" />
    </template>
    <template #footer>
      <CbrButton
        :text="$t('event_screen_popup_map_btn_download')"
        icon="$export"
        :mdiIconColor="$h.colors.baseColors.$StaticDark"
        cbr-icon
        @click="download"
      />
      <CbrButton
        :text="$t('event_screen_popup_map_btn_print')"
        icon="mdi-printer"
        :mdiIconColor="$h.colors.baseColors.$StaticDark"
        cbr-icon
        @click="print"
      />
    </template>
  </CbrDialog>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import eventService from '@/services/event.service'
import missionService from '@/services/mission.service'
import jsFileDownload from 'js-file-download'
import { i18n } from '@/i18n-setup'

const props = defineProps({
  id: Number,
  name: String,
  gameType: String,
  value: Boolean,
  type: {
    type: String,
    required: true
  }
})

const entityTitle = ref(null)

onMounted(() => {
  switch (props.type) {
    case 'mission':
      entityTitle.value = i18n.t('of_mission')
      break
    case 'event':
      entityTitle.value = i18n.t('of_event')
      break
  }
})

const title = computed(() => `${i18n.t('event_card_link_map')}`)
const imgSrc = computed(() => {
  switch (props.type) {
    case 'mission':
      return `/api/mission/${props.id}/media/net-map?locale=${i18n.locale}`
    case 'event':
      return `/api/event/${props.gameType}/${props.id}/network/image?locale=${i18n.locale}`
    default:
      return ''
  }
})

const download = () => {
  let apiFunction = null
  switch (props.type) {
    case 'mission':
      apiFunction = missionService.getNetworkMapAsBlob
      break
    case 'event':
      apiFunction = eventService.getNetworkMapAsBlob
      break
  }
  apiFunction(props.id).then(res => {
    jsFileDownload(
      res,
      `${i18n.t('event_card_link_map')} ${entityTitle.value} ${props.name}.png`
    )
  })
}
const print = () => {
  const window_ = window.open(imgSrc.value, '_blank')
  const script = document.createElement('script')
  script.innerHTML = 'window.print();window.onafterprint = function () {window.close();}'
  window_.document.head.appendChild(script)
}

</script>
