import { render, staticRenderFns } from "./CbrFilesBlock.vue?vue&type=template&id=67c7d720"
import script from "./CbrFilesBlock.vue?vue&type=script&setup=true&lang=js"
export * from "./CbrFilesBlock.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports