export const eventRu = {
  cf_event_card: {
    label_cf: 'Захват флага',
    crumbs: {
      all_events: 'Все мероприятия',
      event: 'Мероприятие'
    },
    btn: {
      scoreboard: 'Табло результатов',
      join: 'Присоединиться',
      prepare: 'Подготовить',
      start: 'Запустить',
      cancel: 'Отменить',
      debrief: 'Подвести итоги',
      finish: 'Завершить',
      change: 'Редактировать',
      card: 'Карточка',
      arbitration: 'Арбитраж',
      publish: 'Опубликовать',
    },
    lbl: {
      pause: 'Пауза',
      continue: 'Продолжить',
      event_status: {
        event_status: 'Статус:',
        waiting: 'Ожидание',
        preparation: 'Подготовка',
        starting_soon: 'Скоро начнется',
        in_progress: 'В процессе!',
        error: 'Ошибка',
        debriefing: 'Подведение итогов',
        canceled: 'Отменено',
      },
      flag: 'Флаг:',
      event_creator: 'Мероприятие создал:',
      event_goals: 'Цели и задачи мероприятия',
      date_time: 'Дата и время начала',
      teacher: 'Преподаватель',
    },
    hdr: {
      team: 'Команда',
      observes: 'Наблюдатели',
      teacher: 'Преподаватель',
    },
    hdr_column: {
      full_name: 'ФИО',
      status: 'Состояние',
      email: 'E-mail',
      position: 'Должность',
      vm: 'VM',
      reboot_vm_hint: 'Перезагрузить машину участника ',
      attack: 'Атака, баллы',
      defence: 'Защита, баллы',
      total: 'Всего баллы',
      attacker: 'Атакующий',
      victim: 'Жертва',
      flag: 'Флаг установлен',
      uri: 'Цель (URI)',
    },
    chk: {
      credited: 'Засчитан'
    },
    popup_reboot: {
      hdr: {
        vm: 'Перезагрузка',
      },
      lbl: {
        vm: 'VM'
      },
      txt: {
        question: 'Вы действительно желаете перезагрузить виртуальную машину?',
      },
      btn: {
        yes: 'Да',
        no: 'Нет',
      }
    }
  }
}
