import httpClient from './http'

export default {
  getServerTime () {
    return httpClient.get('/time')
  },
  getVersion () {
    return httpClient.get('/version').then(json => json.version)
  }
}
