<template>
  <CbrDialog
    :title="$t('add_gif_lbl_add_gif')"
    :value="true"
    @input="emit('showAddGifEffect')"
    max-width="1024px"
    persistent
  >
    <template #content>
      <div class="d-flex w100">
        <div class="object-list">
          <CbrTextField
            v-model="search"
            :placeholder="$t('add_gif_edit_search_object')"
            :maxlength="255"
            search
            small
            hide-details
          />
          <div class="object-list__wrap">
            <button
              v-for="component in filteredComponents"
              :key="component.name"
              @click="selectComponent(component)"
               :class="{ active: component.name === componentName }"
            >
              <CbrHint :description="component.name" no-icon right>
                <img :src="component.stateOn" :id="component.name + '_stateOn'" />
              </CbrHint>
            </button>
          </div>
        </div>
        <div class="gif-effect">
          <div v-if="!selected" class="gif-effect__empty">{{ $t('add_gif_hdr_add_gif') }}</div>
          <div v-else class="gif-effect__wrap">
            <div class="gif-effect__content">
              <CbrTextField
                v-model.trim="name"
                :title="$t('add_gif_edit_name_effect')"
                :placeholder="$t('add_gif_edit_name_effect_plh')"
                class="w100"
                :maxlength="255"
                :error-messages="nameErrors"
                required
                @blur="checkEmptyName"
                @input="checkEmptyName"
              />
              <div class="gif-effect__preview">
                <div class="add-effect">
                  <div
                    class="add-effect__image"
                    :class="{ 'drag-cover': isDraggingOver }"
                    @drop.prevent="uploadFile($event)"
                    @dragenter.prevent="onDragEnter()"
                    @dragleave.prevent="onDragLeave($event)"
                    @dragover.prevent=""
                  >
                    <img v-if="componentImg" :src="componentImg.src" alt="" :style="{ opacity: hideComponent ? 0 : 1 }" />
                    <img
                      v-if="img"
                      :src="img.src"
                      :style="{ top: `calc(50% + ${offsetY}px`, left: `calc(50% + ${offsetX}px` }"
                      alt=""
                    />
                  </div>
                  <div class="file-properties">
                    <template v-if="file">
                      <div class="file-properties__name" :data-type="fileExt()">
                        {{ fileName() }}
                      </div>
                      <div class="file-properties__size">
                        {{ sizeStr }}
                      </div>
                    </template>
                    <div v-else class="file-properties__no-file">{{ $t('add_object_lbl_no_file_loaded') }}</div>
                  </div>
                  <CbrButton
                    :text="$t(file ? 'add_object_btn_reload' : 'add_object_btn_upload')"
                    :icon="file ? '$reload' : '$cbrPlusCircle18'"
                    :border="!!file"
                    @click="$refs.effect.click()"
                  />
                  <input
                    type="file"
                    ref="effect"
                    style="display: none"
                    accept="image/gif"
                    @change="uploadFile"
                  />
                </div>
                <div class="gif-effect__settings" :class="{ 'gif-effect__settings-off': !file}">
                  <div>{{ $t('add_gif_lbl_offset') }}</div>
                  <div class="gif-effect__settings-coords">
                    <div class="d-flex align-center">
                      <div class="gif-effect__settings-title">X</div>
                      <CbrTextField
                        v-model="offsetX"
                        type="number"
                        :placeholder="$t('add_gif_spinneredit_offset_x')"
                        :disabled="!file"
                        hide-details
                        small
                        class="gif-effect__settings-value"
                      />
                    </div>
                    <div class="d-flex align-center">
                      <div class="gif-effect__settings-title">Y</div>
                      <CbrTextField
                        v-model="offsetY"
                        type="number"
                        :placeholder="$t('add_gif_spinneredit_offset_y')"
                        :disabled="!file"
                        hide-details
                        small
                        class="gif-effect__settings-value"
                      />
                    </div>
                  </div>
                  <CbrCheckBox
                    :checked="hideComponent"
                    :label="$t('add_gif_chk_hide_component')"
                    :disabled="!file"
                    class="pl-6"
                    @change="hideComponent = $event.target.checked"
                  />
                </div>
              </div>
            </div>
            <div class="gif-effect__footer">
              <hr class="gif-effect__footer-line"/>
              <div class="gif-effect__footer-btn">
                <CbrButton
                  :text="$t('add_object_btn_cancel')"
                  @click="$emit('showAddGifEffect')"
                  border
                />
                <CbrButton
                  :text="$t('add_gif_btn_save')"
                  :disabled="!file || !name"
                  @click="newComponentGifEffect"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CbrDialog
        v-model="isLoadingError"
        :title="$t('add_object_hdr_upload_error')"
        :error-text="$t('add_gif_file_incorrect_format')"
        error
      >
        <template v-slot:footer>
          <CbrButton
            :text="$t('add_object_btn_close')"
            @click="isLoadingError = false"
          />
        </template>
      </CbrDialog>
    </template>
  </CbrDialog>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from '@/store'
import { i18n } from '@/i18n-setup'

const store = useStore()
const emit = defineEmits(['showAddGifEffect'])

// component
const search = ref('')
const selected = ref(false)
const componentName = ref('')
const componentImg = ref(false)
const componentImgSrc = ref('') // ??
// gif
const name = ref('')
const nameErrors = ref([])
const file = ref(null)
const sizeStr = ref('')
const img = ref(null)
const hideComponent = ref(false)
const offsetX = ref(0)
const offsetY = ref(0)
const isDraggingOver = ref(false)
const isLoadingError = ref(false)

const components = computed(() => store.getters.components)
const filteredComponents = computed(() => {
  return components.value.filter(comp => comp.name.indexOf(search.value) !== -1)
})

const selectComponent = (item) => {
  const src = item.stateOn
  selected.value = true
  componentName.value = item.name
  componentImgSrc.value = src
  componentImg.value = new Image()
  componentImg.value.src = src
}

const checkName = () => !store.getters.gifEffects.some(item => item === name.value)
const checkEmptyName = () =>
  nameErrors.value = !name.value ? [i18n.t('add_object_edit_name_object_empty')] : []

const newComponentGifEffect = () => {
  if (!checkName()) {
    nameErrors.value = [i18n.t('gif_effect_with_this_name_exists')]
    return
  }
  const effect = {
    name: name.value,
    componentName: componentName.value,
    hideComponent: hideComponent.value,
    image: file.value,
    offsetX: Math.round(offsetX.value * 1.25),
    offsetY: Math.round(offsetY.value * 1.25)
  }
  store.dispatch('newGifEffect', effect)
  emit('showAddGifEffect')
}

const fileName = () => file.value.name.split('.')[0]
const fileExt = () => {
  const arr = file.value.name.split('.')
  return arr.length > 1 ? '.' + arr.pop() : ''
}

const uploadFile = (e) => {
  let _file
  if (e.type === 'change') {
    _file = e.target.files[0]
  } else if (e.type === 'drop') {
    isDraggingOver.value = false
    _file = e.dataTransfer.files[0]
  }
  if (!_file) return
  if (_file.type !== 'image/gif') {
    isLoadingError.value = true
    return
  }
  const fileReader = new FileReader()
  fileReader.addEventListener('load', () => {
    const image = new Image()
    image.src = fileReader.result
    image.onload = () => {
      img.value = image
      sizeStr.value = image.naturalWidth + ' x ' + image.naturalHeight
    }
  })
  fileReader.readAsDataURL(_file)
  file.value = _file
}
const onDragEnter = () => isDraggingOver.value = true
const onDragLeave = (e) => {
  if (!e.relatedTarget) {
    isDraggingOver.value = false
    return
  }
  if (e.relatedTarget.closest('.add-effect__image')) {
    return
  }
  isDraggingOver.value = false
}

watch(() => name.value, (val) => checkEmptyName())

</script>

<style lang="scss" scoped>
.object-list {
  width: 196px;
  height: 624px;
  background: rgba($base-color-light, 0.05);
  @include cut-corners(5px, true, 5px, false);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__wrap {
    flex-grow: 1;
    width: 100%;
    display: flex;
    gap: 16px;
    @include scrollbar-medium;
    flex-wrap: wrap;
    align-content: flex-start;
    button {
      position: relative;
      &.active {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($base-color, 0.5);
        }
      }
      img {
        width: 64px;
        height: 64px;
      }
    }
  }
}
.gif-effect {
  padding: 0 32px;
  color: rgba($base-color-light, 0.5);
  flex-grow: 1;
  &__empty {
    padding-top: 96px;
    text-align: center;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__preview {
    display: flex;
    gap: 32px;
    .image-block {
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: relative;
      &__object {
        width: 256px;
        height: 256px;
        background: rgba($base-black, 0.1);
      }
      &__gif {
        position: absolute;
      }
    }
  }
  &__settings {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    color: rgba($base-color-light, 0.8);
    &-off ::v-deep input, div {
      color: rgba($base-color-light, 0.35) !important;
    }
    &-coords {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-title {
      width: 24px;
    }
    &-value {
      width: 172px;
    }
  }
  &__preview {
    display: flex;
    gap: 32px;
  }
  &__footer {
    &-line {
      border: 1px solid rgba($base-color-light, 0.15);
      border-width: 1px 0 0 0;
      margin: 32px 0;
    }
    &-btn {
      display: flex;
      justify-content: center;
      gap: 64px;
    }
  }
}
.add-effect {
  min-width: 256px;
  display: flex;
  flex-direction: column;
  padding: 8px 0 24px 0;
  color: rgba($base-color-light, 0.5);
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 256px;
    background: rgba($base-black, 0.1);
    overflow: hidden;
    img {
      max-width: 256px;
      max-height: 256px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.drag-cover {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px dashed $base-color;
        background: rgba($base-black, 0.15);
        backdrop-filter: blur(50px);
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("~@/assets/icons/svg/uikit/drop-files-plus.svg");
        background-position: center;
      }
    }
  }
  .file-properties {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 16px;
    margin: 6px 0 16px 0;
    &__name {
      max-width: 158px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &::before {
        content: attr(data-type);
        float: right;
      }
    }
    &__size {
      margin-left: 15px;
      text-align: end;
    }
    &__no-file {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
