<template>
  <div ref="sortpicker" class="sortpicker">
    <CbrIcon :class="{ desc, active, disabled }" size="20">
      {{ active ? '$sortingActive' : '$sorting'}}
    </CbrIcon>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, watch } from 'vue'

const vueCurrentInstance = getCurrentInstance().proxy

const props = defineProps({
  isDesc: {
    type: [Boolean, null],
    default: null
  },
  disabled: Boolean
})

const desc = computed(() => props.isDesc)
const asc = computed(() => props.isDesc === false)
const active = computed(() => asc.value || desc.value)

watch(() => active.value, (val) => highlightHeaderText(active.value))

onMounted(() => highlightHeaderText(active.value))

const highlightHeaderText = (active) => {
  const hdr = vueCurrentInstance.$refs.sortpicker.closest('span')
  if (hdr) {
    if (active) {
      hdr.classList.add('highlightHeaderText')
    } else {
      hdr.classList.remove('highlightHeaderText')
    }
  }
}
</script>

<style lang="scss" scoped>
.sortpicker {
  margin-right: 7px;
  .desc {
    transform: rotate(180deg);
  }
  .active {
    background-color: rgba($base-color, 0.8) !important;
  }
  .disabled {
    ::v-deep path {
      fill: $base-color-disabled;
    }
  }
}
</style>
<style lang="scss">
.highlightHeaderText {
  color: $StaticLight !important;
}
</style>
