<template>
  <div class="cbr-spinner" :class="getContainerClasses">
    <div v-if="title" class="cbr-spinner-title">{{ title }}
      <span class="cbr-spinner-title__required" v-if="required">*</span>
    </div>
    <div
      class="cbr-spinner-content d-flex align-center"
    >
      <CbrButton
        :size="size"
        :icon="iconLeft"
        icon-size="0.85em"
        :class="{ bgFilled }"
        class="left"
        :btn-error="!isValid"
        :disabled="disabled || value <= minValue"
        @click="emitValue(value - 1, $event)"
      />
      <div
        class="cbr-spinner-medium d-flex"
        :class="{ 'spinner-error': !isValid }"
        :style="{ width: mediumWidth }"
      >
        <div class="cbr-spinner-inner d-flex align-content-center">
          <div
            class="value"
            :class="disabled"
            ref="valueTemplateRef"
            v-html="divVal"
            :contenteditable="!disabled && manual"
            @keydown="onKeydown($event)"
            @blur="emitValue()"
          />
          <span v-if="measure" class="measure">{{ measure }}</span>
        </div>
        <CbrHint
          v-if="!isValid"
          :description="errorMessages.join('\n')"
          error
          bottom
          content-class="move-left"
        >
          <CbrIcon class="error-icon"> mdi-alert-circle </CbrIcon>
        </CbrHint>
      </div>
      <CbrButton
        :size="size"
        :icon="iconRight"
        icon-size="0.85em"
        :class="{ bgFilled }"
        class="right"
        :disabled="disabled || value >= maxValue"
        :btn-error="!isValid"
        @click="emitValue(value + 1, $event)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

const props = defineProps({
  value: {
    type: Number,
    default: () => 0,
    required: true
  },
  size: {
    type: String,
    default: 'medium',
  },
  mediumWidth: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  bgFilled: {
    type: Boolean,
    default: false
  },
  triangle: {
    type: Boolean,
    default: false
  },
  measure: {
    type: String,
    default: '',
  },
  minValue: {
    type: Number,
    default: 0,
  },
  maxValue: {
    type: Number,
    default: 9999,
  },
  disabled: {
    type: Boolean,
    default: false
  },
  manual: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: '',
  },
  titleLeft: {
    type: Boolean,
    default: false
  },
  errorMessages: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['input'])

const divVal = ref(props.value)
const iconLeft = computed(() => props.triangle ? '$triangle_left' : '$expand_minus')
const iconRight = computed(() => props.triangle ? '$triangle_right' : '$expand_plus')

const getContainerClasses = computed(() => {
  const classes = {}
  classes[props.size] = true
  classes['cbr-spinner--row'] = props.titleLeft
  return classes
})
const isValid = computed(() => !props.errorMessages || !props.errorMessages.length)

const onKeydown = (e) => {
  if (!((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || [8, 46, 37, 38, 39, 40, 109, 189].includes(e.keyCode))) {
    e.preventDefault()
  }
  if (props.minValue >= 0 && [109, 189].includes(e.keyCode)) {
    e.preventDefault()
  }
  if ([8, 46, 37, 39].includes(e.keyCode)) return
  const maxStringLength = Math.max(String(props.minValue).length, String(props.maxValue).length)
  if (maxStringLength <= e.target.innerText.length) {
    e.preventDefault()
  }
}
const valueTemplateRef = ref()
const emitValue = (val) => {
  let v = val === undefined ? +valueTemplateRef.value.innerText : +val
  v = v < props.minValue ? props.minValue : v > props.maxValue ? props.maxValue : v
  valueTemplateRef.value.innerText = v
  emit('input', v)
}

watch(() => props.value, (newValue) => {
  divVal.value = newValue
})
</script>

<style lang="scss" scoped>
.cbr-spinner.small {
  font-size: 16px;
  .cbr-button {
    min-width: 24px;
    max-width: 24px;
  }
  .cbr-spinner-medium {
    min-width: 80px;
    height: 24px;
  }
}
.cbr-spinner.medium {
  font-size: 18px;
  .cbr-button {
    min-width: 36px;
    max-width: 36px;
  }
  .cbr-spinner-medium {
    min-width: 114px;
    height: 36px;
  }
}
.cbr-spinner.large {
  font-size: 24px;
  .cbr-button {
    min-width: 48px;
    max-width: 48px;
  }
  .cbr-spinner-medium {
    min-width: 160px;
    height: 48px;
  }
}

.cbr-spinner {
  &--row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    .cbr-spinner-title {
      margin-bottom: 0;
    }
  }
  &-title {
    color: $base-color;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    line-height: 1em;
    &__required {
      color: $base-color-error-text;
    }
  }
  &-medium {
    border-top: 2px solid rgba($ActionColor, 0.25);
    border-bottom: 2px solid rgba($ActionColor, 0.25);
    background-color: rgba($StaticDark, 0.35);
    padding: 0 5px;
  }
  &-medium.spinner-error {
    border-top: 2px solid $AlarmTextColor;
    border-bottom: 2px solid $AlarmTextColor;
  }
  .cbr-button {
    background: transparent;
    border: 2px solid $ActionColor;
    ::v-deep svg path {
      fill: $ActionColor;
    }
    ::v-deep .v-icon {
      height: 0.67em;
      width: 0.67em;
    }
  }
  .cbr-button.cbr-button-error {
    border: 2px solid $AlarmTextColor;
  }
  .cbr-button.bgFilled {
    background-color: $ActionColor;
    ::v-deep svg path {
      fill: $StaticDark;
    }
  }
  .cbr-button.bgFilled.cbr-button-error {
    background-color: $AlarmTextColor;
  }

  &-inner {
    height: 100%;
    align-items: center;
    margin: auto;
    font-weight: 400;
    letter-spacing: 0;
    justify-content: center;
  }
  .value {
    color: rgba($StaticLight, 0.8);
    margin-right: 6px;
  }
  .value.disabled {
    color: rgba($StaticLight, 0.5);
  }
  .measure {
    color: rgba($StaticLight, 0.4);
  }
  .cbr-button.left {
    @include cut-corners(6px, true, 0px, false, 2px, $ActionColor);
  }
  .cbr-button.right {
    @include cut-corners(0px, true, 6px, false, 2px, $ActionColor);
  }
  .cbr-button.left.cbr-button-error {
    @include cut-corners(6px, true, 0px, false, 2px, $AlarmTextColor);
  }
  .cbr-button.right.cbr-button-error {
    @include cut-corners(0px, true, 6px, false, 2px, $AlarmTextColor);
  }
  .error-icon {
    color: $base-color-error-text !important;
    font-size: 1.2em !important;
    margin-left: auto;
  }
}
</style>
