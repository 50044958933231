import store from '@/store'
import { defaultLineParams, addSelectedCircle, addSelectedPoint, addDraggerOnLine } from '@/helpers/map.helper.js'

export default class Line {
  constructor (startPoint, endPoint, id) {
    this.defaultState = {}
    this.ctx = null
    this.id = store.state.map.players.find(i => i.cvs.id === 'player')?.AddId('vm', id ? +id.slice(2) : 0)
    this.label = ''
    this.color = defaultLineParams.color
    this.lineWidth = defaultLineParams.lineWidth
    this.memoColor = null
    this.animationTimer = 0
    this.animationCurrent = []
    this.animationVisited = []
    this.animationCircles = []
    this.animationDirectionFromLeft = true
    this.effect = []
    this.zoom = 1
    this.triggers = []
    this.name = 'line'
    this.startID = ''
    this.endID = ''
    // 0 - start, 1 - end
    this.points = [{ x: null, y: null, isBlocked: false }, { x: null, y: null, isBlocked: false }]
    this.isBlocked = false
    if (startPoint) {
      this.startID = startPoint.id
      this.endID = endPoint.id
      this.points = [{ x: startPoint.x, y: startPoint.y }, { x: endPoint.x, y: endPoint.y }]
    }
    // cписок смежности
    this.graphList = []
    this.graphList.push([0, [1]])
    this.graphList.push([1, [0]])
  }

  set startX (x) {
    this.points[0].x = Math.round(x)
  }

  get startX () {
    return this.points[0].x
  }

  set startY (y) {
    this.points[0].y = Math.round(y)
  }

  get startY () {
    return this.points[0].y
  }

  set endX (x) {
    this.points[1].x = Math.round(x)
  }

  get endX () {
    return this.points[1].x
  }

  set endY (y) {
    this.points[1].y = Math.round(y)
  }

  get endY () {
    return this.points[1].y
  }

  ModifyPoint (x, y, xNew, yNew) {
    for (let point of this.points) {
      if (x === point.x && y === point.y && !point.isBlocked) {
        point.x = xNew
        point.y = yNew
        return
      }
    }
  }

  ShiftAllPoints (offX, offY) {
    for (let point of this.points) {
      if (!point.isBlocked) {
        point.x += offX
        point.y += offY
      }
    }
  }

  Draw () {
    this.ctx.beginPath()
    this.ctx.fillStyle = this.color
    this.ctx.fill()
    this.ctx.closePath()
    // рисуем линию
    this.ctx.beginPath()
    this.graphList.forEach(entity => {
      const from = entity[0]
      entity[1].forEach(pIndex => {
        this.ctx.moveTo(this.points[pIndex].x, this.points[pIndex].y)
        this.ctx.lineTo(this.points[from].x, this.points[from].y)
      })
    })
    this.ctx.lineWidth = this.lineWidth
    this.ctx.strokeStyle = this.color
    this.ctx.stroke()
    this.ctx.closePath()

    // точки
    this.points.forEach(point => {
      this.ctx.beginPath()
      this.ctx.arc(point.x, point.y, 4, 0, Math.PI + (Math.PI * 2) / 2, false)
      this.ctx.fill()
      this.ctx.closePath()
    })

    // Apply effects
    if (this.effect) {
      this.effect = Array.isArray(this.effect) ? this.effect : [this.effect]
      this.effect.forEach(effectName => this[effectName] ? this[effectName]() : null)
    }
    // если есть выделенная точка
    const selPoint = store.getters.selectedPoint
    if (selPoint && selPoint.line && selPoint.line === this) {
      this.SelectedPoint(selPoint.point)
    } else if (this.selected) {
      this.Selected()
    }
  }

  UserSettings () {
    if (this.memoColor) {
      this.color = this.memoColor
      this.memoColor = null
    }
    this.handleEffect(this.color)
  }

  WarningNetworkScan () {
    if (!this.memoColor) {
      this.memoColor = this.color
    }
    this.handleEffect('#FFDB00', true)
  }

  AdditionalScanBlue () {
    if (!this.memoColor) {
      this.memoColor = this.color
    }
    this.handleEffect('#336dffff', true)
  }

  CriticalSuccessfulAtack () {
    if (!this.memoColor) {
      this.memoColor = this.color
    }
    this.handleEffect('#FF0000', true)
  }

  AdditionalScanGreen () {
    if (!this.memoColor) {
      this.memoColor = this.color
    }
    this.handleEffect('#2eff5bff', true)
  }

  handleEffect (color, animate = false) {
    this.ctx.beginPath()
    this.graphList.forEach(entity => {
      const from = entity[0]
      entity[1].forEach(pIndex => {
        this.ctx.moveTo(this.points[pIndex].x, this.points[pIndex].y)
        this.ctx.lineTo(this.points[from].x, this.points[from].y)
      })
    })
    this.ctx.lineWidth = this.lineWidth
    this.ctx.strokeStyle = color
    this.ctx.stroke()
    this.ctx.closePath()

    if (animate) {
      this.Animate()
    }
  }

  Selected () {
    addDraggerOnLine(this.graphList, this.points, +this.lineWidth, this.ctx)
    this.points.forEach(point => {
      addSelectedCircle(point.x, point.y, this.ctx, point.isBlocked)
    })
  }

  SelectedPoint (point) {
    addSelectedCircle(point.x, point.y, this.ctx, point.isBlocked)
    addSelectedPoint(point.x, point.y, this.ctx, point.isBlocked)
  }

  DecrementGraphListWhenDelete (fromInd) {
    this.graphList.forEach(entity => {
      if (entity[0] > fromInd) {
        entity[0]--
      }
      for (let i = 0; i < entity[1].length; i++) {
        if (entity[1][i] > fromInd) {
          entity[1][i]--
        }
      }
    })
  }

  Animate () {
    this.animationCircles = []

    // определим стартовую вершину
    if (!this.animationVisited.length) {
      // this.animationTimer = 0
      // если есть хотя бы одна вершина с которой связано только одно ребро
      let foundLeafIndexes = this.graphList.filter(entity => entity[1].length === 1).map(entity => entity[0])
      let leafPointsX = []

      if (foundLeafIndexes.length >= 2) {
        foundLeafIndexes.forEach(ind => leafPointsX.push(this.points[ind].x))
      } else {
        leafPointsX = this.points.map(point => point.x)
      }
      // выбор направления анимации
      const minmaxX = this.animationDirectionFromLeft ? Math.min(...leafPointsX) : Math.max(...leafPointsX)
      const startPointIndex = this.points.findIndex(point => point.x === minmaxX)

      this.animationCurrent = this.graphList
        .find(entity => entity[0] === startPointIndex)[1]
        .map(endPointIndex => [startPointIndex, endPointIndex])
      this.animationCircles = new Array(this.animationCurrent.length)
    }

    // логика выбора анимации
    if (this.animationTimer < 1) {
      // если движение только по одному ребру
      if (this.animationCurrent.length === 1) {
        // для одинаковой скорости
        // больше relativelyFrom => больше скорость
        const relativelyFrom = 250
        const startX = this.points[this.animationCurrent[0][0]].x
        const endX = this.points[this.animationCurrent[0][1]].x
        const startY = this.points[this.animationCurrent[0][0]].y
        const endY = this.points[this.animationCurrent[0][1]].y

        this.animationTimer += 0.04 / (Math.sqrt((startX - endX) ** 2 + (startY - endY) ** 2) / relativelyFrom)
        this.animationCircles = [{
          x: startX + (endX - startX) * this.animationTimer,
          y: startY + (endY - startY) * this.animationTimer
        }]
      } else {
        this.animationTimer += 0.04
        this.animationCurrent.forEach(entity => {
          const circle = {}
          circle.x = this.points[entity[0]].x +
            (this.points[entity[1]].x - this.points[entity[0]].x) * this.animationTimer
          circle.y = this.points[entity[0]].y +
            (this.points[entity[1]].y - this.points[entity[0]].y) * this.animationTimer
          this.animationCircles.push(circle)
        })
      }

      // отрисовка
      this.animationCircles.forEach(circle => {
        this.ctx.beginPath()
        this.ctx.fillStyle = Math.round(this.timer * 100) % 12 === 0 ? 'white' : 'red'
        this.ctx.arc(circle.x, circle.y, this.lineWidth < 4 ? 4 : this.lineWidth, 0, Math.PI + (Math.PI * 2) / 2, false)
        this.ctx.fill()
        this.ctx.closePath()
      })
    } else {
      // выбор следующих рёбер
      this.animationVisited = [...this.animationVisited, ...this.animationCurrent]
      let newCurr = []
      this.animationTimer = 0
      for (let edge of this.animationCurrent) {
        let bufferEdges = this.graphList
          .find(entity => entity[0] === edge[1])[1]
          .map(endPoint => [edge[1], endPoint])
          .filter(newEdge => !this.animationVisited
            .find(visitedEdge => (visitedEdge[0] === newEdge[0] && visitedEdge[1] === newEdge[1]) || (visitedEdge[0] === newEdge[1] && visitedEdge[1] === newEdge[0])))
        this.animationVisited.push(...bufferEdges)
        newCurr.push(...bufferEdges)
      }

      this.animationCurrent = newCurr
      if (!this.animationCurrent.length) {
        this.animationVisited = []
      }
    }
  }

  GetConnectedComponents () {
    let visitedVertices = Array(this.points.length)
    visitedVertices.fill(false, 0, visitedVertices.length)
    let components = []
    let currComponent = []

    const depthSearch = (pointInd) => {
      visitedVertices[pointInd] = true
      currComponent.push(pointInd)
      this.graphList.find(entity => entity[0] === pointInd)[1].forEach(endPoint => {
        if (!visitedVertices[endPoint]) {
          depthSearch(endPoint)
        }
      })
    }

    this.points.forEach((point, index) => {
      if (!visitedVertices[index]) {
        depthSearch(index)
        components.push(currComponent)
        currComponent = []
      }
    })

    return components
  }
}
