<template>
  <div class="footer fill-width">
    <div v-if="props.showLanguage" class="footer-languages">
      <div class="footer-col-lang-title">{{ $t('user_authorization_lst_choose_language') }}</div>
      <SelectLanguage :combobox="true"/>
    </div>
    <div class="footer-col">
      <CbrIcon class="footer-col-icon">$logo_solar</CbrIcon>
      <div class="footer-col-version">v. {{ version }}</div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, nextTick } from 'vue'
import SelectLanguage from '@/components/forms/SelectLanguage'
import store from '@/store'

const props = defineProps({
  showLanguage: {
    type: Boolean,
    default: () => false
  }
})

const version = computed(() => store.getters['global/version'])

onMounted(() => {
  const input = document.querySelector('.footer-languages input')
  nextTick(() => {
    if (input) {
      input.style.height = '0'
      input.style.padding = '0'
    }
  })
})
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  align-self: end;
  margin-bottom: 16px;
  &-languages {
    margin-bottom: 60px;
    text-align: -webkit-center;
  }
  &-col {
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    align-self: flex-end;

    &-version {
      position: absolute !important;
      top: 0;
      right: 35px;
      @include cut-corners(6px, true, 6px, false, 0);
      background-color: #00FFF0;
      backdrop-filter: blur(25px);
      width: 120px;
      height: 23px;

      color: $StaticDark;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px; /* 75% */
    }

    &-icon {
      width: 107px;
      height: 20px;
      flex-shrink: 0;
    }

    &-lang-title {
      color: rgba($StaticLight, 0.5);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
</style>
