<template>
  <component v-if="isComponentVisible" :is="componentByRole" />
</template>

<script setup>
import store from '@/store'
import { computed } from 'vue'

const isAuditor = computed(() => store.getters['account/isAuditor'])
const isInternal = computed(() => store.getters['account/isInternal'])
const isComponentVisible = computed(() => {
  return !(isAuditor.value && !isInternal.value)
})
const componentByRole = computed(() => {
  return isAuditor.value && isInternal.value
    ? () => import('@/views/Audit')
    : () => import('@/views/event/EventsList')
})
</script>
<script>
export default {
  beforeRouteEnter (to, from, next) {
    if (store.getters['account/isUser']) {
      next(from.fullPath === '/lk/user' ? '/lk/user?tab=events' : '/lk/user')
    } else {
      next()
    }
  }
}
</script>
