<template>
  <v-dialog
    v-model="isOpen"
    :max-width="maxWidth"
    :persistent="persistent"
    :content-class="filled ? 'filled' : ''"
    :overlay-color="$h.colors.baseColors.$baseBlack"
    overlay-opacity="0.35"
    @click:outside="onOutsideClick"
    @keydown.esc="onOutsideClick"
  >
    <div :id="id" class="cbr-dialog" :style="{ height, 'max-height': maxHeight }">
      <header class="cbr-dialog__header" :class="{ 'justify-center': centeredTitle }">
        <h3 v-if="title" :id="titleId" class="cbr-dialog__title" :class="getTitleClasses">
          <Icon class="cbr-dialog__title-icon" icon-name="error_modal" v-if="error"/>
          {{ title }}
        </h3>
        <div class="cbr-dialog__close-icon" v-if="!centeredTitle" @click="onCloseButtonCLick">
          <Icon icon-name="close_modal" width="32px" height="32px"/>
        </div>
        <slot name="header" />
      </header>

      <div class="cbr-dialog__main"
           :id="contentId"
           :class="{ 'no-scroll-content': noScrollContent, 'pa-0': full }"
      >
        <div class="cbr-dialog__first-line" v-if="firstLineText">
          {{ firstLineText }}
        </div>
        <div class="cbr-dialog__second-line" v-if="secondLineText">
          {{ secondLineText }}
        </div>

        <slot name="content"/>

        <div class="cbr-dialog__warning-text" v-if="warningText">
          {{ warningText }}
        </div>
        <div class="cbr-dialog__error-text" v-if="errorText">
          {{ errorText }}
        </div>
      </div>

      <footer class="cbr-dialog__footer" v-if="slots.footer">
        <slot name="footer" :close="closeModal"/>
      </footer>
    </div>
  </v-dialog>
</template>

<script setup>
import { computed, useSlots } from 'vue'

const slots = useSlots()
const emit = defineEmits(['input'])
const props = defineProps({
  value: {
    type: Boolean,
    required: true
  },
  persistent: {
    type: Boolean,
    default: false
  },
  closeBtnHandler: {
    type: Function,
    default: null
  },
  maxWidth: {
    type: String,
    default: '560px'
  },
  maxHeight: {
    type: String,
    default: ''
  },
  height: {
    type: String,
    default: ''
  },
  noScrollContent: {
    type: Boolean,
    default: false
  },
  title: {
    type: String
  },
  centeredTitle: {
    type: Boolean,
    required: false
  },
  filled: {
    type: Boolean,
    default: false
  },
  full: {
    type: Boolean,
    default: false
  },
  firstLineText: {
    type: String,
    default: ''
  },
  secondLineText: {
    type: String,
    default: ''
  },
  warningText: {
    type: String,
    default: ''
  },
  errorText: {
    type: String,
    default: ''
  },
  headerUppercase: {
    type: Boolean,
    default: true,
  },
  error: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: ''
  },
  titleId: {
    type: String,
    default: ''
  },
  contentId: {
    type: String,
    default: ''
  }
})

const isOpen = computed({
  get: function () {
    return props.value
  },
  set: function (newValue) {
    emit('input', newValue)
  }
})

const getTitleClasses = computed(() => {
  const baseClass = 'cbr-dialog__title'
  return {
    [`${baseClass}--uppercase`]: props.headerUppercase,
    [`${baseClass}--error`]: props.error
  }
})

const onOutsideClick = () => {
  if (props.persistent) return
  closeModal()
}
const onCloseButtonCLick = () => {
  if (props.closeBtnHandler) {
    props.closeBtnHandler()
    return
  }
  closeModal()
}

const closeModal = () => {
  emit('input', false)
}
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  border-radius: 0 !important;
  box-shadow: 0px 25px 150px 0px rgba($base-black, 0.4);
  backdrop-filter: blur(50px);
  background-color: rgba($StaticDark, 0.05);

  &.filled {
    background-color: rgba($StaticDark, 0.7);
  }
}

.cbr-dialog {
  padding: 0;
  display: flex;
  flex-direction: column;

  &__header {
    height: 48px;
    min-width: 460px;
    position: relative;
    display: flex;
    align-items: baseline;
    line-height: 48px;
    padding: 0 32px 0 24px;
    border-bottom: 1px solid rgba($ActionColor, 0.1);
  }

  &__title {
    color: rgba($ActionColor, .8);
    font-size: 16px;
    font-weight: 400;

    &--uppercase {
      text-transform: uppercase;
    }

    &--error {
      color: $AlarmTextColor;
    }
  }

  &__title-icon {
    position: relative;
    top: 4px;
    margin-right: 8px;
    fill: transparent;

    ::v-deep path {
      fill: $AlarmTextColor;
    }

    ::v-deep circle {
      stroke: $AlarmTextColor;
    }
  }

  &__close-icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
  }

  &__main {
    flex: 1;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    overflow-y: auto !important;
    font-size: 18px;
    color: $base-color-text;
    white-space: pre-line;
    @include scrollbar-small;

    &.no-scroll-content {
      overflow: hidden !important;
    }
  }

  &__first-line {
    color: $base-color-text;
    text-align: center;
  }

  &__second-line {
    color: $base-color-error-text;
    text-align: center;
  }

  &__warning-text {
    width: 90%;
    font-size: 16px;
    color: rgba($base-color-warning, 0.8);
  }

  &__error-text {
    color: $base-color-error-text;
    text-align: center;
  }

  ::v-deep .email-text {
    color: $base-color;
  }

  &__footer {
    padding: 18px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;

    ::v-deep button {
      min-width: 160px;
    }
  }
}
</style>
