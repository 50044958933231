<template>
  <svg
    :viewBox="viewBox"
    :width="props.width"
    :height="props.height"
    :fill="props.fill"
    :stroke="props.stroke"
  />
</template>

<script setup>
import { computed, getCurrentInstance, ref, watch } from 'vue'
import { parse } from 'postsvg'
import render from 'posthtml-render'
import { useStore } from '@/store'

const vueCurrentInstance = getCurrentInstance().proxy

const props = defineProps({
  iconName: {
    type: String,
    required: true
  },
  width: {
    type: [Number, String],
    default: 18
  },
  height: {
    type: [Number, String],
    default: 18
  },
  fill: {
    type: String,
    default: null
  },
  stroke: {
    type: String,
    default: 'none'
  },
  generateReadyEvent: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['ready', 'error'])

const store = useStore()

const svgString = ref('')

const filepath = computed(() => {
  return require(`@/assets/icons/svg/${props.iconName}.svg`)
})
const parsedSVG = computed(() => {
  return svgString.value ? parse(svgString.value) : null
})
const viewBox = computed(() => {
  return parsedSVG.value ? parsedSVG.value.root.attrs.viewBox : '0 0 20 20'
})

const loadFile = async () => {
  await store.dispatch('icons/loadIcon', filepath.value)
}
watch(() => store.getters['icons/getIcons'][filepath.value], (value) => {
  if (value) {
    svgString.value = value
    refreshSvg()
  }
}, { immediate: true })

function refreshSvg () {
  Promise.resolve(parsedSVG.value)
    .then(svgTree => {
      if (props.fill) {
        svgTree.each('path', node => (node.attrs.fill = props.fill))
      }
      return svgTree
    })
    .then(svgTree => render(svgTree.root.content))
    .then(svgHtml => (vueCurrentInstance.$el.innerHTML = svgHtml))
    .then(() => props.generateReadyEvent && emit('ready'))
    .catch(error => {
      console.error('Ошибка при обновлении SVG', error)
      emit('error', error)
    })
}

watch(() => filepath.value, () => loadFile(), { immediate: true })
watch(() => props.stroke, () => refreshSvg())
watch(() => props.fill, () => refreshSvg())
</script>
