import httpClient from './http'
import fetchClient from '@/services/http/fetch'

const END_POINT = '/scoreBoard'
const END_POINT_ALONE = '/scoreboard'

export default {
  setSettingScoreboard (reportForm) {
    return httpClient.post(`${END_POINT}/settings`, reportForm)
  },
  getScoreboard () {
    return httpClient.get(`${END_POINT}`)
  },
  getScoreboardSetting () {
    return httpClient.get(`${END_POINT}/settings`)
  },
  getAdminScoreboard (url, isAdmin) {
    const token = url.substr(16)
    if (isAdmin) {
      return httpClient.get(`${END_POINT_ALONE}/${token}?sort=false`)
    }
    return httpClient.get(`${END_POINT_ALONE}/${token}`)
  },
  postGenerateNewToken () {
    return httpClient.post(`${END_POINT_ALONE}/token`)
  },
  getCurrentUrl () {
    return httpClient.get(`${END_POINT_ALONE}/url`)
  },
  getCurrentToken () {
    return httpClient.get(`${END_POINT_ALONE}/token`)
  },
  updateAdminScoreboard (data) {
    return httpClient.post(`${END_POINT_ALONE}`, data)
  },
  updateImage (data) {
    return httpClient.post(`${END_POINT_ALONE}`, data)
  },
  clearAdminScoreboard (data) {
    return httpClient.delete(`${END_POINT_ALONE}`, data)
  },
  getDownloadScoreboardFile () {
    return fetchClient(`${END_POINT_ALONE}/download`, {
      responseType: 'blob'
    })
  },
}
