import store from '@/store'
import { constructor } from './player'
import Icon from './icon'

export const checkerTrigger = () => {
  for (let i in constructor.essence) {
    if (constructor.essence[i].id === store.getters.selected.id && constructor.essence[i].triggers.length !== 0 && store.getters.selectedAll.essence.length < 2 &&
          store.getters.selectedAll.lines.length === 0 && store.getters.selectedAll.planes.length === 0 &&
          store.getters.selectedAll.textFields.length === 0) {
      return {
        type: 'object',
        triggers: constructor.essence[i].triggers
      }
    }
  }
  for (let i in constructor.lines) {
    if (constructor.lines[i].id === store.getters.selected.id && constructor.lines[i].triggers.length !== 0 && store.getters.selectedAll.lines.length < 2 &&
        store.getters.selectedAll.essence.length === 0 && store.getters.selectedAll.planes.length === 0 &&
        store.getters.selectedAll.textFields.length === 0) {
      return {
        type: 'line',
        triggers: constructor.lines[i].triggers
      }
    }
  }
  for (let i in constructor.planes) {
    if (constructor.planes[i].id === store.getters.selected.id && constructor.planes[i].triggers.length !== 0 && store.getters.selectedAll.planes.length < 2 &&
        store.getters.selectedAll.essence.length === 0 && store.getters.selectedAll.lines.length === 0 &&
        store.getters.selectedAll.textFields.length === 0) {
      return {
        type: 'plane',
        triggers: constructor.planes[i].triggers
      }
    }
  }
  for (let i in constructor.textFields) {
    if (constructor.textFields[i].id === store.getters.selected.id && constructor.textFields[i].triggers.length !== 0 && store.getters.selectedAll.textFields.length < 2 &&
        store.getters.selectedAll.essence.length === 0 && store.getters.selectedAll.planes.length === 0 &&
        store.getters.selectedAll.lines.length === 0) {
      return {
        type: 'text',
        triggers: constructor.textFields[i].triggers
      }
    }
  }
}

export const PasteTriggerMenu = () => {
  for (const i in constructor.essence) {
    if (constructor.essence[i].selected && store.getters.copiesTriggers.type === 'object') {
      constructor.essence[i].triggers = [...constructor.essence[i].triggers, ...store.getters.copiesTriggers.triggers]
    }
  }
  for (const i in constructor.lines) {
    if (constructor.lines[i].selected && store.getters.copiesTriggers.type === 'line') {
      constructor.lines[i].triggers = [...constructor.lines[i].triggers, ...store.getters.copiesTriggers.triggers]
    }
  }
  for (const i in constructor.planes) {
    if (constructor.planes[i].selected && store.getters.copiesTriggers.type === 'plane') {
      constructor.planes[i].triggers = [...constructor.planes[i].triggers, ...store.getters.copiesTriggers.triggers]
    }
  }
  for (const i in constructor.textFields) {
    if (constructor.textFields[i].selected && store.getters.copiesTriggers.type === 'text') {
      constructor.textFields[i].triggers = [...constructor.textFields[i].triggers, ...store.getters.copiesTriggers.triggers]
    }
  }
}

export const ApplyTrigger = (item, trigger) => {
  if (item.img) {
    item.state = trigger.state
    item.effect = trigger.effect
    if (trigger.icons) {
      item.icons = []
      trigger.icons.forEach((icon) => {
        var iconItem = new Icon(icon.name, icon.url)
        iconItem.x = (item.width / 2) - (icon.width / 2)
        iconItem.y = icon.y
        iconItem.width = 60
        iconItem.height = 60
        iconItem.parentX = icon.parentX
        iconItem.parentY = icon.parentY
        item.icons.push(iconItem)
      })
    }
  }
  if (item.name === 'line') {
    item.color = trigger.color
    item.lineWidth = trigger.lineWidth
    item.effect = trigger.effect
  }
  if (item.name === 'plane') {
    item.bgColor = trigger.bgColor
    item.lineColor = trigger.lineColor
  }
  if (item.name === 'text') {
    item.message = trigger.message
    item.color = trigger.color
    item.textSize = trigger.textSize
  }
  if (!item.name && item.zoom) {
    item.Notification = trigger.Notification
    item.NotificationColor = trigger.NotificationColor
    item.effect = trigger.effect
  }
  item = false
}
