import { Messages } from './service-worker-messages.enum'
import { SaveMessagesModes } from './save-messages-modes.enum'
import _ from 'lodash'

export class TabsSyncService {
  #serviceWorkerDisconnectCb = null
  #serviceWorkerReconnectCb = null
  #isServiceWorkerDisconnected = false
  #serviceWorkerHeartbeatInterval = 10000

  subscribeToEvents (url, cb, saveMessageMode = SaveMessagesModes.NONE, filterOnSave = []) {
    this.#initPingMessagesInterval()

    navigator.serviceWorker.controller.postMessage({ type: Messages.CONNECT, payload: { url, saveMessageMode } })

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data.type === 'HEARTBEAT') {
        this.#handleHeartbeatMessage()
      }

      if (event.data.type === 'RECONNECT') {
        this.subscribeToEvents(url, cb, saveMessageMode, filterOnSave)
      }

      if (event.data.url === url && event.data.payload) {
        cb(event.data.payload)

        if (this.#isMessageSavingEnabled(saveMessageMode)) {
          this.#saveMessage(url, event.data.payload, saveMessageMode, filterOnSave)
        }
      }

      if (event.data.url === url && event.data.clearCache) {
        this.#clearCache(url)
      }
    })

    if (this.#isMessageSavingEnabled(saveMessageMode)) {
      this.#handleMessagesFromCache(url, cb, saveMessageMode)
    }
  }

  #handleHeartbeatMessage () {
    if (this.#isServiceWorkerDisconnected) {
      if (this.#serviceWorkerReconnectCb) {
        this.#serviceWorkerReconnectCb()
      }
    } else {
      if (this.#serviceWorkerDisconnectCb) {
        this.#serviceWorkerDisconnectCb()
      }
    }
  }

  subscribeToServiceWorkerDisconnect (cb) {
    this.#serviceWorkerDisconnectCb = _.debounce(() => {
      cb()
      this.#isServiceWorkerDisconnected = true
    }, this.#serviceWorkerHeartbeatInterval + 2000)
  }

  subscribeToServiceWorkerReconnect (cb) {
    this.#serviceWorkerReconnectCb = () => {
      cb()
      this.#isServiceWorkerDisconnected = false
    }
  }

  unsubscribeFromEvents (url) {
    navigator.serviceWorker.controller.postMessage({ type: Messages.DISCONNECT, payload: { url } })
    this.#clearPingMessagesInterval()
  }

  // Нужно для поддержания Service Worker в активном состоянии
  #pingIntervalId = null;
  #initPingMessagesInterval = () => {
    this.#pingIntervalId = setInterval(() => {
      navigator.serviceWorker.controller.postMessage(Messages.HEARTBEAT)
    }, this.#serviceWorkerHeartbeatInterval)
  }

  #clearPingMessagesInterval = () => {
    clearInterval(this.#pingIntervalId)
  }

  #saveMessage = (url, payload, saveMessageMode, filterOnSave) => {
    const parsedCache = this.#getParsedSSECache()

    if (saveMessageMode === SaveMessagesModes.ALL) {
      if (!Array.isArray(parsedCache[url])) {
        parsedCache[url] = []
      }

      const parsedPayload = JSON.parse(payload)
      const isMessageAlreadySaved = parsedCache[url].some(message => {
        return parsedPayload.timestamp &&
          message.timestamp &&
          parsedPayload.type === message.type &&
          parsedPayload.timestamp === message.timestamp
      })
      if (isMessageAlreadySaved) {
        return
      }

      if (!filterOnSave.length || filterOnSave.includes(JSON.parse(payload).type)) {
        parsedCache[url].push(payload)
      }
    } else if (!filterOnSave.length || filterOnSave.includes(JSON.parse(payload).type)) {
      parsedCache[url] = [payload]
    }

    localStorage.setItem('SSECache', JSON.stringify(parsedCache))
  }

  #clearCache = (url) => {
    const parsedCache = this.#getParsedSSECache()

    if (parsedCache[url]) {
      delete parsedCache[url]
    }

    localStorage.setItem('SSECache', JSON.stringify(parsedCache))
  }

  #handleMessagesFromCache = (url, cb, saveMessageMode) => {
    const parsedCache = this.#getParsedSSECache()

    const connectionCache = parsedCache[url] ?? []

    if (saveMessageMode === SaveMessagesModes.ALL) {
      connectionCache.forEach(message => cb(message))
      return
    }
    if (saveMessageMode === SaveMessagesModes.LAST && connectionCache[0]) {
      cb(connectionCache[0])
    }
  }

  #getParsedSSECache = () => {
    const cache = localStorage.getItem('SSECache') ?? '{}'
    return JSON.parse(cache)
  }

  #isMessageSavingEnabled = (saveMessageMode) => {
    return saveMessageMode === SaveMessagesModes.ALL || saveMessageMode === SaveMessagesModes.LAST
  }
}
