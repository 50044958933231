export default {
  tab_users: {
    iconName: 'admin/users',
    initFill: true
  },
  btn_scoreboard: {
    iconName: 'admin/btn_scoreboard',
    initFill: true
  },
  tab_organizations: {
    iconName: 'admin/organizations',
    initFill: true
  },
  tab_infrastructure: {
    iconName: 'admin/new_infrastructure',
    initFill: true
  },
  tab_scripts: {
    iconName: 'admin/scripts',
    fill: '#00F0FF',
    initFill: false
  },
  menu_lk: {
    iconName: 'admin/menu_lk'
  },
  menu_admin: {
    iconName: 'admin/menu_admin'
  },
  menu_audit: {
    iconName: 'admin/menu_audit'
  },
  menu_editor: {
    iconName: 'admin/menu_editor'
  },
  menu_3d_editor: {
    iconName: 'admin/menu_3d_editor'
  },
  menu_event: {
    iconName: 'admin/menu_event'
  },
  menu_integrity: {
    iconName: 'admin/menu_integrity'
  },
  menu_mission: {
    iconName: 'admin/menu_mission'
  },
  menu_task: {
    iconName: 'admin/menu_task'
  },
  menu_dashboard: {
    iconName: 'admin/menu_dashboard'
  },
  menu_results: {
    iconName: 'admin/menu_results'
  },
  deploy_infra: {
    iconName: 'admin/deploy_infra'
  },
  config_infra: {
    iconName: 'admin/config_infra'
  },
  infra_busy: {
    iconName: 'admin/infra_busy'
  },
  infra_error: {
    iconName: 'admin/infra_error'
  },
  infra_idle: {
    iconName: 'admin/infra_idle'
  },
  infra_process: {
    iconName: 'admin/infra_process'
  },
  infra_lock: {
    iconName: 'admin/infra_lock'
  },
  infra_unlock: {
    iconName: 'admin/infra_unlock'
  },
  connect_vm: {
    iconName: 'admin/connect_vm'
  },
  manual_slot: {
    iconName: 'admin/manual_slot'
  },
  new_user: {
    iconName: 'admin/new_user',
    initFill: true
  },
  new_organization: {
    iconName: 'admin/new_organization',
    initFill: true
  },
  selected_section_strip: {
    iconName: 'admin/selected_section_strip',
    initFill: true
  },
  back: {
    iconName: 'admin/back',
    initFill: true
  },
  filter: {
    iconName: 'admin/filter'
  },
  add_plus_circle: {
    iconName: 'add_plus_circle'
  },
}
