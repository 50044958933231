<template>
  <div class="cbr-expansion-panel-slim">
    <div
      class="cbr-expansion-panel-slim__triangle-icon"
      :class="getTriangleIconClass"
      @click="onTriangleIconClick"
    >
      <Icon
        icon-name="expansion_triangle"
        :width="14"
        :height="7"
      />
    </div>
    <div class="cbr-expansion-panel-slim__label-container">
      <slot v-if="slots.label" name="label" />
      <template v-else>
        <h3 class="cbr-expansion-panel-slim__label">{{ props.label }}</h3>
        <Icon
          v-if="props.labelIcon"
          class="cbr-expansion-panel-slim__label-icon"
          :icon-name="props.labelIcon"
          :width="labelIconSize"
          :height="labelIconSize"
          fill="#00FFF0"
        />
        <p
          v-if="props.labelCounter !== null"
          class="cbr-expansion-panel-slim__label-counter"
        >
          {{ props.labelCounter }}
        </p>
      </template>
    </div>
    <div
      class="cbr-expansion-panel-slim__container"
      :class="getExpansionPanelContainerClass"
    >
      <v-expand-transition>
        <div v-if="isOpened" class="cbr-expansion-panel-slim__content">
          <slot/>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, useSlots } from 'vue'
const slots = useSlots()

const props = defineProps({
  closed: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  labelIcon: {
    type: String,
    default: null,
  },
  labelIconSize: {
    type: String,
    default: '24'
  },
  labelCounter: {
    type: Number,
    default: null
  }
})

const isOpened = ref(!props.closed)
function onTriangleIconClick () {
  isOpened.value = !isOpened.value
}

const getTriangleIconClass = computed(() => {
  const baseClass = 'cbr-expansion-panel-slim__triangle-icon'
  return {
    [`${baseClass}--closed`]: !isOpened.value
  }
})
const getExpansionPanelContainerClass = computed(() => {
  const baseClass = 'cbr-expansion-panel-slim__container'
  return {
    [`${baseClass}--closed`]: !isOpened.value
  }
})
</script>

<style scoped lang="scss">
.cbr-expansion-panel-slim {
  position: relative;
  width: 100%;
  overflow: visible;

  &__triangle-icon {
    position: absolute;
    top: 0;
    left: -7px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-out;
    &--closed {
      top: -2px;
      rotate: -90deg;
    }
  }

  &__container {
    width: 100%;
    position: relative;
    padding: 0;
  }
  &__container:not(.cbr-expansion-panel-slim__container--closed) {
    @include cut-corners(10px, true, 10px, false, 2px, rgba($ActionColor, .15));
    display: flex;
    padding: 0;
  }
  .cbr-expansion-panel-slim__container--closed {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 8px;
      width: calc(100% - 8px);
      height: 2px;
      background-color: rgba($ActionColor, .15);
    }
  }

  &__label-container {
    position: absolute;
    left: 24px;
    top: -12px;
    background-color: #052844;
    backdrop-filter: blur(12.5px);
    padding: 0 8px;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__label {
    font-size: 1rem;
    font-weight: 400;
    color: $ActionColor;
  }
  &__label-icon {
    margin-left: 8px;
  }
  &__label-counter {
    margin-left: 4px;
    margin-bottom: 0;
    font-size: 1rem;
    color: $ActionColor;
  }

  &__content {
    width: 100%;
    overflow: visible;
    //padding: 12px 10px;
  }
}
</style>
