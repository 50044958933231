import { onBeforeUnmount, onMounted, ref } from 'vue'

import { MessageBusService } from '@/services/message-bus'
import { TabsSyncService } from '@/services/tabs-sync'
import { SSEService } from '@/services/sse/sse-service'
import { useStore } from '@/store'

export const useSSE = () => {
  let messageBusService = null
  if (shouldBeServiceWorkerUsed()) {
    messageBusService = new MessageBusService(new TabsSyncService())
  } else {
    messageBusService = new MessageBusService(new SSEService())
  }

  function shouldBeServiceWorkerUsed () {
    return navigator.serviceWorker.controller && /Chrome/.test(navigator.userAgent)
  }

  const store = useStore()
  const windowOnunloadHandlerId = Date.now()
  const eventsSubscribesMap = ref({})

  const subscribe = (url, object) => {
    eventsSubscribesMap.value[url] = object
    subscribeToEvents(url)
  }
  const subscribeToEvents = (url) => {
    messageBusService.subscribeToEvents(
      url,
      eventsSubscribesMap.value[url].handler ?? (() => {
      }),
      eventsSubscribesMap.value[url].saveMessageMode,
      eventsSubscribesMap.value[url].filterOnSave
    )
    store.commit('sse/addConnection', url)
  }
  const unsubscribe = (url) => {
    messageBusService.unsubscribeFromEvents(url)
    store.commit('sse/removeConnection', url)
  }

  onMounted(() => {
    for (const url in eventsSubscribesMap.value) {
      subscribeToEvents(url)
    }

    store.commit('global/addHandlerForWindowOnunload', {
      id: windowOnunloadHandlerId,
      handler: () => {
        store.getters('sse/activeConnections').forEach(url => messageBusService.unsubscribeFromEvents(url))
        store.commit('sse/resetState')
      }
    })
  })
  onBeforeUnmount(() => {
    for (const url in eventsSubscribesMap.value) {
      unsubscribe(url)
      store.commit('global/removeHandlerForWindowOnunload', windowOnunloadHandlerId)
    }
  })

  return {
    eventsSubscribesMap,
    subscribe,
    unsubscribe
  }
}
