<template>
  <v-tooltip v-bind="attrs" content-class="cbr-tooltip">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }" />
    </template>
    <slot />
  </v-tooltip>
</template>

<script setup>
import { useAttrs } from 'vue'

const attrs = useAttrs()
</script>

<style scoped lang="scss">
.cbr-tooltip {
  max-width: 500px;
  word-wrap: break-word;
  max-height: 400px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid rgba($ActionColor, 0.25);
  background: $base-color-back;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12.5px);
  opacity: 1 !important;
  overflow: hidden;
  font-size: .875rem !important;
  font-weight: 400 !important;
  color: $StaticLight;
  line-height: 22px;
}
</style>
