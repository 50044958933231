import { constructor, Player } from './player'
import store from '@/store'
const clonedeep = require('lodash.clonedeep')

export const mouse = {
  x: 0,
  y: 0
}

export const normalizeCoordsFromEvent = (e) => {
  return {
    x: (e.pageX + (constructor.translateX * (-1))) / constructor.zoom,
    y: (e.pageY - constructor.topOffset + (constructor.translateY * (-1))) / constructor.zoom
  }
}

window.onmousemove = function (e) {
  mouse.x = e.pageX
  mouse.y = e.pageY - constructor.topOffset
}
export function isCursorIn (essence) {
  const mouseX = (mouse.x + (constructor.translateX * (-1))) / constructor.zoom
  const mouseY = (mouse.y + (constructor.translateY * (-1))) / constructor.zoom
  // хит-тест маска объекта
  const player = new Player()
  player.cvs = document.createElement('canvas')
  player.ctx = player.cvs.getContext('2d')
  player.cvs.width = essence.img.width + essence.offsetX
  player.cvs.height = essence.img.height + essence.offsetY
  const cloneEssence = clonedeep(essence)
  cloneEssence.ctx = player.ctx
  cloneEssence.selected = false
  cloneEssence.x = essence.offsetX
  cloneEssence.y = essence.offsetY
  cloneEssence.effect = []
  cloneEssence.Draw()
  let transparent = false
  try {
    const mousePixelColor = player.ctx.getImageData(
      mouseX - essence.x + essence.offsetX, mouseY - essence.y + essence.offsetY, 1, 1
    ).data
    transparent = mousePixelColor.every((val) => val === 0)
  } catch (err) {
    // console.error(err)
  }

  return mouseX > essence.x - essence.offsetX &&
    mouseX < essence.x - essence.offsetX + essence.img.width &&
    mouseY > essence.y - essence.offsetY &&
    mouseY < essence.y - essence.offsetY + essence.img.height &&
    !transparent
}
export function isCursorInIcon (player, playerOffsets, icon, item) {
  const mouseX = (mouse.x + (player.translateX * (-1))) / player.zoom
  const mouseY = (mouse.y + (player.translateY * (-1))) / player.zoom
  const iconX = icon.parentX - item.offsetX + icon.x + playerOffsets.left
  const iconY = icon.parentY - item.offsetY + icon.y + playerOffsets.top - constructor.topOffset
  return mouseX > iconX && mouseX < iconX + icon.width && mouseY > iconY && mouseY < iconY + icon.height
}
export function isCursorInText (text) {
  const mouseX = (mouse.x + (constructor.translateX * (-1))) / constructor.zoom
  const mouseY = (mouse.y + (constructor.translateY * (-1))) / constructor.zoom

  constructor.ctx.save()
  // сдвиг к точке вращения текста и поворот в исходное положение
  const corner = text.leftTopCorner
  constructor.ctx.translate(-corner.x, -corner.y)
  const angleText = text.DegreesToRadians(Number(text.rotateDegree))
  constructor.ctx.rotate(-angleText)
  // расчет координат указателя мыши с учетом поворота
  const fixMouseX = mouseX - corner.x + text.xPadding
  const fixMouseY = mouseY - corner.y
  const deltaAngle = Math.atan2(fixMouseY, fixMouseX) - angleText
  const rotatedRadius = Math.sqrt(Math.pow(fixMouseX, 2) + Math.pow(fixMouseY, 2))
  const rotatedFixMouseX = rotatedRadius * Math.cos(deltaAngle)
  const rotatedFixMouseY = rotatedRadius * Math.sin(deltaAngle)
  constructor.ctx.restore()

  const fixedTextFieldHeight = text.y + text.height + 2 * text.yPadding - corner.y
  return rotatedFixMouseX > 0 &&
    rotatedFixMouseX < text.width &&
    rotatedFixMouseY > 0 &&
    rotatedFixMouseY < fixedTextFieldHeight
}
export function isCursorInPoint (point, mouseX = mouse.x, mouseY = mouse.y) {
  let radius = 35 / constructor.zoom
  if (radius < 10) radius = 10
  if (radius > 35) radius = 35
  return (mouseX + (constructor.translateX * (-1))) / constructor.zoom > point.x - radius &&
    (mouseX + (constructor.translateX * (-1))) / constructor.zoom < point.x + radius &&
    (mouseY + (constructor.translateY * (-1))) / constructor.zoom > point.y - radius &&
    (mouseY + (constructor.translateY * (-1))) / constructor.zoom < point.y + radius
}
export function isCursorInPlane (plane) {
  const x = (mouse.x + (constructor.translateX * (-1))) / constructor.zoom
  const y = (mouse.y + (constructor.translateY * (-1))) / constructor.zoom
  const xp = [plane.a.x, plane.b.x, plane.c.x, plane.d.x] // Массив X-координат полигона
  const yp = [plane.a.y, plane.b.y, plane.c.y, plane.d.y] // Массив Y-координат полигона
  const npol = xp.length
  let j = npol - 1
  let c = 0
  for (let i = 0; i < npol; i++) {
    if ((((yp[i] <= y) && (y < yp[j])) || ((yp[j] <= y) && (y < yp[i]))) &&
      (x > (xp[j] - xp[i]) * (y - yp[i]) / (yp[j] - yp[i]) + xp[i])) {
      c = !c
    }
    j = i
  }
  return c
}

const checkPart = (currentLine, mouseX, mouseY) => {
  const padding = 15
  const x = (mouseX + (constructor.translateX * (-1))) / constructor.zoom
  const y = (mouseY + (constructor.translateY * (-1))) / constructor.zoom
  let xp = [currentLine.startX - padding, currentLine.endX - padding, currentLine.endX + padding, currentLine.startX + padding]
  let yp = [currentLine.startY - padding, currentLine.endY - padding, currentLine.endY + padding, currentLine.startY + padding]
  const npol = xp.length
  let j = npol - 1
  let c = false
  for (let i = 0; i < npol; i++) {
    if ((((yp[i] <= y) && (y < yp[j])) || ((yp[j] <= y) && (y < yp[i]))) &&
      (x > (xp[j] - xp[i]) * (y - yp[i]) / (yp[j] - yp[i]) + xp[i])) {
      c = !c
    }
    j = i
  }
  return c || isCursorInPoint({ x: currentLine.startX, y: currentLine.startY }) || isCursorInPoint({ x: currentLine.endX, y: currentLine.endY })
}

export function isCursorInLine (line, mouseX = mouse.x, mouseY = mouse.y) {
  for (let entry of line.graphList) {
    const startX = line.points[entry[0]].x
    const startY = line.points[entry[0]].y
    for (let toPoint of entry[1]) {
      const endX = line.points[toPoint].x
      const endY = line.points[toPoint].y
      if (checkPart({ startX, startY, endX, endY }, mouseX, mouseY)) {
        return true
      }
    }
  }

  return false
}

// точки начала и конца кликнутого отрезка
export const getPointsIndexesOfLinePart = (line, mouseX, mouseY) => {
  for (let entry of line.graphList) {
    const startX = line.points[entry[0]].x
    const startY = line.points[entry[0]].y
    for (let toPoint of entry[1]) {
      const endX = line.points[toPoint].x
      const endY = line.points[toPoint].y
      if (checkPart({ startX, startY, endX, endY }, mouseX, mouseY)) {
        return [entry[0], toPoint]
      }
    }
  }

  return undefined
}

export const ContextMenu = (e) => {
  e.preventDefault()
  let posX = e.offsetX
  let posY = e.offsetY
  if (e.target.className === 'gifEffect') {
    posX = e.target.x
    posY = e.target.y
  }
  const customMenu = document.getElementById('customMenu')
  customMenu.style.left = `${posX}px`
  customMenu.style.top = `${posY}px`
  if (window.innerHeight - e.offsetY < 280) customMenu.style.top = `${posY - 212}px`
  if (window.innerWidth - e.offsetX < 200) customMenu.style.left = `${posX - 200}px`
  let contextMenu = false
  for (const i in constructor.essence) {
    if (isCursorIn(constructor.essence[i]) && constructor.essence[i].selected) {
      contextMenu = true
      if (constructor.essence[i].triggers.length !== 0 && store.getters.selectedAll.essence.length < 2 &&
        store.getters.selectedAll.lines.length === 0 && store.getters.selectedAll.planes.length === 0 &&
        store.getters.selectedAll.textFields.length === 0) {
        store.commit('setCopyButton', true)
      } else {
        store.commit('setCopyButton', false)
      }
      if (store.getters.copiesTriggers?.type !== 'object') {
        store.commit('setPasteButton', false)
      } else {
        store.commit('setPasteButton', true)
      }
      if (!constructor.essence[i].isBlocked) {
        store.commit('setBlockElements', true)
        store.commit('setDeleteElements', true)
      } else {
        store.commit('setBlockElements', false)
        store.commit('setDeleteElements', false)
      }
    }
  }
  for (const i in constructor.lines) {
    if (isCursorInLine(constructor.lines[i]) && constructor.lines[i].selected) {
      contextMenu = true
      if (constructor.lines[i].triggers.length !== 0 && store.getters.selectedAll.lines.length < 2 &&
        store.getters.selectedAll.essence.length === 0 && store.getters.selectedAll.planes.length === 0 &&
        store.getters.selectedAll.textFields.length === 0) {
        store.commit('setCopyButton', true)
      } else {
        store.commit('setCopyButton', false)
      }
      if (store.getters.copiesTriggers.type !== 'line') {
        store.commit('setPasteButton', false)
      } else {
        store.commit('setPasteButton', true)
      }
      if (!constructor.lines[i].isBlocked) {
        store.commit('setBlockElements', true)
        store.commit('setDeleteElements', true)
      } else {
        store.commit('setBlockElements', false)
        store.commit('setDeleteElements', false)
      }
    }
  }
  for (const i in constructor.textFields) {
    if (isCursorInPoint(constructor.textFields[i]) && constructor.textFields[i].id === store.getters.selected.id) {
      contextMenu = true
      if (constructor.textFields[i].triggers.length !== 0 && store.getters.selectedAll.textFields.length < 2 &&
        store.getters.selectedAll.essence.length === 0 && store.getters.selectedAll.lines.length === 0 &&
        store.getters.selectedAll.planes.length === 0) {
        store.commit('setCopyButton', true)
      } else {
        store.commit('setCopyButton', false)
      }
      if (store.getters.copiesTriggers.type !== 'text') {
        store.commit('setPasteButton', false)
      } else {
        store.commit('setPasteButton', true)
      }
      if (!constructor.textFields[i].isBlocked) {
        store.commit('setBlockElements', true)
        store.commit('setDeleteElements', true)
      } else {
        store.commit('setBlockElements', false)
        store.commit('setDeleteElements', false)
      }
    }
  }
  for (const i in constructor.planes) {
    if (isCursorInPlane(constructor.planes[i]) && constructor.planes[i].id === store.getters.selected.id) {
      contextMenu = true
      if (constructor.planes[i].triggers.length !== 0 && store.getters.selectedAll.planes.length < 2 &&
        store.getters.selectedAll.essence.length === 0 && store.getters.selectedAll.lines.length === 0 &&
        store.getters.selectedAll.textFields.length === 0) {
        store.commit('setCopyButton', true)
      } else {
        store.commit('setCopyButton', false)
      }
      if (store.getters.copiesTriggers.type !== 'plane') {
        store.commit('setPasteButton', false)
      } else {
        store.commit('setPasteButton', true)
      }
      if (!constructor.planes[i].isBlocked) {
        store.commit('setBlockElements', true)
        store.commit('setDeleteElements', true)
      } else {
        store.commit('setBlockElements', false)
        store.commit('setDeleteElements', false)
      }
    }
  }
  // кнопка удаления точки
  if (store.getters.selectedPoint && isCursorInPoint(store.getters.selectedPoint.point)) {
    contextMenu = true
    store.commit('setDeleteElements', false)
  }
  store.commit('setShowContextMenu', contextMenu)
}

export const HandMove = (e) => {
  var startX; var startY; var x = 0; var y = 0; var mouseup = false
  window.onmousedown = (e) => {
    document.body.style.cursor = 'move'
    startX = e.offsetX / constructor.zoom
    startY = e.offsetY / constructor.zoom
    mouseup = true
  }
  window.onmouseup = (e) => {
    document.body.style.cursor = 'initial'
    mouseup = false
  }
  window.onmousemove = (e) => {
    if (mouseup && e.target.id === 'player') {
      x = (e.offsetX / constructor.zoom - startX)
      y = (e.offsetY / constructor.zoom - startY)
      if (constructor.translateX / constructor.zoom + x >= constructor.offset || constructor.translateX / constructor.zoom + x <= -constructor.offset + window.innerWidth) {
        x = 0
      }
      if (constructor.translateY / constructor.zoom + y >= constructor.offset || constructor.translateY / constructor.zoom + y <= -constructor.offset + window.innerHeight) {
        y = 0
      }
      startX += x
      startY += y
      constructor.translateX = constructor.translateX + (x * constructor.zoom)
      constructor.translateY = constructor.translateY + (y * constructor.zoom)
      constructor.ctx.translate(x, y)
      constructor.scene.translateX = constructor.translateX
      constructor.scene.translateY = constructor.translateY
    }
  }
}

export const MoveIconHandler = (player, item) => {
  let icon, iconStartX, iconStartY, mouseStartX, mouseStartY
  const playerOffsets = player.cvs.getBoundingClientRect()
  if (item.img) {
    window.onmousedown = (e) => {
      if (item.icons) {
        item.icons.forEach((_icon) => {
          if (isCursorInIcon(player, playerOffsets, _icon, item)) {
            icon = _icon
            iconStartX = icon.x
            iconStartY = icon.y
            mouseStartX = e.x
            mouseStartY = e.y
          }
        })
      }
    }
    window.onmousemove = (e) => {
      mouse.x = e.pageX
      mouse.y = e.pageY - player.topOffset
      if (icon) {
        icon.x = iconStartX + (e.x - mouseStartX) / player.zoom
        icon.y = iconStartY + (e.y - mouseStartY) / player.zoom
      }
    }
    window.onmouseup = () => {
      icon = false
    }
  }
}
