<template>
  <div class="cbr-autocomplete" :style="getCbrAutocompleteStyles">
    <div class="cbr-autocomplete__input-container">
      <CbrIcon class="cbr-autocomplete__search-icon"> mdi-magnify </CbrIcon>
      <input
        class="cbr-autocomplete__input"
        :placeholder="props.placeholder"
        @input="onSearchInput"
      >
    </div>
    <ul class="cbr-autocomplete__items-list" :style="getItemListStyles">
      <li
        v-for="(item, index) in props.items"
        :key="index"
        class="cbr-autocomplete__item"
        @click="onListItemClick(item)"
      >
        <template>
          <slot v-if="slots.selectedItem && item[SELECTED_PROPERTY_NAME]" name="selectedItem" :item="item"/>
          <slot v-else-if="slots.item" name="item" :item="item"/>
          <CbrHint
            v-else
            bottom
            no-icon
            :title="getItemText(item)"
            custom-activator
          >
            <template #default="{ on }">
              <div
                v-on="on"
                class="cbr-autocomplete__item-text"
                :class="{
                  'cbr-autocomplete__item-text--selected': item[SELECTED_PROPERTY_NAME]
                }"
              >
                {{ getItemText(item) }}
              </div>
            </template>
          </CbrHint>
        </template>
      </li>
      <li v-if="items.length === 0" class="cbr-autocomplete__no-items" >
        <slot v-if="slots.noItems" name="noItems" />
        <span v-else class="cbr-autocomplete__no-items-text">Ничего не найдено! Попробуйте смягчить критерии поиска</span>
      </li>
      <li class="cbr-autocomplete__intersect-list-element" v-intersect="onIntersect"/>
    </ul>
  </div>
</template>

<script setup>
import { computed, useSlots } from 'vue'

const SELECTED_PROPERTY_NAME = 'selected'

const props = defineProps({
  width: {
    type: String,
    default: '100%'
  },
  maxHeight: {
    type: String,
    default: '500px',
  },
  items: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: ''
  },
  itemText: {
    type: [Function, String],
    default: null,
  }
})
const emit = defineEmits(['input:search', 'input', 'scroll-end'])
const slots = useSlots()

const getCbrAutocompleteStyles = computed(() => {
  return {
    width: props.width
  }
})
const getItemListStyles = computed(() => {
  return {
    maxHeight: props.maxHeight
  }
})

function getItemText (item) {
  if (typeof props.itemText === 'function') {
    return props.itemText(item)
  } else if (typeof props.itemText === 'string') {
    return item[props.itemText]
  }
}

function onSearchInput (event) {
  emit('input:search', event.target.value)
}
function onListItemClick (item) {
  if (!item[SELECTED_PROPERTY_NAME]) {
    emit('input', item)
  }
}
function onIntersect (entries, observer, isIntersecting) {
  if (isIntersecting) {
    emit('scroll-end')
  }
}
</script>

<style scoped lang="scss">
.cbr-autocomplete {
  @include cut-corners(8px, true, 8px, false, 2px, $ActionColor);
  display: flex;
  flex-direction: column;
  padding: 0;

  &__input-container {
    width: 100%;
    border-bottom: 1px solid $ActionColor;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 10px;
  }
  &__input {
    width: 100%;
    height: 34px;
    color: $StaticLight;
    &::placeholder {
      color: rgba($StaticLight, .25);
    }
  }
  &__search-icon {
    color: $ActionColor !important;
    font-size: 1.3em !important;
  }

  &__items-list {
    margin: 4px 4px 6px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    list-style: none;
    padding: 0;
    @include scrollbar-small;
  }

  &__item-text {
    @include text-ellipsis;
    width: 100%;
    height: 100%;
    padding: 2px 10px;
    color: $StaticLight;
    font-size: 1rem;
    line-height: normal;
    cursor: pointer;
    &:not(&--selected):hover {
      background: linear-gradient(90deg, rgba(0, 255, 240, 0.20) 0%, rgba(0, 255, 240, 0.00) 100%);
    }
    &--selected {
      background-color: $ActionColor;
      color: $popup-input-back;
      cursor: default;
    }
  }

  &__intersect-list-element {
    height: 0;
  }

  &__no-items {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__no-items-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    color: rgba($StaticLight, .5);
  }
}
</style>
