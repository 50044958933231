import httpClient from './http'

const END_POINT = '/event'
export default {
  startEvent (type, id) {
    return httpClient.post(`${END_POINT}/${type}/${id}/start`)
  },
  prepareEvent (type, id) {
    return httpClient.post(`${END_POINT}/${type}/${id}/prepare`)
  },
  pauseEvent (type, id) {
    return httpClient.post(`${END_POINT}/${type}/${id}/pause`)
  },
  debriefEvent (type, id) {
    return httpClient.post(`${END_POINT}/${type}/${id}/debrief`)
  },
  finishEvent (type, id) {
    return httpClient.post(`${END_POINT}/${type}/${id}/finish`)
  },
  cancelEvent (type, id) {
    return httpClient.post(`${END_POINT}/${type}/${id}/cancel`)
  }
}
