import httpClient from './http'
// import axios from 'axios'

export default {
  logout () {
    return fetch('/api/logout', { method: 'POST' })
      .then(res => {
        if (res.status === 204) {
          return 'ok'
        } else {
          return res.json()
        }
      })
      .then(jsonOrText => {
        if (jsonOrText === 'ok') {
          return jsonOrText
        } else {
          throw jsonOrText.error.message
        }
      })
  },

  login (email, password) {
    const form = new FormData()
    form.append('username', email.trim())
    form.append('password', password)
    return httpClient.post('/login', form)
  },
  setPassword (token, pass, repeatedPass) {
    return httpClient.post('/account/set-password', {
      password: pass,
      repeatedPassword: repeatedPass,
      resetToken: token
    })
  },
  getUserByToken (token) {
    return httpClient.get('/user/by-auth-token/' + token)
  },
  forgotPassword (email) {
    return httpClient.get('/account/reset-password', { params: { email: email } })
  },
  changePassword ({ username, password, newPassword }) {
    const form = new FormData()
    form.append('username', username)
    form.append('password', password)
    form.append('newPassword', newPassword)
    return httpClient.post('/account/change-password', form)
  }
}
