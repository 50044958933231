import { defaultPlaneParams } from '@/helpers/map.helper.js'
import store from '@/store'
import { constructor } from './player'
import { mouse } from './cursor'
import { getItemsInRectangle } from './selector-tools'

export default class SelectRectangle {
  constructor (ctx, x, y) {
    this.ctx = ctx
    this.color = defaultPlaneParams.color
    this.lineWidth = defaultPlaneParams.lineWidth
    this.bgColor = defaultPlaneParams.bgColor
    this.name = 'selectRectangle'
    this.x = x
    this.y = y
    this.width = 0
    this.height = 0
  }

  Draw () {
    this.ctx.beginPath()
    this.ctx.fillStyle = this.color
    this.ctx.lineWidth = this.lineWidth
    this.ctx.fillStyle = this.bgColor
    this.ctx.fillRect(this.x, this.y, this.width, this.height)
    this.ctx.closePath()
  }

  static AddSelectRectangle () {
    let rectangle
    let drawing = false
    const invalidateSelector = () => {
      if (drawing) {
        drawing = false
        delete constructor.selectRectangle
        constructor.selectRectangle = null
        rectangle = null
      }
    }
    const mouseDownHandler = (e) => {
      if (e.target.id === 'player' && e.shiftKey) {
        const x = (e.pageX + (constructor.translateX * (-1))) / constructor.zoom
        const y = (e.pageY - constructor.topOffset + (constructor.translateY * (-1))) / constructor.zoom
        rectangle = new SelectRectangle(constructor.ctx, x, y)
        constructor.selectRectangle = rectangle
        drawing = true
      }
    }
    const mouseMoveHandler = (e) => {
      if (drawing && e.shiftKey) {
        mouse.x = e.pageX
        mouse.y = e.pageY - constructor.topOffset
        rectangle.width = (mouse.x + (constructor.translateX * (-1))) / constructor.zoom - rectangle.x
        rectangle.height = (mouse.y + (constructor.translateY * (-1))) / constructor.zoom - rectangle.y
        const selectedAll = getItemsInRectangle(rectangle.x, rectangle.y, rectangle.width, rectangle.height)
        store.commit('clearSelection')
        store.commit('setSelectedAll', selectedAll)
        if (store.getters.countOfSelected === 1) {
          store.commit('selected', Object.values(selectedAll).find(item => item.length)[0])
        }
      } else if (!e.shiftKey) {
        invalidateSelector()
      }
    }
    window.addEventListener('mousedown', mouseDownHandler)
    window.addEventListener('mousemove', mouseMoveHandler)
    window.addEventListener('mouseup', invalidateSelector)

    return () => {
      window.removeEventListener('mousedown', mouseDownHandler)
      window.removeEventListener('mousemove', mouseMoveHandler)
      window.removeEventListener('mouseup', invalidateSelector)
    }
  }
}
