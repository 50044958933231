import array from './array.helper'
import string from './string.helpers'
import date from './date.helper'
import object from './object.helper'
import events from '../events.helper'
import control from '../control.helper'
import colors from '../colors.helper'
import users from '@/helpers/users.helper'
import roles from '@/helpers/roles.helper'

export default { array, string, date, object, events, users, roles, control, colors }
