<template>
  <div v-if="isShow" class="term-of-use" id="term-of-use-body">
    <CbrForm class="term-of-use-container">
      <h1>{{ $t('user_authorization_chk_accept_eula_title') }}</h1>
      <div class="term-of-use-content">
        <div v-html="content" class="term-of-use-textarea" />
      </div>
      <div class="d-flex justify-center mt-5">
        <CbrButton
          id="term-of-use-btn-cancel"
          :text="$t('eula_btn_go_to_login')"
          @click="close"
        />
      </div>
    </CbrForm>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { i18n } from '@/i18n-setup'

const emit = defineEmits(['close'])

defineProps({
  isShow: {
    type: Boolean,
    required: true,
    default: false
  }
})

const content = computed(() =>
  i18n
    .t('eula')
    .replaceAll('<br /> ', '<br />')
    .replaceAll('<p><br />', '<p>')
    .replaceAll('<li><br />', '<li>')
    .replaceAll('<strong><br />', '<strong>')
    .replaceAll('\n<p>&nbsp;</p>', '')
    .replaceAll('\n<p><strong>&nbsp;</strong></p>', '')
)
const close = () => emit('close')

</script>

<style lang="scss" scoped>
.term-of-use {
  width: 100vw;
  height: 100vh;
  background-image: url('./../assets/images/back-logo.jpg')  !important;
  background-size: cover !important;
  z-index: 1;
  h1 {
    color: $base-color;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: normal;
    padding-left: 16px;
  }
  &-container {
    margin: auto;
    width: 1054px;
    height: 100%;
    padding: 32px 0 24px 0;
  }
  &-content {
    @include cut-corners(11px, true, 11px, false, 1px, rgba($base-color, 0.15));
    margin: 0 auto;
    margin-top: 11px;
    padding: 24px 16px 24px 24px;
    height: calc(100vh - 152px);
  }
  &-textarea {
    @include scrollbar;
    color: $base-color-text;
    padding-right: 24px;
    white-space: pre-wrap;
    ::v-deep p {
      margin: 0;
    }
    ::v-deep strong {
      font-weight: normal;
      font-family: $primary-font-family-medium;
      color: $base-color-light;
    }
    ::v-deep td {
      vertical-align: top;
    }
  }
}
</style>
