<template>
  <CbrDialog
    :title="$t('add_object_hdr_add_add_object')"
    :value="true"
    @input="closeComponent"
    max-width="1360px"
    persistent
  >
    <template #content>
      <CbrTextField
        v-model.trim="name"
        :title="$t('add_object_edit_name_object')"
        :placeholder="$t('add_object_edit_name_object_plh')"
        class="w100"
        :maxlength="255"
        :error-messages="nameErrors"
        required
        @blur="checkEmptyName"
      />
      <div class="d-flex justify-space-around w100">
        <div class="add-comp__item" v-for="item in items" :key="item.state">
          <span class="add-comp__item-title">{{ item.state }}</span>
          <div class="add-comp__item-content">
            <div
              class="set-center"
              :class="{ 'drag-cover': item.isDraggingOver }"
              @drop.prevent="uploadFile($event, item)"
              @dragenter.prevent="onDragEnter(item)"
              @dragleave.prevent="onDragLeave($event, item)"
              @dragover.prevent=""
            >
              <div v-if="item.img" class="set-center__wrap">
                <img
                  class="set-center__point"
                  src="@/assets/icons/svg/tools/centerpoint.svg"
                  alt=""
                  :style="{ top: clientCenterPointY + 'px', left: clientCenterPointX + 'px' }"
                >
                <img :src="item.img.src" alt="" @click="setCenterCoords" />
              </div>
              <div v-else class="set-center__dummy">{{ $t('add_object_lbl_no_image')}}</div>
            </div>
            <div class="file-properties">
              <div v-if="item.img" class="file-properties__wrap">
                <div class="file-properties__name" :data-type="fileExt(item.file)">
                  {{ fileName(item.file) }}
                </div>
              </div>
              <div
                v-if="item.img"
                class="file-properties__size"
                :style="{ color: imgError ? $h.colors.baseColors.$AlarmTextColor : '' }"
              >
                {{ item.sizeStr }}
              </div>
              <div v-else class="file-properties__no-file">{{ $t('add_object_lbl_no_file_loaded') }}</div>
            </div>
          </div>
          <CbrButton
            :text="$t(item.img ? 'add_object_btn_reload' : 'add_object_btn_upload')"
            :icon="item.img ? '$reload' : '$cbrPlusCircle18'"
            :border="!!item.img"
            @click="$refs[item.state][0].click()"
          />
          <input
            type="file"
            :ref="item.state"
            style="display: none"
            accept="image/png, image/jpeg"
            @change="uploadFile($event, item)"
          />
        </div>
      </div>
      <div class="add-comp__footer">
        <hr class="add-comp__line"/>
        <div class="add-comp__error">{{ imgError }}</div>
      </div>
      <CbrDialog
        v-model="isLoadingError"
        :title="$t('add_object_hdr_upload_error')"
        :error-text="$t('add_object_file_incorrect_format')"
        error
      >
        <template v-slot:footer>
          <CbrButton
            :text="$t('add_object_btn_close')"
            @click="isLoadingError = false"
          />
        </template>
      </CbrDialog>
    </template>
    <template #footer>
      <CbrButton
        :text="$t('add_object_btn_cancel')"
        @click="closeComponent"
        border
      />
      <CbrButton
        :text="$t('add_object_btn_add')"
        :disabled="!activeButton"
        @click="newComp"
      />
    </template>
  </CbrDialog>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from '@/store'
import { i18n } from '@/i18n-setup'

const store = useStore()
const emit = defineEmits(['handleEdit', 'showComponents'])

const name = ref('')
const nameErrors = ref([])
const imgError = ref('')
const clientCenterPointX = ref(0)
const clientCenterPointY = ref(0)
const centerPointOffsetX = ref(null)
const centerPointOffsetY = ref(null)
const isLoadingError = ref(false)

const items = ref([{
  state: i18n.t('trigger_object_lst_state_on'),
  file: null,
  img: null,
  sizeStr: '',
  isDraggingOver: false
}, {
  state: i18n.t('trigger_object_lst_state_warning'),
  file: null,
  img: null,
  sizeStr: '',
  isDraggingOver: false
}, {
  state: i18n.t('trigger_object_lst_state_off'),
  file: null,
  img: null,
  sizeStr: '',
  isDraggingOver: false
}, {
  state: i18n.t('trigger_object_lst_state_critical'),
  file: null,
  img: null,
  sizeStr: '',
  isDraggingOver: false
}])

const activeButton = computed(() => items.value.every(item => item.img) && name.value && !imgError.value)

const checkName = () => !store.getters.components.some(item => item.name === name.value)
const checkEmptyName = () =>
  nameErrors.value = !name.value ? [i18n.t('add_object_edit_name_object_empty')] : []

const newComp = () => {
  if (!checkName()) {
    nameErrors.value = [i18n.t('an_incorrect_name_was_entered_or_it_already_exists')]
    return
  }
  const comp = {
    name: name.value,
    stateOn: items.value.find(({ state }) => state === 'On').file,
    stateOff: items.value.find(({ state }) => state === 'Off').file,
    stateWarning: items.value.find(({ state }) => state === 'Warning').file,
    stateCritical: items.value.find(({ state }) => state === 'Critical').file,
    centerPointOffsetX: centerPointOffsetX.value,
    centerPointOffsetY: centerPointOffsetY.value
  }
  store.dispatch('newComp', comp).then(() => {
    emit('handleEdit', false)
    emit('showComponents', { name: 'addComp', isOpen: false })
  })
}
const uploadFile = (e, item) => {
  let file
  if (e.type === 'change') {
    file = e.target.files[0]
  } else if (e.type === 'drop') {
    item.isDraggingOver = false
    file = e.dataTransfer.files[0]
  }
  if (!file) return
  if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
    isLoadingError.value = true
    return
  }
  const fileReader = new FileReader()
  fileReader.addEventListener('load', () => {
    updateImage(item, fileReader.result)
  })
  fileReader.readAsDataURL(file)
  item.file = file
}
const onDragEnter = (item) => {
  items.value.forEach(item => item.isDraggingOver = false)
  item.isDraggingOver = true
}
const onDragLeave = (e, item) => {
  if (!e.relatedTarget) {
    item.isDraggingOver = false
    return
  }
  if (e.relatedTarget.closest('.set-center')) {
    return
  }
  item.isDraggingOver = false
}
const updateImage = (item, file) => {
  const image = new Image()
  image.src = file
  image.onload = () => {
    item.img = image
    item.sizeStr = image.naturalWidth + ' x ' + image.naturalHeight
    if (
      Math.abs(clientCenterPointX.value) > image.naturalWidth / 2 ||
      Math.abs(clientCenterPointY.value) > image.naturalHeight / 2 ||
      centerPointOffsetX.value === null ||
      centerPointOffsetY.value === null
    ) {
      centerPointOffsetX.value = Math.round(image.naturalWidth / 2)
      centerPointOffsetY.value = Math.round(image.naturalHeight / 2)
      clientCenterPointX.value = 0
      clientCenterPointY.value = 0
    }
    checkImages()
  }
}
const checkImages = () => {
  const images = items.value.map(({ img }) => img).filter(Boolean)
  imgError.value = images.every((item, i, arr) =>
    item.naturalWidth === arr[0].naturalWidth && item.naturalHeight === arr[0].naturalHeight)
    ? ''
    : i18n.t('add_object_lbl_same_size')
}

const fileName = (file) => file.name.split('.')[0]
const fileExt = (file) => {
  const arr = file.name.split('.')
  return arr.length > 1 ? '.' + arr.pop() : ''
}

const setCenterCoords = (e) => {
  if (e.target.nodeName === 'IMG') {
    const element = e.target.getBoundingClientRect()
    let scale = Math.min(
      e.composedPath()[2].clientWidth / e.target.naturalWidth,
      e.composedPath()[2].clientHeight / e.target.naturalHeight
    )
    if (scale > 1) scale = 1
    centerPointOffsetX.value = Math.round((e.pageX - element.x) / scale)
    centerPointOffsetY.value = Math.round((e.pageY - element.y) / scale)
    clientCenterPointX.value = e.pageX - element.x - element.width / 2
    clientCenterPointY.value = e.pageY - element.y - element.height / 2
  }
}
const closeComponent = () => emit('showComponents', { name: 'addComp', isOpen: false })

watch(() => name.value, (val) => {
  checkEmptyName()
  emit('handleEdit', !!val || items.value.some(item => item.file))
})
watch(() => items.value, () => emit('handleEdit', true), { deep: true })
</script>

<style lang="scss" scoped>
.add-comp {
  &__item {
    width: 256px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    &-title {
      color: $base-color;
      text-align: center;
      line-height: 1em;
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 6px 0;
      color: rgba($base-color-light, 0.5);
      .set-center {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 256px;
        background: rgba($base-black, 0.1);
        &__wrap {
          position: relative;
          img {
            cursor: crosshair;
            max-width: 248px;
            max-height: 248px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &__point {
          position: absolute;
          z-index: 2;
        }
        &__dummy {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background: rgba($base-black, 0.35);
          box-shadow: 0px 25px 25px rgba($base-black, 0.25);
        }
        &.drag-cover {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px dashed $base-color;
            background: rgba($base-black, 0.15);
            backdrop-filter: blur(50px);
          }
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("~@/assets/icons/svg/uikit/drop-files-plus.svg");
            background-position: center;
          }
        }
      }
      .file-properties {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 16px;
        &__wrap {
          display: flex;
          max-width: 158px;
        }
        &__name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &::before {
            content: attr(data-type);
            float: right;
          }
        }
        &__size {
          margin-left: 15px;
          text-align: end;
        }
        &__no-file {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  &__footer {
    width: 100%;
    text-align: center;
  }
  &__line {
    border: 1px solid rgba($base-color-light, 0.15);
    border-width: 1px 0 0 0;
    margin: 16px 0;
  }
  &__error {
    height: 21px;
    line-height: 21px;
    color: $base-color-error-text;
  }
}
</style>
