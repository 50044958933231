<template>
  <div
    id="cbrFileInput"
    class="cbr-file-input"
    :class="getClass"
    @drop.prevent="onDrop"
    @dragover.prevent="onDragOver"
    @dragleave.prevent="onDragLeave"
    @dragend.prevent="onDragEnd"
    @dragenter.prevent="onDragEnter"
  >
    <CbrButton
      id="cbrFileInputButton"
      :text="buttonText"
      :width="buttonWidth"
      :disabled="disabled"
      :icon="buttonIcon"
      cbr-icon
      @click="onUploadButtonClick"
    />
    <input
      ref="uploadInputRef"
      style="display: none"
      type="file"
      :id="id"
      :accept="accept"
      :multiple="multiple"
      @change="uploadFile"
      @click="onFileInputClick"
    />
    <div
      v-if="showDragArea"
      id="cbrFileInputText"
      class="cbr-file-input__drag-area-text"
      :style="{width: widthDragArea}"
      :class="{'cbr-file-input__drag-container': isDraggingOver}"
    >
      <template v-if="showProgressBar">
        <div class="cbr-file-input__progress-container">
          <v-progress-linear
            :value="uploadProgress"
            height="12"
            class="cbr-file-input__progress"
            :color="uploadProgress > 0 && uploadProgress < 100 ? '' : $h.colors.baseColors.$ActionColor"
          ></v-progress-linear>
          <span>{{ uploadProgress }}%</span>
        </div>
      </template>

      <template v-else-if="isDraggingOver">
        <CbrIcon class="cbr-icon-plus">$cbrPlus</CbrIcon>
      </template>

      <template v-else-if="uploadFileName">
        <CbrIcon>$document</CbrIcon>
        <span>{{ uploadFileName }}</span>
      </template>

      <template v-else>
        <CbrIcon>{{ error ? '$cbrAlarmHint' :  '$cbrPlus'}}</CbrIcon>
        <div
          v-if="error"
          class="error-container"
        >
          <span>{{ i18n.t('file_input_error_lbl') }}</span>
          {{ error }}
        </div>
        <span v-else>{{ dragAreaText }}</span>
      </template>
    </div>

    <div v-if="dragAndDropSpan">
      <span class="drag-message">{{ $t('file_input_drag_files') }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineExpose, watch } from 'vue'
import { i18n } from '@/i18n-setup.js'

const props = defineProps({
  accept: {
    type: String,
  },
  multiple: {
    type: Boolean,
    default: () => false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  buttonWidth: {
    type: String,
    default: '200px'
  },
  justify: {
    type: String,
    default: 'center',
    validator: (value) => {
      return ['center', 'start'].includes(value)
    }
  },
  buttonText: {
    type: String,
    default: i18n.t('user_panel_btn_upload')
  },
  widthDragArea: {
    type: String,
    default: '640px'
  },
  dragAndDropSpan: {
    type: Boolean,
    default: false
  },
  buttonIcon: {
    type: String,
    default: '$cbrPlusCircle18'
  },
  // True - для включения drag-n-drop'а, в случае использования без CbrFilesBlock
  dragAndDrop: {
    type: Boolean,
    default: false
  },
  id: {
    type: String
  },
  error: {
    type: String,
  },
  fileName: {
    type: String
  },
  progress: {
    type: Number,
    default: 0
  },
  showDragArea: {
    type: Boolean,
    default: true
  }
})

const uploadProgress = ref(props.progress - 5)
const emit = defineEmits(['update:error', 'change'])

const uploadFileName = ref('')
watch(() => props.fileName, (newFileName) => {
  uploadFileName.value = newFileName
})

const isDraggingOver = ref(false)
const uploadInputRef = ref(null)

const buttonText = computed(() => {
  return props.error ? i18n.t('event_screen_link_refresh') : (props.buttonText || (props.multiple.value ? i18n.t('file_input_select_files') : i18n.t('file_input_select_file')))
})

// local
const dragAreaText = computed(() => {
  return props.multiple.value ? i18n.t('file_input_drag_files') : 'Загрузите или перетащите файл конфигурации'
})

const onUploadButtonClick = () => {
  if (uploadInputRef.value) {
    uploadInputRef.value.click()
  }
}
const onFileInputClick = () => {
  if (uploadInputRef.value) {
    uploadInputRef.value.value = null
  }
}

// progress-bar
const showProgressBar = ref(false)
const simulateFileUpload = () => {
  showProgressBar.value = true
  const simulateUpload = () => {
    if (uploadProgress.value < 100) {
      uploadProgress.value += 5
      simulateUpload()
    }
  }
  simulateUpload()
  if (uploadProgress.value === 100) {
    setTimeout(() => showProgressBar.value = false, 1000)
  }
}

const uploadFile = (e) => {
  isDraggingOver.value = false
  let files
  if (e.type === 'change') {
    files = e.target.files
  } else if (e.type === 'drop') {
    files = e.dataTransfer.files
  }

  if (props.accept && files.length) {
    if (!props.accept.includes(files[0].type)) {
      uploadFileName.value = ''
      emit('update:error', i18n.t('event_card_txt_demo_stand_err'))
      return
    } else {
      uploadFileName.value = files[0].name
    }
  }

  emit('change', files)
  simulateFileUpload()
}

const getClass = computed(() => {
  const baseClass = 'cbr-file-input'

  return {
    [`${baseClass}--justify-start`]: props.justify === 'start',
    [`${baseClass}--justify-center`]: props.justify === 'center',
  }
})

const onDragOver = () => {
  if (props.dragAndDrop) {
    isDraggingOver.value = true
  }
}

const onDragLeave = () => {
  if (props.dragAndDrop) {
    isDraggingOver.value = false
  }
}

const onDrop = (e) => {
  if (props.dragAndDrop && !props.disabled) {
    uploadFile(e)
    isDraggingOver.value = false
  }
}

const onDragEnter = () => {
  if (props.dragAndDrop) {
    isDraggingOver.value = true
  }
}

const onDragEnd = () => {
  if (props.dragAndDrop) {
    isDraggingOver.value = false
  }
}

defineExpose({
  uploadFile
})
</script>

<style lang="scss" scoped>
.cbr-file-input {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;

  &--justify-start {
    justify-content: flex-start;
  }
  &--justify-center {
    justify-content: center;
  }

  &--dragging-over {
    background-color: red;
  }

  &__drag-area-text {
    @include cut-corners(5px, true, 5px, false);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
    background: rgba($base-color-light, 0.05);
    backdrop-filter: blur(12.5px);
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    color: rgba($base-color-light, 0.35);
    padding-left: 10px;

    span {
      margin-left: 10px;
    }
  }

  &__progress-container {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 95%;
    margin-left: 10px;
    color: $base-color;
  }

  &__progress {
    width: 90%;
  }
}

.cbr-file-input__drag-container {
  border: 2px dashed $base-color !important;
  background: #042941;
  border: 2px dashed $base-color;
  text-align: center;
  padding: 0 10px;
}

.error-container {
  color: $base-color-text;
  span {
    color: $base-color-error-text;
  }
}

.cbr-icon-plus {
  position: relative;
  left: 50%;
  right: 50%;
}

.drag-message {
  color: rgba($base-color-light, 0.35);
  font-size: 16px;
}

::v-deep .icon {
  height: 18px !important;
  width: 18px !important;
}
</style>
