<template>
  <div class="cbr-files-list">
    <div
        v-for="(file, index) in props.files"
        :key="index"
        class="cbr-files-list__file file"
    >
      <div class="file__title">
        <Icon v-if="file.icon" :icon-name="file.icon" :width="20" :height="20" class="mr-2"/>
        <Icon v-else
            class="file__icon"
            icon-name="task/task_attachment"
            width="24px"
            height="24px"
        />
        <span class="file__name">{{ getFileName(file.filename) }}</span>
        <span class="file__extension">.{{ getFileExtension(file.filename) }}</span>
      </div>
      <div class="file__actions">
        <CbrButton
          class="file__button"
          size="small"
          icon="$download_file"
          @click="onDownloadButtonClick(index)"
        />
        <CbrButton
          v-if="!props.noDeleteButton"
          size="small"
          error
          icon="$cbrBasket"
          :mdi-icon-color="$h.colors.baseColors.$StaticLight"
          @click="onDeleteButtonClick(index)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  files: {
    type: Array,
    default: () => []
  },
  noDeleteButton: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['delete', 'download'])

const getFileName = (nameWithExtension) => {
  return nameWithExtension?.split('.').slice(0, -1).join('.')
}
const getFileExtension = (nameWithExtension) => {
  return nameWithExtension?.split('.').at(-1)
}
const onDeleteButtonClick = (fileIndex) => {
  emit('delete', fileIndex)
}
const onDownloadButtonClick = (fileIndex) => {
  emit('download', fileIndex)
}
</script>

<style lang="scss" scoped>
.cbr-files-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.file {
  @include cut-corners(9px, true, 9px, false, 0, transparent);
  @include background-with-filter;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 8px;
  transition-duration: $base-ui-transition-duration;
  transition-timing-function: $base-ui-transition-function;
  transition-property: background;

  &__icon {
    opacity: .35;
  }

  &:hover {
    background: rgba($base-color, 0.25);
    backdrop-filter: blur(12.5px);
  }

  &__title {
    display: inline-flex;
    justify-content: flex-start;
    margin-bottom: 0;
    font-family: $primary-font-family;
    font-size: 16px;
    color: $base-color-light;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    gap: 12px;
  }

  &__button {
    ::v-deep svg {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
