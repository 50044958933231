<template>
  <CbrDialog
    :title="$t('add_image_hdr_add_image')"
    :value="true"
    @input="closeComponent"
    persistent
  >
    <template #content>
      <CbrTextField
        v-model.trim="name"
        :title="$t('add_image_edit_input_name_image')"
        :placeholder="$t('add_image_edit_input_name_image_plh')"
        class="w100"
        :maxlength="255"
        :error-messages="nameErrors"
        required
        @blur="checkEmptyName"
      />
      <div class="add-icon">
        <div
          class="add-icon__image"
          :class="{ 'drag-cover': isDraggingOver }"
          @drop.prevent="uploadFile($event)"
          @dragenter.prevent="onDragEnter()"
          @dragleave.prevent="onDragLeave($event)"
          @dragover.prevent=""
        >
          <img v-if="img" :src="img.src" alt="" />
          <div v-else class="add-icon__image-dummy">{{ $t('add_object_lbl_no_image')}}</div>
        </div>
        <div class="file-properties">
          <template v-if="img">
            <div class="file-properties__name" :data-type="fileExt()">
              {{ fileName() }}
            </div>
            <div class="file-properties__size">
              {{ sizeStr }}
            </div>
          </template>
          <div v-else class="file-properties__no-file">{{ $t('add_object_lbl_no_file_loaded') }}</div>
        </div>
        <CbrButton
          :text="$t(file ? 'add_object_btn_reload' : 'add_object_btn_upload')"
          :icon="file ? '$reload' : '$cbrPlusCircle18'"
          :border="!!file"
          @click="$refs.icon.click()"
        />
        <input
          type="file"
          ref="icon"
          style="display: none"
          accept="image/svg+xml,image/png,image/jpeg"
          @change="uploadFile"
        />
      </div>
      <CbrDialog
        v-model="isLoadingError"
        :title="$t('add_object_hdr_upload_error')"
        :error-text="$t('add_image_file_incorrect_format')"
        error
      >
        <template v-slot:footer>
          <CbrButton
            :text="$t('add_object_btn_close')"
            @click="isLoadingError = false"
          />
        </template>
      </CbrDialog>
    </template>
    <template #footer>
      <CbrButton
        :text="$t('add_object_btn_cancel')"
        @click="closeComponent"
        border
      />
      <CbrButton
        :text="$t('add_object_btn_add')"
        :disabled="!file || !name"
        @click="newIcon"
      />
    </template>
  </CbrDialog>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useStore } from '@/store'
import { i18n } from '@/i18n-setup'

const store = useStore()
const emit = defineEmits(['handleEdit', 'showComponents'])

const name = ref('')
const sizeStr = ref('')
const nameErrors = ref([])
const img = ref(null)
const file = ref(null)
const isDraggingOver = ref(false)
const isLoadingError = ref(false)

const checkName = () => !store.getters.icons.some(item => item.name === name.value)
const checkEmptyName = () =>
  nameErrors.value = !name.value ? [i18n.t('add_object_edit_name_object_empty')] : []

const newIcon = () => {
  if (!checkName()) {
    nameErrors.value = [i18n.t('an_incorrect_name_was_entered_or_it_already_exists')]
    return
  }
  store.dispatch('newIcon', { name: name.value, image: file.value }).then(() => {
    emit('handleEdit', false)
    emit('showComponents', { name: 'addIcon', isOpen: false })
  })
}
const onDragEnter = () => isDraggingOver.value = true
const onDragLeave = (e) => {
  if (!e.relatedTarget) {
    isDraggingOver.value = false
    return
  }
  if (e.relatedTarget.closest('.add-icon__image')) {
    return
  }
  isDraggingOver.value = false
}
const uploadFile = (e) => {
  let _file
  if (e.type === 'change') {
    _file = e.target.files[0]
  } else if (e.type === 'drop') {
    isDraggingOver.value = false
    _file = e.dataTransfer.files[0]
  }
  if (!_file) return
  if (_file.type !== 'image/jpeg' && _file.type !== 'image/png' && _file.type !== 'image/svg+xml') {
    isLoadingError.value = true
    return
  }
  const fileReader = new FileReader()
  fileReader.addEventListener('load', () => {
    const image = new Image()
    image.src = fileReader.result
    image.onload = () => {
      img.value = image
      sizeStr.value = image.naturalWidth + ' x ' + image.naturalHeight
    }
  })
  fileReader.readAsDataURL(_file)
  file.value = _file
}

const fileName = () => file.value.name.split('.')[0]
const fileExt = () => {
  const arr = file.value.name.split('.')
  return arr.length > 1 ? '.' + arr.pop() : ''
}

const closeComponent = () => emit('showComponents', { name: 'addIcon', isOpen: false })

watch(() => name.value, (val) => {
  checkEmptyName()
  emit('handleEdit', !!val || file.value)
})
watch(() => file.value, () => emit('handleEdit', true))
</script>

<style lang="scss" scoped>
.add-icon {
  width: 256px;
  display: flex;
  flex-direction: column;
  padding: 8px 0 24px 0;
  color: rgba($base-color-light, 0.5);
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 256px;
    background: rgba($base-black, 0.1);
    img {
      max-width: 248px;
      max-height: 248px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-dummy {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba($base-black, 0.35);
      box-shadow: 0px 25px 25px rgba($base-black, 0.25);
    }
    &.drag-cover {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px dashed $base-color;
        background: rgba($base-black, 0.15);
        backdrop-filter: blur(50px);
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("~@/assets/icons/svg/uikit/drop-files-plus.svg");
        background-position: center;
      }
    }
  }
  .file-properties {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 16px;
    margin: 6px 0 16px 0;
    &__name {
      max-width: 158px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &::before {
        content: attr(data-type);
        float: right;
      }
    }
    &__size {
      margin-left: 15px;
      text-align: end;
    }
    &__no-file {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
