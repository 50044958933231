import store from '@/store'
import Essence from './essence'
import Line from './line'
import Plane from './plane'
import TextField from './text-field'
import { constructor } from './player'
import { config } from './map-config'
import _ from 'lodash'

const clonedeep = require('lodash.clonedeep')

export default class Objects {
  CopyPasteObjects (e, effect) {
    let pasteCoords = store.getters.clickCoords
    const copyObj = store.getters.copyObjects
    let x = pasteCoords.x
    let y = pasteCoords.y - constructor.topOffset
    const compareEssencesId = new Map()
    const dataStore = {
      essence: [],
      lines: [],
      planes: [],
      textFields: [],
    }
    if (e.keyCode === 67 && e.ctrlKey === true) {
      store.commit('setCopyObjects', _.cloneDeep(store.getters.selectedAll))
    }
    if (e.keyCode === 86 && e.ctrlKey === true) {
      // если много объектов, нужна относительная точка отсчёта
      let relativePoint = null
      for (let i in copyObj) {
        if (copyObj[i][0]) {
          copyObj[i][0].name === 'line' ? relativePoint = { x: copyObj[i][0].startX, y: copyObj[i][0].startY } : relativePoint = { x: copyObj[i][0].x, y: copyObj[i][0].y }
        }
      }
      // если не найдена ни одна сущность, то копировать нечего
      if (!relativePoint) {
        return
      }
      copyObj.essence.forEach((item) => {
        const essence = new Essence(x + item.x - relativePoint.x, y + item.y - relativePoint.y, item.name, item.ctx, item.cvs)
        compareEssencesId.set(item.id, essence.id)
        essence.effect = item.effect
        essence.centerPointOffsetX = item.centerPointOffsetX || 0
        essence.centerPointOffsetY = item.centerPointOffsetY || 0
        essence.triggers = item.triggers
        essence.label = item.label
        essence.state = item.state
        essence.selected = true
        essence.icons = item.icons
        config.setIcons(essence)
        essence.baseEssenceState = {
          state: item.state,
          effect: item.effect,
          icons: essence.icons
        }
        essence.triggers.forEach((trigger) => config.setIcons(trigger))
        if (item.gifEffects.length !== 0) {
          item.gifEffects.forEach((gifEffect) => {
            effect(essence, gifEffect.name)
          })
        }
        constructor.essence.push(essence)
        dataStore.essence.push(essence)
        store.commit('selected', constructor.essence[constructor.essence.length - copyObj.essence.length])
      })
      store.getters.copyObjects.lines.forEach((item) => {
        let endId = item.endID
        let startId = item.startID
        if (typeof item.endID === 'string') {
          endId = item.endID.slice(0, 2) === 'vm' ? compareEssencesId.get(item.endID) : item.endID
        }
        if (typeof item.startID === 'string') {
          startId = item.startID.slice(0, 2) === 'vm' ? compareEssencesId.get(item.startID) : item.startID
        }
        const startPoint = { id: startId, x: x + +item.startX - relativePoint.x, y: y + +item.startY - relativePoint.y }
        const endPoint = { id: endId, x: x + +item.endX - relativePoint.x, y: y + +item.endY - relativePoint.y }
        const line = new Line(startPoint, endPoint, item.id)
        line.ctx = constructor.ctx
        line.label = item.label
        line.color = item.color
        line.lineWidth = item.lineWidth
        line.triggers = item.triggers
        line.points = item.points.map(i => {
          return { x: x + +i.x - relativePoint.x, y: y + +i.y - relativePoint.y }
        })
        line.graphList = item.graphList
        line.animationDirectionFromLeft = item.animationDirectionFromLeft
        line.effect = item.effect
        line.baseLineState = {
          effect: item.effect,
          color: item.color,
          lineWidth: item.lineWidth
        }
        constructor.lines.push(line)
        dataStore.lines.push(line)
        store.commit('selected', constructor.lines[constructor.lines.length - copyObj.lines.length])
      })
      store.getters.copyObjects.planes.forEach((item) => {
        const plane = new Plane(constructor.ctx, item.id)
        plane.triggers = item.triggers
        plane.bgColor = item.bgColor
        plane.lineColor = item.lineColor
        plane.width = item.width
        plane.x = x + +item.x - relativePoint.x
        plane.y = y + +item.y - relativePoint.y
        plane.a = { x: x + +item.a.x - relativePoint.x, y: y + +item.a.y - relativePoint.y }
        plane.b = { x: x + +item.b.x - relativePoint.x, y: y + +item.b.y - relativePoint.y }
        plane.c = { x: x + +item.c.x - relativePoint.x, y: y + +item.c.y - relativePoint.y }
        plane.d = { x: x + +item.d.x - relativePoint.x, y: y + +item.d.y - relativePoint.y }
        constructor.planes.push(plane)
        dataStore.planes.push(plane)
        store.commit('selected', constructor.planes[constructor.planes.length - copyObj.planes.length])
      })
      store.getters.copyObjects.textFields.forEach((item) => {
        const text = new TextField(constructor.ctx, item.id)
        text.triggers = item.triggers
        text.name = item.name
        text.message = item.message
        text.x = x + item.x - relativePoint.x
        text.y = y + item.y - relativePoint.y
        text.color = item.color
        text.textSize = item.textSize
        text.rotateDegree = item.rotateDegree || 0
        text.skewDegree = item.skewDegree || 0
        constructor.textFields.push(text)
        dataStore.textFields.push(text)
        store.commit('selected', constructor.textFields[constructor.textFields.length - copyObj.textFields.length])
      })
      store.commit('clearSelection')
      store.commit('setSelectedAll', dataStore)
    }
  }

  DeleteSelectedObjects () {
    store.getters.selectedAll.essence.forEach((item) => {
      if (!item.isBlocked) {
        constructor.essence = constructor.essence.filter((essence) => {
          store.commit('removeFromSelection', { type: 'essence', item: essence })
          essence.gifEffects.forEach((gifEffect) => {
            gifEffect.img.remove()
          })
          return essence.id !== item.id
        })
        store.commit('selected', constructor.scene)
      }
    })
    store.getters.selectedAll.lines.forEach((item) => {
      if (!item.isBlocked) {
        constructor.lines = constructor.lines.filter((line) => {
          store.commit('removeFromSelection', { type: 'lines', item: line })
          return line.id !== item.id
        })
        store.commit('selected', constructor.scene)
      }
    })
    store.getters.selectedAll.planes.forEach((item) => {
      if (!item.isBlocked) {
        constructor.planes = constructor.planes.filter((plane) => {
          store.commit('removeFromSelection', { type: 'planes', item: plane })
          return plane.id !== item.id
        })
        store.commit('selected', constructor.scene)
      }
    })
    store.getters.selectedAll.textFields.forEach((item) => {
      if (!item.isBlocked) {
        constructor.textFields = constructor.textFields.filter((text) => {
          store.commit('removeFromSelection', { type: 'textFields', item: text })
          return text.id !== item.id
        })
        store.commit('selected', constructor.scene)
      }
    })
    // cancel connector if two objects are removed
    const essences = constructor.essence.map(({ id }) => id)
    constructor.lines.forEach((line) => {
      if (!essences.includes(line.startID)) line.startID = ''
      if (!essences.includes(line.endID)) line.endID = ''
    })
  }

  GoToCenter () {
    constructor.essence.forEach((essence) => {
      if (essence.selected) {
        essence.x = (constructor.cvs.width / 2 + (constructor.translateX * (-1))) / constructor.zoom
        essence.y = (constructor.cvs.height / 2 + (constructor.translateY * (-1))) / constructor.zoom
      }
    })
    constructor.textFields.forEach((text) => {
      if (text.selected) {
        text.x = (constructor.cvs.width / 2 + (constructor.translateX * (-1))) / constructor.zoom
        text.y = (constructor.cvs.height / 2 + (constructor.translateY * (-1))) / constructor.zoom
      }
    })
    constructor.planes.forEach((plane) => {
      if (plane.selected) {
        let b = plane.b.y - plane.a.y
        let dx = plane.d.x - plane.a.x
        let c = plane.c.y - plane.d.y
        let cx = plane.c.x - plane.b.x
        plane.a.x = (constructor.cvs.width / 2 + (constructor.translateX * (-1))) / constructor.zoom
        plane.a.y = (constructor.cvs.height / 2 + (constructor.translateY * (-1))) / constructor.zoom
        // b
        plane.b.x = (constructor.cvs.width / 2 + (constructor.translateX * (-1))) / constructor.zoom
        plane.b.y = (constructor.cvs.height / 2 + (constructor.translateY * (-1))) / constructor.zoom + b
        // d
        plane.d.x = plane.a.x + dx
        plane.d.y = (constructor.cvs.height / 2 + (constructor.translateY * (-1))) / constructor.zoom
        // c
        plane.c.x = plane.b.x + cx
        plane.c.y = plane.d.y + c
      }
    })
    constructor.lines.forEach((line) => {
      if (line.selected) {
        let prev = {
          x: line.points[0].x,
          y: line.points[0].y
        }
        line.points.forEach((point) => {
          point.x = (constructor.cvs.width / 2 + (constructor.translateX * (-1))) / constructor.zoom - (prev.x - point.x)
          point.y = (constructor.cvs.height / 2 + (constructor.translateY * (-1))) / constructor.zoom - (prev.y - point.y)
        })
      }
    })
  }

  BringToFront (id) {
    constructor.essence.forEach(essence => {
      if (essence.id === id) {
        const element = essence
        const index = constructor.essence.indexOf(essence)
        constructor.essence.splice(index, 1)
        constructor.essence.push(element)
      }
    })
  }

  BringToBack (id, tempData = null) {
    constructor.essence.forEach(essence => {
      if (essence.id === id) {
        const element = essence
        const index = constructor.essence.indexOf(essence)
        constructor.essence.splice(index, 1)
        if (tempData) {
          constructor.essence.splice(tempData.initialIndex, 0, essence)
        } else {
          constructor.essence.unshift(element)
        }
      }
    })
  }

  BlockObjects () {
    store.getters.selectedAll.essence.forEach((essence) => {
      essence.isBlocked = true
    })
    store.getters.selectedAll.lines.forEach((line) => {
      line.isBlocked = true
      line.points.forEach(point => {
        point.isBlocked = true
      })
    })
    store.getters.selectedAll.planes.forEach((plane) => {
      plane.isBlocked = true
    })
    store.getters.selectedAll.textFields.forEach((text) => {
      text.isBlocked = true
    })
  }

  UnBlockObjects () {
    store.getters.selectedAll.essence.forEach((essence) => {
      essence.isBlocked = false
    })
    store.getters.selectedAll.lines.forEach((line) => {
      line.isBlocked = false
      line.points.forEach(point => {
        point.isBlocked = false
      })
    })
    store.getters.selectedAll.planes.forEach((plane) => {
      plane.isBlocked = false
    })
    store.getters.selectedAll.textFields.forEach((text) => {
      text.isBlocked = false
    })
  }

  ChangeCenter (id, centerPointOffsetX, centerPointOffsetY) {
    const foundEssence = constructor.essence.find(essence => essence.id === id)
    foundEssence.centerPointOffsetX = centerPointOffsetX
    foundEssence.centerPointOffsetY = centerPointOffsetY

    let lineX = foundEssence.x + centerPointOffsetX
    let lineY = foundEssence.y + centerPointOffsetY

    constructor.lines = constructor.lines.map((line) => {
      if (line.startID === foundEssence.id) {
        line.ctx = constructor.ctx
        line.startX = lineX
        line.startY = lineY
        return line
      } else if (line.endID === foundEssence.id) {
        line.ctx = constructor.ctx
        line.endX = lineX
        line.endY = lineY
        return line
      }
      return line
    })
  }

  static CloneObjects (fromPlayer, toPlayer) {
    fromPlayer.planes.forEach(item => {
      const plane = clonedeep(item)
      plane.ctx = toPlayer.ctx
      plane.selected = false
      toPlayer.planes.push(plane)
    })

    fromPlayer.essence.forEach(item => {
      const essence = clonedeep(item)
      essence.ctx = toPlayer.ctx
      essence.selected = false
      essence.effect = []
      toPlayer.essence.push(essence)
    })

    fromPlayer.lines.forEach(item => {
      const line = clonedeep(item)
      line.ctx = toPlayer.ctx
      line.selected = false
      line.effect = []
      toPlayer.lines.push(line)
    })

    fromPlayer.textFields.forEach(item => {
      const textField = clonedeep(item)
      textField.ctx = toPlayer.ctx
      textField.selected = false
      toPlayer.textFields.push(textField)
    })
  }
}

export const objects = new Objects()
