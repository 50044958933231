/* eslint-disable no-console */
import resourceService from '@/services/resource.service'

const actions = {
  async loadComp ({ commit }) {
    try {
      let compArray = await resourceService.loadComp()
      if (!Array.isArray(compArray)) compArray = []
      commit('loadComp', compArray)
    } catch (error) {
      console.log(error)
    }
  },
  async newComp ({ commit }, payload) {
    try {
      await resourceService.newComp(payload)
      const comp = await resourceService.loadCompByName(payload.name)
      commit('newComp', comp)
    } catch (error) {
      console.log(error)
    }
  },
  async loadIcons ({ commit }) {
    try {
      let iconsArray = await resourceService.loadIcons()
      if (!Array.isArray(iconsArray)) iconsArray = []
      commit('loadIcons', iconsArray)
    } catch (error) {
      console.log(error)
    }
  },
  async newIcon ({ commit }, payload) {
    try {
      await resourceService.newIcon(payload)
      const icon = await resourceService.loadIconByName(payload.name)
      commit('newIcon', icon)
    } catch (error) {
      console.log(error)
    }
  },
  async newGifEffect ({ commit }, payload) {
    try {
      await resourceService.newGifEffect(payload)
      const effect = await resourceService.loadGifEffectByName(payload.name)
      commit('newGifEffects', effect)
    } catch (error) {
      console.log(error)
    }
  },
  async loadGifEffects ({ commit }) {
    try {
      let gifEffectsArray = await resourceService.loadGifEffects()
      if (!Array.isArray(gifEffectsArray)) gifEffectsArray = []
      commit('loadGifEffects', gifEffectsArray)
    } catch (error) {
      console.log(error)
    }
  },
  selected ({ commit }, payload) {
    commit('selected', payload)
  },
  tools ({ commit }, payload) {
    commit('tools', payload)
  },
  baseItems ({ commit }, payload) {
    commit('baseItems', payload)
  },
  base ({ commit }, payload) {
    commit('base', payload)
  }
}

export default actions
