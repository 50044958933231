import { i18n } from '@/i18n-setup.js'
const roles = {
  ADMIN: {
    key: 'ADMIN'
  },
  TEACHER: {
    key: 'TRAINER'
  },
  USER: {
    key: 'USER'
  },
  OBSERVER: {
    key: 'OBSERVER'
  },
  AUDITOR: {
    key: 'AUDITOR'
  },
  INFRASTRUCTURE_ARCHITECT: {
    key: 'INFRASTRUCTURE_ARCHITECT'
  },
  RESEARCHER: {
    key: 'RESEARCHER'
  },
  SCREENWRITER_METHODOLOGIST: {
    key: 'SCREENWRITER_METHODOLOGIST'
  },
  all: function () {
    return [
      this.ADMIN,
      this.TEACHER,
      this.USER,
      this.OBSERVER,
      this.AUDITOR,
      this.INFRASTRUCTURE_ARCHITECT,
      this.RESEARCHER,
      this.SCREENWRITER_METHODOLOGIST
    ].map(role => {
      return { key: role.key, name: this.getRoleName(role.key) }
    })
  },
  getRoleName: function (role) {
    switch (role) {
      case this.ADMIN.key:
        return i18n.t('user_card_lbl_user_role_admin')
      case this.TEACHER.key:
        return i18n.t('event_card_log_lbl_teacher')
      case this.USER.key:
        return i18n.t('user_card_lbl_user_role_participant')
      case this.OBSERVER.key:
        return i18n.t('user_card_lbl_user_role_observer')
      case this.AUDITOR.key:
        return i18n.t('user_card_lbl_user_role_auditor')
      case this.INFRASTRUCTURE_ARCHITECT.key:
        return i18n.t('user_card_lbl_user_role_infrastructure_architect')
      case this.RESEARCHER.key:
        return i18n.t('user_card_lbl_user_role_researcher')
      case this.SCREENWRITER_METHODOLOGIST.key:
        return i18n.t('user_card_lbl_user_role_screenwriter_methodologist')
      default:
        return ''
    }
  },
  teacherRoles () {
    return [this.TEACHER.key, this.INFRASTRUCTURE_ARCHITECT.key, this.RESEARCHER.key, this.SCREENWRITER_METHODOLOGIST.key]
  },
  adminRoles (user, internalOnly) {
    return !internalOnly || user?.organization?.internal
      ? [this.ADMIN.key, this.TEACHER.key, this.INFRASTRUCTURE_ARCHITECT.key, this.RESEARCHER.key, this.SCREENWRITER_METHODOLOGIST.key]
      : []
  },
  isTeacher (item) {
    return this.teacherRoles().includes(item.role)
  },
  isTeacherRole (role) {
    return this.teacherRoles().includes(role)
  }
}

export default roles
