import Vue from 'vue'
import helpers from '@/helpers/base.helper'

const plugin = {
  install () {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
    Vue.prototype.$h = helpers
  }
}

Vue.use(plugin)

export default { helpers }
