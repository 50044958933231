import { defaultPlaneParams, addSelectedCircle } from '@/helpers/map.helper.js'
import store from '@/store'
import { tools } from './tools'
import { constructor } from './player'
import { mouse, isCursorInPoint } from './cursor'

export default class Plane {
  constructor (ctx, id) {
    this.defaultState = {}
    this.ctx = ctx
    this.initialData = {}
    this.selected = false
    this.id = store.state.map.players.find(i => i.cvs.id === 'player')?.AddId('vm', id ? +id.slice(2) : 0)
    this.name = 'plane'
    this.triggers = []
    this.bgColor = defaultPlaneParams.bgColor
    this.lineColor = defaultPlaneParams.lineColor
    this.width = 200
    this.x = 0
    this.y = 0
    this.a = {
      x: Math.round(this.x),
      y: Math.round(this.y)
    }
    this.b = {
      x: Math.round(this.x),
      y: Math.round(this.y) + this.width
    }
    this.c = {
      x: Math.round(this.x) + this.width,
      y: Math.round(this.y) + this.width
    }
    this.d = {
      x: Math.round(this.x) + this.width,
      y: Math.round(this.y)
    }
    this.isBlocked = false
    this.selected = false
  }

  Place (e) {
    if (e) {
      this.x = (e.pageX + (constructor.translateX * (-1))) / constructor.zoom
      this.y = (e.pageY - constructor.topOffset + (constructor.translateY * (-1))) / constructor.zoom
    }
    this.a = {
      x: Math.round(this.x),
      y: Math.round(this.y)
    }
    this.b = {
      x: Math.round(this.x),
      y: Math.round(this.y) + this.width
    }
    this.c = {
      x: Math.round(this.x) + this.width,
      y: Math.round(this.y) + this.width
    }
    this.d = {
      x: Math.round(this.x) + this.width,
      y: Math.round(this.y)
    }
  }

  Draw () {
    this.ctx.beginPath()
    this.ctx.moveTo(this.a.x, this.a.y)
    this.ctx.lineTo(this.b.x, this.b.y)
    this.ctx.lineTo(this.c.x, this.c.y)
    this.ctx.lineTo(this.d.x, this.d.y)
    this.ctx.lineTo(this.a.x, this.a.y)
    this.ctx.lineWidth = 2
    this.ctx.strokeStyle = this.lineColor
    this.ctx.fillStyle = this.bgColor
    this.ctx.fill()
    this.ctx.stroke()
    this.ctx.fillRect(this.a.x - 5, this.a.y - 5, 10, 10)
    this.ctx.fillRect(this.b.x - 5, this.b.y - 5, 10, 10)
    this.ctx.fillRect(this.c.x - 5, this.c.y - 5, 10, 10)
    this.ctx.fillRect(this.d.x - 5, this.d.y - 5, 10, 10)
    this.ctx.closePath()
    if (this.selected) {
      this.Selected()
    }
  }

  Selected () {
    addSelectedCircle(this.a.x, this.a.y, this.ctx, this.isBlocked)
    addSelectedCircle(this.b.x, this.b.y, this.ctx, this.isBlocked)
    addSelectedCircle(this.c.x, this.c.y, this.ctx, this.isBlocked)
    addSelectedCircle(this.d.x, this.d.y, this.ctx, this.isBlocked)
  }

  EditPlane () {
    this.addEditPlaneListeners()
  }

  addEditPlaneListeners () {
    window.addEventListener('mousedown', this.EditPlaneMouseDownHandler)
    window.addEventListener('mousemove', this.EditPlaneMouseMoveHandler)
    window.addEventListener('mouseup', this.EditPlaneMouseUpHandler)
  }

  removeEditPlaneListeners () {
    window.removeEventListener('mousedown', this.EditPlaneMouseDownHandler)
    window.removeEventListener('mousemove', this.EditPlaneMouseMoveHandler)
    window.removeEventListener('mouseup', this.EditPlaneMouseUpHandler)
    tools.editedPlane = false
  }

  EditPlaneMouseDownHandler () {
    this.selected = false
    for (const i in constructor.planes) {
      if (!constructor.planes[i].selected || constructor.planes[i].isBlocked) continue

      if (isCursorInPoint(constructor.planes[i].a)) {
        this.selected = constructor.planes[i].a
      }
      if (isCursorInPoint(constructor.planes[i].b)) {
        this.selected = constructor.planes[i].b
      }
      if (isCursorInPoint(constructor.planes[i].c)) {
        this.selected = constructor.planes[i].c
      }
      if (isCursorInPoint(constructor.planes[i].d)) {
        this.selected = constructor.planes[i].d
      }
    }
  }

  EditPlaneMouseMoveHandler (e) {
    mouse.x = e.pageX
    mouse.y = e.pageY - constructor.topOffset
    if (this.selected) {
      this.selected.y = Math.round((mouse.y - (10 * constructor.zoom) / 2 + (5 * constructor.zoom) + (constructor.translateY * (-1))) / constructor.zoom)
      this.selected.x = Math.round((mouse.x - (10 * constructor.zoom) / 2 + (5 * constructor.zoom) + (constructor.translateX * (-1))) / constructor.zoom)
    }
  }

  EditPlaneMouseUpHandler () {
    this.selected = false
  }

  MakePlane () {
    store.commit('addToSelection', { item: this, type: 'planes' })
    window.removeEventListener('mousemove', this.MakePlaneMouseMoveHandler)
    window.removeEventListener('mouseup', this.MakePlaneMouseUpHandler)
    tools.EditPlane()
  }

  MakePlaneMouseMoveHandler = (e) => {
    this.Place(e)
  }

  MakePlaneMouseUpHandler = (e) => {
    this.Place(e)
    if (e.target.id === 'player') {
      this.MakePlane()
    } else {
      constructor.planes.pop()
      this.removePlaneHandler()
      tools.Placement()
    }
  }

  addPlaneListeners () {
    window.addEventListener('mousemove', this.MakePlaneMouseMoveHandler)
    window.addEventListener('mouseup', this.MakePlaneMouseUpHandler)
  }

  removePlaneHandler () {
    store.commit('selected', constructor.scene)
    window.removeEventListener('mousemove', this.MakePlaneMouseMoveHandler)
    window.removeEventListener('mouseup', this.MakePlaneMouseUpHandler)
  }
}
