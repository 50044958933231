<template>
  <div class="avatar" id="create-edit-picture" v-if="state.isLoaded">
    <div class="avatar-borders"></div>
    <v-avatar v-if="avatarPath.length" id="user-avatar" size="256" tile>
      <v-img
        v-if="avatarPath.length"
        class="avatar-img"
        :class="noAvatar ? 'empty' : ''"
        :lazy-src="avatarPath"
        :src="avatarPath"
      />
    </v-avatar>
    <CbrButton
      v-if="profileEditingAvailable && noAvatar"
      id="create-edit-btn_load_picture"
      class="avatar-inside-btn"
      icon="mdi-pencil"
      :mdi-icon-color="$h.colors.baseColors.$StaticDark"
      cbr-icon
      @click="avatar.click()"
    />
    <input
      @change="uploadAvatar($event)"
      ref="avatar"
      accept="image/jpeg,image/png"
      type="file"
      style="display: none"
    />
    <CbrButton
      v-if="profileEditingAvailable && avatarPath && !noAvatar"
      id="create-edit-btn-drop-picture"
      class="avatar-inside-btn"
      icon="$delete_trash_can"
      @click="resetPhoto"
    />
    <CbrHint
      v-if="!!errors.avatar"
      :title="errors.avatar"
      error
      right
      custom-activator
    >
      <template #default="{ on }">
        <div class="avatar-load-error" v-on="on">
          <CbrIcon id="avatar-load-error" size="20">$information</CbrIcon>
        </div>
      </template>
    </CbrHint>
    <CbrButton
      v-if="user.id && (user.id === currentUserId || isSystemAdmin)"
      id="btn-drop-password"
      :text="$t('user_card_lbl_reset_pwd')"
      class="avatar-outside-btn"
      width="100%"
      size="small"
      @click="onRestorePassword"
    />
    <CbrDialog
      v-model="passwordRestoreSuccessDialog"
      :title="$t('user_card_popup_pwd_hdr_reset')"
      id="drop-password-popup"
      title-id="drop-password-popup-header"
      content-id="drop-password-popup-message"
    >
      <template v-slot:content>
        <div class="w100">
          <CbrHint top no-icon :description="user.email">
            <span class="email-text text-center text-ellipsis w100">{{ user.email }}</span>
          </CbrHint>
        </div>
        {{ $t('user_card_popup_pwd_txt_reset_sent') }}
      </template>
      <template v-slot:footer>
        <CbrButton
          id="drop-password-popup-button"
          :text="$t('org_panel_popup_admins_btn_confirm')"
          @click="passwordRestoreSuccessDialog = false"
        />
      </template>
    </CbrDialog>
  </div>
  <div v-else class="skeleton"></div>
</template>
<script setup>
import roles from '@/helpers/roles.helper'
import usersService from '@/services/user.service'
import accountService from '@/services/account.service'
import { i18n } from '@/i18n-setup.js'
import { useStateLoading } from '@/composables/useStateLoading'
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from '@/store'

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
    required: true
  },
  user: {
    type: Object,
    required: true
  },
  instant: {
    type: Boolean,
    default: true
  }
})
const { state, setStateLoaded, setStateLoading } = useStateLoading()
const ignoreEvents = ref(false)
const avatarPath = ref('')
const passwordRestoreSuccessDialog = ref(false)
const errors = ref({
  avatar: '',
  passwordRestore: '',
})
const noAvatar = ref(false)
const defaultAvatar = ref(require('@/assets/icons/svg/avatar2.svg'))
const avatarTmp = ref(null)
const avatar = ref()
const resetPhoto = async () => {
  avatar.value.value = ''
  setStub()
  if (props.user.id) {
    await usersService.deleteAvatar(props.user.id)
    store.commit('global/userUpdate', { id: props.user.id, avatar: false })
  }
}

const emit = defineEmits(['uploaded'])
const uploadAvatar = async (event) => {
  errors.value.avatar = ''
  const avatar = event.target.files[0]
  const allowedAvatarSize = 262144
  if (!avatar) {
    return
  }
  avatarPath.value = ''
  if (avatar.size > allowedAvatarSize) {
    errors.value.avatar = i18n.t('user_card_lbl_error_upload_smaller_photo')
    setStub()
    return
  } else if (!['image/jpeg', 'image/png'].includes(avatar.type)) {
    errors.value.avatar = i18n.t('user_card_lbl_error_photo_type')
    setStub()
    return
  }
  avatarTmp.value = avatar
  emit('uploaded')
  avatarPath.value = URL.createObjectURL(avatar)
  noAvatar.value = false
  if (props.instant) {
    await upload()
  }
}

const store = useStore()
defineExpose({ reset, upload })
async function upload (id = null) {
  try {
    if (noAvatar.value) return
    const formData = new FormData()
    const userId = id || props.user.id
    formData.append('image', avatarTmp.value)

    const res = await usersService.addAvatar(userId, formData)
    store.commit('global/userUpdate', { id: userId, avatar: true })
    if (res) {
      ignoreEvents.value = true
      if (!id) {
        avatarPath.value = defaultAvatarPath.value + '?' + Date.now()
      }
    }
  } catch (e) {
    errors.value.avatar = i18n.t('user_card_lbl_error_photo')
  } finally {
    ignoreEvents.value = false
  }
}
const setStub = () => {
  avatarPath.value = defaultAvatar.value
  noAvatar.value = true
  setStateLoaded()
}
const onRestorePassword = () => {
  errors.value.passwordRestore = ''
  accountService.forgotPassword(props.user.email)
    .then(() => {
      passwordRestoreSuccessDialog.value = true
    })
    .catch(() => {
      errors.value.passwordRestore = 'Произошла ошибка! Пароль не был сброшен'
    })
}
function reset () {
  errors.value = {
    avatar: '',
    passwordRestore: ''
  }
}
const loadAvatar = () => {
  avatarPath.value = ''
  avatarTmp.value = null
  setStateLoading()

  if (props.user.id) {
    usersService.getAvatar(props.user.id)
      .then(() => {
        avatarPath.value = `${defaultAvatarPath.value}?${Date.now()}`
        noAvatar.value = false
        setStateLoaded()
      })
      .catch(() => {
        setStub()
      })
  } else {
    setStub()
  }
}
watch(() => props.userUpdate, (data) => {
  if (data) {
    if (data.avatar && data.id === props.user.id && !ignoreEvents.value) {
      loadAvatar()
    }
    store.commit('global/userUpdate', null)
  }
})

watch(() => props.value, (value) => {
  if (value) {
    loadAvatar()
  }
})
const defaultAvatarPath = computed(() => {
  return `/api/user/${props.user.id}/image`
})
const userOrganization = computed(() => store.getters['account/userOrganization'])
const currentUserId = computed(() => store.getters['account/currentUserId'])
const isSystemAdmin = computed(() => store.getters['account/isSystemAdmin'])
const isAdmin = computed(() => store.getters['account/isAdmin'])
const profileEditingAvailable = computed(() => {
  return props.user.id === currentUserId.value || isSystemAdmin.value || (isAdmin.value &&
    (userOrganization.value.id === props.user.org?.id || userOrganization.value.id === props.user.organization?.id) &&
    [roles.USER.key, roles.OBSERVER.key].includes(props.user.role))
})
onMounted(() => {
  loadAvatar()
})
</script>

<style lang="scss" scoped>
.avatar {
  width: 258px;
  position: relative;
  &-borders {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/icons/svg/admin/avatar-borders.svg');
    z-index: 1;
  }
  &-img {
    background-color: rgba($base-color, 0.1) !important;
    clip-path: polygon(6px 0%, calc(100% - 6px) 0%, 100% 7px, 100% calc(100% - 6px),
      calc(100% - 6px) 100%, 6px 100%, 0% calc(100% - 6px), 0% 7px);
  }
  &-inside-btn {
    position: absolute !important;
    z-index: 2;
    top: 222px;
    right: -2px;
    ::v-deep .v-icon > path {
      fill: $base-color-dark;
    }
  }
  &-outside-btn {
    margin-top: 12px;
    position: relative;
    z-index: 2;
  }
  &-load-error {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute !important;
    top: 6px;
    left: 6px;
    z-index: 2;
    ::v-deep path {
      fill: $base-color-error;
    }
  }
  ::v-deep .v-image__image {
    background-size: contain;
  }
  .empty {
    ::v-deep .v-image__image {
      mix-blend-mode: screen;
    }
  }
}
.skeleton {
  width: 258px;
  min-height: 258px;
}
</style>
