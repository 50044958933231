<template>
  <div class="login-body" :class="{ container_eula: showTermOfUse }">
    <div v-if="!showTermOfUse" class="login-content">
      <div class="text-center login-content-logo">
        <MainLogo id="login-page-header" />
      </div>
      <v-row align="center" justify="center">
        <div class="text-center block-fixed">
          <CbrForm @submit.prevent lazy-validation ref="form" v-model="valid">
            <CbrTextField
                v-model="email"
                :error="!!isErrorState"
                id="login-page-email"
                @input="dropFailedState"
                :rules="loginRules"
                :maxlength="320"
                :placeholder="$t('user_authorization_edit_login_plh')"
                required
                class="mb-1"
                @keydown.enter="loginAttempt"
            />
            <CbrTextField
                :placeholder="$t('user_authorization_edit_password_plh')"
                id="login-page-password"
                v-model="password"
                password
                :rules="passwordRules"
                @input="dropFailedState"
                :error="!!isErrorState"
                required
                class="mb-1"
                @keydown.enter="loginAttempt"
            />
            <div class="accept_eula">
              <CbrCheckBox
                  :checked="acceptEula"
                  @change="acceptEula = !acceptEula"
                  id="user_authorization_chk_accept_eula"
              />
              <div class="accept_eula_content">
                <span class="accept_eula_title">{{ $t('user_authorization_chk_accept_eula') }}</span><br>
                <a
                    id="user_authorization_chk_accept_eula_link"
                    @click="showTermOfUse = true"
                    @keydown.space="showTermOfUse = true"
                    :tabindex="0" class="accept_eula_a"
                >
                  <span class="accept_eula_link">{{ $t('user_authorization_chk_accept_eula_link') }}</span>
                </a>
              </div>
            </div>
            <v-container style="margin-top: 80px">
              <v-row class="d-flex">
                <v-col class="pa-0 d-flex">
                  <CbrButton
                      id="login-page-btn-enter"
                      :text="
                      $t(isErrorState ? 'event_card_lbl_error' : 'user_authorization_btn_enter')
                    "
                      :disabled="isNotValid"
                      :error="!!isErrorState"
                      @click="loginAttempt()"
                  />
                </v-col>
                <v-col class="pa-0 align-self-center">
                  <a
                      id="login-page-forgot-password-link"
                      @click="showRestoreDialog = true"
                      @keydown.space="showRestoreDialog = true"
                      class="forgot_password_link"
                      :tabindex="0"
                  >
                    {{ $t('user_authorization_btn_forgot_password') }}
                  </a>
                </v-col>
              </v-row>
            </v-container>
            <div
                id="login-page-account-locked-error-pass login-page-login-error"
                class="text-center login-content-error"
            >
              {{ loginFailedError }}
            </div>
          </CbrForm>

          <CbrDialog
            v-model="showRestoreDialog"
            :title="$t('auth_popup_password_change_lbl_password_recovery')"
            :first-line-text="$t('auth_popup_password_change_txt_get_instruction')"
            max-width="640px"
          >
            <template #content>
              <CbrTextField
                v-model="emailForRestore"
                :error-messages="emailMessages"
                :maxlength="320"
                :placeholder="$t('user_card_popup_edit_input_email_plh')"
                class="w100"
                required
                @input="$v.emailForRestore.$touch()"
                @blur="$v.emailForRestore.$touch()"
              />
            </template>
            <template #footer>
              <CbrButton
                :text="$t('auth_popup_password_change_btn_cancel')"
                border
                @click="showRestoreDialog=false"
              />
              <CbrButton
                :text="$t('auth_popup_password_change_edit_next')"
                @click="onConfirmRestorePasswordClick"
              />
            </template>
          </CbrDialog>
          <CbrDialog
            v-model="showSuccessDialog"
            :title="$t('auth_popup_password_change_lbl_password_recovery')"
            max-width="740px"
          >
            <template #content>
              <span class="email-text">{{ emailForSuccess }}</span>
              <span>{{ $t('auth_popup_password_change_lbl_confirmation_letter') }}</span>
            </template>
            <template v-slot:footer="{close}">
              <CbrButton
                :text="$t('auth_change_pwd_btn_confirm')"
                @click="close"
              />
            </template>
          </CbrDialog>
        </div>
      </v-row>
    </div>
    <LoginFooter v-if="!showTermOfUse" :show-language="false"/>
    <TermsOfUseForm :isShow="showTermOfUse" @close="showTermOfUse = false" />
    <SelectLanguage class="login-select-language"/>
  </div>
</template>

<script setup>
import accountService from '@/services/account.service'
import ErrorsService from '@/services/errors'
import mainService from '@/services/main.service'
import moment from 'moment'
import { i18n } from '@/i18n-setup'
import main from '@/helpers/main.helper'
import { required } from 'vuelidate/lib/validators'
import SelectLanguage from '@/components/forms/SelectLanguage'
import MainLogo from '@/components/login/MainLogo'
import TermsOfUseForm from '@/views/TermsOfUse'
import LoginFooter from '@/views/LoginFooter'
import { computed, getCurrentInstance, ref, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { useRouter } from 'vue-router/composables'
import { useStore } from '@/store'

const vueCurrentInstance = getCurrentInstance().proxy
const router = useRouter()
const store = useStore()

const email = ref('')
const password = ref('')
const loginFailedError = ref('')
const emailRestoreError = ref('')
const acceptEula = ref(false)
const valid = ref(false)
const showTermOfUse = ref(false)
const showRestoreDialog = ref(false)
const showSuccessDialog = ref(false)
const emailForRestore = ref(null)
const emailForSuccess = ref(null)

const validations = computed(() => ({
  emailForRestore: {
    required
  }
}))
const v$ = useVuelidate(validations, { emailForRestore })

const loginRules = computed(() => {
  return [
    value => !!value || i18n.t('user_authorization_lbl_error_login_wrong')
  ]
})
const locale = computed(() => {
  return i18n.locale
})
const passwordRules = computed(() => {
  return [
    value => {
      password.value = password.value.slice(0, 254)
      return true
    },
    value => !!value || i18n.t('user_authorization_lbl_error_password_wrong')
  ]
})
const isNotValid = computed(() => {
  return !valid.value || !acceptEula.value || !password.value || !email.value
})
const isErrorState = computed(() => {
  return loginFailedError.value
})
const emailMessages = computed(() => {
  if (v$.value.emailForRestore.$dirty && !emailForRestore.value) {
    return [i18n.t('user_authorization_lbl_error_login_wrong')]
  }
  return emailRestoreError.value ? [emailRestoreError.value] : []
})

watch(() => locale.value, () => {
  vueCurrentInstance.$refs.form && vueCurrentInstance.$refs.form.resetValidation()
})
watch(() => showRestoreDialog.value, (value) => {
  if (!value) {
    emailForRestore.value = null
    v$.value.$reset()
  }
})

const dropFailedState = () => {
  loginFailedError.value = ''
}
const setUnknownError = () => {
  loginFailedError.value = ErrorsService.getErrorText({
    error: 'unknown_error',
    message: 'Unknown error'
  })
}
const loginAttempt = () => {
  if (isNotValid.value) {
    return
  }
  dropFailedState()

  accountService
    .login(email.value, password.value)
    .then(() => {
      store.dispatch('account/retrieveUser').then(async () => {
        localStorage.removeItem('logoutStatus')
        router.push('/')
        const firstMeasure = moment()
        const serverTime = await mainService.getServerTime()
        const timeToServer = Math.round(moment().diff(firstMeasure) / 2)
        const timeDiff = moment(serverTime || 0).diff(firstMeasure.add(timeToServer))
        localStorage.setItem('time-correction', timeDiff)
      })
    })
    .catch(error => {
      if (error.response) {
        if (error.response.data.type === '/problems/credentials-expired') {
          localStorage.setItem('usernameForPasswordChange', email.value)
          router.push('/password-expired')
          return
        }

        switch (error.response.status) {
          case 401:
            loginFailedError.value = error.response.data.detail
            password.value = ''
            break
          case 429:
          case 500:
            loginFailedError.value = ErrorsService.getErrorText(error.response.data)
            password.value = ''
            break
          default:
            setUnknownError()
        }
      } else {
        setUnknownError()
      }
    })
}
const onConfirmRestorePasswordClick = () => {
  emailRestoreError.value = ''
  v$.value.$touch()
  if (!v$.value.$invalid) {
    accountService
      .forgotPassword(emailForRestore.value)
      .then(() => {
        emailForSuccess.value = emailForRestore.value
        showRestoreDialog.value = false
        showSuccessDialog.value = true
      })
      .catch(e => {
        if (!e.response) {
          main.showNoConnectionErrorPage()
        } else if (e.response.data && e.response.data.violations) {
          emailRestoreError.value = e.response.data.violations.map(v => v.message).join('; ')
        }
      })
  }
}
</script>
<style lang="scss" scoped>
.block-fixed {
  width: 300px;
}
.container_eula {
  padding: 0 !important
}
.accept_eula {
  height: 24px;
  display: flex;
  ::v-deep .label-checkbox {
    width: auto !important;
  }
  &_content {
    line-height: 12px;
    text-align: start;
    margin-left: 15px;
    align-self: center;
  }
  &_title {
    color: rgba($StaticLight, 0.75);
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
  }
  &_a {
    color: rgba($ActionColor, 0.80);
  }
  &_link {
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    text-decoration-line: underline;
  }
  &_a:focus {
    color: $ActionColor;
  }
}
.forgot_password_link {
  color: rgba($ActionColor, 0.8);
  leading-trim: both;
  text-edge: cap;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  text-decoration-line: underline !important;
}
.forgot_password_link:focus {
  color: $ActionColor;
}

.login {
  &-body {
    height: 100%;
    width: 100%;
    position: relative;
    display: grid;
  }
  &-content {
    align-self: self-end;
    &-logo {
      padding-bottom: 81px;
    }
    &-error {
      height: 50px;
      min-height: 50px;
      width: 500px;
      margin-left: -100px;
      margin-top: 10px;

      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: center;
      color: $AlarmTextColor;
    }
  }
}
.login-select-language {
  position: absolute;
  top: 50px;
  right: 50px;
  justify-content: right;
  gap: 10px;
}
</style>
