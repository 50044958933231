<template>
  <v-data-table
      :headers="props.headers"
      :items="props.items"
      :server-items-length="props.serverItemsLength"
      :options.sync="options"
      :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 25, 50, 100]
        }"
      v-bind="$attrs"
      :loading-text="props.loadingText"
      :no-data-text="props.noDataText"
      :hide-default-header="hideDefaultHeader"
      hide-default-footer
      :disable-filtering="disableFiltering"
      :fixed-header="fixedHeader"
      @click:row="$emit('click:row', $event)"
  >
    <template v-slot:top="{ pagination, options, updateOptions }">
      <div class="cbr-top-panel" v-if="!Object.keys($attrs).includes('disable-pagination')">
        <slot name="topTitle" :pagination="pagination" />
        <CbrPagination
            :value="options.page"
            :length="Math.ceil(pagination.itemsLength / pagination.itemsPerPage)"
            :total-visible="7"
            @update="updateOptions({ page: $event })"
            :pagination="pagination"
            :options="options"
            :perPageOptions="[5, 10, 25, 50, 100]"
            @updateOptions="updateOptions({ itemsPerPage: $event })"
            class="ml-auto"
        />
      </div>
    </template>
    <template v-slot:header="data" v-if="hideDefaultHeader && !Object.keys($attrs).includes('disable-header')">
      <thead>
      <tr class="cbr-data-table__table-header">
        <th
            @click="
                sortData(
                  header.value,
                  !isItemSortDesc(header),
                  header.sortModifier,
                  header.sortable
                )
              "
            v-for="header in props.headers"
            :key="header.value"
            class="pointer"
            :width="header.width"
        >
          <div v-if="header.icon" class="cbr-data-table__btn-container">
            <CbrIcon>{{ header.icon }}</CbrIcon>
          </div>
          <span v-else class="cbr-data-table__th">
                <CbrSortPicker
                    v-if="header.sortable"
                    :is-desc="isItemSortDesc(header)"
                />
                {{ header.text }}
              </span>
        </th>
      </tr>
      </thead>
    </template>
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
    <template #no-data>
      <slot name="no-data"></slot>
    </template>
  </v-data-table>
</template>

<script setup>
import { watch } from 'vue'
import { useSavedPaging } from '@/composables/useSavedPaging'

const { paginationPageName, options } = useSavedPaging()

const props = defineProps({
  paginationName: {
    type: String,
    default: () => null
  },
  headers: {
    type: Array,
    default: () => []
  },
  items: {
    type: Array,
    default: () => []
  },
  serverItemsLength: {
    type: Number,
  },
  loadingText: {
    type: String,
  },
  noDataText: {
    type: String,
  },
  sort: {
    type: Object
  },
  disableFiltering: {
    type: Boolean,
    default: () => true
  },
  hideDefaultHeader: {
    type: Boolean,
    default: () => true
  },
  fixedHeader: {
    type: Boolean,
    default: () => true
  },
})
const emit = defineEmits(['sort', 'options'])

paginationPageName.value = props.paginationName

const sortData = (field, sortDesc, modifier = null, sortable) => {
  if (!sortable) return
  emit('sort', { field, sortDesc, modifier })
}

const isItemSortDesc = (item) => {
  return item.value === props.sort.field ? props.sort.sortDesc : null
}

watch(() => options.value, () => {
  emit('options', options.value)
}, { deep: true })
</script>

<style lang="scss" scoped>
.cbr-data-table {
  &__table-header {
    th {
      padding-left: 0 !important;
      background-color: transparent !important;
    }
  }
  &__th {
    display: flex;
    align-items: center;
    color: rgba($base-color, 0.8);
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
  &__btn-container {
    width: 24px;
    text-align: center;
  }
}
</style>
