<template>
  <v-form
    ref="formTemplateRef"
    :disabled="props.disabled"
    :lazy-validation="props.lazyValidation"
    :readonly="props.readonly"
    :value="props.value"
    @input="emit('input', $event)"
    @submit="emit('submit', $event)"
  >
    <template>
      <slot></slot>
    </template>
  </v-form>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  lazyValidation: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  value: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['submit', 'input'])

const formTemplateRef = ref()
function reset () {
  formTemplateRef.value.reset()
}
function resetValidation () {
  formTemplateRef.value.resetValidation()
}
function validate () {
  return formTemplateRef.value.validate()
}

defineExpose({
  reset,
  resetValidation,
  validate
})
</script>

<style scoped lang="scss">

</style>
