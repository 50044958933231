export const defaultSceneParams = {
  Notification: '',
  NotificationColor: '#FFFFFFFF',
  effect: []
}

export const defaultLineParams = {
  lineWidth: 4,
  color: '#FFFFFFFF',
  effect: []
}

export const defaultPlaneParams = {
  bgColor: '#FFFFFF1F',
  lineColor: '#FFFFFFFF',
  effect: []
}

export const defaultEssenceParams = {
  state: 'StateOn',
  icons: [],
  effect: [],
  gifEffects: []
}

export const defaultTextParams = {
  color: '#FFFFFFFF',
  textSize: 16,
  message: 'text...',
  effect: []
}

export const defaultSelectedColor = 'rgba(65, 199, 172, 1)'

export const defaultBlockedColor = 'rgb(243,203,0)'

export default {
  getTextStartAndEndCoordinates (text) {
    return {
      startX: text.x,
      endX: text.x + text.width * Math.cos(text.rotateDegree * Math.PI / 180),
      startY: text.y,
      endY: text.y + text.width * Math.sin(text.rotateDegree * Math.PI / 180)
    }
  },
  getLineStartAndEndCoordinates (line) {
    const xPoints = line.points?.map(point => point.x)
    const yPoints = line.points?.map(point => point.y)

    return xPoints ? {
      startX: Math.min(...xPoints),
      endX: Math.max(...xPoints),
      startY: Math.min(...yPoints),
      endY: Math.max(...yPoints)
    } : {}
  },
  getEssenceStartAndEndCoordinates (essence) {
    const essenceX = essence.x - essence.offsetX
    const essenceY = essence.y - essence.offsetY

    return {
      startX: essenceX,
      endX: essenceX + essence.width,
      startY: essenceY - essence.labelOffset - 10,
      endY: essenceY + essence.height
    }
  },
  getPlaneStartAndEndCoordinates (plane) {
    const xPoints = [plane.a.x, plane.b.x, plane.c.x, plane.d.x]
    const yPoints = [plane.a.y, plane.b.y, plane.c.y, plane.d.y]

    return {
      startX: Math.min(...xPoints),
      endX: Math.max(...xPoints),
      startY: Math.min(...yPoints),
      endY: Math.max(...yPoints)
    }
  },
  setStartAndEndCoordinates (data) {
    let startX = null
    let startY = null
    let endX = null
    let endY = null

    data.planes.forEach(plane => {
      const planeMaxAndMinCoordinates = this.getPlaneStartAndEndCoordinates(plane)
      if (startX === null || planeMaxAndMinCoordinates.startX < startX) {
        startX = planeMaxAndMinCoordinates.startX
      }
      if (startY === null || planeMaxAndMinCoordinates.startY < startY) {
        startY = planeMaxAndMinCoordinates.startY
      }
      if (endX === null || planeMaxAndMinCoordinates.endX > endX) {
        endX = planeMaxAndMinCoordinates.endX
      }
      if (endY === null || planeMaxAndMinCoordinates.endY > endY) {
        endY = planeMaxAndMinCoordinates.endY
      }
    })
    data.lines.forEach(line => {
      const lineStartAndEndCoordinates = this.getLineStartAndEndCoordinates(line)
      if (startX === null || lineStartAndEndCoordinates.startX < startX) {
        startX = lineStartAndEndCoordinates.startX
      }
      if (startY === null || lineStartAndEndCoordinates.startY < startY) {
        startY = lineStartAndEndCoordinates.startY
      }
      if (endX === null || lineStartAndEndCoordinates.endX > endX) {
        endX = lineStartAndEndCoordinates.endX
      }
      if (endY === null || lineStartAndEndCoordinates.endY > endY) {
        endY = lineStartAndEndCoordinates.endY
      }
    })
    data.essence.forEach(essence => {
      const essenceStartAndEndCoordinates = this.getEssenceStartAndEndCoordinates(essence)
      if (startX === null || essenceStartAndEndCoordinates.startX < startX) {
        startX = essenceStartAndEndCoordinates.startX
      }
      if (startY === null || essenceStartAndEndCoordinates.startY < startY) {
        startY = essenceStartAndEndCoordinates.startY
      }
      if (endX === null || essenceStartAndEndCoordinates.endX > endX) {
        endX = essenceStartAndEndCoordinates.endX
      }
      if (endY === null || essenceStartAndEndCoordinates.endY > endY) {
        endY = essenceStartAndEndCoordinates.endY
      }
    })
    // const textFields = [...data.textFields, data.scene]
    const textFields = data.textFields
    textFields.forEach(textField => {
      const textStartAndEndCoordinates = this.getTextStartAndEndCoordinates(textField)
      if (startX === null || textStartAndEndCoordinates.startX < startX) {
        startX = textField.x
      }
      if (startY === null || textStartAndEndCoordinates.startY < startY) {
        startY = textField.y
      }
      if (endX === null || textStartAndEndCoordinates.endX > endX) {
        endX = textField.x + textField.width
      }
      if (endY === null || textStartAndEndCoordinates.endY > endY) {
        endY = textField.y + textField.height
      }
    })

    return { startX, startY, endX, endY }
  },
  scaleAndCenterScene (player, paddingOffsetX = 100, paddingOffsetY = 100) {
    // If a map larger then canvas - scale it
    if (player.endX - player.startX > player.cvs.width || player.endY - player.startY > player.cvs.height) {
      // Calculate scaleRation depends on X or Y scene offset
      let scaleRatio
      if (player.endX - player.cvs.width > player.endY - player.cvs.height) {
        scaleRatio = (player.cvs.width - paddingOffsetX * 2) / (player.endX - player.startX)
      } else {
        scaleRatio = (player.cvs.height - paddingOffsetY * 2) / (player.endY - player.startY)
      }

      // Translate
      player.translateX = (player.cvs.width - (player.endX - player.startX) * scaleRatio) / 2
      player.translateY = (player.cvs.height - (player.endY - player.startY) * scaleRatio) / 2
      player.ctx.translate(player.translateX, player.translateY)
      if (player.scene) {
        player.scene.translateX = player.translateX
        player.scene.translateY = player.translateY
      }

      // Scale
      player.Zoom(scaleRatio)
    } else if (player.endX > player.cvs.width) { // If map smaller then canvas and have offset - move it to the center
      player.translateX = -player.startX + (player.cvs.width - (player.endX - player.startX)) / 2
      player.translateY = 0
      player.ctx.translate(player.translateX, player.translateY)
    }
  }
}
export const addSelectedCircle = (x, y, ctx, isBlocked = false) => {
  ctx.beginPath()
  ctx.arc(x, y, 10, 0, 2 * Math.PI)
  ctx.strokeStyle = isBlocked ? defaultBlockedColor : defaultSelectedColor
  ctx.stroke()
  ctx.closePath()
}

export const addSelectedPoint = (x, y, ctx, isBlocked = false) => {
  ctx.beginPath()
  ctx.arc(x, y, 5, 0, 2 * Math.PI)
  ctx.fillStyle = isBlocked ? defaultBlockedColor : defaultSelectedColor
  ctx.fill()
  ctx.closePath()
}

const sortPointsFromLine = (graphList, points) => {
  const result = []
  let index = 0 // started index
  let keys = Object.keys(points).map(key => Number(key))
  do {
    result.push(points[index])
    keys = keys.filter(key => key !== index)
    index = graphList[index][1].find(val => keys.includes(val))
  } while (index !== undefined)
  return result
}

export const addDraggerOnLine = (graphList, points, lineWidth, ctx) => {
  ctx.save()
  const dragger = lineWidth + 4
  ctx.lineWidth = dragger
  ctx.strokeStyle = defaultSelectedColor
  sortPointsFromLine(graphList, points).reduce((start, end) => {
    const deltaX = end.x - start.x
    const deltaY = end.y - start.y
    const len = Math.hypot(deltaX, deltaY)
    const interval = (len - dragger) / 2
    ctx.beginPath()
    ctx.setLineDash([0, interval, dragger, interval])
    ctx.moveTo(start.x, start.y)
    ctx.lineTo(end.x, end.y)
    ctx.stroke()
    return end
  })
  ctx.restore()
}
