import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import router from './router'
import store from './store'
import initPlugin from './plugins/init'
import vuetify from './plugins/vuetify/index'
import moment from './plugins/moment'
import helpers from './plugins/helpers'
import VueMeta from 'vue-meta'
import initComponent from './init'
import { i18n } from './i18n-setup'
import VueSSE from 'vue-sse'

Vue.use(initPlugin)
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(initComponent)
Vue.use(VueSSE)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  helpers,
  i18n,
  moment,
  vuetify,
  Vuelidate,
  render: h => h(App)
}).$mount('#app')
