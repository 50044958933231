<template>
  <v-breadcrumbs :items="breadCrumbs" divider=">">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact :class="{ 'non-underline': !item.to }">
        <CbrIcon v-if="item.to === '/'">$home</CbrIcon>
        <CbrHint
          v-else-if="isCompositeText(item)"
          :title="getCompositeTextForHint(item)"
          no-icon
        >
          <template>
            <span
              v-for="row in item.text"
              :key="row.text"
              :style="row.style"
              class="text-ellipsis"
            >
              {{ row.text }}&nbsp;
            </span>
          </template>
        </CbrHint>
        <CbrHint
          v-else
          :disabled="!item.ellipsis"
          :title="itemText(item)"
          no-icon
        >
          <span class="text-ellipsis">{{ itemText(item) }}</span>
        </CbrHint>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script setup>
import { computed, nextTick, onDeactivated, onMounted, ref, watch } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router/composables'
import { i18n } from '@/i18n-setup'

const store = useStore()
const route = useRoute()

const mutationObserver = ref(null)

const userRole = computed(() => store.getters['account/userRole'])
const breadCrumbs = computed(() => {
  const brdCrumbs =
    typeof route.meta?.breadCrumb === 'function'
      ? route.meta?.breadCrumb(route, store)
      : [...(route.meta?.breadCrumb || [])].filter(bc => !bc.role || bc.role.includes[userRole.value])
  if (brdCrumbs.length) {
    brdCrumbs.unshift({ to: '/' })
  }
  return brdCrumbs
})

const itemText = (item) => {
  if (item.customText) {
    return item.text
  }
  return i18n.t(item.text) + ' ' + (item.id ?? '')
}
const isCompositeText = (item) => {
  return Array.isArray(item.text)
}
const getCompositeTextForHint = (item) => {
  return item.text.join('')
}
const ellipsisItem = () => {
  nextTick(() => {
    const items = breadCrumbs.value.filter(bc => !!bc.ellipsis)
    const count = items.length
    const childIndexes = items.map((item) => breadCrumbs.value.indexOf(item) * 2)
    const childs = Array.from(document.getElementsByClassName('v-breadcrumbs')[0].children)
    if (!childs[0]) return
    const left = childs[0].getBoundingClientRect().left
    const tl = document.getElementById('event-timeline-container')
    const sum = childs.reduce((acc, item, index) => {
      if (childIndexes.includes(index)) return acc
      return acc + item.getBoundingClientRect().width
    }, 30)
    const maxWidthArr = []
    items.forEach(item => {
      const idx = breadCrumbs.value.indexOf(item) * 2
      if (tl) {
        maxWidthArr[idx] = `calc(${tl.getBoundingClientRect().left - left - sum}px / ${count})`
      } else {
        const right = document.getElementById('toolbar-right-panel').getBoundingClientRect().width
        maxWidthArr[idx] = `calc((100vw - ${right + sum + left}px) / ${count})`
      }
    })
    maxWidthArr.forEach((item, index) => childs[index].style['max-width'] = item)
  })
}

watch(() => breadCrumbs.value, () => ellipsisItem(), { deep: true })

onMounted(() => {
  ellipsisItem()
  mutationObserver.value = new MutationObserver(() => ellipsisItem())
  mutationObserver.value.observe(document.getElementById('html'), { childList: true, subtree: false })
})
onDeactivated(() => {
  mutationObserver.value.disconnect()
})
</script>

<style lang="scss" scoped>
.v-breadcrumbs {
  font-family: $primary-font-family;
  padding-left: 69px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  @include text-ellipsis;

  ::v-deep .v-breadcrumbs__item {
    color: $base-color;
    text-decoration: underline !important;
    font-size: 18px;
    line-height: 21px;

    &--disabled {
      color: $base-color-light;
      text-decoration: none !important;
    }
  }

  ::v-deep .non-underline .v-breadcrumbs__item {
    color: $base-color-light;
    text-decoration: none !important;
  }
  ::v-deep .v-breadcrumbs__divider {
    color: rgba($ActionColor, .5);
    font-size: 18px;
    line-height: 21px;
    padding: 0 11px;
  }
}

::v-deep .v-breadcrumbs__item {
  max-width: inherit;
  div {
    max-width: inherit;
  }
}

li:last-child {
  @include text-ellipsis;
  max-width: 100%;
}
</style>
