import store from '@/store'
export default class Icon {
  constructor (
    name,
    url
  ) {
    this.img = new Image()
    this.x = 0
    this.y = 0
    this.name = name
    this.img.src = url
    this.url = url
    this.load = false
    this.img.onload = () => {
      this.load = true
      this.width = this.img.width
      this.height = this.img.height
      this.id = store.getters.icons.find(({ name, image }) => this.name === name && this.url === image)?.id
    }
  }
}
