import { ref } from 'vue'

export const useStateLoading = (context) => {
  const state = ref({
    isLoading: true,
    isLoaded: false,
    isError: false,
    errorText: ''
  })
  const setStateLoading = () => {
    state.value.isLoading = true
    state.value.isLoaded = false
    state.value.isError = false
    state.value.errorText = ''
  }
  const setStateLoaded = () => {
    state.value.isLoading = false
    state.value.isLoaded = true
    state.value.isError = false
    state.value.errorText = ''
    if (context) context.emit('loaded') // вроде нигде не используется, оставил пока что
  }
  const setStateError = (errorText) => {
    state.value.isError = true
    state.value.isLoading = false
    state.value.isLoaded = true
    state.value.errorText = errorText
  }

  return { state, setStateLoading, setStateLoaded, setStateError }
}
