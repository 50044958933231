var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"cbr-checkbox-container cbr-checkbox-label",class:[
    {'cbr-checkbox-checked': _setup.props.checked},
    {'cbr-checkbox-not-checked': !_setup.props.checked},
    {'cbr-checkbox-disabled': _vm.disabled},
    _vm.size
  ]},[_c('div',{staticClass:"cbr-checkbox-border"},[_c('input',{attrs:{"type":"checkbox","disabled":_vm.disabled,"id":_vm.id,"tabindex":0},domProps:{"checked":_setup.props.checked},on:{"change":function($event){return _vm.$emit('change', $event)}}}),(_vm.notAll)?_c('span',{staticClass:"checkmark-not-all"}):_c('span',{staticClass:"checkmark"})]),(_vm.label)?_c('span',{staticClass:"label-value",class:{
      'label-value--filter': _vm.filter
    }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }