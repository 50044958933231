<template>
  <v-item-group
    v-if="props.items.length"
    :value="props.value"
    :style="{ width: props.width }"
    class="btn-group"
    :class="{ small: props.small, disabled: props.disabled }"
    :mandatory="props.mandatory"
    @change="$emit('change', $event)"
  >
    <v-item
      v-for="item in props.items"
      :key="item.value"
      :value="item.value"
      v-slot="{ active, toggle }"
    >
      <button @click="toggle" class="btn-toggle" :class="{ 'btn-toggle-active': active }" :style="btnStyle" :disabled="props.disabled">
        <CbrIcon v-if="item.icon" size="16" class="pr-2">
          {{ item.icon }}
        </CbrIcon>
        {{ item.title }}
      </button>
    </v-item>
  </v-item-group>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    default: () => [{
      title: '',
      value: '',
      icon: ''
    }]
  },
  value: [Number, String],
  width: String,
  small: Boolean,
  disabled: Boolean,
  mandatory: {
    type: Boolean,
    default: true
  }
})

const btnStyle = computed(() => {
  return !props.width ? {} : { width: `calc(${props.width} / ${props.items.length})` }
})
</script>

<style lang="scss" scoped>
$text-light: rgba($base-color-light, 0.5);
$text-dark: $base-color-dark;

.btn-group {
  @include cut-corners(9px, true, 9px, false, 1px, $base-color);
  display: inline-flex;
  align-items: center;
  padding: 0;
  height: 36px;
  border-width: 1px;
  font-family: $primary-font-family;
  font-size: 17px;
  font-weight: 400;

  .btn-toggle {
    height: 100%;
    color: $text-light;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    white-space: nowrap;
    border-left: 1px solid $base-color;
    padding: 0 24px;
    &:first-child {
      border-left: none;
    }
    i {
      color: $text-light !important;
      transition: none !important;
    }
    &-active {
      color: $text-dark;
      background: $base-color;
      i {
        color: $text-dark !important;
      }
    }
  }
}
.btn-group.small {
  height: 24px;
}
.btn-group.disabled {
  opacity: 0.5;
}
</style>
