import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messagesRu from './locales/ru.js'
import messagesEn from './locales/en.js'
import messagesUz from './locales/uz.js'
import axios from 'axios'

Vue.use(VueI18n)

const lang = window.localStorage.getItem('lang') || 'ru'

export const i18n = new VueI18n({
  locale: lang, // установка локализации
  fallbackLocale: lang,
  messages: {
    ru: messagesRu,
    en: messagesEn,
    uz: messagesUz
  },
  pluralizationRules: {
    ru: function (choice, choicesLength) {
      if (choice === 0) {
        return 0
      }
      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1
      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }
      return (choicesLength < 4) ? 2 : 3
    }
  }
})

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  return lang
}

export function loadLanguageAsync (lang) {
  setI18nLanguage(lang)
  // Если локализация та же
  // if (i18n.locale === lang) {
  //   return Promise.resolve(setI18nLanguage(lang))
  // }
  //
  // // Если локализация уже была загружена
  // if (loadedLanguages.includes(lang)) {
  //   return Promise.resolve(setI18nLanguage(lang))
  // }
  //
  // // Если локализация ещё не была загружена
  // return import(
  //   ).then(messages => {
  //   i18n.setLocaleMessage(lang, messages.default)
  //   loadedLanguages.push(lang)
  //   return setI18nLanguage(lang)
  // })
  // eslint-disable-next-line no-console
  console.log(lang)
}
