<template>
  <CbrDialog
    v-model="isOpen"
    :title="title"
    :id="id"
    :title-id="titleId"
    :max-width="maxWidth || '650px'"
    @cancel="emit('success')"
    @closeModal="emit('input', false)"
    header-uppercase
  >
    <span :id="contentId" slot="content" class="content">
       <template v-if="userName">
          <span>
          {{ userName }}
        </span>
        <br/>
        <span>
          {{ organisationName }}
        </span>
        <br/>
       </template>
        <div v-if="fullText" v-html="fullText"/>
        <span v-if="!fullText">
          {{firstLineText || i18n.t('event_creation_editing_popup_delete_event_txt_data_lost_first')}}
        </span>
        <span v-if="!fullText">
          {{secondLineText || i18n.t('event_creation_editing_popup_delete_event_txt_data_lost_next')}}
        </span>
        <div class="mt-3" v-if="descriptionLineText" >
          <br>{{ descriptionLineText }}
        </div>
    </span>
    <slot name="footer" slot="footer"></slot>
  </CbrDialog>
</template>
<script setup>
import { computed } from 'vue'
import { i18n } from '@/i18n-setup.js'

const emit = defineEmits(['success', 'input'])

const props = defineProps({
  title: String,
  firstLineText: String,
  secondLineText: String,
  value: Boolean,
  titleId: String,
  contentId: String,
  id: String,
  userName: String,
  organisationName: String,
  descriptionLineText: String,
  fullText: String,
  maxWidth: String
})

const isOpen = computed({
  get: () => props.value,
  set: (val) => {
    emit('input', val)
  }
})
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: $AlarmTextColor;
  line-height: 1.185em;
  text-align: center;
}
</style>
