export const scoreboardEn = {
  scoreboard_standalone: {
    lbl: {
      logo: 'Cyberworld',
      results_table: 'Results table',
      sorting_by_score: 'Sort by score',
      distribution_in_two_columns: 'Distribution in 2 columns',
      team: 'Team', // newIdLoc
      image: 'Image',
      drag_and_drop: 'or drag here',
      scores: 'Points',
      adjustment: 'Adjustment',
      total: 'Total'
    },
    btn: {
      add: 'Add',
      clear_all: 'Clear All',
      publish: 'Publish',
      download_in_Excel: 'Download .XLSX', // newIdLoc
      select_file: 'Select file'
    },
    link: {
      link_on_scoreboard: 'Link to scoreboard',
      update: 'Update link'
    },
    hint: {
      update_link: 'Update link',
      select_file: {
        incorrect_file_format: 'Incorrect format (select .png)',
        incorrect_file_size: 'The file must not exceed 50MB'
      },
      err_invalid: 'Value must be integer from {min} to {max}'
    },
    edit: {
      event_name: 'Type event name' // newIdLoc
    },
    txt: {
      no_data: 'Add teams to display in the scoreboard' // newIdLoc
    }
  },
  scoreboard_standalone_publish_result: {
    lbl: {
      question: 'Are you sure you want to publish the results?',
    },
    btn: {
      cancel: 'Cancel', // newIdLoc
      publish: 'Publish' // newIdLoc
    },
    hdr: {
      header: 'Publish results',
    },
  },
  scoreboard_standalone_delete_team: {
    lbl: {
      question: 'Are you sure you want to delete the command?',
    },
    btn: {
      cancel: 'Cancel', // newIdLoc
      delete: 'Delete' // newIdLoc
    },
    hdr: {
      header: 'Remove team',
    },
  },
  scoreboard_standalone_clear_data: {
    lbl: {
      question: 'Are you sure you want to clear all data?',
    },
    btn: {
      cancel: 'Cancel', // newIdLoc
      clear: 'Clear' // newIdLoc
    },
    hdr: {
      header: 'Clear data',
    },
  },
  scoreboard_standalone_update_link: {
    lbl: {
      question: 'Are you sure you want to update the scoreboard link?',
    },
    btn: {
      cancel: 'Cancel', // newIdLoc
      refresh: 'Refresh' // newIdLoc
    },
    hdr: {
      header: 'Update Link',
    },
  },
}
