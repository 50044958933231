const lodashClonedeep = require('lodash.clonedeep')

export default {
  isObject (object) {
    return typeof object === 'object' && object !== null && !Array.isArray(object)
  },
  updateFieldsById (object, values) {
    return object.map(objectItem => {
      if (objectItem.id === values.id) {
        for (const item in values) {
          objectItem[item] = values[item]
        }
      }

      return objectItem
    })
  },
  merge (object, values) {
    for (const item in values) {
      object[item] = values[item]
    }
  },
  compareIfExists (main, comparable, excluded = []) {
    let result = true
    const comparableCopy = lodashClonedeep(comparable)
    if (excluded?.length) {
      excluded.forEach(key => {
        delete comparableCopy[key]
      })
    }
    for (const field in comparableCopy) {
      if (!result) {
        return false
      }
      if (main[field] !== undefined) {
        switch (typeof main[field]) {
          case 'object':
            if (Array.isArray(main[field])) {
              if (main[field].length !== comparableCopy[field].length) {
                result = false
              } else {
                main[field].forEach(item => {
                  const foundItem = comparableCopy[field].find(cItem => cItem.id === item.id)
                  if (!foundItem) {
                    result = false
                  } else {
                    Object.keys(item).forEach(fieldName => {
                      if (typeof item[fieldName] !== 'object' && item[fieldName] !== foundItem[fieldName]) {
                        result = false
                      }
                    })
                  }
                })
              }
            } else {
              if (!main[field] && !comparableCopy[field]) {
                result = true
              } else if (main[field] && comparableCopy[field]) {
                result = main[field].id === comparableCopy[field].id
              } else {
                result = false
              }
            }
            break
          default:
            result = main[field] === comparableCopy[field]
            break
        }
      }
    }

    return result
  }
}
