import { ref } from 'vue'

export const useFilesDragAndDrop = () => {
  const elementId = `cbrFilesBlock${Date.now()}`
  const cbrFilesBlockTemplateRef = ref()

  const isDraggingOver = ref(false)

  function onDragEnter () {
    isDraggingOver.value = true
  }

  function onDragEnd () {
    isDraggingOver.value = false
  }

  function onDragOver () {
    isDraggingOver.value = true
  }

  const fileInputTemplateRef = ref()
  function onDrop (e) {
    isDraggingOver.value = false
    fileInputTemplateRef.value.uploadFile(e)
  }

  function onDragLeave (e) {
    if (isDragLeaveInside(e)) {
      return
    }

    isDraggingOver.value = false
  }

  function isDragLeaveInside (e) {
    const isItself = e.relatedTarget?.id === elementId

    const isChild = cbrFilesBlockTemplateRef.value.contains(e.relatedTarget)

    return isItself || isChild
  }

  return {
    elementId,
    cbrFilesBlockTemplateRef,
    fileInputTemplateRef,
    isDraggingOver,
    onDragEnter,
    onDragLeave,
    onDragEnd,
    onDragOver,
    onDrop
  }
}
