<template>
  <div
    class="cbr-expansion-panel-base"
    :class="{
      'small-block': small
    }"
    :style="{ width: width ? width : '100%' }"
    :id="id"
  >
    <div class="cbr-expansion-panel-base__header">
      <div
        class="cbr-expansion-panel-base__trigger"
        :class="{
          'cbr-expansion-panel-base__trigger--small': small
        }"
        @click="toggleOpen"
      >
        <Icon
          class="cbr-expansion-panel-base__trigger-icon"
          :class="getTriggerIconClass"
          icon-name="uikit/base_expand_arrow"
          :width="18"
          :height="18"
        />
      </div>

      <p v-if="label" class="cbr-expansion-panel-base__label" @click="onLabelClick">{{ label }}</p>
      <div v-else class="cbr-expansion-panel-base__label" @click="onLabelClick">
        <slot name="labelContent" v-bind="{ toggleOpen }"/>
      </div>

      <div class="cbr-expansion-panel-base__header-right-slot">
        <slot name="headerRight"/>
      </div>

    </div>
    <v-expand-transition>
      <div
        v-if="isOpen"
      >
        <div
          class="cbr-expansion-panel-base__content"
          :style="{
            padding: contentPadding
          }"
        >
          <slot/>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'

const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: null
  },
  small: Boolean,
  icon: String,
  width: String,
  itemLength: Number,
  contentPadding: {
    type: String,
    default: '24px 62px'
  },
  openOnArrowClickOnly: {
    type: Boolean,
    default: false
  },
  id: String
})
const emit = defineEmits(['input'])

const isOpen = ref(props.value)
watch(() => props.value, (newValue) => {
  isOpen.value = newValue
})
function onLabelClick () {
  if (!props.openOnArrowClickOnly) {
    toggleOpen()
  }
}
const toggleOpen = () => {
  isOpen.value = !isOpen.value
  emit('input', isOpen.value)
}

const getTriggerIconClass = computed(() => {
  const baseClass = 'cbr-expansion-panel-base__trigger-icon'
  return {
    [`${baseClass}--opened`]: isOpen.value
  }
})

const open = () => {
  isOpen.value = true
  emit('input', isOpen.value)
}
defineExpose({
  open
})
</script>

<style lang="scss" scoped>
.cbr-expansion-panel-base {
  clip-path: polygon(
      0 10px,
      0 100%,
      100% 100%,
      100% 0,
      10px 0,
  );

  &__header {
    display: flex;
    align-items: center;
    background: rgba(217, 217, 217, 0.15);
    min-height: 52px;
    cursor: pointer !important;

    &:hover {
      background: rgba(217, 217, 217, 0.25);
    }
  }

  &__header-right-slot {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 6px;
  }

  &__trigger {
    width: 52px;
    min-width: 52px;
    height: 52px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba($StaticLight, .1);
    &--small {
      width: 36px;
      height: 36px;
    }
  }

  &__trigger-icon {
    transition: transform 0.25s ease-out;

    &--opened {
      transform: rotate(90deg);
    }

    ::v-deep path {
      fill: none;
    }
  }

  &__label {
    width: 100%;
    margin-left: 16px;
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: $base-color-text;
  }

  &__content {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(12.5px);
  }
}
.small-block {
  .cbr-expansion-panel-base__header {
    height: 36px;
    min-height: 36px;
    .cbr-expansion-panel-base__label {
      font-size: 20px;
      width: 100%;
      margin-right: 6px;
    }
  }
}
</style>
