<template>
  <div class="cbr-paginaton">
    <v-data-footer
      class="cbr-paginaton__per-page"
      :pagination="pagination"
      :options="options"
      @update:options="updateOptions"
      :items-per-page-options="perPageOptions"
    />
    <v-pagination
      v-model="currentPage"
      :length="length"
      :total-visible="totalVisible"
      @input="updatePage"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, nextTick, ref, onUnmounted } from 'vue'

const props = defineProps({
  length: Number,
  totalVisible: {
    type: Number,
    default: 7
  },
  value: Number,
  pagination: Object,
  options: Object,
  perPageOptions: Array
})
const emit = defineEmits(['update', 'updateOptions'])

const mutationObserver = ref(null)
const nodes = ref([])

const currentPage = computed(() => props.value)

const updatePage = (page) => {
  currentPage.value = page
  emit('update', page)
}

const updateOptions = (currentPaginate) => {
  emit('updateOptions', currentPaginate.itemsPerPage)
}

const styleDropDown = () => {
  Array.from(document.getElementsByClassName('cbr-paginaton__per-page'))
    .forEach(pagination => {
      Array.from(pagination.getElementsByClassName('v-input__slot'))
        .forEach(input => {
          const id = input.getAttribute('aria-owns')
          const listbox = document.getElementById(id)
          if (listbox) {
            nextTick(() => {
              listbox.classList.add('cbr-paginaton__per-page-list')
              if (!listbox.parentNode.classList.contains('cbr-paginaton__per-page-list-div')) {
                listbox.parentNode.style['min-width'] = `${input.getBoundingClientRect().width + 7}px`
                listbox.parentNode.style.width = listbox.parentNode.style['min-width']
                listbox.parentNode.classList.add('cbr-paginaton__per-page-list-div')
              }
              if (!nodes.value.includes(id)) {
                nodes.value.push(id)
                mutationObserver.value.observe(listbox.parentNode, { attributes: true })
              }
            })
          }
        })
    })
}

onMounted(() => {
  nextTick(() => {
    mutationObserver.value = new MutationObserver(() => styleDropDown())
    mutationObserver.value.observe(document.getElementById('html'), { childList: true, subtree: true })
  })
})

onUnmounted(() => mutationObserver.value.disconnect())
</script>

<style lang="scss" scoped>
.cbr-paginaton {
  display: flex;
  align-items: center;
  &__per-page {
    margin-right: 28px;

    ::v-deep .v-input__icon--append {
      background: url('../../assets/icons/svg/uikit/menu_open.svg');
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: center;
      i {
        display: none;
      }
    }
    ::v-deep .v-select.v-select--is-menu-active {
      .v-input__icon--append {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
}
::v-deep .v-pagination {
  @include cut-corners(5px, false, 5px, true, 1px, $base-color);
  display: flex;
  padding: 0;
}
::v-deep .v-pagination__item {
  margin: 0;
  border-radius: 0;
  height: 36px;
  min-width: 36px;
  background: $base-color-dark !important;
  color: $base-color !important;
  -webkit-box-shadow: none;
}
::v-deep .v-pagination__navigation {
  margin: 0;
  border-radius: 0;
  height: 36px;
  width: 36px;
  background: $base-color-dark !important;
  color: $base-color !important;
}
::v-deep .v-pagination__navigation[aria-label="Предыдущая страница"], ::v-deep .v-pagination__navigation[aria-label="Previous page"] {
  background-image: url('../../assets/icons/svg/uikit/menu_open_triangle.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  i {
    display: none;
  }
}
::v-deep .v-pagination__navigation[aria-label="Следующая страница"], ::v-deep .v-pagination__navigation[aria-label="Next page"] {
  background-image: url('../../assets/icons/svg/uikit/menu_open_triangle.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  transform: rotate(180deg);
  i {
    display: none;
  }
}
::v-deep .v-pagination__more {
  margin: 0;
  padding: 0;
  height: 36px;
  width: 36px;
  background: $base-color-dark !important;
  color: $base-color !important;
}
::v-deep .v-pagination {
  li:first-child {
    border-right: 1px solid $base-color;
  }
  li:last-child {
    border-left: 1px solid $base-color;
  }
  .v-icon {
    color: $base-color;
  }
}
::v-deep .v-pagination__item.v-pagination__item--active {
  color: $base-color-dark !important;
  background: $base-color !important;
}

::v-deep .v-data-footer {
  border-top: none !important;
}
::v-deep .v-data-footer__pagination {
  display: none;
}
::v-deep .v-data-footer__icons-before {
  display: none;
}
::v-deep .v-data-footer__icons-after {
  display: none;
}
::v-deep .v-input__control{
  @include cut-corners(0, true, 5px, true, 1px, $base-color);
  background: $base-color-dark !important;
}
::v-deep .v-select__selection {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $base-color-text !important;
  width: 64px;
  height: 33px;
  border-right: 1px solid $base-color;
  border-bottom: none;
  margin: 0;
  font-size: 16px !important;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}
::v-deep .v-data-footer__select {
  font-size: 14px;
  color: $base-color-text;
  .v-select {
    margin: 0px;
    margin-left: 23px;
  }
}
::v-deep .v-icon {
  color: $base-color !important;
}
</style>
