export class SSEService {
  #connectionsMap = {}

  subscribeToEvents (url, cb) {
    if (this.#connectionsMap[url]) {
      this.unsubscribeFromEvents(url)
    }

    this.#connectionsMap[url] = new EventSource(url)
    this.#connectionsMap[url].onmessage = (msg) => {
      if (msg) {
        cb(msg.data)
      }
    }
    this.#connectionsMap[url].onerror = (err) => console.error('Failed to parse or lost connection:', err)
  }

  unsubscribeFromEvents (url) {
    if (this.#connectionsMap[url]) {
      this.#connectionsMap[url].close()
      delete this.#connectionsMap[url]
    }
  }
}
