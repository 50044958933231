export class MessageBusService {
  #messageBusClient

  constructor (messageBusClient) {
    this.#messageBusClient = messageBusClient
  }

  subscribeToEvents (url, cb, saveMessageMode, filterOnSave) {
    return this.#messageBusClient.subscribeToEvents(url, cb, saveMessageMode, filterOnSave)
  }

  unsubscribeFromEvents (url) {
    return this.#messageBusClient.unsubscribeFromEvents(url)
  }

  subscribeToServiceWorkerDisconnect (cb) {
    try {
      this.#messageBusClient.subscribeToServiceWorkerDisconnect(cb)
    } catch (e) {
      throw Error('not implemented')
    }
  }

  subscribeToServiceWorkerReconnect (cb) {
    try {
      this.#messageBusClient.subscribeToServiceWorkerReconnect(cb)
    } catch (e) {
      throw Error('not implemented')
    }
  }
}
