const mutations = {
  loadComp (state, payload) {
    state.components = payload
  },
  newComp (state, payload) {
    state.components.push(payload)
  },
  loadIcons (state, payload) {
    state.icons = payload
  },
  newIcon (state, payload) {
    state.icons.push(payload)
  },
  loadGifEffects (state, payload) {
    state.gifEffectsData = payload
    state.gifEffectsData.forEach(el => {
      state.gifEffects.push(el.name)
    })
  },
  newGifEffects (state, payload) {
    state.gifEffects.push(payload.name)
    state.gifEffectsData.push(payload)
  },
  selected (state, payload) {
    state.selected = payload
  },
  tools (state, payload) {
    state.tools = payload
  },
  switchToolsMenu (state, payload) {
    state.tools.menu = payload
  },
  players (state, payload) {
    state.players = [...state.players, payload]
  },
  removeAddTriggersPlayer (state) {
    state.players = state.players.filter(i => !i.cvs.classList.contains('modal'))
  },
  removePlayer (state) {
    state.players = []
  },
  baseItems (state, payload) {
    state.baseItems.push(payload)
  },
  removeBaseItem (state, baseId) {
    const ind = state.baseItems.findIndex(i => i.baseId === baseId)
    state.baseItems = state.baseItems.filter((i, index) => index !== ind)
  },
  base (state, payload) {
    state.base = payload
  },
  setTriggersDialogShown (state, triggersDialogShown) {
    state.triggers.dialogShown = triggersDialogShown
  },
  setSelectedAll (state, data) {
    data.essence.forEach(i => i.selected = true)
    data.lines.forEach(i => i.selected = true)
    data.textFields.forEach(i => i.selected = true)
    data.planes.forEach(i => i.selected = true)
    state.selectedAll = data
  },
  clearSelection (state) {
    state.selectedAll.essence.forEach(i => i.selected = false)
    state.selectedAll.lines.forEach(i => i.selected = false)
    state.selectedAll.textFields.forEach(i => i.selected = false)
    state.selectedAll.planes.forEach(i => i.selected = false)
    state.selectedAll = {
      essence: [],
      lines: [],
      planes: [],
      textFields: [],
    }
  },
  addToSelection (state, payload) {
    payload.item.selected = true
    state.selectedAll[payload.type] = [...state.selectedAll[payload.type], payload.item]
  },
  removeFromSelection (state, payload) {
    payload.item.selected = false
    state.selectedAll[payload.type] = state.selectedAll[payload.type].filter(i => i !== payload.item)
  },
  setIsConfigOpened (state, payload) {
    state.isConfigOpened = payload
  },
  setShowContextMenu (state, payload) {
    state.showContextMenu = payload
  },
  setCopyTriggers (state, payload) {
    state.copiesTriggers = payload
  },
  setCopyButton (state, payload) {
    state.copy = payload
  },
  setSelectedPoint (state, point) {
    state.selectedPoint = point
  },
  setPasteButton (state, payload) {
    state.paste = payload
  },
  setCopyObjects (state, payload) {
    state.copyObjects = payload
  },
  setClickCoords (state, payload) {
    state.clickCoords = payload
  },
  setElemTarget (state, payload) {
    state.elemTarget = payload
  },
  setBlockElements (state, payload) {
    state.block = payload
  },
  setDefaultBase (state) {
    state.base = { ...state.defaultBase }
  },
  setDeleteElements (state, payload) {
    state.delBtn = payload
  },
  changeAnimationDirection (state, newDirection) {
    state.selected.animationDirectionFromLeft = newDirection
    state.selected.animationCurrent = []
    state.selected.animationVisited = []
  },
  setDefaultStateItems (state, payload) {
    state.defaultStateItems[payload.type] = [...state.defaultStateItems[payload.type], payload.item]
  },
  clearDefaultStateItems (state) {
    state.defaultStateItems = {}
  },
  setPlayerGame (state, payload) {
    state.playerGame = payload
  },
  setSwitchBaseObj (state, payload) {
    state.switchBaseObj = payload
  },
  setDoubleIds (state, payload) {
    state.doubleIds = payload
  },
  clearBase (state) {
    state.baseItems = []
    state.base = { ...state.defaultBase }
  }
}

export default mutations
