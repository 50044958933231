import Vue from 'vue'

const icons = {
  namespaced: true,
  state: {
    icons: {},
    loadingIcons: {}
  },
  mutations: {
    setIcon (state, { path, content }) {
      Vue.set(state.icons, path, content)
      Vue.delete(state.loadingIcons, path)
    },
    setLoading (state, path) {
      Vue.set(state.loadingIcons, path, true)
    },
    clearLoading (state, path) {
      Vue.delete(state.loadingIcons, path)
    }
  },
  actions: {
    async loadIcon ({ commit, state }, path) {
      if (!state.icons[path] && !state.loadingIcons[path]) {
        commit('setLoading', path)
        try {
          const response = await fetch(path)
          const content = await response.text()
          await commit('setIcon', { path, content })
          await commit('clearLoading', path)
        } catch (error) {
          commit('clearLoading', path)
          throw error
        }
      }
    }
  },
  getters: {
    getIcons: (state) => state.icons,
    isLoading: (state) => (path) => {
      return !!state.loadingIcons[path]
    }
  }
}

export default icons
