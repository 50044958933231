export const momentLocale = {
  monthsShort: [
    'янв.', 'фев.', 'мар.', 'апр.', 'май', 'июн.', 'июл.', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'
  ]
}

export const commonRu = {
  // org type
  common_org_type_internal: 'Внутренняя',
  common_org_type_external: '',
  // event type name
  common_event_type_defence: 'Защита',
  common_event_type_ctf: 'Захват флага',
  common_event_type_rvb: 'Противостояние',
  // event type description
  common_event_type_desc_defence: 'Практические и контрольные занятия по обнаружению, расследованию и защите от КА. Система автоматически атакует инфраструктуру по выбранному сценарию.',
  common_event_type_desc_ctf: 'Соревнование, в ходе которого команды должны проникнуть в инфраструктуру противников и разместить флаг, при этом защищая свою инфраструктуру от проникновения.',
  common_event_type_desc_rvb: 'Команды должны защищать свою инфраструктуру и атаковать инфраструктуру противников с целью вызвать отказ в обслуживании критических сервисов.',
  // switch on/off
  common_switch_on: 'Вкл',
  common_switch_off: 'Выкл',
  // error hint title
  common_error_title: 'Ошибка!',
  // button text
  common_btn_cancel: 'Отменить',
  common_btn_delete: 'Удалить',
}
