import ErrorsService from '@/services/errors'
import Spinner from 'vue-spinner/src/ClipLoader'

ErrorsService.init()

export default {
  install (Vue) {
    Vue.component('Spinner', Spinner)
  }
}
