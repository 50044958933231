import store from '../store'

const users = {
  getFullName (user) {
    return `${user.lastName} ${user.firstName} ${user.middleName}`.trim()
  },
  setEventFullName (event) {
    if (!event) return
    event.teams[0].participants && event.teams[0].participants.forEach(participant => participant.fullName = this.getFullName(participant))
    if (event.observers) {
      event.observers.forEach(observer => observer.fullName = this.getFullName(observer))
    }
    if (event.teacher) {
      event.teacher.fullName = this.getFullName(event.teacher)
    }
  },
  getFirstLastName (user) {
    return `${user.lastName} ${user.firstName}`
  },
  getNameWithInitials (user) {
    return `${user.lastName} ${user.firstName.charAt(0)}${!user.firstName ? '' : '.'} ${user.middleName.charAt(0)}${!user.middleName ? '' : '.'}`.trim()
  },
  showProfile (user) {
    store.commit('global/userProfileShow', user)
  },
  showCreateEditForm (user) {
    store.commit('global/showCreateEditUserForm', user)
  },
  isValidEmailLite (email) {
    return /^(?=.{3,255}$)([^\s]{1,64}@[^\s@]+)$/.test(email)
  },
  isValidEmail (email) {
    if (!email) return false
    const localPart = email.substr(0, email.indexOf('@'))
    if (localPart.length === 0 || localPart.length > 64) return false
    // eslint-disable-next-line no-control-regex
    const regexp = /^(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
    return regexp.test(email.toLowerCase())
  }
}

export default users
