import store from '@/store'
import GifEffect from './gif-effect'
import { defaultEssenceParams, defaultSelectedColor, defaultBlockedColor } from '@/helpers/map.helper.js'

export default class Essence {
  constructor (x, y, name, ctx, cvs, id) {
    this.defaultState = {}
    this.ctx = ctx
    this.cvs = cvs
    this.label = ''
    this.selected = false
    this.labelOffset = 20
    this.id = store.state.map.players.find(i => i.cvs.id === 'player')?.AddId('vm', id ? +id.slice(2) : 0)
    this.x = Math.round(x)
    this.y = Math.round(y)
    this.load = false
    this.img = new Image()
    this.timer = 0
    this.progress = 0
    this.name = name
    this.designation = 'essence'
    this.state = defaultEssenceParams.state
    this.effect = []
    this.gifEffects = []
    this.offsetX = 0
    this.offsetY = 0
    this.centerPointOffsetX = 0
    this.centerPointOffsetY = 0
    this.triggers = []
    this.scene = false
    this.components = store.state.map.components
    this.icons = []
    this.hide = false
    this.imgs = []
    this.isBlocked = false
    this.img.onload = function () {
      this.load = true
    }
    for (const i in this.components) {
      if (this.name === this.components[i].name) {
        this.stateOnUrl = this.components[i].stateOn
        this.stateOffUrl = this.components[i].stateOff
        this.stateWarningUrl = this.components[i].warning
        this.stateCriticalUrl = this.components[i].critical
        this.img.src = this.stateOnUrl
        this.centerPointOffsetX = this.components[i].centerPointOffsetX
        this.centerPointOffsetY = this.components[i].centerPointOffsetY
      }
    }
    this.Preload()
    this.Draw()
    this.UpdateGifEffects()
  }

  UpdateGifEffects () {
    for (var i in store.state.map.gifEffects) {
      for (var j in this.effect) {
        if (store.state.map.gifEffects[i] === this.effect[j]) {
          const essenceState = {
            x: this.x,
            y: this.y,
            name: this.name
          }
          const effectItem = new GifEffect(this.effect[j], essenceState, this.cvs)
          this.gifEffects.push(effectItem)
        }
      }
    }
  }

  Draw () {
    this.width = this.img.width
    this.height = this.img.height
    if (!this.hide && this.width > 0 && this.height > 0) {
      this.ctx.drawImage(this.img, this.x - this.offsetX, this.y - this.offsetY)
    }
    const uniq = []
    for (const str of this.effect) {
      if (!uniq.includes(str)) {
        uniq.push(str)
      }
    }
    this.effect = uniq
    for (const i in store.state.map.states) {
      if (store.state.map.states[i] === this.state) {
        this[store.state.map.states[i]]()
      }
    }
    for (const i in store.state.map.effects) {
      for (var j in this.effect) {
        if (store.state.map.effects[i] === this.effect[j]) {
          this[store.state.map.effects[i]]()
        }
      }
    }

    // гиф эффекты
    for (const i in this.effect) {
      if ((store.getters.gifEffects.indexOf(this.effect[i])) !== -1) {
        let isEffectExists = false
        for (const j in this.gifEffects) {
          if (this.gifEffects[j].name === this.effect[i]) {
            isEffectExists = true
            this.gifEffects[j].essenceCenterX = this.offsetX
            this.gifEffects[j].essenceCenterY = this.offsetY
            this.gifEffects[j].Draw(this.x, this.y)
            this.hide = this.gifEffects[j].hideComponent
          }
        }
        if (!isEffectExists) {
          const essenceState = {
            x: this.x,
            y: this.y,
            name: this.name,
            centerX: this.offsetX,
            centerY: this.offsetY
          }
          const effectItem = new GifEffect(this.effect[i], essenceState, this.cvs)
          this.gifEffects.push(effectItem)
        }
      }
    }
    for (const i in this.gifEffects) {
      if (
        (store.getters.gifEffects.indexOf(this.gifEffects[i].name)) !== -1 &&
        this.effect.indexOf(this.gifEffects[i].name) === -1
      ) {
        if (this.gifEffects[i].hideComponent) {
          this.hide = false
        }
        this.gifEffects[i].ResetEffect()
        this.hide = false
        const index = this.gifEffects.indexOf(this.gifEffects[i])
        this.gifEffects.splice(index, 1)
      }
    }
    // конец гиф

    if (this.selected) {
      this.Selected()
    }
    for (const i in this.icons) {
      this.icons[i].parentX = this.x
      this.icons[i].parentY = this.y
      this.ctx.drawImage(this.icons[i].img, this.icons[i].x + (this.x - this.offsetX), this.icons[i].y + (this.y - this.offsetY))
    }
    this.ctx.beginPath()
    this.ctx.fillStyle = 'white'
    this.ctx.font = '12px Rubik'
    this.ctx.fillText(this.label, this.x - this.offsetX + this.width / 2, this.y - this.offsetY - this.labelOffset)
    this.ctx.closePath()
  }

  AttackDown () {
    var grad = this.ctx.createLinearGradient(
      this.x - this.offsetY - this.width,
      this.y - this.offsetY + this.height,
      this.x - this.offsetX - this.width / 2,
      this.y - this.offsetY - this.height / 2
    )
    grad.addColorStop(0, 'white')
    grad.addColorStop(0.7, 'rgba(255, 255, 255, 0)')

    this.ctx.beginPath()
    if (this.width > this.height) {
      this.ctx.arc(this.x - this.offsetX + this.width / 2, this.y - this.offsetY + this.height / 2, this.width * 0.7, 0, Math.PI + (Math.PI * 2) / 2, false)
    } else {
      this.ctx.arc(this.x - this.offsetX + this.width / 2, this.y - this.offsetY + this.height / 2, this.height * 0.7, 0, Math.PI + (Math.PI * 2) / 2, false)
    }
    this.ctx.fillStyle = grad
    this.ctx.fill()
    this.ctx.closePath()
  }

  Progressbar () {
    var lineWidth = 1; var RectWidth = 10; var RectHeight = 100; var rectProgH = 0.4
    this.ctx.beginPath()
    this.ctx.rect(this.x - this.offsetX + this.width + 10, this.y - this.offsetY, RectWidth, RectHeight)
    this.ctx.strokeStyle = '#0A0A1F'
    this.ctx.fillStyle = '#535376'

    this.ctx.lineWidth = lineWidth
    this.ctx.stroke()
    this.ctx.fill()
    this.ctx.closePath()
    this.ctx.beginPath()
    this.progress += 0.05
    var sin = ((RectHeight - (RectHeight * rectProgH)) * Math.sin(this.progress))
    if (sin < 0) {
      sin = sin * (-1)
    }
    this.ctx.rect((this.x - this.offsetX + this.width + 10) + lineWidth / 2, this.y - this.offsetY - lineWidth / 2 + sin + (RectHeight * rectProgH), RectWidth - lineWidth, -((RectHeight * rectProgH) - lineWidth))
  }

  ProgressbarYellow () {
    this.Progressbar()
    this.ctx.fillStyle = '#FFDB00'
    this.ctx.fill()
    this.ctx.closePath()
  }

  ProgressbarRed () {
    this.Progressbar()
    this.ctx.fillStyle = 'red'
    this.ctx.fill()
    this.ctx.closePath()
  }

  Selected () {
    const lengthStroke = 10
    this.ctx.beginPath()
    // left top corner
    this.ctx.moveTo((this.x - this.offsetX), (this.y - (this.offsetY) + lengthStroke))
    this.ctx.lineTo((this.x - this.offsetX), (this.y - (this.offsetY)))
    this.ctx.lineTo((this.x - this.offsetX + lengthStroke), (this.y - this.offsetY))
    // right top corner
    this.ctx.moveTo((this.x - this.offsetX + this.width - lengthStroke), (this.y - this.offsetY))
    this.ctx.lineTo((this.x - this.offsetX + this.width), (this.y - this.offsetY))
    this.ctx.lineTo((this.x - this.offsetX + this.width), (this.y - this.offsetY + lengthStroke))
    // right bottom corner
    this.ctx.moveTo((this.x - this.offsetX + this.width), (this.y - this.offsetY + this.height - lengthStroke))
    this.ctx.lineTo((this.x - this.offsetX + this.width), (this.y - this.offsetY + this.height))
    this.ctx.lineTo((this.x - this.offsetX + this.width - lengthStroke), (this.y - this.offsetY + this.height))
    // left bottom corner
    this.ctx.moveTo((this.x - this.offsetX + lengthStroke), (this.y - this.offsetY + this.height))
    this.ctx.lineTo((this.x - this.offsetX), (this.y - this.offsetY + this.height))
    this.ctx.lineTo((this.x - this.offsetX), (this.y - this.offsetY + this.height - lengthStroke))
    if (this.isBlocked) {
      this.ctx.strokeStyle = defaultBlockedColor
    } else {
      this.ctx.strokeStyle = defaultSelectedColor
    }
    this.ctx.lineWidth = 1.5
    this.ctx.stroke()
    this.ctx.closePath()
  }

  Scanning () {
    this.timer += 1
    var lengthStroke = 20
    this.ctx.beginPath()
    this.ctx.moveTo((this.x - this.offsetX), (this.y - (this.offsetY) + lengthStroke))
    this.ctx.lineTo((this.x - this.offsetX), (this.y - (this.offsetY)))
    this.ctx.lineTo((this.x - this.offsetX + lengthStroke), (this.y - this.offsetY))
    this.ctx.moveTo((this.x - this.offsetX + this.width - lengthStroke), (this.y - this.offsetY))
    this.ctx.lineTo((this.x - this.offsetX + this.width), (this.y - this.offsetY))
    this.ctx.lineTo((this.x - this.offsetX + this.width), (this.y - this.offsetY + lengthStroke))
    this.ctx.moveTo((this.x - this.offsetX + this.width), (this.y - this.offsetY + this.height - lengthStroke))
    this.ctx.lineTo((this.x - this.offsetX + this.width), (this.y - this.offsetY + this.height))
    this.ctx.lineTo((this.x - this.offsetX + this.width - lengthStroke), (this.y - this.offsetY + this.height))
    this.ctx.moveTo((this.x - this.offsetX + lengthStroke), (this.y - this.offsetY + this.height))
    this.ctx.lineTo((this.x - this.offsetX), (this.y - this.offsetY + this.height))
    this.ctx.lineTo((this.x - this.offsetX), (this.y - this.offsetY + this.height - lengthStroke))
    this.ctx.strokeStyle = 'white'
    this.ctx.lineWidth = 1
    this.ctx.stroke()
    this.ctx.closePath()
    this.ctx.beginPath()
    var padding = 5
    this.ctx.rect(this.x - this.offsetX + padding, this.y - this.offsetY + padding, this.width - padding * 2, this.height - padding * 2)
    this.ctx.fillStyle = 'rgba(255, 255, 255, 0.1)'
    this.ctx.fill()
    this.ctx.closePath()
    this.ctx.beginPath()
    if (this.timer > (this.height - padding * 2)) {
      this.ctx.moveTo((this.x - this.offsetX + padding), (this.y - this.offsetY + padding + (this.timer % (this.height - padding * 2))))
      this.ctx.lineTo((this.x - this.offsetX + this.width - padding), (this.y - this.offsetY + padding + (this.timer % (this.height - padding * 2))))
    } else {
      this.ctx.moveTo((this.x - this.offsetX + padding), (this.y - this.offsetY + padding + this.timer))
      this.ctx.lineTo((this.x - this.offsetX + this.width - padding), (this.y - this.offsetY + padding + this.timer))
    }
    this.ctx.strokeStyle = 'white'
    this.ctx.lineWidth = 3
    this.ctx.stroke()
    this.ctx.closePath()
  }

  NetworkScanning () {
    this.Scanning()
  }

  VulnerabilitiesScanning () {
    this.timer += 1
    var lengthStroke = 10
    this.ctx.beginPath()
    this.ctx.moveTo((this.x - this.offsetX), (this.y - (this.offsetY) + lengthStroke))
    this.ctx.lineTo((this.x - this.offsetX), (this.y - (this.offsetY)))
    this.ctx.lineTo((this.x - this.offsetX + lengthStroke), (this.y - this.offsetY))
    this.ctx.moveTo((this.x - this.offsetX + this.width - lengthStroke), (this.y - this.offsetY))
    this.ctx.lineTo((this.x - this.offsetX + this.width), (this.y - this.offsetY))
    this.ctx.lineTo((this.x - this.offsetX + this.width), (this.y - this.offsetY + lengthStroke))
    this.ctx.moveTo((this.x - this.offsetX + this.width), (this.y - this.offsetY + this.height - lengthStroke))
    this.ctx.lineTo((this.x - this.offsetX + this.width), (this.y - this.offsetY + this.height))
    this.ctx.lineTo((this.x - this.offsetX + this.width - lengthStroke), (this.y - this.offsetY + this.height))
    this.ctx.moveTo((this.x - this.offsetX + lengthStroke), (this.y - this.offsetY + this.height))
    this.ctx.lineTo((this.x - this.offsetX), (this.y - this.offsetY + this.height))
    this.ctx.lineTo((this.x - this.offsetX), (this.y - this.offsetY + this.height - lengthStroke))
    this.ctx.strokeStyle = 'red'
    this.ctx.lineWidth = 1
    this.ctx.stroke()
    this.ctx.closePath()
    this.ctx.beginPath()
    var padding = 1
    this.ctx.rect(this.x - this.offsetX + padding, this.y - this.offsetY + padding, this.width - padding * 2, this.height - padding * 2)
    this.ctx.fillStyle = 'rgba(255, 0, 0, 0.2)'
    this.ctx.fill()
    this.ctx.closePath()
    this.ctx.beginPath()
    if (this.timer > (this.height - padding * 2)) {
      this.ctx.moveTo((this.x - this.offsetX + padding), (this.y - this.offsetY + padding + (this.timer % (this.height - padding * 2))))
      this.ctx.lineTo((this.x - this.offsetX + this.width - padding), (this.y - this.offsetY + padding + (this.timer % (this.height - padding * 2))))
    } else {
      this.ctx.moveTo((this.x - this.offsetX + padding), (this.y - this.offsetY + padding + this.timer))
      this.ctx.lineTo((this.x - this.offsetX + this.width - padding), (this.y - this.offsetY + padding + this.timer))
    }
    this.ctx.strokeStyle = 'red'
    this.ctx.lineWidth = 2
    this.ctx.stroke()
    this.ctx.closePath()
  }

  async Preload () {
    const imagesUrls = [this.stateOffUrl, this.stateOnUrl, this.stateWarningUrl, this.stateCriticalUrl]
    imagesUrls.forEach((url, i) => {
      if (!url) return
      const img = new Image()
      img.src = url
      let name = ''
      if (i === 0) name = 'stateOff'
      if (i === 1) name = 'stateOn'
      if (i === 2) name = 'stateWarning'
      if (i === 3) name = 'stateCritical'
      this.imgs.push({
        name,
        img
      })
    })
  }

  StateOff () {
    this.imgs.forEach(state => {
      if (state.name === 'stateOff') {
        this.img = state.img
      }
    })
  }

  StateOn () {
    this.imgs.forEach(state => {
      if (state.name === 'stateOn') {
        this.img = state.img
      }
    })
  }

  StateWarning () {
    this.imgs.forEach(state => {
      if (state.name === 'stateWarning') {
        this.img = state.img
      }
    })
  }

  StateCritical () {
    this.imgs.forEach(state => {
      if (state.name === 'stateCritical') {
        this.img = state.img
      }
    })
  }
}
