import errors from './errors.json'
import { i18n } from '@/i18n-setup'

export default class Errors {
  static init () {
    this.errors = errors
  }

  static getErrorText (error, language = i18n.locale) {
    if (!this.errors[language]) {
      return error.message
    }

    return this.errors[language][error.error] || error.message
  }
}
