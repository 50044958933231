import MapHelper from '@/helpers/map.helper.js'
import { tools } from './tools'
import store from '@/store'
import Scene from './scene'
import Objects from './objects'
import fileDownload from 'js-file-download'

export class Player {
  constructor () {
    this.darkSvgImage = new Image()
    this.darkSvgImage.src = require('@/assets/images/background-pattern-1.svg')
    this.lightSvgImage = new Image()
    this.lightSvgImage.src = require('@/assets/images/background-pattern-2.svg')
    this.img = this.darkSvgImage
    this.translateX = 0
    this.translateY = 0
    this._translateX = 0
    this._translateY = 0
    this.cursorZoomTranlateX = 0
    this.cursorZoomTranlateY = 0
    this.essence = []
    this.lines = []
    this.icons = []
    this.planes = []
    this.textFields = []
    this.scene = null
    this.selectRectangle = null
    this.zoom = 1
    this.cvs = null
    this.ctx = null
    this.playInterval = null
    this.topOffset = 64
    this.offset = 100000
    this.ids = {
      vm: { maxId: 0, ids: [] },
      text: { maxId: 0, ids: [] },
      pl: { maxId: 0, ids: [] }
    }
    this.bottomBorder = 0
    this.appendConfigOffset = 100
    this.screenshotWidth = 1920
    this.screenshotHeight = 1080
    this.screenshotPadding = 3 // in percents
  }

  Scroll () {
    this.cvs.onmousewheel = (e) => {
      const zoomStep = 0.05
      const _x = (e.clientX - this.translateX)
      const _y = (e.clientY - this.translateY - this.topOffset)
      const x = _x / this.zoom
      const y = _y / this.zoom
      let zoomX = x
      let zoomY = y
      if (e.wheelDelta > 0 && this.zoom <= 5 && (e.target.id === 'player' || e.target.className === 'gifEffect')) {
        if (this.zoom >= 0.5) {
          this.img = this.darkSvgImage
        }
        this.ctx.scale(1 + zoomStep, 1 + zoomStep)
        this.zoom = this.zoom * (1 + zoomStep)
      }
      if (e.wheelDelta < 0 && this.zoom >= 0.2 && (e.target.id === 'player' || e.target.className === 'gifEffect')) {
        if (this.zoom <= 0.5) {
          this.img = this.lightSvgImage
        }
        this.ctx.scale(1 - zoomStep, 1 - zoomStep)
        this.zoom = this.zoom * (1 - zoomStep)
      }
      zoomX = _x / this.zoom
      zoomY = _y / this.zoom
      this.scene.zoom = this.zoom
      const offsetX = Math.round(x - zoomX)
      const offsetY = Math.round(y - zoomY)
      this.ctx.translate(-offsetX, -offsetY)
      this.translateX = (this.translateX - (offsetX * this.zoom))
      this.translateY = (this.translateY - (offsetY * this.zoom))
      this.scene.translateX = this.translateX
      this.scene.translateY = this.translateY
    }
  }

  Size (canvasWidth, canvasHeight) {
    this.cvs.width = canvasWidth || window.innerWidth
    this.cvs.height = canvasHeight || window.innerHeight
  }

  Resize () {
    this.Size()
    this.ctx.scale(this.zoom, this.zoom)
    // this.ctx.translate((this.translateX * (-1)), (this.translateY * (-1)))
    this.translateX = 0
    this.translateY = 0
    this.scene.translateX = 0
    this.scene.translateY = 0
    this.scene.width = this.cvs.width
    this.scene.height = this.cvs.height
    this.scene.zoom = this.zoom
  }

  Zoom (scaleRatio) {
    this.ctx.scale(scaleRatio, scaleRatio)
    this.zoom = this.zoom * scaleRatio
    if (this.scene) {
      this.scene.zoom = this.zoom
    }
    this.img = this.zoom <= 0.5 ? this.lightSvgImage : this.darkSvgImage
  }

  Draw () {
    // Fill background
    const pattern = this.ctx.createPattern(this.img, 'repeat')
    this.ctx.fillStyle = pattern
    const scale = this.offset / this.zoom + this.offset
    this.ctx.fillRect(-this.offset, -this.offset, scale, scale)

    // Draw objects
    for (const i in this.planes) {
      this.planes[i].Draw()
    }
    for (const i in this.lines) {
      this.lines[i].Draw()
    }
    for (const i in this.essence) {
      this.essence[i].Draw()
    }
    for (const i in this.textFields) {
      this.textFields[i].Draw()
    }
    if (this.selectRectangle) {
      this.selectRectangle.Draw()
    }
    this.scene.Draw()
  }

  Play (width, height) {
    this.Size(width, height)
    this.scene = new Scene(this.ctx, this.zoom, this.translateX, this.translateY)
    this.playInterval = setInterval(() => {
      this.Draw()
    }, 50)
  }

  Stop () {
    clearInterval(this.playInterval)
  }

  Refresh () {
    this.essence.forEach((item) => {
      if (item.gifEffects.length !== 0) {
        item.gifEffects.forEach((effect) => {
          effect.img.remove()
        })
      }
    })
    this.essence = []
    this.lines = []
    this.icons = []
    this.planes = []
    this.textFields = []
    this.scene = new Scene(this.ctx, this.zoom, this.translateX, this.translateY)
    store.commit('selected', this.scene)
    this.ids = {
      vm: { maxId: 0, ids: [] },
      text: { maxId: 0, ids: [] },
      pl: { maxId: 0, ids: [] }
    }
    this.bottomBorder = 0
  }

  ScreenShot () {
    const cvs = document.createElement('canvas')
    const ctx = cvs.getContext('2d')
    const player = new Player()

    player.ctx = ctx
    player.cvs = cvs
    player.scene = new Scene(ctx, 1, 0, 0)
    cvs.width = this.screenshotWidth
    cvs.height = this.screenshotHeight

    Objects.CloneObjects(this, player)
    player.SetStartAndEndCoordinates(this)

    const sourceWidth = player.endX - player.startX
    const sourceHeight = player.endY - player.startY
    const targetWidth = cvs.width * (100 - 2 * this.screenshotPadding) / 100
    const targetHeight = cvs.height * (100 - 2 * this.screenshotPadding) / 100
    let zoom = Math.min(targetWidth / sourceWidth, targetHeight / sourceHeight)

    if (zoom > 1.5) zoom = 1.5
    ctx.scale(zoom, zoom)

    // translate to (0, 0)
    ctx.translate(-player.startX, -player.startY)
    // move to center
    ctx.translate((cvs.width - sourceWidth * zoom) / zoom / 2, (cvs.height - sourceHeight * zoom) / zoom / 2)

    player.Draw()
    cvs.toBlob(data => fileDownload(data, 'network-map.png'))
  }

  Update () {
    // This variable for eval() function
    var base = store.getters.base // eslint-disable-line
    let hasChanges = false
    for (const i in this.essence) {
      if (this.essence[i].baseEssenceState) {
        this.essence[i].state = this.essence[i].baseEssenceState.state
        this.essence[i].effect = this.essence[i].baseEssenceState.effect
        this.essence[i].icons = this.essence[i].baseEssenceState.icons
      } else {
        this.essence[i].state = 'StateOn'
        this.essence[i].effect = []
        this.essence[i].icons = []
      }
      for (const j in this.essence[i].triggers) {
        try {
          var condition = eval(this.essence[i].triggers[j].condition)
          if (condition) {
            tools.ApplyTrigger(this.essence[i], this.essence[i].triggers[j])
            hasChanges = true
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }
    }
    for (const i in this.lines) {
      if (this.lines[i].baseLineState) {
        const lineBaseStateEffect = this.lines[i].baseLineState.effect
        if (lineBaseStateEffect) {
          this.lines[i].effect = lineBaseStateEffect
        }
        this.lines[i].color = this.lines[i].baseLineState.color
        this.lines[i].lineWidth = this.lines[i].baseLineState.lineWidth
      } else {
        this.lines[i].color = '#e8e8e8'
        this.lines[i].lineWidth = 2
        this.lines[i].effect = []
      }
      for (const j in this.lines[i].triggers) {
        try {
          if (eval(this.lines[i].triggers[j].condition)) {
            tools.ApplyTrigger(this.lines[i], this.lines[i].triggers[j])
            hasChanges = true
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }
    }
    if (this.scene.baseSceneState) {
      this.scene.effect = this.scene.baseSceneState.effect
      this.scene.Notification = this.scene.baseSceneState.Notification
      this.scene.NotificationColor = this.scene.baseSceneState.NotificationColor
    } else {
      this.scene.effect = []
      this.scene.Notification = ''
      this.scene.NotificationColor = 'white'
    }
    for (const i in this.scene.triggers) {
      try {
        if (eval(this.scene.triggers[i].condition)) {
          tools.ApplyTrigger(this.scene, this.scene.triggers[i])
          hasChanges = true
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
    for (const i in this.planes) {
      for (const j in this.planes[i].triggers) {
        try {
          if (eval(this.planes[i].triggers[j].condition)) {
            tools.ApplyTrigger(this.planes[i], this.planes[i].triggers[j])
            hasChanges = true
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }
    }
    for (const i in this.textFields) {
      for (const j in this.textFields[i].triggers) {
        try {
          if (eval(this.textFields[i].triggers[j].condition)) {
            tools.ApplyTrigger(this.textFields[i], this.textFields[i].triggers[j])
            hasChanges = true
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }
    }
    if (!hasChanges) this.resetBase()
  }

  resetBase () {
    this.essence.forEach(essence => {
      essence.state = essence.defaultState.state || essence.state
      essence.effect = essence.defaultState.effect || essence.effect
      essence.icons = essence.defaultState.icons || essence.icons
      essence.progress = essence.defaultState.progress || essence.progress
    })
    this.lines.forEach(line => {
      line.effect = line.defaultState.effect || line.effect
      line.color = line.defaultState.color || line.color
      line.memoColor = line.defaultState.memoColor || line.memoColor
      line.lineWidth = line.defaultState.lineWidth || line.lineWidth
      line.animationDirectionFromLeft = line.defaultState.animationDirectionFromLeft || line.animationDirectionFromLeft
    })
    this.planes.forEach(plane => {
      plane.bgColor = plane.defaultState.bgColor || plane.bgColor
      plane.lineColor = plane.defaultState.lineColor || plane.lineColor
    })
    this.textFields.forEach(text => {
      text.textSize = text.defaultState.textSize || text.textSize
      text.message = text.defaultState.message || text.message
      text.color = text.defaultState.color || text.color
    })
    this.scene.effect = this.scene.defaultState.effect || this.scene.effect
    this.scene.progress = this.scene.defaultState.progress || this.scene.progress
    this.scene.Notification = this.scene.defaultState.Notification || this.scene.Notification
    this.scene.NotificationColor = this.scene.defaultState.NotificationColor || this.scene.NotificationColor
  }

  SetStartAndEndCoordinates (data) {
    const coordinates = MapHelper.setStartAndEndCoordinates(data)
    this.startX = coordinates.startX
    this.startY = coordinates.startY
    this.endX = coordinates.endX
    this.endY = coordinates.endY
  }

  ScaleAndCenter (paddingOffsetX = 100, paddingOffsetY = 100) {
    MapHelper.scaleAndCenterScene(this, paddingOffsetX, paddingOffsetY)
  }

  AddId (type, value) {
    if (this.ids[type].ids.includes(value)) {
      this.ids[type].maxId++
      this.ids[type].ids.push(this.ids[type].maxId)
      return type + this.ids[type].maxId
    } else {
      this.ids[type].ids.push(value)
      if (value > this.ids[type].maxId) {
        this.ids[type].maxId = value
      }
      return type + value
    }
  }
}

export var constructor = new Player()
