const lk = {
  namespaced: true,
  state: {
    tab: 'events'
  },
  getters: {
    tab: state => state.tab
  },
  mutations: {
    setTab (state, tab) {
      state.tab = tab
    }
  }
}

export default lk
