import store from '@/store'
import Scene from './scene'
import Essence from './essence'
import Icon from './icon'
import Line from './line'
import Plane from './plane'
import TextField from './text-field'
import { constructor } from './player'
import MapHelper from '@/helpers/map.helper.js'

export default class MapConfig {
  GetConfig () {
    var configuration = {
      scene: constructor.scene,
      essence: [],
      lines: [],
      planes: [],
      textFields: [],
      baseItems: store.state.baseItems
    }
    constructor.essence.forEach((item) => {
      var essence = {
        x: item.x,
        y: item.y,
        id: item.id,
        name: item.name,
        state: item.state,
        effect: item.effect,
        offsetX: item.offsetX,
        offsetY: item.offsetY,
        centerPointOffsetX: item.centerPointOffsetX,
        centerPointOffsetY: item.centerPointOffsetY,
        triggers: item.triggers,
        label: item.label,
        icons: item.icons,
        isBlocked: item.isBlocked
      }
      configuration.essence.push(essence)
    })
    constructor.lines.forEach((item) => {
      var line = {
        id: item.id,
        label: '',
        color: item.color,
        lineWidth: item.lineWidth,
        effect: item.effect,
        triggers: item.triggers,
        startID: item.startID,
        endID: item.endID,
        startX: item.startX,
        startY: item.startY,
        endX: item.endX,
        endY: item.endY,
        points: item.points,
        graphList: item.graphList,
        isBlocked: item.isBlocked,
        animationDirectionFromLeft: item.animationDirectionFromLeft
      }
      configuration.lines.push(line)
    })
    constructor.planes.forEach((item) => {
      var plane = {
        triggers: item.triggers,
        bgColor: item.bgColor,
        lineColor: item.lineColor,
        width: item.width,
        x: item.x,
        y: item.y,
        a: item.a,
        b: item.b,
        c: item.c,
        d: item.d,
        isBlocked: item.isBlocked
      }
      configuration.planes.push(plane)
    })
    constructor.textFields.forEach((item) => {
      var text = {
        triggers: item.triggers,
        name: item.name,
        id: item.id,
        message: item.message,
        x: item.x,
        y: item.y,
        color: item.color,
        textSize: item.textSize,
        isBlocked: item.isBlocked,
        rotateDegree: item.rotateDegree || 0,
        skewDegree: item.skewDegree || 0
      }
      configuration.textFields.push(text)
    })
    return configuration
  }

  setIcons (item) {
    item.icons = item.icons?.map((icon) => {
      const iconItem = new Icon(icon.name, icon.url)
      iconItem.x = icon.x
      iconItem.y = icon.y
      iconItem.width = 60
      iconItem.height = 60
      iconItem.parentX = icon.parentX
      iconItem.parentY = icon.parentY
      return iconItem
    })
  }

  SetConfig (data) {
    const essences = []
    const lines = []
    const planes = []
    const textFields = []
    data.essence.forEach((item) => {
      const essence = new Essence(item.x, item.y, item.name, constructor.ctx, constructor.cvs, item.id)
      essence.state = item.state
      essence.effect = item.effect || essence.effect
      essence.offsetX = item.offsetX
      essence.offsetY = item.offsetY
      essence.centerPointOffsetX = item.centerPointOffsetX || 0
      essence.centerPointOffsetY = item.centerPointOffsetY || 0
      essence.triggers = item.triggers || essence.triggers
      essence.label = item.label
      essence.isBlocked = item.isBlocked || false
      essence.icons = item.icons
      this.setIcons(essence)
      essence.baseEssenceState = {
        state: item.state,
        effect: item.effect,
        icons: essence.icons
      }
      essence.triggers.forEach((trigger) => this.setIcons(trigger))
      essences.push(essence)
    })
    data.lines.forEach((item) => {
      const startPoint = { id: item.startID, x: item.startX, y: item.startY }
      const endPoint = { id: item.endID, x: item.endX, y: item.endY }
      const line = new Line(startPoint, endPoint, item.id)
      line.ctx = constructor.ctx
      line.label = item.label
      line.color = item.color
      line.lineWidth = item.lineWidth
      line.triggers = item.triggers || line.triggers
      line.effect = item.effect || line.effect
      line.animationDirectionFromLeft = item.animationDirectionFromLeft ?? line.animationDirectionFromLeft
      line.isBlocked = item.isBlocked || false
      line.baseLineState = {
        effect: item.effect,
        color: item.color,
        lineWidth: item.lineWidth
      }
      if (item.points && item.graphList) {
        line.graphList = item.graphList
        line.points = item.points
      } else if (item.point) {
        // если конфиг старый, с одной точкой
        line.points.push(item.point)
        line.graphList.push([2, [0, 1]])
        line.graphList[0][1] = [2]
        line.graphList[1][1] = [2]
      }
      lines.push(line)
    })
    data.planes.forEach((item) => {
      const plane = new Plane(constructor.ctx, item.id)
      plane.triggers = item.triggers || plane.triggers
      plane.bgColor = item.bgColor
      plane.lineColor = item.lineColor
      plane.width = item.width
      plane.isBlocked = item.isBlocked || false
      plane.x = item.x
      plane.y = item.y
      plane.a = item.a
      plane.b = item.b
      plane.c = item.c
      plane.d = item.d
      planes.push(plane)
    })
    data.textFields.forEach((item) => {
      const text = new TextField(constructor.ctx, item.id)
      text.triggers = item.triggers || text.triggers
      text.name = item.name
      text.message = item.message
      text.x = item.x
      text.y = item.y
      text.color = item.color
      text.textSize = item.textSize
      text.isBlocked = item.isBlocked || false
      text.rotateDegree = item.rotateDegree || 0
      text.skewDegree = item.skewDegree || 0
      textFields.push(text)
    })
    const scene = new Scene(constructor.ctx, constructor.zoom, constructor.translateX, constructor.translateY)
    scene.effect = data.scene.effect || scene.effect
    scene.triggers = data.scene.triggers || scene.triggers
    scene.Notification = data.scene.Notification
    scene.NotificationColor = data.scene.NotificationColor
    scene.x = data.scene.x
    scene.y = data.scene.y
    scene.textWidth = data.scene.textWidth
    scene.textHeight = data.scene.textHeight
    scene.baseSceneState = {
      effect: data.scene.effect,
      Notification: data.scene.Notification,
      NotificationColor: data.scene.NotificationColor
    }
    constructor.scene = scene
    constructor.essence = essences
    constructor.lines = lines
    constructor.planes = planes
    constructor.textFields = textFields
  }

  SetDefaultConfig () {
    constructor.defaultConfig = config.GetConfig()
  }

  CalculateBottomBorder () {
    constructor.bottomBorder = MapHelper.setStartAndEndCoordinates(constructor).endY
  }

  checkCorrectConfig (essence) {
    const essenceIDs = essence.map(({ id }) => id)
    const double = []
    essenceIDs.forEach((item, index) => {
      if (index !== essenceIDs.indexOf(item)) double.push(item)
    })
    return double
  }

  LoadConfig () {
    const input = document.getElementById('loadconfig')
    input.onchange = (e) => {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {
        let data = reader.result
        data = JSON.parse(data)
        const double = this.checkCorrectConfig(data.essence)
        store.commit('setDoubleIds', double)
        if (double.length) return

        localStorage.removeItem('savedConfig')
        store.commit('clearBase')
        this.SetConfig(data)
        store.commit('setSelectedAll', {
          essence: constructor.essence,
          lines: constructor.lines,
          planes: constructor.planes,
          textFields: constructor.textFields
        })
        return double
      }
      this.CalculateBottomBorder()
      reader.onerror = function () {
        // eslint-disable-next-line no-console
        console.log(reader.error)
      }
    }
  }

  LoadMoreConfig () {
    const input = document.getElementById('appendConfig')
    const essences = [...constructor.essence]
    const lines = [...constructor.lines]
    const planes = [...constructor.planes]
    const textFields = [...constructor.textFields]
    const dataStore = {
      essence: [],
      lines: [],
      planes: [],
      textFields: [],
    }
    input.onchange = (e) => {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {
        let data = reader.result
        data = JSON.parse(data)
        const double = this.checkCorrectConfig(data.essence)
        store.commit('setDoubleIds', double)
        if (double.length) return

        // map for resolving id matching (line start, end)
        const compareEssencesId = new Map()
        const dataOffset = MapHelper.setStartAndEndCoordinates(data).startY || 0
        const offset = constructor.bottomBorder - dataOffset + constructor.appendConfigOffset
        data.essence.forEach((item) => {
          const essence = new Essence(item.x, item.y + offset, item.name, constructor.ctx, constructor.cvs, item.id)
          compareEssencesId.set(item.id, essence.id)
          essence.state = item.state
          essence.effect = item.effect || essence.effect
          essence.offsetX = item.offsetX
          essence.offsetY = item.offsetY
          essence.centerPointOffsetX = item.centerPointOffsetX || 0
          essence.centerPointOffsetY = item.centerPointOffsetY || 0
          essence.triggers = item.triggers || essence.triggers
          essence.label = item.label
          essence.isBlocked = item.isBlocked
          essence.selected = true
          essence.icons = item.icons
          this.setIcons(essence)
          essence.baseEssenceState = {
            state: item.state,
            effect: item.effect,
            icons: essence.icons
          }
          essence.triggers.forEach((trigger) => this.setIcons(trigger))
          dataStore.essence.push(essence)
          essences.push(essence)
        })
        data.lines.forEach((item) => {
          let endId = item.endID || true
          let startId = item.startID
          if (typeof item.endID === 'string') {
            endId = item.endID.slice(0, 2) === 'vm' ? compareEssencesId.get(item.endID) : item.endID
          }
          if (typeof item.startID === 'string') {
            startId = item.startID.slice(0, 2) === 'vm' ? compareEssencesId.get(item.startID) : item.startID
          }
          const startPoint = { id: startId, x: +item.startX, y: +item.startY + offset }
          const endPoint = { id: endId, x: +item.endX, y: +item.endY + offset }
          const line = new Line(startPoint, endPoint, item.id)
          line.ctx = constructor.ctx
          line.label = item.label
          line.color = item.color
          line.lineWidth = item.lineWidth
          line.triggers = item.triggers || line.triggers
          line.startX = +item.startX
          line.startY = +item.startY + offset
          line.endX = +item.endX
          line.endY = +item.endY + offset
          if (item.points && item.graphList) {
            line.graphList = item.graphList
            line.points = item.points.map(i => { return { y: +i.y + offset, x: +i.x } })
          } else if (item.point) {
            // если конфиг старый, с одной точкой
            item.point.y += this.bottomBorder
            line.points.push(item.point)
            line.graphList.push([2, [0, 1]])
            line.graphList[1][1] = [2]
            line.graphList[0][1] = [2]
          }
          line.animationDirectionFromLeft = item.animationDirectionFromLeft ?? line.animationDirectionFromLeft
          line.effect = item.effect || line.effect
          line.isBlocked = item.isBlocked
          line.selected = true
          line.baseLineState = {
            effect: item.effect,
            color: item.color,
            lineWidth: item.lineWidth
          }
          dataStore.lines.push(line)
          lines.push(line)
        })
        data.planes.forEach((item) => {
          const plane = new Plane(constructor.ctx, item.id)
          plane.triggers = item.triggers || plane.triggers
          plane.bgColor = item.bgColor
          plane.lineColor = item.lineColor
          plane.width = item.width
          plane.x = +item.x
          plane.y = +item.y + offset
          plane.a = { x: +item.a.x, y: +item.a.y + offset }
          plane.b = { x: +item.b.x, y: +item.b.y + offset }
          plane.c = { x: +item.c.x, y: +item.c.y + offset }
          plane.d = { x: +item.d.x, y: +item.d.y + offset }
          plane.isBlocked = item.isBlocked
          plane.selected = true
          dataStore.planes.push(plane)
          planes.push(plane)
        })
        data.textFields.forEach((item) => {
          const text = new TextField(constructor.ctx, item.id)
          text.triggers = item.triggers || text.triggers
          text.name = item.name
          text.message = item.message
          text.x = item.x
          text.y = item.y + offset
          text.color = item.color
          text.textSize = item.textSize
          text.isBlocked = item.isBlocked
          text.selected = true
          text.rotateDegree = item.rotateDegree
          text.skewDegree = item.skewDegree
          dataStore.textFields.push(text)
          textFields.push(text)
        })
      }
      store.commit('clearSelection')
      store.commit('setSelectedAll', dataStore)
      constructor.essence = essences
      constructor.lines = lines
      constructor.planes = planes
      constructor.textFields = textFields
      // move to uploaded essences
      let shift = constructor.bottomBorder
      this.CalculateBottomBorder()
      shift = constructor.bottomBorder - shift
      constructor.ctx.translate(0, -shift)
      constructor.translateY -= shift * constructor.zoom
      constructor.scene.translateY = constructor.translateX
      reader.onerror = function () {
        // eslint-disable-next-line no-console
        console.log(reader.error)
      }
    }
  }

  UploadConfig () {
    const configObject = store.getters.switchBaseObj
      ? constructor.defaultConfig
      : config.GetConfig()
    const text = JSON.stringify(configObject, null, 2)
    var a = document.getElementById('download')
    var file = new Blob([text], { type: 'text/txt' })
    a.href = URL.createObjectURL(file)
    a.download = 'network-map.json'
  }
}

export const config = new MapConfig()
