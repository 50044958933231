import addSelectClickHandler from '@/helpers/scrollingPaginationTable.helper'
import { computed, onMounted, ref, watch } from 'vue'
import store from '@/store'

export const useSavedPaging = () => {
  const paginationPageName = ref(null)
  const options = ref({})

  const currentUserId = computed(() => store.getters['account/currentUserId'])

  watch(() => options.value,
    (newVal, oldVal) => {
      if (!paginationPageName.value) {
        return
      }
      if (newVal.itemsPerPage !== oldVal.itemsPerPage && oldVal.page) {
        newVal.page = Math.ceil((oldVal.page * oldVal.itemsPerPage - oldVal.itemsPerPage + 1) / newVal.itemsPerPage)
      }
      localStorage.setItem(`${currentUserId.value}${paginationPageName.value}`, JSON.stringify(newVal))
    }, { deep: true }
  )

  onMounted(() => {
    if (!paginationPageName.value) {
      return
    }
    const key = `${currentUserId.value}${paginationPageName.value}`
    if (localStorage.getItem(key)) {
      try {
        options.value = JSON.parse(localStorage.getItem(key))
      } catch (e) {
        localStorage.removeItem(key)
      }
    }
    if (document.querySelector('.pageable-data-table')) {
      addSelectClickHandler(document.querySelector('.pageable-data-table'))
    }
  })

  return {
    paginationPageName,
    options
  }
}
