<template>
  <div class="preloader" :class="{ 'dark-background': props.darkBg }" :style="styles" ref="preloader">
    <Icon class="preloader__image" icon-name="cybermir_preloader_hud" width="180" height="180" />
    <div class="preloader-title">{{ props.titleLineOne }}</div>
    <div class="preloader-title">{{ props.titleLineTwo }}</div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

const props = defineProps({
  titleLineOne: String,
  titleLineTwo: String,
  darkBg: {
    type: Boolean,
    default: false
  }
})
const styles = ref({
  top: 0
})
onMounted(() => {
  const toolbar = document.querySelector('.app-bar')
  if (toolbar) {
    styles.value.top = `${toolbar.offsetHeight}px`
  }
})
</script>

<style lang="scss" scoped>
.dark-background {
  background: #000000B3;
}
.preloader-title {
  color: #00F0FFCC;
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  top: 50%;
}
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 999;

  &__image {
    position: relative;
    top: 50%;
    left: 50%;
    width: 180px;
    height: 180px;
    margin-top: -90px;
    margin-left: -90px;
    // background: url('../assets/images/loader.gif') no-repeat 50% 50%;
  }
}
</style>
