var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cbr-expansion-panel-base",class:{
    'small-block': _vm.small
  },style:({ width: _vm.width ? _vm.width : '100%' }),attrs:{"id":_vm.id}},[_c('div',{staticClass:"cbr-expansion-panel-base__header"},[_c('div',{staticClass:"cbr-expansion-panel-base__trigger",class:{
        'cbr-expansion-panel-base__trigger--small': _vm.small
      },on:{"click":_setup.toggleOpen}},[_c('Icon',{staticClass:"cbr-expansion-panel-base__trigger-icon",class:_setup.getTriggerIconClass,attrs:{"icon-name":"uikit/base_expand_arrow","width":18,"height":18}})],1),(_vm.label)?_c('p',{staticClass:"cbr-expansion-panel-base__label",on:{"click":_setup.onLabelClick}},[_vm._v(_vm._s(_vm.label))]):_c('div',{staticClass:"cbr-expansion-panel-base__label",on:{"click":_setup.onLabelClick}},[_vm._t("labelContent",null,null,{ toggleOpen: _setup.toggleOpen })],2),_c('div',{staticClass:"cbr-expansion-panel-base__header-right-slot"},[_vm._t("headerRight")],2)]),_c('v-expand-transition',[(_setup.isOpen)?_c('div',[_c('div',{staticClass:"cbr-expansion-panel-base__content",style:({
          padding: _vm.contentPadding
        })},[_vm._t("default")],2)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }