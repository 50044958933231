export default {
  deleteByIdProp (array, id) {
    return array.filter(item => { return parseInt(item.id) !== parseInt(id) })
  },
  findByIdProp (array, id) {
    return array.filter(item => { return parseInt(item.id) === parseInt(id) })
  },
  findFirstByIdProp (array, id) {
    const found = this.findByIdProp(array, id)
    return found ? found[0] : []
  },
  sortByField (array, sortBy, sortDesc) {
    const result = array.sort((a, b) => {
      return (a[sortBy] || '').toString().toUpperCase() > (b[sortBy] || '').toString().toUpperCase() ? 1 : -1
    })

    return sortDesc ? result : result.reverse()
  },
  sortWithModify (array, modifier, sortDesc) {
    const result = array.sort((a, b) => {
      if (Number.isInteger(modifier(a))) {
        return modifier(a) > modifier(b) ? 1 : -1
      } else {
        return modifier(a).toUpperCase() > modifier(b).toUpperCase() ? 1 : -1
      }
    })

    return sortDesc ? result : result.reverse()
  }
}
