import Vue from 'vue'
import moment from 'moment'
import { i18n } from '@/i18n-setup'

moment.locale(i18n.locale)

const plugin = {
  install () {
    Vue.moment = moment
    Vue.prototype.$moment = moment
  }
}

Vue.use(plugin)

export default { moment }
