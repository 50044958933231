export const eventEn = {
  cf_event_card: {
    label_cf: 'Capture flag',
    crumbs: {
      all_events: 'All events',
      event: 'Event'
    },
    btn: {
      scoreboard: 'Scoreboard',
      join: 'Join',
      prepare: 'Prepare',
      start: 'Start',
      cancel: 'Cancel',
      debrief: 'Debrief',
      finish: 'Finish',
      change: 'Edit',
      card: 'Card',
      arbitration: 'Arbitration',
      publish: 'Publish',
    },
    lbl: {
      pause: 'Pause',
      continue: 'Continue',
      event_status: {
        event_status: 'State:',
        waiting: 'Waiting',
        preparation: 'Preparation',
        starting_soon: 'Starting soon',
        in_progress: 'In progress!',
        error: 'Error',
        debriefing: 'Debriefing',
        canceled: 'Canceled',
      },
      flag: 'Flag:',
      event_creator: 'Created by:',
      event_goals: 'Event\'s goals and objectives',
      date_time: 'Starting date and time',
      teacher: 'Teacher',
    },
    hdr: {
      team: 'Team',
      observes: 'Observes',
      teacher: 'Teacher',
    },
    hdr_column: {
      full_name: 'Full name',
      status: 'Status',
      email: 'E-mail',
      position: 'Position',
      vm: 'VM',
      reboot_vm_hint: 'Reboot VM',
      attack: 'Attack, scores',
      defence: 'Defence, scores',
      total: 'Total score',
      attacker: 'Attacker',
      victim: 'Victim',
      flag: 'Flag set',
      uri: 'Target (URI)',
    },
    chk: {
      credited: 'Credited'
    },
    popup_reboot: {
      hdr: {
        vm: 'Reboot',
      },
      lbl: {
        vm: 'VM'
      },
      txt: {
        question: 'Are you sure you want to reboot this machine?',
      },
      btn: {
        yes: 'Yes',
        no: 'No',
      }
    }
  }
}
