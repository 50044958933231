import httpClient from './http'

const END_POINT = '/resource'
export default {
  loadComp () {
    return httpClient.get(END_POINT + '/components/list')
  },
  loadCompByName (name) {
    return httpClient.get(END_POINT + '/components?name=' + name)
  },
  newComp (comp) {
    const formData = new FormData()
    formData.append('fileOn', comp.stateOn)
    formData.append('fileOff', comp.stateOff)
    formData.append('fileCritical', comp.stateCritical)
    formData.append('fileWarning', comp.stateWarning)
    formData.append('name', comp.name)
    formData.append('centerPointOffsetX', comp.centerPointOffsetX)
    formData.append('centerPointOffsetY', comp.centerPointOffsetY)
    return httpClient.post(END_POINT + '/components', formData)
  },
  loadIcons () {
    return httpClient.get(END_POINT + '/icons/list')
  },
  loadIconByName (name) {
    return httpClient.get(END_POINT + '/icons?name=' + name)
  },
  newIcon (icon) {
    const formData = new FormData()
    formData.append('file', icon.image)
    formData.append('name', icon.name)
    return httpClient.post(END_POINT + '/icons', formData)
  },
  loadGifEffects () {
    return httpClient.get(END_POINT + '/gifs/list')
  },
  loadGifEffectByName (name) {
    return httpClient.get(END_POINT + '/gifs?name=' + name)
  },
  newGifEffect (effect) {
    const formData = new FormData()
    formData.append('file', effect.image)
    formData.append('name', effect.name)
    formData.append('componentName', effect.componentName)
    formData.append('hideComponent', effect.hideComponent)
    formData.append('offsetX', effect.offsetX)
    formData.append('offsetY', effect.offsetY)
    return httpClient.post(END_POINT + '/gifs', formData)
  }
}
