export default {
  task_plus: {
    iconName: 'task/task_plus'
  },
  task_plus_secondary: {
    iconName: 'task/task_plus_secondary'
  },
  task_download: {
    iconName: 'task/task_download'
  },
  task_publish: {
    iconName: 'task/task_publish'
  },
  task_add_plus_small: {
    iconName: 'task/task_add_plus_small'
  },
  task_search: {
    iconName: 'task/task_search'
  },
  task_linked: {
    iconName: 'task/task_linked'
  },
  task_copy: {
    iconName: 'task/task_copy'
  },
  task_panel_arrow: {
    iconName: 'task/task_panel_arrow'
  },
  task_send: {
    iconName: 'task/task_send'
  },
  task_create_task_plus: {
    iconName: 'task/task_create_task_plus'
  },
  task_link: {
    iconName: 'task/task_link'
  },
  task_unlink: {
    iconName: 'task/task_unlink'
  },
  task_link_plus: {
    iconName: 'task/task_link_plus'
  },
  task_send_to_assignee: {
    iconName: 'task/task_send_to_assignee'
  },
  task_mission_install_button: {
    iconName: 'task/task_mission_install_button'
  },
  task_attachment: {
    iconName: 'task/task_attachment'
  },
  task_delete_mission: {
    iconName: 'task/task_delete_mission'
  }
}
