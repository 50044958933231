import roles from '@/helpers/roles.helper'

const getters = {
  isInit: state => state.isInit,
  isLoggedIn: state => state.logged,
  isAdmin: state => state.user.role === roles.ADMIN.key || roles.teacherRoles().includes(state.user.role),
  isSystemAdmin: state => state.user.role === roles.ADMIN.key,
  isUser: state => state.user.role === roles.USER.key,
  isObserver: state => state.user.role === roles.OBSERVER.key,
  isTeacher: state => roles.teacherRoles().includes(state.user.role),
  isAuditor: state => state.user.role === roles.AUDITOR.key,
  userRole: state => state.user.role || '',
  userFullName: state => state.user.lastName + ' ' + state.user.firstName + ' ' + state.user.middleName || '',
  currentUserId: state => state.user.id,
  userOrganization: state => state.user.organization,
  isInternal: state => !!state.user?.organization?.internal,
  user: state => state.user
}

export default getters
