<template>
  <div class="user-console-inner-container" :class="fixed ? 'fill-width' : ''" :style="styles">
    <div class="user-console">
      <div
        v-if="fixed"
        @mousedown="emit('mousedown')"
        @mouseup="emit('mouseup')"
        id="drag"
        class="header d-flex"
      >
        <div
          @mousemove="checkTitle"
          class="d-flex align-center font-weight-bold white--text user-console-title"
        >
          <CbrIcon size="24" class="mr-1 user-console-title-icon" light>mdi-eye</CbrIcon>
          <span v-if="!isTitleTruncated" class="user-console-name" ref="userConsoleTitleTemplateRef">{{
            fullName
          }}</span>
          <CbrHint v-else bottom :description="fullName" noIcon>
            <span class="user-console-name" ref="userConsoleTitleTemplateRef">{{ fullName }}</span>
          </CbrHint>
        </div>
        <div class="control-buttons d-flex">
          <CbrButton
            :icon="isFullscreen ? '$minimize_chat_2' : '$expand_chat_2'"
            transparent
            @click="emit('changeFullscreen')"
          />
          <CbrButton
            v-if="canCloseConsole"
            icon="close"
            transparent
            @click="emit('close')"
          />
        </div>
      </div>
      <div class="console" :class="{fixed: fixed}">
        <Spinner v-if="state.isLoading" class="loader" color="white" />
        <component
          v-if="state.isLoaded && !state.isError"
          :is="currentConsole"
          :token="token"
          :force-http="false"
          :isFullscreen="isFullscreen"
          @error="handleError()"
          @connected="onConnected"
        />
        <div v-if="state.isError" class="d-flex justify-center align-center h-100 flex-column">
          <div>
            <CbrIcon class="console-error-icon">$info</CbrIcon>{{ state.errorText }}
          </div>
          <div>
            <div class="flex">
              <CbrButton @click="refreshTokenAfterError"
                         :text="$t('event_screen_link_refresh')"
              />
            </div>
          </div>
        </div>
        <div
          v-else-if="isReloadingVM"
          v-html="$t('event_screen_lbl_reboot')"
          class="d-flex justify-center text-center h-100 flex-column reloading-vm"
          style="white-space: pre-line;"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import eventService from '@/services/event.service'
import events from '@/helpers/events.helper'
import roles from '@/helpers/roles.helper'
import { i18n } from '@/i18n-setup.js'
import Spinner from 'vue-spinner/src/ClipLoader'
import { useStateLoading } from '@/composables/useStateLoading'
import { useStore } from '@/store'

const GuacClient = defineAsyncComponent(() => import('@/components/map/GuacClient'))
const SpiceConsole = defineAsyncComponent(() => import('@/components/event/console/SpiceConsole'))
const VncConsole = defineAsyncComponent(() => import('@/components/event/console/VncConsole'))

const props = defineProps({
  user: Object,
  event: Object,
  y: Number,
  x: Number,
  fixed: Boolean,
  guacamoleMode: Boolean,
  isReloadingVM: Boolean,
  isFullscreen: {
    type: Boolean,
    default: () => false
  }
})
const emit = defineEmits(['mousedown', 'mouseup', 'reloading-vm', 'close', 'changeFullscreen'])

const store = useStore()
const { state, setStateLoaded, setStateLoading, setStateError } = useStateLoading()

const CONSOLE_TYPE_NOVNC = 'NOVNC'
const CONSOLE_TYPE_SPICE = 'SPICE'
const CONSOLE_TYPE_GUACAMOLE = 'GUACAMOLE'

const mainErrorText = computed(() => {
  return i18n.t('event_screen_lbl_error_console')
})
const consoleType = ref(CONSOLE_TYPE_NOVNC)
const refreshTokenAttempts = ref(0)
const beforeUnloadEventHandler = ref(null)
const token = ref('')
const isTitleTruncated = ref(false)

watch(() => props.user, () => {
  getData()
}, { deep: true })
watch(() => props.isReloadingVM, (value) => {
  if (value) {
    getData()
  }
}, { deep: true })
watch(() => props.guacamoleMode, () => {
  refreshTokenAttempts.value = 0
  getData()
}, { deep: true })

const userRole = computed(() => store.getters['account/userRole'])
const canCloseConsole = computed(() => userRole.value !== roles.USER.key)
const currentConsole = computed(() => {
  return consoleType.value === CONSOLE_TYPE_GUACAMOLE
    ? GuacClient
    : consoleType.value === CONSOLE_TYPE_SPICE
      ? SpiceConsole
      : VncConsole
})
const styles = computed(() => {
  const styles = {}
  if (!props.fixed) {
    styles.height = '100%'
  }
  if (props.isFullscreen) {
    styles.height = `${window.innerHeight}px`
  }
  return styles
})
const fullName = computed(() => props.user.firstName + ' ' + props.user.lastName)

function onConnected () {
  emit('reloading-vm', false)
}
async function getData () {
  setStateLoading()
  if (props.user && props.user.id && props.event.id) {
    try {
      const data = await eventService.getConsoleToken(props.event.id, props.user.id, props.guacamoleMode, props.event.gameType.toLowerCase())
      consoleType.value = data.consoleType
      token.value = data.token
      setStateLoaded()
    } catch (e) {
      refreshToken().catch(() => setStateError(mainErrorText.value))
    }
  } else {
    setStateError('Участник не подключен')
  }
}
function handleCloseTabEvent () {
  beforeUnloadEventHandler.value = window.onbeforeunload
  window.onbeforeunload = () => {
    if (beforeUnloadEventHandler.value) {
      beforeUnloadEventHandler.value()
    }
    return 'Возможно, внесенные изменения не сохранятся.'
  }
}
function refreshTokenAfterError () {
  refreshTokenAttempts.value = 0
  handleError()
}
function refreshToken () {
  if (!events.isPreFinishState(props.event.eventState) || refreshTokenAttempts.value > 4) {
    return Promise.reject(mainErrorText.value)
  }

  refreshTokenAttempts.value++

  return eventService.getConsoleToken(props.event.id, props.user.id, props.guacamoleMode, props.event.gameType.toLowerCase())
    .then(() => getData())
}
function handleError () {
  refreshToken().catch(() => setStateError(mainErrorText.value))
}

const userConsoleTitleTemplateRef = ref()
function checkTitle () {
  const title = userConsoleTitleTemplateRef.value
  isTitleTruncated.value = title.scrollWidth > title.clientWidth
}

onMounted(() => {
  getData()
  handleCloseTabEvent()
})
onBeforeUnmount(() => {
  window.onbeforeunload = beforeUnloadEventHandler.value
})
</script>

<style lang="scss" scoped>

.user-console {
  width: 100%;
  height: 100%;

  .header {
    cursor: grabbing;
    display: flex;
    justify-content: space-between;
    background-color: rgba($StaticLight, 0.15);
    height: 36px;
    width: 100%;
    @include cut-corners(5px, true, 0px, false);
    padding-right: 0;

    .user-console-title {
      width: 100%; // calc(100% - 136px);
    }

    .user-console-name {
      color: $ActionColor;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      width: 100%;
      text-align: start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }
}

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
}

.console {
  height: 100%;
  width: 100%;
  background-color: rgba($PopupDarkBack, 0.8);
  color: $WarningColor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-left: 2px solid rgba($StaticLight, 0.2);
  border-right: 2px solid rgba($StaticLight, 0.2);
  border-bottom: 2px solid rgba($StaticLight, 0.2);
}
.console.fixed {
  height: calc(100% - 36px);
}
.user-console-btn {
  border-left: 2px solid rgba($StaticLight, 0.1);
  border-radius: 0;
  background-color: transparent;
  color: $ActionColor;
  min-width: 36px !important;
}
.user-console-title-icon {
  color: $ActionColor;
}
.console-error-icon {
  ::v-deep path {
    fill: $WarningColor;
    fill-opacity: 1;
  }
}
.reloading-vm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.44px;
  text-align: center;
  color: $StaticLight;
}
</style>
