export default {
  send_msg: {
    iconName: 'chat/send_msg',
    initFill: true
  },
  next_chat: {
    iconName: 'chat/next',
    initFill: true
  },
  chat_user_online: {
    iconName: 'chat/chat_user_online'
  },
  chat_user_offline: {
    iconName: 'chat/chat_user_offline'
  },
  expand_chat: {
    iconName: 'chat/expand_chat'
  },
  expand_chat_2: {
    iconName: 'chat/expand_chat_2'
  },
  has_message: {
    iconName: 'chat/has_message'
  },
  minimize_chat: {
    iconName: 'chat/minimize_chat'
  },
  minimize_chat_2: {
    iconName: 'chat/minimize_chat_2'
  },
  chat_menu: {
    iconName: 'chat/chat_menu'
  },
  chat_menu_fill: {
    iconName: 'chat/chat_menu_fill'
  }
}
