export default {
  toolsMenu: { iconName: 'tools/menu' },
  toolsCursor: { iconName: 'tools/movecursor' },
  toolsLine: { iconName: 'tools/line' },
  toolsConnect: { iconName: 'tools/connect' },
  toolsAddlinepoint: { iconName: 'tools/addlinepoint' },
  toolsAddlinepointGray: { iconName: 'tools/addlinepoint_gray' },
  toolsPlane: { iconName: 'tools/plane' },
  toolsEditplane: { iconName: 'tools/editplane' },
  toolsText: { iconName: 'tools/text' },
  toolsCamera: { iconName: 'tools/camera' },
  toolsIcon: { iconName: 'tools/icon' },
  toolsBaseobj: { iconName: 'tools/baseobj' },
  toolsMenuNewConfig: { iconName: 'tools/menuNewConfig' },
  toolsMenuOpenConfig: { iconName: 'tools/menuOpenConfig' },
  toolsMenuAppendConfig: { iconName: 'tools/menuAppendConfig' },
  toolsMenuSaveConfig: { iconName: 'tools/menuSaveConfig' },
  toolsMenuAddComponent: { iconName: 'tools/menuAddComponent' },
  toolsMenuAddIcon: { iconName: 'tools/menuAddIcon' },
  toolsMenuAddGif: { iconName: 'tools/menuAddGif' },
  toolsLibPlus: { iconName: 'tools/libPlus' },
  toolsLocked: { iconName: 'tools/locked' },
  toolsUnlocked: { iconName: 'tools/unlocked' },
  toolsTypeEssence: { iconName: 'tools/typeEssence' },
  toolsTypeLine: { iconName: 'tools/typeLine' },
  toolsTypePlane: { iconName: 'tools/typePlane' },
  toolsTypeText: { iconName: 'tools/typeText' },
  toolsCoordX: { iconName: 'tools/coordX' },
  toolsCoordY: { iconName: 'tools/coordY' },
  toolsRotateAngle: { iconName: 'tools/rotateAngle' },
  toolsSkewAngle: { iconName: 'tools/skewAngle' },
  toolsCornerLeftBottom: { iconName: 'tools/cornerLeftBottom' },
  toolsCornerLeftTop: { iconName: 'tools/cornerLeftTop' },
  toolsCornerRightBottom: { iconName: 'tools/cornerRightBottom' },
  toolsCornerRightTop: { iconName: 'tools/cornerRightTop' },
  toolsToFront: { iconName: 'tools/toFront' },
  toolsToBack: { iconName: 'tools/toBack' },
  toolsArrowOpened: { iconName: 'tools/arrowOpened' },
  toolsArrowClosed: { iconName: 'tools/arrowClosed' },
}
