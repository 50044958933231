import { defaultTextParams, defaultSelectedColor, defaultBlockedColor } from '@/helpers/map.helper.js'
import store from '@/store'
import { tools } from './tools'
import { constructor } from './player'

export default class TextField {
  constructor (ctx, id) {
    this.defaultState = {}
    this.ctx = ctx
    this.initialData = {}
    this.triggers = []
    this.name = 'text'
    this.id = store.state.map.players.find(i => i.cvs.id === 'player')?.AddId('text', id ? +id.slice(4) : 0)
    this.x = 200
    this.y = 200
    this.message = defaultTextParams.message
    this.color = defaultTextParams.color
    this.textSize = defaultTextParams.textSize
    this.textBaseline = 'top'
    this.yPadding = 2
    this.xPadding = 3
    this.width = this.ctx.measureText(this.message).width + 2 * this.xPadding
    this.height = parseInt(this.textSize) + 2 * this.yPadding
    this.isBlocked = false
    this.rotateDegree = 0
    this.skewDegree = 0
  }

  get leftTopCorner () {
    return { x: this.x - this.xPadding, y: this.y }
  }

  DegreesToRadians (deg) {
    return (Math.PI * deg) / 180
  }

  Draw () {
    const rotateRadians = this.DegreesToRadians(this.rotateDegree)
    const skewRadians = this.DegreesToRadians(this.skewDegree)
    let x = Math.cos(rotateRadians) * (this.x - this.xPadding) + Math.sin(rotateRadians) * (this.y)
    let y = Math.cos(rotateRadians) * (this.y) - Math.sin(rotateRadians) * (this.x - this.xPadding)
    this.ctx.beginPath()
    this.ctx.rotate(rotateRadians)
    this.ctx.fillStyle = this.color
    this.ctx.font = this.textSize + 'px Rubik'
    this.ctx.textAlign = 'left'
    this.ctx.textBaseline = this.textBaseline
    // наклон текста
    this.ctx.transform(1, 0, -Math.tan(skewRadians), 1, 0, 0)
    // применяем уравнение прямой с противоположным наклоном
    this.ctx.fillText(this.message, x - (y + 2 * this.yPadding) * -Math.tan(skewRadians), (y + 2 * this.yPadding))
    this.ctx.transform(1, 0, Math.tan(skewRadians), 1, 0, 0)
    this.width = this.ctx.measureText(this.message).width + 2 * this.xPadding
    this.height = parseInt(this.textSize) + 2 * this.yPadding
    if (this.selected) {
      this.Selected(x, y)
    }
    this.ctx.rotate(-rotateRadians)
    this.ctx.closePath()
  }

  Selected (x, y) {
    const lengthStroke = 5 + this.textSize / 10
    x -= this.xPadding
    // left top corner
    this.ctx.moveTo(x, y + lengthStroke)
    this.ctx.lineTo(x, y)
    this.ctx.lineTo(x + lengthStroke, y)
    // right top corner
    this.ctx.moveTo(x + this.width - lengthStroke, y)
    this.ctx.lineTo(x + this.width, y)
    this.ctx.lineTo(x + this.width, y + lengthStroke)
    // right bottom corner
    this.ctx.moveTo(x + this.width, y + this.height - lengthStroke + 2 * this.yPadding)
    this.ctx.lineTo(x + this.width, y + this.height + 2 * this.yPadding)
    this.ctx.lineTo(x + this.width - lengthStroke, y + this.height + 2 * this.yPadding)
    // left bottom corner
    this.ctx.moveTo(x + lengthStroke, y + this.height + 2 * this.yPadding)
    this.ctx.lineTo(x, y + this.height + 2 * this.yPadding)
    this.ctx.lineTo(x, y + this.height - lengthStroke + 2 * this.yPadding)
    if (this.isBlocked) {
      this.ctx.strokeStyle = defaultBlockedColor
    } else {
      this.ctx.strokeStyle = defaultSelectedColor
    }
    this.ctx.lineWidth = 1.5
    this.ctx.stroke()
  }

  Place (e) {
    this.x = Math.round((e.pageX + (constructor.translateX * (-1))) / constructor.zoom)
    this.y = Math.round((e.pageY - constructor.topOffset + (constructor.translateY * (-1))) / constructor.zoom)
  }

  addTextHandler () {
    window.addEventListener('mousemove', this.mouseMoveHandler)
    window.addEventListener('mousedown', this.mouseDownHandler)
    window.addEventListener('mouseup', this.mouseUpHandler)
  }

  mouseMoveHandler = (e) => {
    this.Place(e)
  }

  mouseDownHandler = (e) => {
    if (e.target.id === 'player') this.newTextField = true
  }

  mouseUpHandler = (e) => {
    if (e.target.id === 'player' && this.newTextField) {
      tools.clearSelectionObjects()
      this.selected = true
      store.commit('addToSelection', { item: this, type: 'textFields' })
      store.commit('selected', this)
      this.removeTextHandler()
      tools.CreateText(e)
    } else if (e.composedPath().find((el) => ['components-panel', 'tools-panel'].includes(el.id))) {
      tools.clearSelectionObjects()
      store.commit('selected', constructor.scene)
      constructor.textFields.pop().removeTextHandler()
      tools.Placement()
    }
    this.newTextField = false
  }

  removeTextHandler () {
    window.removeEventListener('mousemove', this.mouseMoveHandler)
    window.removeEventListener('mousedown', this.mouseDownHandler)
    window.removeEventListener('mouseup', this.mouseUpHandler)
  }
}
