const state = {
  version: '',
  btnPause: false,
  currentEvent: null,
  currentMission: null,
  beforeunload: null,
  refreshMissionTabs: null,
  editingMission: null,
  bus: {
    userProfileShow: null,
    showCreateEditUserForm: null,
    showMaterials: null,
    showNetworkMap: null,
    showScoreBoard: null,
    showEventLog: null,
    showReport: null,
    showUserDialog: null,
    userUpdate: null,
    // guacamoleMode: null
  },
  scoreBoardDialog: false,
  hasUnpublishedData: false,
  hasBeenMissionPublished: false,
  errorRequestVisible: false,
  errorRequestType: '',
  errorRequestMsg: '',
  dashboardFilters: {
    organizations: [],
    'event-types': [],
    'start-date': '',
    'end-date': ''
  },
  eventTypes: [],
  integrityState: null,
  integrityUpdatedAll: true,
  windowOnunloadHandlers: {},
  isVisibilityChangeHandlerRegistered: false,
  commandScoreboard: null
}
export default state
