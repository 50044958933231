import { i18n } from '@/i18n-setup.js'

const errors = {
  500: {
    key: 500,
    text: i18n.t('event_card_lbl_validation_error')
  },
  NONE: {
    key: 'NONE',
    text: i18n.t('event_screen_lbl_error_server')
  },
  validation: {
    key: 'validation',
    text: i18n.t('event_card_lbl_validation_error')
  }
}

export default errors
