import Vue from 'vue'
import Vuetify from 'vuetify'
import ru from 'vuetify/es5/locale/ru'
import en from 'vuetify/es5/locale/en'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.min.css'
import 'vuetify/dist/vuetify.min.css'
import theme from '@/plugins/vuetify/modules/theme'
import icons from '@/plugins/vuetify/modules/icons/index'
import { i18n } from '@/i18n-setup'

Vue.use(Vuetify)

const opts = {
  lang: {
    locales: { ru, en },
    current: i18n.locale
  },
  theme: {
    themes: { light: theme },
    options: {
      customProperties: true
    }
  },
  icons: icons
}

export default new Vuetify(opts)
