import mtz from 'moment-timezone'
import { i18n } from '@/i18n-setup'
import moment from 'moment'

export default {
  getMoscowZoneTitle () {
    return i18n.t('moscow_zone_title')
  },
  getVladivostokZoneTitle () {
    return i18n.t('vladivostok_zone_title')
  },
  getSortValue (offset) {
    return parseInt(offset.split(':')[0])
  },
  getTimeZonesList: function () {
    const d = new Date()
    const offsetTmz = []
    const existed = []
    mtz.locale(i18n.locale)
    mtz.tz.names().forEach(zone => {
      try {
        const name = new Intl.DateTimeFormat(i18n.locale, {
          timeZone: zone,
          timeZoneName: 'long'
        }).format(d)

        const offset = mtz.tz(zone).format('Z')
        const clearName = name.split(',')[1].trim()
        const abbr = mtz.tz(zone).zoneAbbr()

        if (clearName.indexOf('GMT') === -1 && !existed[clearName] && isNaN(parseInt(abbr))) {
          offsetTmz.push({
            clearName,
            abbr,
            offset,
            value: zone,
            title: `${this.getTitle(clearName, zone)} (GMT${offset})`,
            class: this.getAccentedClass(zone)
          })
          existed[clearName] = {}
        }
      } catch (e) {}
    })

    offsetTmz.sort((a, b) => {
      return this.getSortValue(a.offset) > this.getSortValue(b.offset) ? 1 : -1
    })
    return this.sortTimeZones(offsetTmz)
  },
  sortTimeZones (timeZones) {
    const sorting = {
      [this.getMoscowZoneTitle()]: 2,
      [this.getVladivostokZoneTitle()]: 1
    }
    timeZones.sort((previousItem, nextItem) => {
      const previousItemSortingWeight = sorting[this.getTitle(previousItem.clearName, previousItem.value)]
      const nextItemSortingWeight = sorting[this.getTitle(nextItem.clearName, nextItem.value)]

      const previousItemWeight = previousItemSortingWeight || 0
      const nextItemWeight = nextItemSortingWeight || 0

      return previousItemWeight > nextItemWeight ? -1 : 1
    })

    return timeZones
  },
  momentByUTC (time) {
    return moment(time, 'YYYY-MM-DDThh:mm:ssZ')
  },
  formatTime (time, format = 'DD.MM.YYYY') {
    return this.momentByUTC(time).format(format)
  },
  getTitle (clearName, zone) {
    let title = clearName
    if (zone === 'Europe/Moscow') {
      title = this.getMoscowZoneTitle()
    } else if (zone === 'Pacific/Guam') {
      title = this.getVladivostokZoneTitle()
    }
    return title
  },
  getAccentedClass (zone) {
    return zone === 'Europe/Moscow' || zone === 'Pacific/Guam'
      ? 'accented-list-item' : ''
  }
}
