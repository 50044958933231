<template>
  <div
    class="cbr-expansion-panel-slim"
    :class="getClasses"
    :id="elementId"
    ref="cbrFilesBlockTemplateRef"
    @drop.prevent="onDrop"
    @dragover.prevent="onDragOver"
    @dragleave.prevent="onDragLeave"
    @dragend.prevent="onDragEnd"
    @dragenter.prevent="onDragEnter"
  >
    <div
      class="cbr-expansion-panel-slim__triangle-icon"
      :class="getTriangleIconClass"
      @click="onTriangleIconClick"
    >
      <Icon
        icon-name="expansion_triangle"
        :width="14"
        :height="7"
      />
    </div>
    <div class="cbr-expansion-panel-slim__label-container">
      <h3 class="cbr-expansion-panel-slim__label">{{ props.label }}</h3>
      <Icon
        v-if="props.labelIcon"
        class="cbr-expansion-panel-slim__label-icon"
        :icon-name="props.labelIcon"
        :width="labelIconSize"
        :height="labelIconSize"
        fill="#00FFF0"
      />
      <p
        v-if="props.labelCounter !== null"
        class="cbr-expansion-panel-slim__label-counter"
      >
        {{ props.labelCounter }}
      </p>
    </div>
    <div
      class="cbr-expansion-panel-slim__container"
      :class="getExpansionPanelContainerClass"
    >
      <v-expand-transition>
        <div v-if="isOpened" class="cbr-expansion-panel-slim__content">
          <div class="cbr-files-block">
            <div
              class="cbr-files-block__wrapper"
              :style="getStyles"
            >
              <CbrFilesList
                v-if="files.length"
                :files="props.files"
                :no-delete-button="props.noDeleteButton"
                @download="onFileDownload"
                @delete="onFileDelete"
              />
              <div v-else class="cbr-files-block__no-files">
                <p>{{ props.placeholder }}</p>
              </div>
              <CbrFileInput
                ref="fileInputTemplateRef"
                class="cbr-files-block__input"
                :justify="props.justifyInput"
                :button-text="props.buttonText"
                :showDragArea="false"
                :buttonWidth="btnWidth"
                dragAndDropSpan
                @change="onFilesUpload"
              />
            </div>
          </div>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useFilesDragAndDrop } from '@/composables/useFilesDragAndDrop'

const props = defineProps({
  closed: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  labelIcon: {
    type: String,
    default: null,
  },
  labelIconSize: {
    type: String,
    default: '24'
  },
  labelCounter: {
    type: Number,
    default: null
  },

  backgroundColor: {
    type: String,
    default: 'transparent'
  },

  // FilesList prop
  files: {
    type: Array,
    default: () => []
  },
  // FilesList prop
  noDeleteButton: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: 'Добавьте файл'
  },

  // FilesInput prop
  accept: {
    type: String,
  },
  // FilesInput prop
  multiple: {
    type: Boolean,
    default: () => false
  },
  // FilesInput prop
  btnWidth: {
    type: String,
    default: '278px'
  },
  // FilesInput prop
  buttonText: {
    type: String,
    default: null
  },
  // FilesInput prop
  justifyInput: {
    type: String,
    default: 'center',
    validator: (value) => {
      return ['center', 'start'].includes(value)
    }
  }
})

const isOpened = ref(!props.closed)

function onTriangleIconClick () {
  isOpened.value = !isOpened.value
}

const getTriangleIconClass = computed(() => {
  const baseClass = 'cbr-expansion-panel-slim__triangle-icon'
  return {
    [`${baseClass}--closed`]: !isOpened.value
  }
})
const getExpansionPanelContainerClass = computed(() => {
  const baseClass = 'cbr-expansion-panel-slim__container'
  return {
    [`${baseClass}--closed`]: !isOpened.value
  }
})

const emit = defineEmits(['files-upload', 'file-download', 'file-delete'])

function onFilesUpload (e) {
  emit('files-upload', e)
}

function onFileDownload (fileIndex) {
  emit('file-download', fileIndex)
}

function onFileDelete (fileIndex) {
  emit('file-delete', fileIndex)
}

const getClasses = computed(() => {
  const baseClass = 'cbr-expansion-panel-slim'

  return {
    [`${baseClass}--dragging-over`]: isDraggingOver.value,
    [`${baseClass}--closed`]: !isOpened.value
  }
})
const getStyles = computed(() => {
  return {
    backgroundColor: props.backgroundColor
  }
})

const {
  elementId,
  cbrFilesBlockTemplateRef,
  fileInputTemplateRef,
  isDraggingOver,
  onDragEnter,
  onDragLeave,
  onDragEnd,
  onDrop,
  onDragOver
} = useFilesDragAndDrop()
</script>

<style scoped lang="scss">
.cbr-expansion-panel-slim {
  position: relative;
  width: 100%;
  overflow: visible;

  &--dragging-over:not(.cbr-expansion-panel-slim--closed) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px dashed var(--ActionColor, #00FFF0);
      background: rgba($PopupDarkBack, 0.70);
      z-index: 2;
      backdrop-filter: blur(12.5px);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/icons/svg/uikit/drop-files-plus.svg");
      background-position: center;
      z-index: 3;
    }

    .cbr-expansion-panel-slim__triangle-icon {
      display: none;
    }

    .cbr-expansion-panel-slim__label-container {
      display: none;
    }
  }

  &__triangle-icon {
    position: absolute;
    top: 0;
    left: -7px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-out;

    &--closed {
      top: -2px;
      rotate: -90deg;
    }
  }

  &__container {
    width: 100%;
    position: relative;
    padding: 0;
  }

  &__container:not(.cbr-expansion-panel-slim__container--closed) {
    @include cut-corners(10px, true, 10px, false, 2px, rgba($ActionColor, .15));
    display: flex;
    padding: 0;
  }

  .cbr-expansion-panel-slim__container--closed {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 8px;
      width: calc(100% - 8px);
      height: 2px;
      background-color: rgba($ActionColor, .15);
    }
  }

  &__label-container {
    position: absolute;
    left: 24px;
    top: -12px;
    background-color: #052844;
    backdrop-filter: blur(12.5px);
    padding: 0 8px;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__label {
    font-size: 1rem;
    font-weight: 400;
    color: $ActionColor;
  }

  &__label-icon {
    margin-left: 8px;
  }

  &__label-counter {
    margin-left: 4px;
    margin-bottom: 0;
    font-size: 1rem;
    color: $ActionColor;
  }

  &__content {
    width: 100%;
    overflow: visible;
    //padding: 12px 10px;
  }

  .cbr-files-block {
    width: 100%;
    padding: 0;

    &:not(&--dragging-over) {
      .cbr-files-block__wrapper {
        padding: 10px;
      }
    }

    &--dragging-over {
      .cbr-files-block__wrapper {
        padding: 12px;
      }
    }

    &__wrapper {
      width: 100%;
      height: 100%;
    }

    &__no-files {
      @include expansion-panel-no-data;
    }

    &__input {
      margin-top: 8px;
    }
  }
}
</style>
