const sse = {
  namespaced: true,
  state: {
    activeConnections: new Set()
  },
  getters: {
    activeConnections: state => state.activeConnections
  },
  mutations: {
    addConnection (state, url) {
      state.activeConnections.add(url)
    },
    removeConnection (state, url) {
      state.activeConnections.delete(url)
    },
    resetState (state) {
      state.activeConnections = new Set()
    }
  }
}

export default sse
