<template>
  <div class="context-menu" id="customMenu">
    <div class="context-menu_wrapper">
      <div class="context-menu_block">
        <button
          @click="copyTriggers"
          :disabled="!copy"
          class="context-menu_item"
          :class="{ 'context-menu_item-disabled': !copy }"
        >
          {{ $t('context_menu_lst_copy_triggers') }}
          <span class="context-menu_item-hotkey">Ctrl + Insert</span>
        </button>
        <button
          @click="pasteTriggers"
          :disabled="!paste"
          class="context-menu_item"
          :class="{ 'context-menu_item-disabled': !paste }"
        >
          {{ $t('context_menu_lst_past_triggers') }}
          <span class="context-menu_item-hotkey">Shift + Insert</span>
        </button>
        <button @click="deleteSelectedObjects" v-show="deleteBtn" class="context-menu_item">
          {{ $t('context_menu_lst_delete') }}
          <span class="context-menu_item-hotkey">Delete</span>
        </button>
        <button
          @click="deletePointOnLine"
          v-show="selectedPoint && !selectedPoint.point.isBlocked && !selectedPoint.line.isBlocked"
          class="context-menu_item"
        >
          {{ $t('context_menu_lst_delete') }} point
        </button>
        <button
          @click="blockPoint"
          v-show="selectedPoint && !selectedPoint.point.isBlocked"
          class="context-menu_item"
        >
          {{ $t('context_menu_lst_block') }} point
        </button>
        <button
          @click="unblockPoint"
          v-show="selectedPoint && selectedPoint.point.isBlocked"
          class="context-menu_item"
        >
          {{ $t('context_menu_lst_unblock') }} point
        </button>
        <button
          @click="blockObject"
          v-show="!selectedPoint"
          :disabled="!block"
          class="context-menu_item"
          :class="{ 'context-menu_item-disabled': !block }"
        >
          {{ $t('context_menu_lst_block') }}
        </button>
        <button
          @click="unBlockObject"
          v-show="!selectedPoint"
          :disabled="!!block"
          class="context-menu_item"
          :class="{ 'context-menu_item-disabled': !!block }"
        >
          {{ $t('context_menu_lst_unblock') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@/store'

const store = useStore()

const tools = computed(() => store.getters.tools)
const copy = computed(() => store.getters.copy)
const selectedPoint = computed(() => store.getters.selectedPoint)
const paste = computed(() => store.getters.paste)
const block = computed(() => store.getters.block)
const deleteBtn = computed(() => store.getters.delBtn)

const blockObject = () => tools.value.BlockObjects()
const unBlockObject = () => tools.value.UnBlockObjects()
const blockPoint = () => selectedPoint.value.point.isBlocked = true
const unblockPoint = () => selectedPoint.value.point.isBlocked = false
const deleteSelectedObjects = () => tools.value.DeleteObjects()
const copyTriggers = () => tools.value.CopyTriggerMenu()
const pasteTriggers = () => tools.value.PasteTriggerMenu()
const deletePointOnLine = () => tools.value.DeletePoint(selectedPoint.value)
</script>

<style lang="scss" scoped>
.context-menu {
  position: absolute;
  top: 48px;
  left: 0px;
  box-shadow: 0px 4px 4px rgba($base-black, 0.25);
  &_wrapper {
    width: 346px;
    background: $base-color-dark;
    @include cut-corners(0px, false, 11px, false);
    place-content: normal;
    padding: 0;
  }
  &_block {
    background: rgba($base-color, 0.05);
    @include cut-corners(0px, false, 11px, false, 1px, rgba($base-color, 0.25));
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &_item {
    margin: 0;
    font-size: 16px;
    background: none;
    border-radius: 0;
    color: $base-color;
    padding: 0 16px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    &-hotkey {
      color: rgba($base-color-light, 0.5);
    }
    &-disabled {
      color: rgba($base-color-light, 0.5);
    }
    &:hover {
      background: rgba($base-color, 0.08);
    }
  }
}
</style>
