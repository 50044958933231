<template>
  <CbrDialog
    v-model="dialog"
    :id="id"
    :title="title"
    :title-id="titleId"
    :first-line-text="firstLineText"
    :second-line-text="secondLineText"
    :warning-text="warningText"
    :max-width="maxWidth"
  >
    <template slot="footer">
      <CbrButton
        :text="yesText"
        :id="yesBtnId"
        @click="success"
        error
      />
      <CbrButton
        :text="noText"
        :id="noBtnId"
        @click="close"
      />
    </template>
  </CbrDialog>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  value: {
    type: Boolean,
    default: () => false
  },
  title: {
    type: String
  },
  firstLineText: {
    type: String
  },
  secondLineText: {
    type: String
  },
  warningText: {
    type: String
  },
  maxWidth: {
    type: String
  },
  yesText: {
    type: String,
    default: () => 'Да'
  },
  noText: {
    type: String,
    default: () => 'Нет'
  },
  id: {
    type: String,
    default: ''
  },
  titleId: {
    type: String,
    default: ''
  },
  yesBtnId: {
    type: String,
    default: ''
  },
  noBtnId: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['input', 'cancel', 'success'])

const dialog = computed({
  get () {
    return props.value
  },
  set (val) {
    if (!val) emit('input', false)
  }
})

const close = () => {
  dialog.value = false
  emit('cancel')
}
const success = () => {
  dialog.value = false
  emit('success', true)
}

</script>
