const getters = {
  icons: state => state.icons,
  components: state => state.components,
  selected: state => state.selected,
  states: state => state.states,
  statesName: state => state.statesName,
  effects: state => state.effects,
  effectsName: state => state.effectsName,
  gifEffects: state => state.gifEffects,
  gifEffectsData: state => state.gifEffectsData,
  tools: state => state.tools,
  lineEffects: state => state.lineEffects,
  lineEffectsName: state => state.lineEffectsName,
  base: state => state.base,
  baseItems: state => state.baseItems,
  sceneEffects: state => state.sceneEffects,
  sceneEffectsName: state => state.sceneEffectsName,
  selectedAll: state => state.selectedAll,
  countOfSelected: ({ selectedAll }) => {
    return selectedAll.essence.length + selectedAll.lines.length + selectedAll.planes.length + selectedAll.textFields.length
  },
  showContextMenu: state => state.showContextMenu,
  contextMenuItems: state => state.contextMenuItems,
  copiesTriggers: state => state.copiesTriggers,
  copy: state => state.copy,
  paste: state => state.paste,
  selectedPoint: state => state.selectedPoint,
  copyObjects: state => state.copyObjects,
  clickCoords: state => state.clickCoords,
  elemTarget: state => state.elemTarget,
  block: state => state.block,
  delBtn: state => state.delBtn,
  defaultStateItems: state => state.defaultStateItems,
  playerGame: state => state.playerGame,
  switchBaseObj: state => state.switchBaseObj,
  switchToJson: state => state.switchToJson,
  doubleIds: state => state.doubleIds,
  triggersDialogShown: state => state.triggers.dialogShown,
  selectBase: state => state.selectBase,
}
export default getters
