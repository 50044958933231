export const authRu = {
  change_exp_pwd: {
    lbl: {
      expired_1: {
        span_1: 'Истек срок действия пароля ',
        span_2: '',
      },
      expired_2: 'Для продолжения работы измените пароль!',
      reqs_line1: {
        span_1: 'Длина не менее 10 ',
        span_2: 'символов'
      },
      reqs_line2: 'Пароль не повторяется',
      reqs_line3: {
        span_1: 'Содержит ',
        span_2: 'строчные и прописные ',
        span_3: 'латинские буквы'
      },
      reqs_line4: {
        span_1: 'Содержит ',
        span_2: 'арабские цифры'
      },
      reqs_line5: {
        span_1: 'Содержит как минимум один специальный символ:',
        span_2: '! $ & ’ " ` | ( ) < > [ ] { } + - / , : ; = ? @ _ ~'
      },
      error_required: 'Обязательное поле',
      error_wrong_pwd: 'Неверный пароль!',
      error_weak_pwd: 'Небезопасный пароль!',
      error_retry_pwd: 'Пароли не совпадают',
      error_same_pwd: 'Новый пароль должен отличаться от старого',
    },
    hdr: {
      reqs: 'Требования к безопасности пароля'
    },
    edit: {
      old_pwd_plh: 'Введите старый пароль',
      new_pwd_plh: 'Введите новый пароль',
      retry_pwd_plh: 'Повторите новый пароль'
    },
    btn: {
      confirm: 'Изменить пароль',
      back: 'Назад',
    }
  },

  user_authorization: {
    lbl: {
      rostelecom: 'Разработано в Ростелеком'
    }
  }
}
