import { render, staticRenderFns } from "./ColorPicker.vue?vue&type=template&id=feb4b564&scoped=true"
import script from "./ColorPicker.vue?vue&type=script&setup=true&lang=js"
export * from "./ColorPicker.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ColorPicker.vue?vue&type=style&index=0&id=feb4b564&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "feb4b564",
  null
  
)

export default component.exports