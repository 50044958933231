import { render, staticRenderFns } from "./AvatarPasswordEdit.vue?vue&type=template&id=616fcca4&scoped=true"
import script from "./AvatarPasswordEdit.vue?vue&type=script&setup=true&lang=js"
export * from "./AvatarPasswordEdit.vue?vue&type=script&setup=true&lang=js"
import style0 from "./AvatarPasswordEdit.vue?vue&type=style&index=0&id=616fcca4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "616fcca4",
  null
  
)

export default component.exports