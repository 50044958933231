const mutations = {
  init (state) {
    state.isInit = true
  },
  login (state, data) {
    state.user = data
    state.logged = 1
  },
  logout (state) {
    state.user = {}
    state.logged = 0
  },
  setUserData (state, userData) {
    for (const item in userData) {
      state.user[item] = userData[item]
    }
  },
}

export default mutations
