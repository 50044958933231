const getters = {
  version: state => state.version,
  btnPause: state => state.btnPause,
  currentEvent: state => state.currentEvent,
  currentMission: state => state.currentMission,
  beforeunload: state => state.beforeunload,
  scoreBoardDialog: state => state.scoreBoardDialog,
  hasUnpublishedData: state => state.hasUnpublishedData,
  errorRequestVisible: state => state.errorRequestVisible,
  errorRequestType: state => state.errorRequestType,
  errorRequestMsg: state => state.errorRequestMsg,
  refreshMissionTabs: state => state.refreshMissionTabs,
  userProfileShow: state => state.bus.userProfileShow,
  showCreateEditUserForm: state => state.bus.showCreateEditUserForm,
  showMaterials: state => state.bus.showMaterials,
  showNetworkMap: state => state.bus.showNetworkMap,
  showScoreBoard: state => state.bus.showScoreBoard,
  showEventLog: state => state.bus.showEventLog,
  showReport: state => state.bus.showReport,
  showUserDialog: state => state.bus.showUserDialog,
  userUpdate: state => state.bus.userUpdate,
  // guacamoleMode: state => state.bus.guacamoleMode,
  dashboardFilters: state => state.dashboardFilters,
  eventTypes: state => state.eventTypes,
  integrityState: state => state.integrityState,
  integrityUpdatedAll: state => state.integrityUpdatedAll,
  windowOnunloadHandlers: state => state.windowOnunloadHandlers,
  isVisibilityChangeHandlerRegistered: state => state.isVisibilityChangeHandlerRegistered,
  commandScoreboard: state => state.commandScoreboard,
  editingMission: state => state.editingMission
}
export default getters
